import React from 'react';

import { REVIEW_QUESTION_TYPES_V1 } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Button, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { StyledRickTextView } from '~/components/SurveyQuestionComments/design';
import { UserAvatar } from '~/components/UserAvatar';
import {
  Arrow,
  CommentWrapper,
  EditButton,
  EditButtonWrapper,
  Label,
  Name,
} from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/Questions/SkillAnswersV1/design';
import { RoleName } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/Questions/SkillAnswersV1/RoleName';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

import type { IUser, IReviewRating, IUserReviewQuestion } from '@learned/types';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;

  ${Arrow} {
    border-bottom: 8px solid ${COLORS.BG_LIST};
  }

  :hover {
    ${Arrow} {
      border-bottom: 8px solid ${COLORS.HOVER};
    }
  }

  .comment {
    border-radius: 1px;
    background-color: ${COLORS.BG_LIST};
    padding: 15px 16px 15px 17px;

    :hover {
      background-color: ${COLORS.HOVER};

      div {
        background-color: ${COLORS.HOVER};

        p {
          color: ${COLORS.TEXT_HOVER};
        }
      }
    }
  }
`;

export const Answer = styled.div`
  padding-top: 10px;
  font-size: 14px;
  background-color: ${COLORS.BG_LIST};
  color: ${COLORS.TEXT_MAIN};
  display: flex;
  gap: 7px;
  flex-direction: column;

  p {
    color: ${COLORS.TEXT_MAIN};
  }
`;

interface ICommentProps {
  value: number;
  valueLabel: string;
  valueMax: number;
  rating: IReviewRating;
  onEdit: () => void;
  showEditButton: boolean;
  showSkillCoverage?: boolean;
}

export const Comment = ({
  value,
  valueLabel,
  valueMax,
  rating,
  onEdit,
  showEditButton,
  showSkillCoverage,
}: ICommentProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  const finalValue = (value / valueMax) * 100;

  return (
    <Wrapper key={rating.id}>
      <Name>
        {rating.createdBy.id ? (
          <UserAvatar userId={rating.createdBy.id as string} />
        ) : (
          <UserAvatar
            user={
              {
                firstName: '',
                lastName: '',
                email: rating.createdBy.email as string,
              } as IUser
            }
          />
        )}{' '}
        <span className="role">
          (<RoleName ratingType={rating.type} />)
        </span>
      </Name>
      <EditButtonWrapper>
        <Arrow />
        <Answer className="comment">
          <Label>
            {finalValue > 0 ? (
              <>
                {![REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1 as IUserReviewQuestion['type']].includes(
                  rating.userReviewQuestionType,
                ) ? (
                  <>
                    {value}/<span className="light">{valueMax}</span> -{' '}
                  </>
                ) : (
                  ''
                )}
              </>
            ) : (
              <>
                <Trans>N/A</Trans>
              </>
            )}
            {valueLabel}{' '}
            {finalValue > 0 && showSkillCoverage && (
              <>
                <span>
                  {finalValue.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1,
                  })}
                </span>
                <Trans>% Skill coverage</Trans>
              </>
            )}
          </Label>
          {rating.comment && (
            <CommentWrapper>
              <StyledRickTextView html={getMultiLangString(rating.comment || '')} />
            </CommentWrapper>
          )}
        </Answer>
        {showEditButton && (
          <EditButton>
            <Button
              variant={ButtonVariant.SECONDARY}
              icon={ICONS.EDIT_PENCIL}
              label={i18n._(t`Edit`)}
              onClick={onEdit}
            />
          </EditButton>
        )}
      </EditButtonWrapper>
    </Wrapper>
  );
};
