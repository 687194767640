import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Container = styled.div<{ marginBottom?: string }>`
  display: flex;
  align-items: center;
  position: relative;
  min-height: 58px;
  box-sizing: border-box;

  padding: 8px 32px 8px 16px;
  border-radius: 6px;
  background-color: ${COLORS.WHITE};

  background: linear-gradient(225deg, rgba(255, 138, 138, 0.09) 0%, rgba(255, 82, 82, 0.2) 100%),
    ${COLORS.WHITE};

  gap: 16px;
  margin-bottom: ${(props) => props.marginBottom || '16px'};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  width: 26px;
  height: 26px;
  min-width: 26px;
  box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);

  background: ${COLORS.ACCENT_ERROR};

  svg {
    color: ${COLORS.WHITE};

    &.rotate {
      transform: rotate(180deg);
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Title = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: ${COLORS.ACCENT_ERROR};
`;

export const Description = styled.span`
  display: flex;
  font-size: 12px;
  font-weight: 400;
  color: ${COLORS.SUBTEXT};
`;
