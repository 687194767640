import { REVIEW_QUESTION_TYPES } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { isEmpty } from 'lodash';

import { isRequiredRatedQuestion, skillWithFocusAreaCheck } from '~/pages/Reviews/utils';

import type { IReviewThemePreview, IThemeValidation } from './types';
import type {
  IJobProfile,
  IReviewQuestionSkillCategory,
  ISkill,
  ISkillCategory,
} from '@learned/types';

export const prepareData = (
  reviewThemes: IReviewThemePreview[],
  jobProfiles: IJobProfile[],
  skills: ISkill[],
  skillCategories: Record<ISkillCategory['id'], ISkillCategory>,
) => {
  const items: IThemeValidation[] = [];

  // return [] if jobs not selected
  if (isEmpty(jobProfiles)) {
    return items;
  }

  // we validate only reviewThemes with weights
  const themesWithWeights = reviewThemes.filter(
    (reviewTheme) => reviewTheme?.themeWeight && reviewTheme?.themeWeight > 0,
  );

  themesWithWeights.forEach((reviewTheme) => {
    const skillCategoryQuestions = reviewTheme.questions.filter((question) =>
      [REVIEW_QUESTION_TYPES.SKILL_CATEGORY].includes(question.type as REVIEW_QUESTION_TYPES),
    );

    // we skip validation for themes that has other types of "rated" questions
    // "rated question":
    // - evaluator - coach
    // - question type - Rating/Custom-skill
    // - scale - default
    const hasThemeOtherRatedQuestions = reviewTheme.questions
      // filter out "skill-category" questions
      .filter(
        (question) =>
          ![REVIEW_QUESTION_TYPES.SKILL_CATEGORY].includes(question.type as REVIEW_QUESTION_TYPES),
      )
      // find "rated" questions
      .some((question) => isRequiredRatedQuestion(question));

    // Skip themes that has other "rated" questions except "skill-category"
    // Add only themes that has skill-category questions
    if (!hasThemeOtherRatedQuestions && !isEmpty(skillCategoryQuestions)) {
      let isThemeValid = false;
      const theme: IThemeValidation = {
        reviewTheme,
        items: skillCategoryQuestions.map((question) => {
          // in JobProfile we are going to check only skills in current skill-category
          const skillCategoryId = (question as IReviewQuestionSkillCategory).settings.skillCategory;

          // we assume to always find skill-category
          const skillCategory = skillCategories[skillCategoryId] as ISkillCategory;

          const skillsInCategory = skills.filter(
            (skill) => skill.skillCategory === skillCategoryId,
          );
          let isSkillCategoryValid = false;

          const jobProfilesInSkillCategory = jobProfiles.map((jobProfile) => {
            const { skillsWithFocusArea, skillsWithoutFocusArea: _skillsWithoutFocusArea } =
              skillWithFocusAreaCheck({
                job: jobProfile,
                skills: skillsInCategory,
              });

            const isJobProfileValid = !isEmpty(skillsWithFocusArea);

            if (isJobProfileValid) {
              isSkillCategoryValid = true;
            }

            // add requirements with validation check
            return {
              jobProfile,
              items: [
                {
                  title: (i18n: I18n) => i18n._(t`Contains skills with focus areas`),
                  isValid: isJobProfileValid,
                },
              ],
            };
          });

          // if min 1 skill-category valid in theme
          // theme also valid then
          if (isSkillCategoryValid) {
            isThemeValid = true;
          }

          return {
            skillCategory,
            items: jobProfilesInSkillCategory,
          };
        }),
      };

      // add theme only if it's not valid
      if (!isThemeValid) {
        items.push(theme);
      }
    }
  });

  return items;
};
