import { useCallback, useEffect, useState } from 'react';

import { ROLES, USER_REVIEW_HRIS_REPORT_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { differenceInMinutes, isPast } from 'date-fns';
import { isEmpty } from 'lodash';
import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';

import { IHrisIntegrationReport, IIntegrationSettings } from '~/constants/hrisIntegrations';
import { getUser } from '~/selectors/baseGetters';
import { getCompanyIntegrationSettings } from '~/services/integrationSettings';
import { getUserReviewReportStatus, sendReportToIntegration } from '~/services/userReviews';

type useIntegrationSettingsProps = {
  userReviewId?: string;
};

const TIMEOUT_REFETCH = 8000; // milliseconds
const GAP_FOR_RETRY = 30; // minutes

const isMoreThanGap = (date: Date): boolean => {
  const difference = differenceInMinutes(new Date(), date);
  return difference > GAP_FOR_RETRY && isPast(date);
};

/*
   Get & Send Reports Details to Kombo Integrations
* */
const useIntegrationSettings = ({ userReviewId }: useIntegrationSettingsProps) => {
  const user = useSelector(getUser);

  const { i18n } = useLingui();

  const [reports, setReports] = useState<IHrisIntegrationReport[]>([]);
  const [integrationSettings, setIntegrationSettings] = useState<IIntegrationSettings[]>([]);
  const [loading, setLoading] = useState(false);

  let intervalId: NodeJS.Timeout | undefined;

  const getReportStatus = (report: Pick<IHrisIntegrationReport, 'status' | 'executionDate'>) => {
    if (
      isMoreThanGap(new Date(report.executionDate || '')) &&
      report.status === USER_REVIEW_HRIS_REPORT_STATUS.IN_PROGRESS
    ) {
      return {
        status: USER_REVIEW_HRIS_REPORT_STATUS.FAILED,
        message: i18n._(t`Something went wrong, please try again`),
      };
    }
    return {};
  };

  // Fetch Only Company Integrations Settings
  const fetchIntegrationsSettings = useCallback(async () => {
    if (!user?.isAdmin) {
      return;
    }

    const response: Record<string, IIntegrationSettings> = await getCompanyIntegrationSettings(
      true,
      { forceRole: ROLES.ADMIN },
    );

    setIntegrationSettings(
      Object.values(response).filter((item) => item.integrationModules.uploadDocuments.enabled),
    );
  }, [user]);

  // Fetch Integrations Reports by UserReview
  const fetchReports = useCallback(async () => {
    if (!userReviewId || !user?.isAdmin || isEmpty(integrationSettings)) {
      return;
    }
    setLoading(true);
    const response = await getUserReviewReportStatus(userReviewId, ROLES.ADMIN);

    const integrationsReports = integrationSettings.map((item) => {
      const reportStatusDetails = response?.data?.userReview
        ? response?.data?.userReview[item.id] || {}
        : {};
      return {
        ...reportStatusDetails,
        id: item.id,
        name: item.name,
        companyId: item.company,
        status: reportStatusDetails.status
          ? reportStatusDetails.status
          : USER_REVIEW_HRIS_REPORT_STATUS.NOT_SENT,
        userReviewId,
        isIntegrationActive: item.komboData?.isActive,
        ...getReportStatus(reportStatusDetails),
      };
    });

    if (!isEqual(reports, integrationsReports)) {
      setReports(integrationsReports);
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationSettings, reports, user?.isAdmin, userReviewId]);

  // first integrations loading
  useEffect(() => {
    fetchIntegrationsSettings();
  }, [fetchIntegrationsSettings]);

  // first reports loading
  useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  // Set interval under only when in progress and the execution time is less than 15 min
  useEffect(() => {
    if (reports.some((item) => item.status === USER_REVIEW_HRIS_REPORT_STATUS.IN_PROGRESS)) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      intervalId = setInterval(() => fetchReports(), TIMEOUT_REFETCH);
    } else {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [reports]);

  // Send Report to HRIS Integration
  const onSendReport = async (integrationSettingsId: string) => {
    if (!user?.isAdmin || !userReviewId) {
      return;
    }
    const reportRes = await sendReportToIntegration(
      userReviewId,
      integrationSettingsId,
      ROLES.ADMIN,
    );

    const updatedReports = reports.map((item) => {
      if (item.id === integrationSettingsId && reportRes?.data?.reportHRIS) {
        return {
          ...item,
          ...reportRes.data.reportHRIS[integrationSettingsId],
        };
      }
      return item;
    });

    setReports(updatedReports);
  };

  return { integrationSettings, reports, onSendReport, loading };
};

export default useIntegrationSettings;
