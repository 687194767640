import React from 'react';
import type { MouseEvent, MouseEventHandler } from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Icon, ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';

import { COLORS } from '~/styles';

interface IFooterProps {
  onDelete?: MouseEventHandler<HTMLButtonElement>;
  onEdit?: MouseEventHandler<HTMLButtonElement>;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  border-top: 1px solid ${COLORS.BORDER};
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--company-color);
  cursor: pointer;
  font-size: 12px;
`;

const Footer = ({ onEdit, onDelete }: IFooterProps) => {
  const { i18n } = useLingui();

  const deleteQuestion = async (e: MouseEvent<HTMLButtonElement>) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Delete question?`),
      description: i18n._(t`Are you sure you want to delete this question from the template?`),
    });

    if (isConfirmed && onDelete) {
      onDelete(e);
    }
  };

  return (
    <Wrapper>
      <Button type="button" onClick={deleteQuestion}>
        <Icon icon={ICONS.SUBTRACT_MINUS} />
        <Trans>Remove from template</Trans>
      </Button>
      <Button type="button" onClick={onEdit}>
        <Icon icon={ICONS.EDIT_PENCIL} />
        <Trans>Edit</Trans>
      </Button>
    </Wrapper>
  );
};

export { Footer };
