import React, { useState } from 'react';

import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { HeaderTabs } from '~/components/HeaderTabs';
import { Icon, ICONS } from '~/components/Icon';

import { AllAnswersWrapper, Answers, TabLabelWrapper } from './design';

import { COLORS } from '~/styles';

interface IAllAnswersProps {
  children: (currentTab: ALL_ANSWERS_TAB) => React.ReactNode;
  isAllAnswersEnabled: boolean;
  isPreviousAnswersEnabled: boolean;
}

export enum ALL_ANSWERS_TAB {
  ALL_ANSWERS = 'All answers',
  PREVIOUS_ANSWERS = 'Previous answers',
}

const AllAnswers: React.FC<IAllAnswersProps> = ({
  children,
  isAllAnswersEnabled = true,
  isPreviousAnswersEnabled = true,
}) => {
  const TABS = [
    ...(isAllAnswersEnabled
      ? [
          {
            key: ALL_ANSWERS_TAB.ALL_ANSWERS,
            label: (i18n: I18n) => i18n._(t`All answers`),
          },
        ]
      : []),
    ...(isPreviousAnswersEnabled
      ? [
          {
            key: ALL_ANSWERS_TAB.PREVIOUS_ANSWERS,

            label: (i18n: I18n) => {
              return (
                <TabLabelWrapper isSelected={currentTab === ALL_ANSWERS_TAB.PREVIOUS_ANSWERS}>
                  <Icon
                    icon={ICONS.HISTORY}
                    size={16}
                    color={
                      currentTab === ALL_ANSWERS_TAB.PREVIOUS_ANSWERS ? COLORS.CAREER : undefined
                    }
                  />
                  {i18n._(t`All previous answers`)}
                </TabLabelWrapper>
              );
            },
          },
        ]
      : []),
  ];

  const [currentTab, setCurrentTab] = useState(TABS[0]?.key);

  if (TABS.length === 0) {
    return <></>;
  }

  return (
    <AllAnswersWrapper>
      <HeaderTabs
        selectedTab={currentTab}
        tabs={TABS}
        handleChangeTab={setCurrentTab as (key: string) => void}
      />
      <Answers>{children(currentTab)}</Answers>
    </AllAnswersWrapper>
  );
};

export default AllAnswers;
