import styled, { css } from 'styled-components';

import { Dropdown } from '~/components/Dropdown';

import { COLORS } from '~/styles';

import type { IReviewTemplate } from '@learned/types';

export const Wrapper = styled.div`
  display: flex;
  align-items: start;
  gap: 150px;
  margin-top: 50px;
`;

export const Form = styled.form`
  border-radius: 10px;
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 24px 32px;
  box-sizing: border-box;

  .tableList {
    padding: 13px 0 0 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  .icon {
    color: ${COLORS.SUBTEXT};
  }
`;

export const Section = styled.div<{
  $marginTop?: string;
  $paddingTop?: string;
  $width?: string;
  $isBorderTop?: boolean;
}>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ $marginTop }) => $marginTop || '0'};
  padding-top: ${({ $paddingTop }) => $paddingTop || '0'};
  width: ${({ $width }) => $width || 'unset'};

  .error-message {
    padding-top: 5px;
  }
`;

export const Title = styled.h2<{ marginBottom?: string }>`
  font-size: 26px;
  color: ${COLORS.TEXT_HOVER};
  font-weight: normal;
  margin-top: 0;

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom};
    `};
`;
export const InputContainer = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || '100%'};
  gap: 10px;
`;

export const Label = styled.label<{
  $paddingBottom?: string;
  error?: boolean;
}>`
  color: ${({ error }) => (error ? COLORS.ACCENT_ERROR : COLORS.TEXT_MAIN)};
  padding-bottom: ${({ $paddingBottom }) => $paddingBottom || '4px'};
  font-size: 14px;
`;

export const Description = styled.label`
  color: ${COLORS.SUBTEXT};
  font-size: 14px;
  display: flex;
  .title {
    padding-left: 18px;
    line-height: 21px;
  }
  padding-bottom: 8px;
`;

export const ParticipantSection = styled.div`
  margin-bottom: 24px;
  & .title {
    border-bottom: 1px solid ${COLORS.BORDERS};
    padding-bottom: 12px;
    margin-bottom: 18px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    color: ${COLORS.TEXT_MAIN};
  }

  & .inputWrapper {
    display: flex;
    flex-direction: column;
  }
`;

export const UserAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${COLORS.BG_LIST};
  border-radius: 6px;
  height: 62px;
  padding: 0 24px;
`;

export const StyledReviewTemplatesDropdown = styled(Dropdown<IReviewTemplate>)`
  .slider-animation {
    width: 100%;
  }

  max-width: unset;
`;

export const SubTitle = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: ${COLORS.TEXT_MAIN};
`;

export const SubSectionWrapper = styled.div`
  margin-top: 13px;
  margin-bottom: 42px;
`;

export const Separator = styled.div<{
  marginTop?: string;
  marginBottom?: string;
}>`
  border-top: solid 1px ${COLORS.BORDERS};
  width: 100%;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '9px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0px')};
`;

export const ToggleContainer = styled.div<{
  marginTop?: string;
  marginBottom?: string;
}>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '30px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 'unset')};
`;

export const ToggleRow = styled.div<{ marginBottom?: string; marginTop?: string }>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => marginBottom || '8px'};
  margin-top: ${({ marginTop }) => marginTop || 'unset'};
  border-radius: 6px;
  background-color: ${COLORS.BG_LIST};
  height: 64px;
  padding-left: 20px;
`;

export const ToggleText = styled.div`
  font-family: Poppins;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${COLORS.TEXT_MAIN};
`;

export const PrivacyDescription = styled.label`
  color: ${COLORS.SUBTEXT};
  font-size: 14px;
  display: flex;
  margin-top: 12px;
`;

export const StepTimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FinalCheckWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
