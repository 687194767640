import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { type UseFormReturn } from 'react-hook-form';

import type { ISectionState } from '~/components/SideBar/SectionStateHook';

import { SubHeader } from './SubHeader';
import { SummaryField } from './SummaryField';

import type { IReviewCycleForm } from '../../../types';

interface ParticipantsSummaryProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewCycleForm>;
}

const ParticipantsSummary = ({ formMethods, sectionState }: ParticipantsSummaryProps) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;

  const employeesCount = watch('employees').length || 0;

  const navigateToSection = () => sectionState.setCurrentSection(1);

  return (
    <>
      <SubHeader title={i18n._(t`Participants`)} onEdit={navigateToSection} />
      <SummaryField
        value={employeesCount.toString()}
        label={i18n._(t`Employees`)}
        marginBottom="22px"
      />
    </>
  );
};

export { ParticipantsSummary };
