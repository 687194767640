import React, { useEffect, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';

import { ButtonSize, ButtonVariant, Button } from '~/components/Buttons';
import Modal from '~/components/Modal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import { Switch } from './components/Switch';
import { UploadDocuments } from './components/UploadDocuments';
import {
  Header,
  Title,
  Subtitle,
  Content,
  Label,
  Footer,
  Actions,
  SettingsHeader,
  HeaderRow,
  HeaderTitle,
  Row,
  RowElement,
  FilterTitle,
  FilterInput,
  ColumnRow,
} from './design';

import { getIntegrationDocumentCategories } from '~/services/integrations';

import { DocumentCategory, IIntegration, IntegrationModule, IntegrationSetting } from '../../types';

const INTEGRATION_SETTINGS: IntegrationSetting[] = [
  { label: 'Import employees', key: 'importUsers' },
  { label: 'Synchronize employees', key: 'syncUsers' },
  { label: 'Clean up employees', key: 'deleteUsers' },
  { label: 'Import jobs', key: 'importRoles' },
  { label: 'Synchronize jobs', key: 'syncRoles' },
  { label: 'Clean up jobs', key: 'deleteRoles' },
  { label: 'Import teams', key: 'importTeams' },
  { label: 'Synchronize teams', key: 'syncTeams' },
  { label: 'Clean up teams', key: 'deleteTeams' },
  { label: 'Send report of archived review to', key: 'uploadDocuments' },
];

type UpdateIntegrationModalProps = {
  onClose: (
    integration: IIntegration,
    integrationSettings: Record<string, IntegrationModule>,
  ) => void;
  onSave: (
    integration: IIntegration,
    integrationSettings: Record<string, IntegrationModule>,
  ) => Promise<void>;
  integration: IIntegration;
};

const UpdateIntegrationModal = ({ onClose, onSave, integration }: UpdateIntegrationModalProps) => {
  const { i18n } = useLingui();
  const [integrationSettings, setIntegrationSettings] = useState(integration.integrationModules);
  const [domainFilter, setDomainFilter] = useState(integration.domainFilter);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState<DocumentCategory[]>([]);

  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateForm = () => {
    let errors = {};
    // TODO: Validate fields, add more if needed
    if (
      integrationSettings.uploadDocuments?.enabled &&
      !isEmpty(categories) &&
      !integrationSettings.uploadDocuments?.documentCategoryId
    ) {
      errors = { ...errors, uploadDocuments: i18n._(t`Please select a category`) };
    }

    setErrors(errors);
    return errors;
  };

  const handleSave = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (isEmpty(validateForm())) {
      onSave({ ...integration, domainFilter }, integrationSettings);
    }
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    onClose({ ...integration, domainFilter }, integrationSettings);
  };

  // On Upload Documents enabled
  const fetchCategories = async () => {
    try {
      if (!integrationSettings.uploadDocuments?.enabled) {
        return;
      }
      setIsLoading(true);
      const response = await getIntegrationDocumentCategories(integration.id);
      const categoriesRes: DocumentCategory[] = response.data?.documentCategories || [];
      if (!isEmpty(categoriesRes)) {
        setCategories(categoriesRes || []);
      }
    } catch (err) {
      // Do nothing
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      width={600}
      contentStyles={{ padding: '30px' }}
      hideFooter
      isHideHeader
      centerModal
      showDivider={false}
    >
      <ShowSpinnerIfLoading loading={isLoading}>
        <Header>
          <Title>
            <Trans>Integration settings</Trans>
          </Title>
          <Subtitle>
            <Trans>{integration.name}</Trans>
          </Subtitle>
        </Header>
        <Content>
          <Label>
            <Trans>
              When the modules are set as automatic, we run the integration every night. When set as
              manual, manage them on the related pages.
            </Trans>
          </Label>
          <FilterTitle>
            <Trans>Filter</Trans>
          </FilterTitle>
          <FilterInput
            type="text"
            value={domainFilter}
            placeholder={i18n._(t`Enter filter text for user emails...`)}
            onChange={(e: any) => {
              setDomainFilter(e.target.value);
            }}
          />
          <SettingsHeader>
            <HeaderRow>
              <HeaderTitle>
                <Trans>Module</Trans>
              </HeaderTitle>
              <HeaderTitle flexBasis={150}>
                <Trans>Automatic</Trans>
              </HeaderTitle>
            </HeaderRow>
            {INTEGRATION_SETTINGS.map((setting) => {
              if (integrationSettings[setting.key]?.enabled) {
                switch (setting.key) {
                  case 'uploadDocuments': {
                    return (
                      integrationSettings[setting.key]?.enabled && (
                        <UploadDocuments
                          integrationName={integration.name}
                          integrationModule={integrationSettings[setting.key]}
                          onChange={(value) => {
                            setIntegrationSettings({
                              ...integrationSettings,
                              [setting.key]: value,
                            });
                          }}
                          label={setting.label}
                          categories={categories}
                          errorMessage={errors.uploadDocuments}
                        />
                      )
                    );
                  }
                  default: {
                    return (
                      <ColumnRow key={setting.key}>
                        <Row>
                          <RowElement>{i18n._(t`${setting.label}`)}</RowElement>
                          <RowElement flexBasis={150}>
                            <Switch
                              disabled={!integrationSettings[setting.key].enabled}
                              width={64}
                              value={integrationSettings[setting.key].automatic}
                              onChange={() => {
                                setIntegrationSettings({
                                  ...integrationSettings,
                                  [setting.key]: {
                                    ...integrationSettings[setting.key],
                                    automatic: !integrationSettings[setting.key].automatic,
                                  },
                                });
                              }}
                            />
                          </RowElement>
                        </Row>
                      </ColumnRow>
                    );
                  }
                }
              }
              return null;
            })}
          </SettingsHeader>
        </Content>
        <Footer>
          <Actions>
            <Button
              label={i18n._(t`Cancel`)}
              onClick={handleClose}
              type="button"
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
            />
            <Button
              label={i18n._(t`Save`)}
              type="button"
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              onClick={handleSave}
            />
          </Actions>
        </Footer>
      </ShowSpinnerIfLoading>
    </Modal>
  );
};

export { UpdateIntegrationModal };
