export enum HRIS_INTEGRATION_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface IFilterType {
  search?: string;
  pagination: { skip: number; index: number; limit: number };
}

export type IntegrationSetting = {
  key: string;
  label: string;
};

export type DocumentCategory = {
  id: string;
  name: string;
};

export type IntegrationModule = {
  automatic: boolean;
  enabled: boolean;
  documentCategoryId?: DocumentCategory['id'];
};

export interface IIntegration {
  id: string;
  name: string;
  externalSoftware: string;
  status: HRIS_INTEGRATION_STATUS;
  domainFilter?: string;
  komboData: { isActive: boolean; icon?: string };
  integrationModules: Record<string, IntegrationModule>;
}
