import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { type UseFormReturn } from 'react-hook-form';

import type { IReviewDashboardCycleForm } from '~/pages/Reviews/DashboardCycle/ReviewDashboardForm/types';

import { SectionField } from '../components/SectionField';
import { SectionHeader } from '../components/SectionHeader';
import { SectionGrid, SubSectionTitle, ExplanationText } from '../design';

interface ISectionGeneralProps {
  formMethods: UseFormReturn<IReviewDashboardCycleForm>;
  onEdit?: () => void;
}

const SectionSettings = ({ formMethods, onEdit }: ISectionGeneralProps) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;

  const getOnOffStatus = (value: boolean) => {
    return value ? i18n._(t`ON`) : i18n._(t`OFF`);
  };

  return (
    <>
      <SectionHeader title={i18n._(t`Settings`)} onEdit={onEdit} />
      <>
        <SubSectionTitle marginBottom="16px">
          <Trans>General</Trans>
        </SubSectionTitle>
        <SectionGrid>
          <SectionField
            value={getOnOffStatus(watch('settings.isDigitalSign'))}
            label={i18n._(t`Sign digitally`)}
          />
          <SectionField
            value={getOnOffStatus(watch('settings.isCoachesAskedToScheduleReview'))}
            label={i18n._(t`Coaches schedule review`)}
          />
          <SectionField
            value={getOnOffStatus(watch('settings.isCalibrate'))}
            label={i18n._(t`Calibration`)}
          />
          <SectionField
            value={getOnOffStatus(watch('settings.isAutoArchive'))}
            label={i18n._(t`Archive review cycle automatically`)}
          />
        </SectionGrid>
      </>

      <>
        <SubSectionTitle marginBottom="16px" marginTop="32px">
          <Trans>Task notifications</Trans>
        </SubSectionTitle>
        <SectionGrid>
          <SectionField
            value={getOnOffStatus(watch('notifications.isOnTaskStartDate'))}
            label={i18n._(t`On start date`)}
          />
          <SectionField
            value={getOnOffStatus(watch('notifications.isOn7DaysBeforeTaskDeadline'))}
            label={i18n._(t`7 days before deadline`)}
          />
          <SectionField
            value={getOnOffStatus(watch('notifications.isOnTaskDeadline'))}
            label={i18n._(t`On the day of the deadline`)}
          />
        </SectionGrid>
      </>

      <>
        <SubSectionTitle marginBottom="16px" marginTop="32px">
          <Trans>Privacy</Trans>
        </SubSectionTitle>
        <ExplanationText>
          {!watch('privacy.isAllEmployeeCoachesCanSeeReview')
            ? i18n._(t`Employees, guests and coaches selected for coach review can see the review.`)
            : i18n._(
                t`Employees, guests and all current and future coaches of the employee can view the review.`,
              )}
        </ExplanationText>
      </>
    </>
  );
};

export { SectionSettings };
