import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 24px 32px 26px;
  margin-bottom: 46px;
  border-radius: 6px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const ChartBarContainer = styled.div`
  height: auto;
  background-color: ${COLORS.BG_SWITCH};
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ChartBarBg = styled.div<{ color: string; width?: number; isLargeBar: boolean }>`
  width: ${({ width }) => (width ? width : 1)}%;
  height: ${({ isLargeBar }) => (isLargeBar ? 48 : 22)}px;
  background-color: ${({ color }) => color};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ChartBarText = styled.span<{ zeroValueColor?: string; isLargeBar: boolean }>`
  margin-left: 15px;
  font-size: ${({ isLargeBar }) => (isLargeBar ? 14 : 12)}px;
  color: ${({ zeroValueColor }) => (zeroValueColor ? zeroValueColor : COLORS.WHITE)};
`;

export const ChatBarRating = styled.span<{ isLargeBar: boolean }>`
  margin-right: 15px;
  font-size: ${({ isLargeBar }) => (isLargeBar ? 16 : 14)}px;
  color: ${COLORS.WHITE};
`;

export const ParentContainer = styled.div`
  position: relative;
  height: 22px;
  margin-bottom: 20px;
`;

export const GradientContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
`;

export const LabelContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const ChartLabel = styled.div<{ width: number }>`
  font-size: 12px;
  position: relative;
  height: 100%;
  width: ${({ width }) => (width ? `${width}%` : 'auto')};
  max-width: ${({ width }) => (width ? `${width}%` : 'auto')};
  color: ${COLORS.SUBTEXT};
  text-align: center;
  border-right: 1px solid ${COLORS.WHITE};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 5px 0;

  display: inline-block;
  vertical-align: middle;

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
`;

export const LabelGradientOne = styled.div`
  width: 20%;
  background-image: linear-gradient(to left, ${COLORS.LIGHT_CORAL} 5%, ${COLORS.PEACH} 99%);
`;
export const LabelGradientTwo = styled.div`
  width: 20%;
  background-image: linear-gradient(to left, ${COLORS.LIGHT_PEACH} 4%, ${COLORS.LIGHT_CORAL} 99%);
`;

export const LabelGradientThree = styled.div`
  width: 20%;
  background-image: linear-gradient(to left, ${COLORS.LIGHT_YELLOW} 2%, ${COLORS.LIGHT_PEACH} 98%);
`;

export const LabelGradientFour = styled.div`
  width: 20%;
  background-image: linear-gradient(to left, ${COLORS.MINT} 0%, ${COLORS.LIGHT_YELLOW} 98%);
`;

export const LabelGradientFive = styled.div`
  width: 20%;
  background-image: linear-gradient(to left, ${COLORS.AQUA}, ${COLORS.MINT} 98%);
`;
