import React from 'react';

import { Icon, ICONS } from '~/components/Icon';
import CheckIcon from '~/components/Icons/Check';
import {
  ActiveCircle,
  CompletedCircle,
  Dot,
  ErrorCircle,
  LockedCircle,
  Minus,
  NotCompletedFutureCircle,
  NotCompletedPastCircle,
} from '~/components/TableOfContents/design';
import { SECTION_STATUS } from '~/components/TableOfContents/types';

import { COLORS } from '~/styles';

function ParentCircle({
  status,
  index,
  currentSection,
  isShowOutro,
}: {
  status?: SECTION_STATUS;
  index: number;
  currentSection: number;
  isShowOutro?: boolean;
}) {
  return (
    <>
      {status === SECTION_STATUS.DONE && (
        <CompletedCircle>
          <CheckIcon fill={COLORS.WHITE} size={9} />
        </CompletedCircle>
      )}
      {status === SECTION_STATUS.CURRENT && (
        <ActiveCircle>
          <Dot />
        </ActiveCircle>
      )}
      {status === SECTION_STATUS.ERROR && (
        <ErrorCircle>
          <Minus />
        </ErrorCircle>
      )}
      {status === SECTION_STATUS.LOCKED && (
        <LockedCircle>
          <Icon icon={ICONS.LOCK} width={14} height={14} />
        </LockedCircle>
      )}
      {!status && (index < currentSection || isShowOutro) && <NotCompletedPastCircle />}
      {!status && index > currentSection && <NotCompletedFutureCircle />}
    </>
  );
}

export { ParentCircle };
