import React, { memo } from 'react';

import { t } from '@lingui/macro';
import first from 'lodash/first';
import styled from 'styled-components';

import ToolTip from '~/components/Tooltip';
import { UserAvatar } from '~/components/UserAvatar';

import { TextRow, FlexRow } from './design';
import { SORT_OPTIONS } from './types';

import type { IColumnTable } from '~/@types/table';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

import type { IJobProfile, IMultiLangString, ITeam, IUser } from '@learned/types';
import type { I18n } from '@lingui/core';

const Container = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 350px;
`;

const NameColumn = memo((value?: IMultiLangString) => {
  const getMultiLangString = useMultiLangString();
  return (
    <TextRow>
      <FlexRow>
        <ToolTip tooltip={getMultiLangString(value ?? '')}>
          <span>{getMultiLangString(value ?? '')}</span>
        </ToolTip>
      </FlexRow>
    </TextRow>
  );
});

export const createColumns = (
  getMultiLangString: (multiLangString: IMultiLangString) => string,
  userTeams: Record<string, ITeam>,
): IColumnTable<IUser & { jobProfiles: IJobProfile[]; teams: string[] }>[] => {
  return [
    {
      name: 'Employee',
      accessor: 'name',
      minWidth: '290px',
      maxWidth: '290px',
      renderCell: (item, _onClick, isHovered, isSelected) => {
        return (
          <Container>
            <UserAvatar
              userId={item.id}
              options={{
                customColor: isSelected
                  ? COLORS.COMPANY
                  : isHovered
                  ? COLORS.TEXT_HOVER
                  : COLORS.TEXT_MAIN,
                showTooltip: true,
              }}
            />
          </Container>
        );
      },
      sortBy: {
        asc: {
          key: SORT_OPTIONS.NAME_A_Z,
          label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
        },
        desc: {
          key: SORT_OPTIONS.NAME_Z_A,
          label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
        },
      },
    },
    {
      name: 'Job',
      accessor: 'role',
      minWidth: '170px',
      maxWidth: '170px',
      renderCell: (item) => {
        const firstProfile = first(item.jobProfiles);
        const firstJobProfileName =
          firstProfile && firstProfile.name ? getMultiLangString(firstProfile.name) : '';
        let jobName = '';
        if (item?.jobProfiles?.length > 1) {
          jobName = `${firstJobProfileName} +${item.jobProfiles.length - 1}`;
        } else {
          jobName = firstJobProfileName;
        }
        return <NameColumn value={jobName} />;
      },
    },
    {
      name: 'Team',
      accessor: 'team',
      minWidth: '170px',
      maxWidth: '170px',
      renderCell: (item) => {
        const firstTeam = userTeams[first(item.teams) ?? ''];

        let teamName = '';
        if (item?.teams?.length > 1) {
          teamName = `${firstTeam?.name} +${item.teams.length - 1}`;
        } else {
          teamName = firstTeam?.name;
        }
        return <NameColumn value={teamName} />;
      },
    },
  ];
};
