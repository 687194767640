import styled from 'styled-components';

export const RotateIcon180 = styled.div`
  svg {
    transform: rotate(180deg);
  }
`;

export const Wrapper = styled.div`
  & .status {
    padding: 0px 4px;
  }
`;
