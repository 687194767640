import React from 'react';

import { SURVEY_TYPE } from '@learned/constants';
import { ISurveyPulse } from '@learned/types';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { addDays, isAfter, isSameDay } from 'date-fns';
import { type UseFormReturn, Controller } from 'react-hook-form';
import styled from 'styled-components';

import DatePicker from '~/components/DatePickerDeprecated';
import Switch from '~/components/Switch';
import { StepSection } from '~/pages/SurveyUpdate/components';
import type { IGeneralForm } from '~/pages/SurveyUpdate/types';

import { useAutoSaveFunc } from '~/hooks/useAutoSave';
import { COLORS } from '~/styles';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

import { Label } from '../../design';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 14px;
`;

const Block = styled.div`
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 40px; // same as datepicker
`;

const SwitchLabel = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.BLACK};
`;

interface ISectionDates {
  formMethods: UseFormReturn<IGeneralForm>;
  autosave: useAutoSaveFunc;
}

const SectionDates = ({ formMethods, autosave }: ISectionDates) => {
  const { i18n } = useLingui();
  const { watch, setValue, register, control } = formMethods;

  const dates = watch('dates');
  const type = watch('type');
  const isEndDate =
    type === SURVEY_TYPE.TRADITIONAL ? true : (dates as ISurveyPulse['dates']).isEndDate;

  const onToggleEndDate = (value: boolean) => {
    setValue('dates.isEndDate', value);

    // additional logic
    // on enable - set endDate = today + 28 days
    // on disable - set endDate = null (infinite)
    const endDate = value ? convertToTimeString(addDays(new Date(), 28), TIME_FORMATS.ISO) : null;
    setValue('dates.endDate', endDate);
    autosave.run('dates.isEndDate');
  };

  return (
    <StepSection title={i18n._(t`Dates`)}>
      <Wrapper>
        {/* Start date */}
        <Block>
          <Label>
            <Trans>Start date</Trans>
          </Label>
          <Controller
            {...register('dates.startDate')}
            control={control}
            render={({ field: { value, onChange } }) => (
              <DatePicker
                // eslint-disable-next-line
                // @ts-ignore
                selected={value}
                onChange={(date: Date) => {
                  onChange(convertToTimeString(date, TIME_FORMATS.ISO));

                  // if endDate present and it's in the past -> change to default endDate (+28 days from startDate)
                  const dateA = date;
                  const dateB = new Date(dates.endDate as unknown as string);
                  const isStartDateAfterEndDate = isAfter(dateA, dateB);
                  const isStartAndEndDatesSame = isSameDay(dateA, dateB);

                  if (isEndDate && (isStartDateAfterEndDate || isStartAndEndDatesSame)) {
                    setValue(
                      'dates.endDate',
                      convertToTimeString(addDays(date, 28), TIME_FORMATS.ISO),
                    );
                  }

                  autosave.run('dates.startDate');
                }}
                minDate={new Date()} // validation: min date - today
              />
            )}
          />
        </Block>

        {/* Toggle */}
        {type === SURVEY_TYPE.PULSE && (
          <Block>
            <Label>
              <Trans>End date</Trans>
            </Label>
            <Row>
              <Controller
                {...register('dates.isEndDate')}
                control={control}
                render={({ field: { value } }) => (
                  <Switch onChange={onToggleEndDate} checked={value} />
                )}
              />
              <SwitchLabel>
                <Trans>End survey on specific date</Trans>
              </SwitchLabel>
            </Row>
          </Block>
        )}

        {/* End date */}
        {isEndDate && (
          <Block>
            <Label>
              <Trans>End date</Trans>
            </Label>
            <Controller
              {...register('dates.endDate')}
              control={control}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  // eslint-disable-next-line
                  // @ts-ignore
                  selected={value}
                  onChange={(date: Date) => {
                    onChange(convertToTimeString(date, TIME_FORMATS.ISO));
                    autosave.run('dates.endDate');
                  }}
                  minDate={addDays(new Date(dates.startDate), 1)} // validation: min date - startDate + 1
                />
              )}
            />
          </Block>
        )}
      </Wrapper>
    </StepSection>
  );
};

export { SectionDates };
