import React, { useEffect } from 'react';

import { t } from '@lingui/macro';
import { Trans, useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import last from 'lodash/last';
import size from 'lodash/size';
import { useFieldArray, type UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';

import { ButtonShadow } from '~/components/Button';
import { IconOld } from '~/components/IconOld';
import { Input } from '~/components/Input';
import SvgIcon from '~/components/SvgIcon';

import starIcon from '~/assets/main-menu-icons/star.svg';

import type { IQuestionForm } from '~/@types/question';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { COLORS } from '~/styles';

interface IStarsProps {
  formMethods: UseFormReturn<IQuestionForm>;
  languageState: ILanguageStateReturn;
  isPreloaded?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;

  & .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const Header = styled.div`
  display: flex;
  color: ${COLORS.BLACK};
  gap: 30px;
  font-size: 14px;
  margin-bottom: 38px;
`;

const StarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: ${COLORS.BORDER_HARD};
`;

const Star = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ActionButton = styled(ButtonShadow)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 41px;
  border-radius: 6px;
  padding: 0;
  border: solid 1px ${COLORS.BORDER_HARD};
  &.selected {
    background-color: var(--company-color);
    color: ${COLORS.WHITE};
  }
`;

const Stars = ({ isPreloaded, languageState, formMethods }: IStarsProps) => {
  const { i18n } = useLingui();
  const { control, register, resetField, getValues } = formMethods;
  const { fields, append, remove, update } = useFieldArray({
    name: 'options',
    control,
  });
  const languages = languageState.companyLanguages.filter(({ locale }) =>
    languageState.languages.find((language) => language.locale === locale),
  );

  useEffect(() => {
    if (!isPreloaded) {
      resetField('options', {
        defaultValue: [],
      });

      [true, true, true, true, true].forEach(() => {
        append(
          {
            label: languages.map(({ locale }) => ({ locale, value: '' })),
          },
          { shouldFocus: false },
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeStar = () => {
    if (size(fields) > 2) {
      const label = getValues(`options.${size(fields) - 1}.label`);
      update(size(fields) - 2, {
        label,
      });

      remove(size(fields) - 1);
    }
  };

  const addStar = () => {
    if (size(fields) < 10) {
      append({
        label: languages.map(({ locale }) => {
          const value = getValues(`options.${size(fields) - 1}.label`);
          return {
            locale,
            value: value.find((item) => item.locale === locale)?.value || '',
          };
        }),
      });
    }
  };

  return (
    <Wrapper>
      <Header>
        <div>
          <Trans id="Stars and labels" />
        </div>
      </Header>

      <Row>
        <ActionButton type="button" onClick={removeStar}>
          <IconOld name="RemoveCircle" width={14} height={14} />
        </ActionButton>

        <StarWrapper>
          {fields.map((_, index) => (
            <Star key={`star-${index}`}>
              <SvgIcon
                width="32px"
                height="32px"
                isDefaultColor
                defaultColor={COLORS.MIDDLE_GRAY}
                url={starIcon}
              />
            </Star>
          ))}
        </StarWrapper>

        <ActionButton type="button" onClick={addStar}>
          <IconOld name="AddCircle" width={14} height={14} />
        </ActionButton>
      </Row>
      <Row>
        <div>
          {fields[0]?.label.map(({ locale }, i) => {
            const isLocale = languages.find((lang) => lang.locale === locale);
            return (
              isLocale && (
                <div className="inputWrapper" key={`first-${locale}`}>
                  <Input
                    name={`options.0.label.${i}.value`}
                    width="150px"
                    height="38px"
                    register={register(`options.0.label.${i}.value`, {
                      required: true,
                    })}
                    placeholder={i18n._(t`Not satisfied`)}
                    leftIcon={
                      size(fields[0].label) > 1
                        ? getUnicodeFlagIcon(locale.substring(locale.indexOf('_') + 1))
                        : undefined
                    }
                  />
                </div>
              )
            );
          })}
        </div>
        <div>
          {last(fields)?.label.map(({ locale }, i) => {
            const isLocale = languages.find((lang) => lang.locale === locale);
            return (
              isLocale && (
                <div className="inputWrapper" key={`last-${locale}`}>
                  <Input
                    name={`options.${size(fields) - 1}.label.${i}.value`}
                    width="150px"
                    height="38px"
                    register={register(`options.${size(fields) - 1}.label.${i}.value`, {
                      required: true,
                    })}
                    placeholder={i18n._(t`Perfect!`)}
                    leftIcon={
                      size(last(fields)?.label) > 1
                        ? getUnicodeFlagIcon(locale.substring(locale.indexOf('_') + 1))
                        : undefined
                    }
                  />
                </div>
              )
            );
          })}
        </div>
      </Row>
    </Wrapper>
  );
};

export { Stars };
