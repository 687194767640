import React from 'react';

import { Trans } from '@lingui/macro';

import { Icon, ICONS } from '~/components/Icon';

import { UploadLogoWrapper, UploadLogoText } from '../styles/menuItemDesign';

const UploadLogoButton = ({ onUploadChange }: { onUploadChange: (e: any) => void }) => {
  return (
    <UploadLogoWrapper>
      <Icon icon={ICONS.UPLOAD} />
      <UploadLogoText>
        <Trans>logo</Trans>
      </UploadLogoText>
      <input
        type="file"
        accept="image/*"
        id="uploadFile"
        name="attach"
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => onUploadChange(e)}
      />
    </UploadLogoWrapper>
  );
};

export { UploadLogoButton };
