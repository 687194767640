import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { type UseFormReturn } from 'react-hook-form';

import type { ISectionState } from '~/components/SideBar/SectionStateHook';

import { SubHeader } from './SubHeader';
import { SummaryField } from './SummaryField';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import type { IReviewCycleForm } from '../../../types';

interface GeneralSummaryProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewCycleForm>;
}

const GeneralSummary = ({ formMethods, sectionState }: GeneralSummaryProps) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;
  const getMultiLangString = useMultiLangString();

  const watchName = watch('name') || '';
  const watchTemplateName = watch('reviewTemplateName');

  const navigateToSection = () => sectionState.setCurrentSection(0);

  return (
    <>
      <SubHeader title={i18n._(t`General`)} onEdit={navigateToSection} />
      <SummaryField
        value={getMultiLangString(turnArrayIntoMultiLang(watchName))}
        label={i18n._(t`Name`)}
      />
      <SummaryField
        value={getMultiLangString(turnArrayIntoMultiLang(watchTemplateName))}
        label={i18n._(t`Review template`)}
        marginBottom="22px"
        marginTop="16px"
      />
    </>
  );
};

export { GeneralSummary };
