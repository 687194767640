import { WithPartial } from '@learned/types/src/generic';

import type { ICreateServerRoute, IServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

import type { IReviewQuestion } from '@learned/types';

interface ICreateReviewQuestionProps {
  question: IReviewQuestion;
  position: number;
}

export function createReviewQuestion(data: ICreateReviewQuestionProps) {
  return cloudRequest(serverRoutes.reviewQuestions.createReviewQuestion as IServerRoute, {}, data);
}

export function updateReviewQuestion(
  questionId: IReviewQuestion['id'],
  question: Omit<WithPartial<IReviewQuestion, 'name' | 'type'>, 'id' | 'company' | 'meta'>,
) {
  return cloudRequest(
    (serverRoutes.reviewQuestions.updateReviewQuestion as ICreateServerRoute)(questionId),
    {},
    question,
  );
}

export function copyReviewQuestion(questionId: IReviewQuestion['id']) {
  return cloudRequest(
    (serverRoutes.reviewQuestions.copyReviewQuestion as ICreateServerRoute)(questionId),
  );
}

export function deleteReviewQuestion(questionId: IReviewQuestion['id']) {
  return cloudRequest(
    (serverRoutes.reviewQuestions.deleteReviewQuestion as ICreateServerRoute)(questionId),
  );
}

export function deleteReviewQuestions(reviewQuestionIds: IReviewQuestion['id'][]) {
  return cloudRequest(
    serverRoutes.reviewQuestions.deleteReviewQuestions as IServerRoute,
    {},
    {
      reviewQuestionIds,
    },
  );
}
