import styled from 'styled-components';

import { Icon } from '~/components/Icon';

import { COLORS } from '~/styles';

export const StyledDeleteIcon = styled(Icon)`
  color: ${COLORS.ACCENT_ERROR};
`;

export const DeleteText = styled.span`
  color: ${COLORS.ACCENT_ERROR};
`;

export const DeleteOptionWrapper = styled.div`
  svg {
    color: ${COLORS.ACCENT_ERROR};
  }
`;
