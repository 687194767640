import styled from 'styled-components';

import { COLORS } from '~/styles';
export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  align-items: center;
  margin-bottom: 16px;
`;

export const LabelContainer = styled.div`
  min-width: 116px;
  max-width: 116px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${COLORS.TEXT_MAIN};
  max-height: 2.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  // Only for ellipsis in case long title
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const DisableText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${COLORS.DISABLE_TEXT};
  line-height: 18px;
`;
