import styled from 'styled-components';

import { Button } from '~/components/Buttons';
import { Icon } from '~/components/Icon';
import Label from '~/components/Label';

import { COLORS } from '~/styles';

/**
 * @deprecated since version 3.0
 */
export const BlockRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 250px;
  column-gap: 16px;
  width: 100%;
  align-items: center;
`;

export const RowName = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${COLORS.TEXT_MAIN};
`;

export const RowDescription = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
`;

export const RowAction = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CompanyAvatar = styled.div`
  width: 40px;
  height: 40px;
  outline-color: transparent;
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  border: solid 1px ${COLORS.BORDERS};
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

export const CompanyLogoEmail = styled.div`
  width: 81px;
  height: 46px;
  outline-color: transparent;
  border-radius: 6px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  border: solid 1px ${COLORS.BORDERS};
  cursor: pointer;
  display: flex;
  justify-content: center;
  box-shadow: 0 2px 8px 0 rgba(145, 157, 165, 0.12);
  background-color: var(--white);
`;

export const CompanyAvatarPreview = styled.div`
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  color: ${COLORS.BORDERS};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WrapperPad = styled.div`
  margin: 0 8px;
`;

export const Wrapper = styled.div`
  background-color: white;
  width: calc(100%-52px);
  margin-bottom: 32px;
  padding: 26px;
  border-radius: 6px;
  box-shadow: 0 8px 15px 0 rgb(149 157 165 / 20%);
  border: solid 1px ${COLORS.BORDERS};
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  color: ${COLORS.TEXT_HOVER};
  margin-bottom: 24px;
`;

export const BottomStatement = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  padding: 20px 30px;
`;

export const ProductRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  padding: 20px 30px;
  &:hover {
    background-color: ${COLORS.BG_LIST};
  }
`;

export const ProductInfoBlock = styled.span`
  flex: auto;
`;

export const ProductColumn = styled.span`
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: center;
`;

export const OnStatus = styled.span`
  font-size: 14px;
  color: ${COLORS.COMPANY};
  min-width: 44px;
  max-width: 44px;
`;

export const StyledProductName = styled.span`
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
  text-transform: capitalize;
`;

export const TrialLabel = styled(Label)`
  font-size: 12px;
  margin-left: 20px;
`;

export const SettingsBlock = styled.span`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const ButtonLabel = styled.span`
  font-size: 14px;
  color: ${COLORS.TEXT_HOVER};
`;

export const SettingsButton = styled(Button)`
  color: ${COLORS.TEXT_HOVER};
`;

export const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  min-width: 44px;
  max-width: 44px;
`;

export const InfoIcon = styled(Icon)`
  color: ${COLORS.PLACEHOLDERS};
`;

export const ViewAllText = styled.span`
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
`;

export const Link = styled.span`
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
`;
