import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { MainInfoWrap, QuestionsBlockHeader } from '~/pages/ReviewThemeView/design';

import { LeftSide, MainInfoRow, RightSide, ThemesListWrap } from './styling';
import { ThemeCard } from './ThemeCard';

const ThemeBlock = ({ themes }: { themes: any[] }) => {
  const { i18n } = useLingui();
  return (
    <MainInfoWrap>
      <QuestionsBlockHeader>
        <Trans>Themes</Trans>
      </QuestionsBlockHeader>
      <MainInfoRow>
        <LeftSide>{i18n._(t`Theme`)}</LeftSide>
        <RightSide>{i18n._(t`Weighting`)}</RightSide>
      </MainInfoRow>
      <ThemesListWrap>
        {themes.map((theme, idx) => {
          return <ThemeCard theme={theme} key={idx} />;
        })}
      </ThemesListWrap>
    </MainInfoWrap>
  );
};

export { ThemeBlock };
