import React from 'react';

import { ITask, IUserReview, IUserReviewQuestion } from '@learned/types';

import { Scores } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/Scores';

const AverageScorePDFView = ({
  userReview,
}: {
  userReview: IUserReview & {
    tasks: ITask[];
    employeeTasksInOtherUserReviews: ITask[];
    userReviewQuestions: IUserReviewQuestion[];
  };
}) => {
  const isShowOverallRating = userReview.settings.isShowOverallRating;

  return isShowOverallRating ? (
    <Scores userReview={userReview} width="100%" isRatingGridScrollable isPDFView />
  ) : (
    <></>
  );
};

export default AverageScorePDFView;
