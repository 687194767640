import React from 'react';

import { Trans } from '@lingui/macro';

import { Icon, ICONS, ICON_SIZES } from '~/components/Icon';

import { PreviousAnswerLegendWrapper } from './design';

import { COLORS } from '~/styles';

const PreviousAnswerLegend = () => {
  return (
    <PreviousAnswerLegendWrapper>
      <div className="icon-container">
        <Icon icon={ICONS.HISTORY} size={ICON_SIZES.SMALL} color={COLORS.TIPPY_BACKGROUND} />
      </div>
      <Trans>
        <div>Previous answer</div>
      </Trans>
    </PreviousAnswerLegendWrapper>
  );
};

export default PreviousAnswerLegend;
