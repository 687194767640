import styled from 'styled-components';

import { COLORS } from '~/styles';

const Wrapper = styled.div<{ minSize: string }>`
  background-color: ${COLORS.WHITE};
  padding: 24px 32px;
  border-radius: 8px;
  min-width: ${({ minSize }) => minSize};
  font-size: 14px;
`;

const Header = styled.div`
  padding-bottom: 8px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: ${COLORS.TEXT_HOVER};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Subtitle = styled.div`
  font-size: 14px;
  color: ${COLORS.SUBTEXT};
`;

const BodyArea = styled.div`
  margin-top: 16px;
`;

const SecondaryTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${COLORS.TEXT_HOVER};
`;

const InputCtr = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 8px;
`;

const ButtonCtr = styled.div<{ isSpaceBetween: boolean }>`
  display: flex;
  gap: 8px;
  margin-top: 32px;
  justify-content: ${({ isSpaceBetween }) => (isSpaceBetween ? 'space-between' : 'flex-end')};
`;

const Cell = styled.div`
  display: flex;
  width: 100%;
`;

const LastColumn = styled.div`
  display: flex;
  justify-content: center;
  visibility: hidden;
  min-width: 32px;
`;

const LeftItems = styled.div`
  width: 40%;
  & .content {
    display: flex;
    align-items: center;
    gap: 10px;
    & .dragIcon {
      display: none;
    }
  }
`;

const Row = styled.div`
  display: flex;
  background: ${COLORS.BG_LIST};
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 10px;

  &:hover,
  &.active {
    & {
      background: ${COLORS.HOVER};
      cursor: pointer;
    }
    ${LastColumn} {
      visibility: visible;
    }

    & .dragIcon {
      display: inline-block;
    }
  }
`;

const RightItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: auto;
`;

const RowCtr = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ScrollWrapper = styled.div`
  max-height: 350px;
  overflow-y: auto;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
`;

const RightItemsCtr = styled.div`
  display: flex;
  flex: auto;
  gap: 16px;
  align-items: flex-start;
`;

const SkillCategoryCtr = styled.div`
  max-width: 200px;
`;

export {
  Wrapper,
  Header,
  Title,
  Subtitle,
  BodyArea,
  SecondaryTitle,
  InputCtr,
  ButtonCtr,
  Cell,
  Row,
  LeftItems,
  RightItems,
  RowCtr,
  TitleRow,
  LastColumn,
  RightItemsCtr,
  SkillCategoryCtr,
  ScrollWrapper,
};
