import React from 'react';

import { Trans } from '@lingui/macro';
import styled, { css } from 'styled-components';

import { Icon, ICONS, ICON_SIZES } from '~/components/Icon';
import Tooltip, { TOOLTIP_PLACEMENTS, TOOLTIP_SIZES } from '~/components/Tooltip';

import { COLORS } from '~/styles';

import type { IMultiLangString, ILatestPreviousRatingResponse } from '@learned/types';

interface IProps {
  option: {
    label: IMultiLangString;
  };
  canAnswer?: boolean;
  isSelected?: boolean;
  onChange?: () => void;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
  previousRating?: ILatestPreviousRatingResponse;
}

const Container = styled.div`
  width: 100%;
  height: 50px;
  text-overflow: ellipsis;
  border-radius: 6px;
  position: relative;

  div.history-label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    text-align: center;
    transform: translate(0, -20px);
    width: 100%;
    background-color: ${COLORS.BG_ELEMENTS};
    border-radius: 6px 6px 0 0;
    font-weight: 600;
    font-size: 12px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .selected {
    background: color-mix(in srgb, ${COLORS.COMPANY}, transparent 90%);
    color: ${COLORS.COMPANY};
    font-weight: 600;
  }
`;

const LabelWrapper = styled.div<{ $isSelected: boolean; $canAnswer?: boolean }>`
  min-width: 100px;
  height: 50px;
  background-color: ${COLORS.BG_LIST};
  flex-base: 130px;
  flex-grow: 1;
  text-align: center;
  align-content: center;
  padding: 0 20px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: ${COLORS.BG_LIST};
  color: ${COLORS.ACTIVITY_GREY};

  ${({ $canAnswer }) =>
    $canAnswer &&
    css`
      &:hover {
        background: color-mix(in srgb, ${COLORS.COMPANY_HOVER}, transparent 95%);
        font-size: 14px;
        line-height: 1.36;
        text-align: center;
        color: ${COLORS.COMPANY};
      }
    `}
`;

const Label = styled.span`
  width: 100%;
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-wrap: anywhere;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 2.5em;
  line-height: 1.25em;
`;

const RatingLabel = ({
  option,
  isSelected,
  onChange,
  useMultiLangString,
  canAnswer,
  previousRating,
}: IProps) => {
  const getMultiLangString = useMultiLangString();

  return (
    <Tooltip
      size={TOOLTIP_SIZES.DEFAULT}
      tooltip={
        <ToolTipText
          labelName={option.label}
          previousRatingReviewName={previousRating?.userReview.name}
          useMultiLangString={useMultiLangString}
        />
      }
      placement={TOOLTIP_PLACEMENTS.BOTTOM}
    >
      <Container>
        {previousRating && (
          <div className="history-label">
            <Icon icon={ICONS.HISTORY} size={ICON_SIZES.SMALL} color={COLORS.TIPPY_BACKGROUND} />
            &nbsp;<Trans>Previous answer</Trans>
          </div>
        )}
        <LabelWrapper
          $canAnswer={canAnswer}
          $isSelected={!!isSelected}
          onClick={() => onChange?.()}
          className={isSelected ? 'selected' : ''}
        >
          <Label>{getMultiLangString(option.label || {})}</Label>
        </LabelWrapper>
      </Container>
    </Tooltip>
  );
};

export { RatingLabel };

const ToolTipText = ({
  labelName,
  previousRatingReviewName,
  useMultiLangString,
}: {
  labelName: IMultiLangString;
  previousRatingReviewName?: IMultiLangString;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
}) => {
  const getMultiLangString = useMultiLangString();
  return (
    <div>
      <div>{getMultiLangString(labelName || {})}</div>
      {previousRatingReviewName && (
        <>
          <br />
          <div>
            <Trans>Previous review</Trans>
            <div>{getMultiLangString(previousRatingReviewName || {})}</div>
          </div>
        </>
      )}
    </div>
  );
};
