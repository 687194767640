import React, { useMemo, useState } from 'react';

import { GOAL_TYPES, GOAL_VIEWERS } from '@learned/constants';
import { IGoal } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';
import Modal from '~/components/Modal';
import Divider from '~/components/UI/Divider';

import {
  ActionButtonContainer,
  Content,
  DropdownContainer,
  Footer,
  Header,
  Label,
  Placeholder,
  StyledDropdown,
  Title,
} from './design';
import { DropdownItem, DropdownSubItem, SETTING_TYPES } from './types';

import type { I18n } from '@lingui/core';

const SETTING_ITEMS = [
  {
    id: SETTING_TYPES.VIEWERS,
    label: (i18n: I18n) => i18n._(t`People who can view the goal`),
  },
  {
    id: SETTING_TYPES.SUBTYPE,
    label: (i18n: I18n) => i18n._(t`Subtype`),
  },
];

const privacyItems = (i18n: I18n) => [
  {
    id: GOAL_VIEWERS.OWNER_AND_COACHES,
    label: i18n._(t`Private`),
    tooltip: i18n._(t`Goal owner and coach(es) of goal owner`),
  },
  {
    id: GOAL_VIEWERS.TEAMS,
    label: i18n._(t`Team`),
    tooltip: i18n._(
      t`Owner(s), coach(es) of owner(s), coach(es) of selected team(s), members of selected team(s)`,
    ),
  },
  {
    id: GOAL_VIEWERS.PUBLIC,
    label: i18n._(t`Public`),
    tooltip: i18n._(t`All members in the company`),
  },
];

const subtypeItems = (i18n: I18n) => [
  {
    id: GOAL_TYPES.PERSONAL,
    label: i18n._(t`Learning goal`),
  },
  {
    id: GOAL_TYPES.BUSINESS,
    label: i18n._(t`Business goal`),
  },
];

interface IProps {
  goals: IGoal[];
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (update: Record<string, string>) => void;
}

export const EditGoalModal = ({ goals, isLoading, onClose, onSubmit }: IProps) => {
  const { i18n } = useLingui();

  const [selectedSettingType, setSelectedSettingType] = useState<DropdownItem>();
  const [selectedSetting, setSelectedSetting] = useState<DropdownSubItem>();

  const secondaryDropdownItems = useMemo(() => {
    if (selectedSettingType?.id === SETTING_TYPES.VIEWERS) {
      return Object.values(privacyItems(i18n));
    }
    return Object.values(subtypeItems(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSettingType]);

  const handleSubmit = () => {
    if (selectedSettingType && selectedSetting) {
      const params = {
        setting: selectedSettingType.id,
        value: selectedSetting.id,
      };

      onSubmit(params);
    }
  };

  return (
    <Modal
      width={500}
      contentStyles={{
        padding: '24px 32px',
        borderRadius: '10px',
        overflow: 'visible',
      }}
      hideFooter
      isHideHeader
      showDivider={false}
    >
      <Header>
        <Title>
          <Trans>Edit {goals.length} goals</Trans>
        </Title>
        <Button
          type="button"
          variant={ButtonVariant.ICON}
          size={ButtonSize.BIG}
          icon={ICONS.CLOSE}
          iconSize={ICON_SIZES.LARGE}
          onClick={onClose}
        />
      </Header>
      <Content>
        <DropdownContainer>
          <Label>
            <Trans>Setting</Trans>
          </Label>
          <StyledDropdown
            onChange={(item) => {
              setSelectedSettingType(item as DropdownItem);
              setSelectedSetting(undefined);
            }}
            items={SETTING_ITEMS}
            selectedItem={selectedSettingType}
            stringifyItem={(item) => (item as DropdownItem).label(i18n)}
            placeholder={i18n._(t`Select a setting`)}
            isSingleSelect
            disableTooltip
          />
        </DropdownContainer>
        <Divider />
        {selectedSettingType ? (
          <DropdownContainer>
            <Label>
              <Trans>{selectedSettingType.label(i18n)}</Trans>
            </Label>
            <StyledDropdown
              onChange={(item) => setSelectedSetting(item as DropdownSubItem)}
              items={secondaryDropdownItems}
              selectedItem={selectedSetting}
              stringifyItem={(item) => (item as DropdownSubItem)?.label}
              placeholder={i18n._(t`Select`)}
              isSingleSelect
              skipSort
              disableTooltip={selectedSettingType.id !== SETTING_TYPES.VIEWERS}
            />
          </DropdownContainer>
        ) : (
          <Placeholder>
            <p>
              <Trans>No setting selected</Trans>
            </p>
          </Placeholder>
        )}
      </Content>
      <Divider />
      <Footer>
        <ActionButtonContainer>
          <Button
            label={i18n._(t`Cancel`)}
            type="button"
            variant={ButtonVariant.TEXT_PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={onClose}
          />
          <Button
            label={i18n._(t`Save`)}
            type="button"
            isLoading={isLoading}
            disabled={isLoading || !selectedSetting}
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={handleSubmit}
          />
        </ActionButtonContainer>
      </Footer>
    </Modal>
  );
};
