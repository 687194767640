import React, { useEffect } from 'react';
import type { ReactNode } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import size from 'lodash/size';
import { Controller, useForm } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Modal from '~/components/Modal';
import { TextArea } from '~/pages/ReviewThemeSetup/components/QuestionModal/components/TextArea';

import { Actions, Content, Footer, Header, Subtitle, Title, InputContainer, Label } from './design';

import useBoolState from '~/hooks/useBoolState';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';

import type { IMultiLangString } from '@learned/types';

export interface CreateFocusModalProps {
  title: ReactNode;
  onClose: () => void;
  onCreate: (newValues: { name: IMultiLangString }) => void;
  languageState: ILanguageStateReturn;
  skillName?: string;
  editingFocusArea?:
    | {
        name: IMultiLangString;
        _id: string;
      }
    | undefined;
  onUpdate?: (newValues: { name: IMultiLangString; _id: string }) => void;
}

function CreateFocusModal({
  title,
  onClose,
  onCreate,
  languageState,
  skillName,
  editingFocusArea,
  onUpdate,
}: CreateFocusModalProps) {
  const { i18n } = useLingui();

  const isSaveDisabled = useBoolState(true);
  const { control, register, unregister, handleSubmit, getValues } = useForm<{
    name: IMultiLangString[];
  }>({
    mode: 'all',
    defaultValues: {
      name: languageState.languages.map((lang) => ({ locale: lang.locale, value: '' })),
    },
  });

  useEffect(() => {
    if (editingFocusArea) {
      // find the values of the editingFocusArea and set them to the form
      descriptionFieldArray.replace(
        languageState.languages.map((lang) => ({
          locale: lang.locale,
          value: editingFocusArea.name[lang.locale],
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingFocusArea]);

  const descriptionFieldArray = useMultiLangFieldArray({
    name: 'name',
    control,
    unregister,
    languageState,
  });

  const onSubmit = (data: { name: IMultiLangString[] }) => {
    if (editingFocusArea) {
      onUpdate?.({
        name: data.name.reduce((acc, field) => {
          acc[field.locale] = field.value;
          return acc;
        }, {} as IMultiLangString),
        _id: editingFocusArea._id,
      });
      onClose();
      return;
    } else {
      const newValues = data.name.reduce((acc, field) => {
        acc[field.locale] = field.value;
        return acc;
      }, {} as IMultiLangString);
      onCreate({ name: newValues });
      onClose();
    }
  };

  const onValueChange = () => {
    getValues('name').some((field) => field.value) ? isSaveDisabled.off() : isSaveDisabled.on();
  };

  return (
    <Modal
      width={486}
      contentStyles={{ padding: '30px' }}
      hideFooter
      isHideHeader
      showDivider={false}
    >
      <Header>
        <Title>
          <Trans>{title}</Trans>
        </Title>
        <Subtitle>
          <Trans>{skillName}</Trans>
        </Subtitle>
      </Header>
      <Content>
        <Label>
          <Trans>Focus area</Trans>
        </Label>
        <InputContainer>
          {descriptionFieldArray.fields.map((field) => (
            <Controller
              key={field.id}
              {...register(`name.${field.index}.value`)}
              control={control}
              render={({ field: { onChange, value } }) => {
                onValueChange();
                return (
                  <TextArea
                    placeholder={i18n._(t`Focus area`)}
                    value={value}
                    onChange={onChange}
                    key={field.id}
                    height="90px"
                    leftIcon={
                      size(languageState.languages) > 1
                        ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                        : undefined
                    }
                  />
                );
              }}
            />
          ))}
        </InputContainer>
      </Content>
      <Footer>
        <Button
          label={i18n._(t`Delete`)}
          type="button"
          variant={ButtonVariant.TEXT_DELETE}
          size={ButtonSize.MEDIUM}
          onClick={onClose}
        />
        <Actions>
          <Button
            label={i18n._(t`Cancel`)}
            type="button"
            variant={ButtonVariant.TEXT_PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={onClose}
          />
          <Button
            label={i18n._(t`Save`)}
            type="button"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={handleSubmit(onSubmit)}
            disabled={isSaveDisabled.value}
          />
        </Actions>
      </Footer>
    </Modal>
  );
}

export { CreateFocusModal };
