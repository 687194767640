import React from 'react';

import { useLingui } from '@lingui/react';
import { type UseFormReturn } from 'react-hook-form';

import { IQuestionForm, IRatingLabel } from '~/pages/ReviewThemeSetup/types';

import { useLanguageState } from '~/hooks/useLanguageState';

import { Options } from '../Options';

interface IProps {
  formMethods: UseFormReturn<IQuestionForm>;
  ratingLabels: IRatingLabel[];
}

const Ratings = ({ formMethods, ratingLabels }: IProps) => {
  const { i18n } = useLingui();
  const languageState = useLanguageState();

  return (
    <div>
      <span>Rating scale</span>
      <p>
        {i18n._(
          'We recommend that you use the default rating scale. Need a different scale? Enable customize rating scale in the settings below.',
        )}
      </p>
      <Options
        languageState={languageState}
        formMethods={formMethods}
        ratingLabels={ratingLabels}
      />
    </div>
  );
};

export { Ratings };
