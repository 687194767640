import React, { useEffect, useRef, useState } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Filler,
} from 'chart.js';
import moment from 'moment';
import { Chart } from 'react-chartjs-2';

import { GraphFooter, ColoredCircle, Tile, GraphCtr, GraphOuterCtr } from './AreaGraph.design';

import { COLORS } from '~/styles';
import { getRgba } from '~/utils/colorManipulator';
import { handleTooltipData } from '~/utils/reports';

import { GraphTooltip } from '../components/GraphTooltip';
import { ILineGraphProps } from '../types';

import type { ChartData, ChartOptions } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineController, LineElement, Filler);

const createGradient = (
  canvas: HTMLCanvasElement,
  colorRatio: number,
  color1: string,
  color2: string,
) => {
  const ctx = canvas.getContext('2d');
  const gradient = ctx?.createLinearGradient(0, 0, 0, ctx.canvas.height * colorRatio);
  gradient?.addColorStop(0, color1);
  gradient?.addColorStop(1, color2);
  return gradient;
};

function AreaGraph({
  min = 0,
  max = 100,
  height,
  stepSize = 20,
  width,
  colorRatio = 1,
  timeData = [],
  tooltipTitle,
  isTooltipDisabled = false,
  ratingLabelsCount,
  onPointClick,
}: ILineGraphProps) {
  const filterMainData = timeData?.filter((a) => a.key === 'primary');
  const primaryData = filterMainData && filterMainData.length > 0 ? filterMainData[0].data : [];
  const primaryDataLabels = primaryData.length > 0 ? primaryData.map((a) => a.key) : [];

  const toolRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<ChartJS<'line'>>(null);

  const [chartDataSet, setChartDataSet] = useState<ChartData<'line'>>({ labels: [], datasets: [] });

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const GRADIENT = createGradient(
      chart.canvas,
      colorRatio,
      COLORS.CAREER,
      getRgba(COLORS.CAREER),
    );

    const dataSets = timeData.map((item, i) => {
      const data = item.data.map((d) => d.value);
      return {
        label: item.key,
        data,
        tension: i === 0 ? 0.2 : 0,
        fill: i === 0,
        backgroundColor: i === 0 ? GRADIENT : COLORS.TRANSPARENT,
        borderWidth: i === 0 ? 0 : 3,
        ...(i !== 0 && { borderDash: [2, 2] }),
        borderColor:
          (timeData && timeData.length > i && timeData[i].colors[0]) || COLORS.TEXT_HOVER,
        pointRadius: 3,
        pointBorderWidth: 1,
        pointBackgroundColor:
          (timeData && timeData.length > i && timeData[i].colors[0]) || COLORS.WHITE,
        pointHoverBorderWidth: 1,
        pointHoverRadius: 6,
        pointHoverBorderColor: 'rgba(0, 0, 0, 0.5)',
        pointHoverBackgroundColor:
          (timeData && timeData.length > i && timeData[i].colors[0]) || COLORS.WHITE,
        order: timeData.length - i,
      };
    });

    if (dataSets?.length) {
      setChartDataSet({
        labels: primaryDataLabels,
        datasets: dataSets,
      } as ChartData<'line'>);
    }

    // eslint-disable-next-line
  }, [chartRef]);

  const options: ChartOptions = {
    onHover: (event, _chartElement) => {
      const element = event.native?.target as HTMLElement;
      element.style.cursor = 'pointer';
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: (context) => {
          if (context.tooltip.dataPoints?.length > 0) {
            const primaryPoint = context.tooltip.dataPoints.find(
              (dp) => dp.dataset.label === 'primary',
            );

            if (!primaryPoint) {
              return;
            }

            const dataPointIndex = primaryPoint.dataIndex;

            const ValueGroups = timeData.map((dataGroup) => ({
              value: dataGroup.data[dataPointIndex].value,
              deviation: dataGroup.data[dataPointIndex].deviation,
              label: dataGroup.label,
              color: dataGroup.colors[0],
            }));

            // only format date like strings to MMM YYYY
            const titleStr = primaryPoint.label;
            const monthRegexp = /^\d{4}-\d{2}$/;

            handleTooltipData(
              toolRef.current,
              {
                title: monthRegexp.test(titleStr)
                  ? moment(titleStr, 'YYYY-MM').format('MMM YYYY')
                  : titleStr,
                values: ValueGroups,
                ...(ratingLabelsCount && { ratingLabels: ratingLabelsCount }),
              },
              context,
            );
          }
        },
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
          lineWidth: 0,
        },
        ticks: {
          callback: (value) => {
            const valueIndex = parseInt(value.toString(), 10);
            const label = primaryDataLabels[valueIndex];

            if (label.includes('01')) {
              return moment(label).format('YYYY MMM');
            }
            return moment(label).format('MMM');
          },
        },
      },
      y: {
        beginAtZero: true,
        min,
        ...(ratingLabelsCount && { max: ratingLabelsCount }),
        suggestedMax: max,
        ticks: {
          stepSize: ratingLabelsCount ? 1 : stepSize,
        },
        grid: {
          display: true,
          color: COLORS.BORDERS,
        },
        border: {
          display: false,
        },
      },
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    onClick(_event, elements, _chart) {
      const primaryDataIndex = timeData.findIndex((a) => a.key === 'primary');
      const elementData = elements.find((e) => e.datasetIndex === primaryDataIndex);

      if (!elementData) {
        return;
      }

      // find the correct element from the primaryData
      const element = timeData[primaryDataIndex].data[elementData.index];

      // Assume currently only y dimension for the area graph is the month,
      !!onPointClick && onPointClick({ key: element.key, value: element.value });
    },
  };

  return (
    <GraphOuterCtr>
      <GraphCtr>
        <Chart
          ref={chartRef}
          height={height}
          width={width}
          type="line"
          data={chartDataSet}
          options={options as any}
        />
        {filterMainData.length > 0 && !isTooltipDisabled && (
          <GraphTooltip
            ref={toolRef}
            tooltipTitle={tooltipTitle}
            additionalDataGroupsLength={timeData.length - 1}
            // according to the Ui design for area tooltip, the primary title and secondary title positions are swapped
            swapTitles
          />
        )}
      </GraphCtr>
      {timeData && (
        <GraphFooter>
          {timeData.map((item, i) => (
            <Tile key={`tiles-${i + 1}`}>
              <ColoredCircle selectedColor={item.colors[0]} />
              {item.label}
            </Tile>
          ))}
        </GraphFooter>
      )}
    </GraphOuterCtr>
  );
}

export { AreaGraph };
