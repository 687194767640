import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Section = styled.div<{
  $marginTop?: string;
  $paddingTop?: string;
  $width?: string;
  $isBorderTop?: boolean;
}>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ $marginTop }) => $marginTop || '0'};
  padding-top: ${({ $paddingTop }) => $paddingTop || '0'};
  width: ${({ $width }) => $width || 'unset'};
`;

export const EvaluatorsContainer = styled.div`
  display: flex;

  .evaluator {
    color: ${COLORS.SUBTEXT};
    font-size: 14px;
    display: flex;
    .title {
      color: ${COLORS.TEXT_HOVER};
      padding-left: 8px;
      line-height: 21px;
    }
    padding-right: 24px;
  }
  margin-bottom: 12px;
`;

export const EvaluatorsDescription = styled.div`
  font-size: 12px;
  color: ${COLORS.SUBTEXT};
  margin-bottom: 4px;
`;

export const SectionTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.TEXT_MAIN};
`;

export const Divider = styled.div`
  border-top: 1px solid ${COLORS.BORDERS};
  margin-top: 8px;
  margin-bottom: 12px;
`;

export const Placeholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  font-size: 12px;
  font-weight: 400;
  background-color: ${COLORS.BG_LIST};
  color: ${COLORS.DISABLE_TEXT};
  border-radius: 6px;
`;

export const NameContainer = styled.div`
  .head {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
    padding-right: 48px;
  }
  .content {
    padding: 16px 48px 0;
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .title {
    font-size: 14px;
    line-height: 1.5;
    color: ${COLORS.TEXT_HOVER};
  }
`;

export const Subtitle = styled.span`
  font-size: 12px;
  color: ${COLORS.SUBTEXT};
`;

export const ThemeWeightRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: start;
  padding-bottom: 16px;
`;

export const ThemesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Percentage = styled.span<{ isOff?: boolean }>`
  display: flex;
  align-items: center;
  padding-top: 8px;

  font-size: 14px;
  color: ${({ isOff }) => (isOff ? COLORS.COMPANY : COLORS.TEXT_HOVER)};
`;

export const RowHeader = styled.span`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.33;
  color: ${COLORS.SUBTEXT};
`;

export const WeightTotal = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: solid 1px ${COLORS.BORDERS};
  padding-top: 19px;
  font-size: 14px;
`;

export const RotateIcon180 = styled.div`
  svg {
    transform: rotate(180deg);
  }
`;

export const QuestionStatusWrapper = styled.div`
  margin-top: 20px;
  color: ${COLORS.TEXT_MAIN};

  .name {
    font-size: 12px;
    color: ${COLORS.TEXT_MAIN};
    font-weight: 500;
    word-break: break-all;
    padding-bottom: 5px;
  }

  .requirement {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 1px;

    .title {
      font-size: 12px;
      font-weight: 400;
      flex-grow: 1;

      :before {
        content: '•';
        padding-right: 8px;
      }
    }
  }

  .editQuestion {
    margin-top: 8px;
  }
`;
