import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const RatingsGridWrapper = styled.div`
  & > div > div {
    gap: 0;
  }

  & tr:last-of-type {
    td:not(:last-of-type) {
      div {
        background-color: ${COLORS.BG_ELEMENTS};
      }
    }

    td:first-of-type {
      div {
        background-color: ${COLORS.BG_ELEMENTS};

        & button {
          opacity: 0;
          pointer-events: none;
          cursor: pointer;
        }
        &:hover {
          color: ${COLORS.TEXT_MAIN};
        }
      }
    }
  }
`;

export const CellWrapper = styled.div<{
  clickable: boolean;
  fontSize?: number;
  $margin?: string;
  $padding?: string;
  enableHover?: boolean;
}>`
  display: flex;
  align-items: center;
  height: 60px;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  background: ${COLORS.BG_LIST};
  margin: ${({ $margin }) => $margin || 'auto'};
  padding: ${({ $padding }) => $padding || 'auto'};
  border-radius: 6px;
  font-size: ${({ fontSize }) => fontSize && fontSize}px;

  ${({ enableHover }) =>
    enableHover &&
    css`
      &:hover {
        background: ${COLORS.HOVER};
        color: ${COLORS.TEXT_HOVER};
      }
    `}
`;

export const CellLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: ${COLORS.SUBTEXT};
    font-size: 12px;
  }
`;

export const CellContent = styled.div`
  height: auto;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const OneLineWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: ${COLORS.DROPDOWN_ICON};
  }
`;

export const CellViewWrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.BG_LIST};
`;

export const CountLabel = styled.span`
  color: ${COLORS.PLACEHOLDERS};
  font-weight: 400;
`;

export const TableItem = styled.div<{ limitWidth?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
  background-color: ${COLORS.BG_LIST};
  color: ${COLORS.TEXT_MAIN};
`;

export const RatingHeader = styled(TableItem)`
  display: flex;
  flex-direction: row;
  background-color: ${COLORS.GRAY_LIGHT_BLUE};
  color: ${COLORS.SUBTEXT};
  justify-content: center;
  gap: 5px;
  font-weight: 600;

  > span {
    font-size: 12px;
    color: ${COLORS.PLACEHOLDERS};
    font-weight: 400;
    text-transform: capitalize;
  }
  > svg {
    cursor: pointer;
  }
  font-size: 12px;
`;
