import React from 'react';

import { REVIEW_QUESTION_EVALUATORS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { ICONS, Icon, ICON_SIZES } from '~/components/Icon';
import { IconWithGradientBlock } from '~/components/IconWithGradientBlock';
import Label from '~/components/Label';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { ValidationMessage } from '~/components/UI/ValidationMessage';
import { ValidationMessageStatus } from '~/components/UI/ValidationMessage/types';
import type { PopulatedReviewTemplate } from '~/pages/ReviewTemplateView/types';
import { QuestionModal } from '~/pages/ReviewThemeSetup/components/QuestionModal';
import { SkillModal } from '~/pages/ReviewThemeSetup/components/SkillModal';

import {
  Section,
  SectionTitle,
  Divider,
  Placeholder,
  EvaluatorsContainer,
  EvaluatorsDescription,
  NameContainer,
  NameWrapper,
  Subtitle,
  ThemeWeightRow,
  ThemesWrapper,
  RowHeader,
  Percentage,
  WeightTotal,
  RotateIcon180,
} from './design';
import { QuestionStatus } from './QuestionStatus';
import { useEvaluatorsAndThemes } from './useEvaluatorsAndThemes';

import { useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';

import { isRequiredRatedQuestion } from '../../utils';

import type { ReviewThemePreview } from './types';
import type { IReviewQuestion, ISkillCategory, WithPartial } from '@learned/types';

interface EvaluatorsAndThemesProps {
  reviewTemplate?: PopulatedReviewTemplate;
  evaluators: Array<{
    value: REVIEW_QUESTION_EVALUATORS;
    icon: ICONS;
    title: string;
  }>;
  reviewThemes?: ReviewThemePreview[];
  updateQuestion: (
    question: Omit<WithPartial<IReviewQuestion, 'name' | 'type'>, 'company' | 'meta'>,
  ) => void;
  skillCategories?: Record<string, ISkillCategory>;
  showReviewThemes?: boolean;
}

export const EvaluatorsAndThemes = ({
  reviewTemplate,
  evaluators,
  reviewThemes,
  updateQuestion,
  skillCategories = {},
  showReviewThemes = false,
}: EvaluatorsAndThemesProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const currentUser = useSelector(getUser);

  const languageState = useLanguageState();
  const {
    loading,
    prepareQuestionToEdit,
    editQuestion,
    handleSelectedSkillSubmit,
    handleSubmit,
    $isSkillModalOpen,
    preparedSkillCategories,
    setSelectedSkills,
    selectedSkills,
    selectedTheme,
    setSelectedTheme,
    setSelectedSkill,
    selectedSkill,
    skillModalSkills,
    setSkillModalSkills,
    setEditQuestion,
  } = useEvaluatorsAndThemes({
    languageState,
    template: reviewTemplate,
    updateQuestion,
    skillCategories,
  });

  const showTotalWeight = !isEmpty(
    Object.values(reviewTemplate?.themeWeights || {}).filter((item) => item > 0),
  );

  return (
    <>
      <Section $marginTop="14px">
        <SectionTitle>
          <Trans>Evaluators</Trans>
        </SectionTitle>
        <Divider />
        {!!reviewTemplate && !isEmpty(evaluators) ? (
          <>
            <EvaluatorsContainer>
              {evaluators?.map(({ title, icon }) => (
                <div className="evaluator" key={title}>
                  <Icon icon={icon} size={ICON_SIZES.LARGE} />
                  <span className="title">{title}</span>
                </div>
              ))}
            </EvaluatorsContainer>
            <EvaluatorsDescription>
              <Trans>They will receive a task to provide input for this review.</Trans>
            </EvaluatorsDescription>
          </>
        ) : (
          <Placeholder>
            <Trans>No template selected.</Trans>
          </Placeholder>
        )}
      </Section>
      {showReviewThemes && (
        <ShowSpinnerIfLoading loading={loading}>
          <Section $marginTop="14px">
            <SectionTitle>
              <Trans>Themes</Trans>
            </SectionTitle>
            <Divider />

            {!!reviewTemplate && !isEmpty(reviewThemes) ? (
              <ThemesWrapper>
                <ThemeWeightRow>
                  <RowHeader>
                    <Trans>Theme</Trans>
                  </RowHeader>
                  <RowHeader>
                    <Trans>Weighting</Trans>
                  </RowHeader>
                </ThemeWeightRow>
                {reviewThemes?.map(({ id, name, icon, iconColor, questions, themeWeight }) => {
                  const isWeighted = themeWeight && themeWeight > 0;
                  // if all questions have problem
                  const hasProblem = isWeighted
                    ? questions.every((question) => !isRequiredRatedQuestion(question))
                    : false;

                  // if only some of questions have problem
                  const hasWarning =
                    !hasProblem && isWeighted
                      ? questions.some((question) => !isRequiredRatedQuestion(question))
                      : false;

                  return (
                    <ThemeWeightRow key={id}>
                      <NameContainer>
                        <div className="head">
                          <IconWithGradientBlock
                            icon={icon}
                            iconColor={iconColor}
                            size={40}
                            iconSize={20}
                          />

                          <NameWrapper>
                            <span className="title">{getMultiLangString(name ?? '')}</span>
                            <Subtitle>Includes {questions.length} questions</Subtitle>
                          </NameWrapper>

                          {hasProblem ? (
                            <RotateIcon180>
                              <Label
                                color={COLORS.ACCENT_ERROR}
                                icon={ICONS.INFO_3}
                                backgroundByPercentage={30}
                              >
                                <Trans>Problem</Trans>
                              </Label>
                            </RotateIcon180>
                          ) : (
                            <Label
                              color={COLORS.ACCENT_SUCCESS}
                              icon={ICONS.CHECKMARK}
                              backgroundByPercentage={30}
                            >
                              <Trans>Success</Trans>
                            </Label>
                          )}
                        </div>
                        {(hasProblem || hasWarning) && (
                          <div className="content">
                            <ValidationMessage
                              message={
                                currentUser.isAdmin
                                  ? i18n._(t`Please fix the issues for at least 1 job`)
                                  : i18n._(t`Your admin can edit the theme.`)
                              }
                              fixedCount={hasProblem ? 0 : 1}
                              totalCount={1}
                              showCounterBadge={currentUser.isAdmin}
                              status={
                                hasProblem
                                  ? ValidationMessageStatus.ERROR
                                  : ValidationMessageStatus.SUCCESS
                              }
                            />
                            {questions.map((item) => (
                              <QuestionStatus
                                key={item.id}
                                question={item}
                                hasWarning={hasWarning}
                                prepareQuestionToEdit={prepareQuestionToEdit}
                                showEditButton={currentUser.isAdmin}
                              />
                            ))}
                          </div>
                        )}
                      </NameContainer>

                      <Percentage isOff={!themeWeight}>
                        {themeWeight ? (
                          <span>{themeWeight}%</span>
                        ) : (
                          <span>
                            <Trans>OFF</Trans>
                          </span>
                        )}
                      </Percentage>
                    </ThemeWeightRow>
                  );
                })}
                {showTotalWeight && (
                  <ThemeWeightRow>
                    <div />
                    <WeightTotal>100%</WeightTotal>
                  </ThemeWeightRow>
                )}
              </ThemesWrapper>
            ) : (
              <Placeholder>
                <Trans>No template selected.</Trans>
              </Placeholder>
            )}
          </Section>
        </ShowSpinnerIfLoading>
      )}
      {editQuestion && (
        <QuestionModal
          languageState={languageState}
          onClose={() => setEditQuestion(undefined)}
          onSubmit={handleSubmit}
          setIsSkillModalOpen={$isSkillModalOpen.set}
          skillCategories={preparedSkillCategories}
          selectedSkills={selectedSkills}
          setSelectedSkills={setSelectedSkills}
          selectTheme
          selectedQuestionToEdit={editQuestion}
          selectedTheme={selectedTheme ?? null}
          hidePreview
          setSelectedTheme={setSelectedTheme}
          // @ts-ignore
          setPreviewQuestion={prepareQuestionToEdit}
        />
      )}
      {$isSkillModalOpen.value && (
        <SkillModal
          onClose={() => $isSkillModalOpen.off()}
          onSubmit={handleSelectedSkillSubmit}
          setSelectedSkill={setSelectedSkill}
          selectedSkill={selectedSkill}
          skills={skillModalSkills}
          setSkills={setSkillModalSkills}
          selectedSkills={selectedSkills}
        />
      )}
    </>
  );
};
