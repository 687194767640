import React from 'react';

import { REVIEW_AVERAGE_SCORE_ROW_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { TableGrid } from '~/components/TableGrid';

import { RatingsGridWrapper } from './design';

import type { IColumnTable } from '~/@types/table';

import type { IChildTransformed, IRowTransformed } from '../../types';

interface IRatingsGridProps {
  items: (IRowTransformed | IChildTransformed)[];
  columns: IColumnTable<any>[];
  onToggleTheme: (item: IChildTransformed) => void;
}

export const RatingsGrid = ({ items, columns, onToggleTheme }: IRatingsGridProps) => {
  const { i18n } = useLingui();

  return (
    <RatingsGridWrapper>
      <TableGrid
        data={items.filter(
          (item: IRowTransformed | IChildTransformed) =>
            (item as IChildTransformed).type === REVIEW_AVERAGE_SCORE_ROW_TYPE.THEME ||
            (item as IChildTransformed).isVisible,
        )}
        columns={columns}
        isLeftColumnsStriped
        isRightColumnsStriped
        onColClick={{
          column: REVIEW_AVERAGE_SCORE_ROW_TYPE.THEME,
          onClick: onToggleTheme,
        }}
        visibleColumn={'noColumn'}
        isScrollbarVisible={false}
        placeholderProps={{
          noResultText: i18n._(t`No review participation data`),
        }}
        disableHeaderHover
      />
    </RatingsGridWrapper>
  );
};
