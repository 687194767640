import React from 'react';

import { Trans } from '@lingui/macro';

import { ContextMenu, ContextOption } from '~/components/ContextMenu';
import { ICONS } from '~/components/Icon';

import { DeleteOptionWrapper, DeleteText } from './design';

interface EditDeleteFocusMenuProps {
  editFunction: (id: string, index: number) => void;
  deleteFunction: (id: string) => void;
  index: number;
  focusArea?: string;
}

const EditDeleteFocusMenu = ({
  editFunction,
  deleteFunction,
  focusArea,
  index,
}: EditDeleteFocusMenuProps) => {
  return (
    <ContextMenu>
      <ContextOption
        action={() => {
          focusArea && editFunction(focusArea, index);
        }}
        icon={ICONS.EDIT_PENCIL}
        persistOnClick
      >
        <Trans>Edit</Trans>
      </ContextOption>
      <DeleteOptionWrapper>
        <ContextOption
          action={() => focusArea && deleteFunction(focusArea)}
          icon={ICONS.DELETE_BIN}
          persistOnClick
        >
          <DeleteText>
            <Trans>Delete</Trans>
          </DeleteText>
        </ContextOption>
      </DeleteOptionWrapper>
    </ContextMenu>
  );
};

export { EditDeleteFocusMenu };
