import styled, { css } from 'styled-components';

import { Button } from '~/components/Buttons';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import { COLORS } from '~/styles';

export const ItemTitle = styled.div<{ fontSize?: string }>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  color: ${COLORS.TEXT_MAIN};
  font-size: ${({ fontSize }) => fontSize || '16px'};
  line-height: 1;
  letter-spacing: -0.18px;
  gap: 4px;
`;

export const ItemRow = styled.div<{ isChecked?: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 0 25px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  height: 64px;

  .checkbox {
    visibility: ${({ isChecked }) => (isChecked ? 'visible' : 'hidden')};
  }

  &:hover {
    background-color: ${COLORS.HOVER};
    border-radius: 6px;
    .checkbox {
      visibility: visible;
    }
  }
`;

export const SubRow = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 21px 0 23px;
  margin-left: 64px;
  border-radius: 6px;
  min-height: 64px;

  ${(props) =>
    props.selected &&
    css`
      ${ItemTitle} {
        color: ${COLORS.TEXT_MAIN};
      }
    `};
  :nth-child(odd) {
    background-color: ${COLORS.BG_LIST};
  }
  :hover {
    background-color: ${COLORS.HOVER};
  }

  .checkbox {
    visibility: ${({ selected }) => (selected ? 'visible' : 'hidden')};
  }

  &:hover {
    background-color: ${COLORS.HOVER};
    border-radius: 6px;
    .checkbox {
      visibility: visible;
    }
  }
`;

export const AddNewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  & > span {
    border: 1px solid ${COLORS.PLACEHOLDERS};
    display: flex;
    padding: 5px 9px;
    border-radius: 6px;
    cursor: pointer;
  }
`;

export const Rows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;

  :nth-child(odd) {
    ${ItemRow} {
      border-radius: 6px;
      background-color: ${COLORS.BG_LIST};
      :hover {
        background-color: ${COLORS.HOVER};
      }
    }
  }
`;

export const BoxNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 19px;
  width: 100%;
`;

export const IconNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 9px;
`;

export const RowClickContainer = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const SelectAllBar = styled.div<{ hasItems: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 11px 25px 9px;
  background-color: ${(props) => (props.hasItems ? COLORS.COMPANY : COLORS.BG_PAGE)};
  gap: 19px;
  font-size: 12px;
  line-height: 1.33;
  color: ${(props) => (props.hasItems ? COLORS.WHITE : COLORS.MIDDLE_GRAY)};
  text-transform: uppercase;
  align-items: center;
  margin-bottom: 3px;
  border-radius: 6px;
`;

export const Items = styled(ShowSpinnerIfLoading)`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const SubItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  max-height: 460px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: ${COLORS.BG_PAGE};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.PLACEHOLDERS};
    border-radius: 10px;
  }

  &::-webkit-scrollbar:hover {
    cursor: pointer;
  }
`;

export const SubTitle = styled.span`
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.MIDDLE_GRAY};
  font-size: 14px;
  margin: 0;
  padding: 0;
`;

export const ItemsSubTitle = styled(SubTitle)`
  font-size: 12px;
  line-height: 1;
  letter-spacing: -0.13px;
`;

export const ButtonWrapper = styled.button`
  cursor: pointer;
`;

export const ShowItemButton = styled(Button)<{ showItems?: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  background-color: transparent;
  border: none;
  color: ${COLORS.COMPANY};
  cursor: pointer;
  pointer-events: none;

  ${({ showItems }) =>
    !showItems
      ? css`
          svg {
            rotate: -90deg;
            transition: rotate 0.1s ease-in;
          }
        `
      : css`
          svg {
            rotate: 0deg;
            transition: rotate 0.1s ease-in;
          }
        `}
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
