import React from 'react';

import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { IconStatus } from '~/pages/Reviews/components/IconStatus';
import type { IThemeValidation } from '~/pages/Reviews/components/JobsValidation/types';
import { IconStatusType } from '~/pages/Reviews/types';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

const JobProfileWrapper = styled.div`
  margin-top: 12px;
  color: ${COLORS.TEXT_MAIN};

  .name {
    font-size: 12px;
    color: ${COLORS.TEXT_MAIN};
    font-weight: 500;
    word-break: break-all;
    padding-bottom: 5px;
  }

  .requirement {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 1px;

    .title {
      font-size: 12px;
      font-weight: 400;
      flex-grow: 1;

      :before {
        content: '•';
        padding-right: 8px;
      }
    }
  }

  .editQuestion {
    margin-top: 8px;
  }
`;

export const JobProfileSection = ({
  jobProfile,
  items,
}: IThemeValidation['items'][0]['items'][0]) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  return (
    <JobProfileWrapper>
      <div className="name">{getMultiLangString(jobProfile?.name ?? '')}</div>
      {items.map((item, index) => (
        <div key={index} className="requirement">
          <div className="title">{item.title(i18n)}</div>
          <IconStatus status={item.isValid ? IconStatusType.SUCCESS : IconStatusType.ERROR} />
        </div>
      ))}
    </JobProfileWrapper>
  );
};
