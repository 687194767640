import React, { ChangeEvent, useEffect, useState } from 'react';

import { HRIS_TOOLS_NAMES, REVIEW_PARTICIPATION_COLUMNS, TASK_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import size from 'lodash/size';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Dropdown } from '~/components/Dropdown';
import { ICON_SIZES, ICONS } from '~/components/Icon';
import type { IReviewDashboardCycleForm } from '~/pages/Reviews/DashboardCycle/ReviewDashboardForm/types';

import { FirstRow, RowLeft, RowRight, SearchFieldWrapper, ItemWrapper } from './design';
import { columnsNames } from './utils';

import useIntegrationSettings from '~/hooks/useIntegrationSettings';

import type { UseFormReturn } from 'react-hook-form';

interface IFiltersProps {
  isExpandMode: boolean;
  onToggleExpandMode: () => void;
  isAllTeamsOpened: boolean;
  onOpenAllTeams: () => void;
  onCloseAllTeams: () => void;
  filters: {
    columns: REVIEW_PARTICIPATION_COLUMNS[];
    search: string;
  };
  onChangeFilters: (key: string, value: unknown) => any;
  formMethods: UseFormReturn<IReviewDashboardCycleForm>;
}

const Filters = ({
  isExpandMode,
  onToggleExpandMode,
  filters,
  onChangeFilters,
  onOpenAllTeams,
  onCloseAllTeams,
  isAllTeamsOpened,
  formMethods,
}: IFiltersProps) => {
  const { i18n } = useLingui();

  const { watch } = formMethods;
  const settings = watch('settings');
  const tasks = watch('tasks');

  const [columnsList, setColumnsList] = useState<
    {
      key: false | REVIEW_PARTICIPATION_COLUMNS | string;
      name: string;
    }[]
  >([]);

  const { integrationSettings } = useIntegrationSettings({});

  useEffect(() => {
    const columns: {
      key: false | REVIEW_PARTICIPATION_COLUMNS | string;
      name: string;
    }[] = [
      tasks?.[TASK_TYPE.REVIEW_PEER_NOMINATE] && REVIEW_PARTICIPATION_COLUMNS.NOMINATED_PEERS,
      tasks?.[TASK_TYPE.REVIEW_SELF_EVALUATE] && REVIEW_PARTICIPATION_COLUMNS.SELF_REVIEW,
      tasks?.[TASK_TYPE.REVIEW_PEER_EVALUATE] && REVIEW_PARTICIPATION_COLUMNS.PEER_REVIEW,
      tasks?.[TASK_TYPE.REVIEW_COACH_EVALUATE] && REVIEW_PARTICIPATION_COLUMNS.COACH_REVIEW,
      settings?.isDigitalSign && REVIEW_PARTICIPATION_COLUMNS.SIGNED,
      REVIEW_PARTICIPATION_COLUMNS.COMPLETED_REVIEW,
      tasks?.[TASK_TYPE.REVIEW_PEER_EVALUATE] && REVIEW_PARTICIPATION_COLUMNS.PEER_REVIEW_PROVIDED,
      tasks?.[TASK_TYPE.REVIEW_COACH_EVALUATE] &&
        REVIEW_PARTICIPATION_COLUMNS.COACH_REVIEW_PROVIDED,
    ]
      .filter((i) => i)
      .map((key) => {
        const getName = columnsNames[key as REVIEW_PARTICIPATION_COLUMNS];
        return {
          key,
          name: getName && getName(i18n),
        };
      });

    // Check and adding current kombo integration
    integrationSettings.forEach((item) => {
      columns.push({
        key: item.id,
        name: HRIS_TOOLS_NAMES[item.komboData.tool],
      });
    });

    setColumnsList(columns);
  }, [tasks, integrationSettings, settings?.isDigitalSign, i18n]);

  const selectedColumns = columnsList.filter((item) =>
    filters.columns.includes(item.key as REVIEW_PARTICIPATION_COLUMNS),
  );

  const searchComponent = (
    <SearchFieldWrapper
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        onChangeFilters('search', e.currentTarget.value)
      }
      value={filters.search}
      placeholder={i18n._(t`Search...`)}
      style={{
        width: '350px',
        borderRadius: '10rem',
        fontSize: '14px',
        fontWeight: 600,
      }}
    />
  );

  const expandButton = (
    <Button
      type="button"
      size={ButtonSize.MEDIUM}
      variant={ButtonVariant.ICON}
      icon={isExpandMode ? ICONS.COLLAPSE_WINDOW : ICONS.EXPAND}
      onClick={onToggleExpandMode}
    />
  );

  const openAllTeamsButton = (
    <Button
      variant={ButtonVariant.SECONDARY}
      icon={isAllTeamsOpened ? ICONS.CLOSE_ALL : ICONS.OPEN_ALL}
      iconSize={ICON_SIZES.MEDIUM}
      size={ButtonSize.MEDIUM}
      label={isAllTeamsOpened ? i18n._('Close all') : i18n._('Open all')}
      onClick={isAllTeamsOpened ? onCloseAllTeams : onOpenAllTeams}
    />
  );

  const showHideColumnsDropdown = (
    <Dropdown
      placeholder={i18n._(t`Show/hide columns`)}
      items={columnsList}
      skipSort={true}
      isSearchable={size(columnsList) > 7}
      stringifyItem={(item) => item.name}
      selectedItems={selectedColumns}
      onChange={(newValues) =>
        onChangeFilters(
          'columns',
          newValues.map((item) => item.key),
        )
      }
      isSingleSelect={false}
    />
  );

  return (
    <FirstRow>
      <RowLeft>{searchComponent}</RowLeft>
      <RowRight>
        <ItemWrapper>{openAllTeamsButton}</ItemWrapper>
        <ItemWrapper>{showHideColumnsDropdown}</ItemWrapper>
        <ItemWrapper>{expandButton}</ItemWrapper>
      </RowRight>
    </FirstRow>
  );
};

export { Filters };
