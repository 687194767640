import React, { useCallback, useEffect, useRef } from 'react';

import { t } from '@lingui/macro';
import { Trans, useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import debounce from 'lodash/debounce';
import size from 'lodash/size';
import { Controller, type UseFormReturn } from 'react-hook-form';
import styled, { css } from 'styled-components';

import { ButtonShadow } from '~/components/Button';
import Editor from '~/components/Editor';
import { IconOld } from '~/components/IconOld';
import Pencil2Icon from '~/components/Icons/Pencil2';
import { Input } from '~/components/Input';
import Popover from '~/components/Popover';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import { IconColorPicker } from './IconColorPicker';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';
import { COLORS } from '~/styles';

import { SubmitButton, Error } from '../design';
import { ERRORS } from '../validation';

import type { IGeneralForm } from '../types';
import type { ISurveyTheme } from '@learned/types';

export interface IGeneralProps {
  isLoading: boolean;
  themeId: ISurveyTheme['id'];
  formMethods: UseFormReturn<IGeneralForm>;
  setCurrentSection: (index: number) => void;
  languageState: ILanguageStateReturn;
  handleUpdateTheme: () => void;
  triedToSubmit: boolean;
}

const Form = styled.form`
  border-radius: 10px;
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 32px 40px;
`;

const Title = styled.h2`
  font-size: 26px;
  color: ${COLORS.BLACK};
  font-weight: normal;
  margin-top: 0;
`;

const Label = styled.label`
  font-size: 14px;
  color: ${COLORS.TEXT_BLACK};
  padding-bottom: 4px;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 14px;
  gap: 68px;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowSwitch = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 26px;
`;

const EditIconColorButton = styled(ButtonShadow)`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  font-size: 12px;
  margin-top: 10px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: right;
`;

const IconPreview = styled.div<{ colors: string[] }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  ${({ colors }) =>
    colors?.length && colors.length > 1
      ? css`
          background: linear-gradient(to bottom, ${colors[0]}, ${colors[1]});
        `
      : css`
          background-color: ${colors[0]};
        `}
  border-radius: 6px;
  &:after {
    content: '*';
    position: absolute;
    top: -10px;
    right: -10px;
    color: ${COLORS.BLACK};
  }
`;

const IconPreviewWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-top: 22px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const General = ({
  isLoading,
  languageState,
  setCurrentSection,
  formMethods,
  handleUpdateTheme,
  triedToSubmit,
}: IGeneralProps) => {
  const { i18n } = useLingui();
  const popoverRef = useRef();

  const {
    register,
    watch,
    control,
    unregister,
    formState: { errors, isValid },
    setError,
    clearErrors,
    trigger,
  } = formMethods;

  useEffect(() => {
    if (!watch('icon')) {
      setError('icon', { type: 'required' });
    } else {
      clearErrors('icon');
    }
  }, [clearErrors, setError, watch]);

  const onSubmit = () => {
    setCurrentSection(1);
  };

  const nameFieldArray = useMultiLangFieldArray({
    name: 'name',
    control,
    unregister,
    languageState,
  });

  const descriptionFieldArray = useMultiLangFieldArray({
    name: 'description',
    control,
    unregister,
    languageState,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdateTheme = useCallback(
    debounce(() => handleUpdateTheme(), 10_000),
    [],
  );

  const iconErrorMessage =
    !!errors.icon && triedToSubmit ? i18n._(t`Icon field is required`) : undefined;

  return (
    <Form>
      <Title>{i18n._(t`General`)}</Title>
      <ShowSpinnerIfLoading loading={isLoading}>
        <Row>
          <InputContainer>
            <Label>
              <Trans id={t`Name*`} />
            </Label>
            {nameFieldArray.fields.map((field) => {
              const primaryLangError =
                errors.name?.message === ERRORS.missingPrimaryLang &&
                field.locale === languageState.companyPrimaryLanguage.locale;
              return (
                <Controller
                  key={field.id}
                  {...register(`name.${field.index}.value`)}
                  control={control}
                  render={({ field: { onBlur, onChange, value } }) => {
                    return (
                      <Input
                        error={
                          primaryLangError && triedToSubmit
                            ? i18n._(t`Name field is required`)
                            : undefined
                        }
                        value={value}
                        onBlur={async () => {
                          onBlur();
                          await trigger(['name', 'icon']);
                          handleUpdateTheme();
                        }}
                        onChange={async (args) => {
                          onChange(args);
                          await trigger(['name', 'icon']);
                          debounceUpdateTheme();
                        }}
                        key={field.id}
                        placeholder={i18n._(t`Example: Work environment`)}
                        width="281px"
                        leftIcon={
                          size(languageState.languages) > 1
                            ? getUnicodeFlagIcon(
                                field.locale.substring(field.locale.indexOf('_') + 1),
                              )
                            : undefined
                        }
                        maxLength={60}
                      />
                    );
                  }}
                />
              );
            })}
          </InputContainer>
          <div>
            <div>
              <IconPreviewWrapper>
                <IconPreview colors={watch('iconColor')?.split('-')}>
                  {/* @ts-ignore */}
                  <IconOld name={watch('icon')} width={32} height={32} />
                </IconPreview>
                {/* @ts-ignore */}
                <Popover
                  ref={popoverRef}
                  trigger="click"
                  content={<IconColorPicker formMethods={formMethods} />}
                  minWidth={480}
                  maxWidth={480}
                  boxShadow="0 8px 8px 0 rgba(30, 8, 99, 0.21)"
                  placement="bottom"
                  $border={10}
                  noBorder
                  appendTo={document.body}
                  onHide={() => handleUpdateTheme()}
                >
                  <EditIconColorButton type="button">
                    <Pencil2Icon size={20} />
                    <Trans id={t`Edit icon & color`} />
                  </EditIconColorButton>
                </Popover>
              </IconPreviewWrapper>
            </div>
            <div>{iconErrorMessage && <Error>{iconErrorMessage}</Error>}</div>
          </div>
        </Row>
        <DescriptionWrapper>
          <Label>
            <Trans id={t`Description`} />
          </Label>
          <InputContainer>
            {descriptionFieldArray.fields.map((field) => (
              <Controller
                key={field.id}
                {...register(`description.${field.index}.value`)}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Editor
                    minHeight={'250px'}
                    placeholder={i18n._(
                      t`Example: The work environment refers to the physical and social surroundings in which employees perform their job duties. This can include factors such as…`,
                    )}
                    value={value}
                    compact
                    // @ts-ignore
                    onChange={(args) => {
                      onChange(args);
                      debounceUpdateTheme();
                    }}
                    onBlur={() => {
                      handleUpdateTheme();
                    }}
                    big
                    leftIcon={
                      size(languageState.languages) > 1
                        ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                        : undefined
                    }
                  />
                )}
              />
            ))}
          </InputContainer>
        </DescriptionWrapper>
        <RowSwitch>
          {/* <Controller
            name="isHideOnUserDashboard"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Switch
                // @ts-ignore
                onChange={async (args) => {
                  onChange(args);
                  handleUpdateTheme();
                  await trigger(['isHideOnUserDashboard', 'name', 'icon']);
                }}
                checked={value}
              />
            )}
          />
          <Trans id={t`Hide results from user dashboard reporting`} /> */}
        </RowSwitch>
        <Footer>
          <SubmitButton type="button" onClick={onSubmit} disabled={!!size(errors) || !isValid}>
            <span>{i18n._(t`Next`)}</span>
            <IconOld name="ChevronForward" width={16} height={16} className="icon" />
          </SubmitButton>
        </Footer>
      </ShowSpinnerIfLoading>
    </Form>
  );
};

export { General };
