import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';

import { InlineMultipleInput } from '~/components/InlineMultipleInput';

import { Container, NoteTitle, NotePoints, NotesContainer } from './design';

import { getUsers } from '~/selectors/baseGetters';
import isValidEmail from '~/utils/isValidEmail';

type InviteExternalPeersProps = {
  selectedItems?: string[];
  excludedEmails?: string[];
  onSelectedEmails?(emails: string[]): void;
  internalPeers?: string[];
};

const InviteExternalPeers = ({
  selectedItems = [],
  onSelectedEmails,
  excludedEmails = [],
  internalPeers = [],
}: InviteExternalPeersProps) => {
  const { i18n } = useLingui();
  const users = useSelector(getUsers);
  const selectedInternalPeerEmails: string[] = internalPeers.map((peer) => users[peer]?.email);

  return (
    <Container>
      <InlineMultipleInput
        placeholder={i18n._('Enter email address and press enter')}
        validator={isValidEmail}
        noSpace
        onSelectedItems={onSelectedEmails}
        excludeItems={[...excludedEmails, ...selectedInternalPeerEmails]}
        selectedItems={selectedItems}
        alreadySelectedErrorMessage={i18n._(
          t`Please enter an email address that has not been invited`,
        )}
      />
      <NotesContainer>
        <NoteTitle>{i18n._(t`Please note`)}</NoteTitle>
        <NotePoints>
          {i18n._(t`• External peers do not require login credentials for the Learned platform`)}
        </NotePoints>
        <NotePoints>
          {i18n._(
            t`• External peers will receive an invitation to provide input on the e-mail address above`,
          )}
        </NotePoints>
      </NotesContainer>
    </Container>
  );
};
export { InviteExternalPeers };
