import React from 'react';

import { TableOfContents } from '~/components/TableOfContents';
import { ReviewOutro } from '~/pages/ReviewGiveFeedback/components/ReviewOutro';

import { Footer } from './Footer';
import { MainView, QuestionWrapper, TableAndQuestions } from './QuestionsView.design';
import { QuestionView } from './QuestionView';
import { ReviewConfirmation } from './ReviewConfirmation';

import { ReturnTypeUseReviewRatingsState } from '../components/GiveReview.hooks';
import { PreviousAnswersContext } from '../hooks/previousAnswersContext';
import { useAllPreviousAnswers } from '../hooks/useAllPreviousAnswers';
import { IPopulatedReviewTask, type IPopulatedUserReview } from '../types';

import type { IUser } from '@learned/types';

function QuestionsView({
  reviewRatingsState,
  reviewTask,
  userReview,
  isCoach,
  userFromObject,
  useMultiLangString,
  isPreview,
  validationModalToggle,
}: {
  reviewRatingsState: ReturnTypeUseReviewRatingsState;
  reviewTask: IPopulatedReviewTask;
  userReview: IPopulatedUserReview;
  isCoach?: boolean;
  userFromObject?: IUser;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
  isPreview?: boolean;
  validationModalToggle?: {
    value: boolean;
    on: () => void;
    off: () => void;
  };
}) {
  const {
    isSubmitting,
    hasError,
    hasCommentError,
    sectionState,
    showOutro,
    nextSection,
    previousSection,
    setShowOutro,
    languageState,
    formData,
    onChange,
    onPublish,
    onPublishOutside,
    onSave,
    onSaveOutside,
    onSaveCalibrate,
    showExternalConfirmation,
    onBlurTextArea,
    lastQuestion,
    highestAnsweredQuestion,
  } = reviewRatingsState;

  const allPreviousAnswers = useAllPreviousAnswers({ userReviewId: userReview.id });

  const enableBackButton = () => {
    if (sectionState.currentSection === 0) {
      return true;
    } else if (sectionState.currentSection === 1) {
      const section = sectionState.sections.find((section) => section.sectionNumber === '1');
      return section?.childSections && section?.childSections?.length > 0;
    }
    return false;
  };
  return (
    <>
      {showExternalConfirmation.value ? (
        <ReviewConfirmation />
      ) : (
        <MainView>
          <TableAndQuestions>
            <TableOfContents
              isPreview={isPreview}
              sections={sectionState.sections}
              currentSection={sectionState.currentSection}
              hideErrorState={!sectionState.triedToSubmit}
              setCurrentSection={(i) => {
                sectionState.setCurrentSection(i);
                setShowOutro(false);
              }}
              noNextSection={showOutro || (!!lastQuestion && !!isPreview)}
              nextSection={nextSection}
              previousSection={previousSection}
              isShowLastPage
              setShowOutro={setShowOutro}
              highestAnsweredQuestion={highestAnsweredQuestion}
              isShowOutro={showOutro}
            />
            <QuestionWrapper>
              {!showOutro && sectionState.sections.at(sectionState.currentSection)?.data && (
                <PreviousAnswersContext.Provider value={allPreviousAnswers}>
                  <QuestionView
                    questionData={sectionState.sections.at(sectionState.currentSection)!.data!}
                    languageState={languageState}
                    ratings={formData.getValues('ratings')}
                    onChange={onChange}
                    reviewTask={reviewTask}
                    hasError={hasError}
                    hasCommentError={hasCommentError}
                    showOtherRatings={isCoach}
                    useMultiLangString={useMultiLangString}
                    onBlurTextArea={onBlurTextArea}
                    setGoalsPlanned={reviewRatingsState.setGoalsPlanned}
                    goalsPlanned={reviewRatingsState.goalsPlanned}
                    userReview={userReview}
                    isPreview={isPreview}
                  />
                </PreviousAnswersContext.Provider>
              )}
              {showOutro && (
                <ReviewOutro
                  isSubmitting={isSubmitting}
                  onPublish={reviewTask.userTo?.email ? onPublishOutside : onPublish}
                  onSave={reviewTask.userTo?.email ? onSaveOutside : onSave}
                  taskType={reviewTask.type}
                  taskStatus={reviewTask.status}
                  isExternalPeer={!!reviewTask.userTo?.email}
                  onSaveCalibrate={onSaveCalibrate}
                  userReview={userReview}
                  userFromObject={userFromObject}
                  validationModalToggle={validationModalToggle}
                  isPreview={isPreview}
                />
              )}
            </QuestionWrapper>
          </TableAndQuestions>
          <Footer
            isLoading={isSubmitting}
            onNext={showOutro ? undefined : nextSection}
            onBack={enableBackButton() ? undefined : previousSection}
          />
        </MainView>
      )}
    </>
  );
}

export { QuestionsView };
