import React, { ReactNode } from 'react';

import { IGoal } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';

import { CheckBox } from './components/CheckBox';
import { CheckBoxContainer, SelectActions, SelectedContainer, SelectText, Wrapper } from './design';

import { COLORS } from '~/styles';

export type IMultiSelect = {
  checkedCount: number;
  isAllChecked?: boolean;
  onCheckAll?: () => void;
  onSelectItem: (item: any) => void;
  isItemChecked: (item: any) => boolean;
  onDuplicate?: () => void;
  onUnarchive?: () => void;
  onArchive?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  isUserAllowedToSelectGoal?: (item: IGoal) => boolean;
  onDeleteButtonText?: string;
  extraButtons?: ReactNode[];
};

interface ISelectedHeader {
  multiSelect: IMultiSelect;
  isLoading?: boolean;
}

const SelectedHeader = ({ multiSelect, isLoading = false }: ISelectedHeader) => {
  const { i18n } = useLingui();

  return (
    <Wrapper>
      <SelectedContainer>
        <CheckBoxContainer>
          <CheckBox
            isSelected={multiSelect.isAllChecked}
            onChange={multiSelect.onCheckAll}
            color={COLORS.WHITE}
            isHovered={multiSelect.checkedCount > 0}
          />
        </CheckBoxContainer>
        <SelectText>{i18n._(t`Selected: ${multiSelect.checkedCount}`)}</SelectText>
        <SelectActions>
          {Boolean(multiSelect.extraButtons?.length) && multiSelect.extraButtons}
          {multiSelect.onDuplicate && (
            <Button
              type="button"
              label={i18n._(t`Duplicate`)}
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.PRIMARY}
              onClick={multiSelect.onDuplicate}
              icon={ICONS.DUPLICATE}
            />
          )}
          {multiSelect.onEdit && (
            <Button
              type="button"
              label={i18n._(t`Edit`)}
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.PRIMARY}
              onClick={multiSelect.onEdit}
              icon={ICONS.EDIT_PENCIL}
            />
          )}
          {multiSelect.onArchive && (
            <Button
              type="button"
              label={i18n._(t`Unarchive`)}
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.PRIMARY}
              onClick={multiSelect.onUnarchive}
              icon={ICONS.UNARCHIVE}
            />
          )}
          {multiSelect.onArchive && (
            <Button
              type="button"
              label={i18n._(t`Archive`)}
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.PRIMARY}
              onClick={multiSelect.onArchive}
              icon={ICONS.ARCHIVE}
            />
          )}
          {Boolean(multiSelect.onDelete) && (
            <Button
              type="button"
              label={multiSelect.onDeleteButtonText ?? i18n._(t`Delete`)}
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.PRIMARY}
              onClick={multiSelect.onDelete}
              icon={ICONS.DELETE_BIN}
              isLoading={isLoading}
            />
          )}
        </SelectActions>
      </SelectedContainer>
    </Wrapper>
  );
};

export { SelectedHeader };
