import AvatarEditor from 'react-avatar-editor';
import styled, { css } from 'styled-components';

import { Button } from '~/components/Buttons';
import { HeaderTabs } from '~/components/HeaderTabs';
import { Input } from '~/components/Input';
import Slider from '~/components/Slider';
import { SearchField } from '~/components/Text';

import { COLORS, PRIMARY_COLOR } from '~/styles';

export const Header = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.SUBTEXT};
  flex: auto;
`;

export const DeleteWrapper = styled.div`
  display: flex;
  flex: auto;
  justify-content: flex-end;
`;

export const Tabs = styled(HeaderTabs)`
  display: flex;
`;

export const AddButton = styled(Button)<{ toggleIcon: boolean }>`
  border: 1px solid ${COLORS.BORDERS};
  border-radius: 4px;

  & > .icon {
    transform: ${(props) => (props.toggleIcon ? 'rotate(45deg)' : 'rotate(0deg)')};
  }
`;

export const Wrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
`;

export const ColorTray = styled.div`
  & .sketch-picker {
    box-shadow: none !important;
    border: 1px solid ${COLORS.BORDERS};
    border-radius: 6px 6px 0px 0px !important;
  }
`;

export const ColorsWrapper = styled.div`
  & .grid {
    width: 82px;
    max-height: 285px;
    overflow-y: scroll;
    margin-top: 15px;
    padding-right: 10px;

    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    gap: 10px;

    & .customColor,
    & .colorWrapper {
      transform: rotateY(180deg);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      width: 32px;
      height: 32px;
      border-radius: 4px;
      background-color: ${COLORS.BG_PAGE};
      border: 1px solid ${COLORS.BORDERS};

      &.selected {
        border: 1px solid ${COLORS.COMPANY};
      }
    }

    & .customColor {
      border: 1px solid ${COLORS.PERFORMANCE_LIGHT};
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      overflow: hidden;
      input[type='color'] {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        appearance: none;
        -webkit-appearance: none;
        border: none;
        width: 100%;
        height: 100%;
        transform: scale(1.2);
      }
      input[type='color']::-webkit-color-swatch-wrapper {
        padding: 0;
        border: none;
      }
      input[type='color']::-webkit-color-swatch {
        border: none;
        padding: 0;
      }

      & .icon {
        cursor: pointer;
        z-index: 100;
      }
    }

    & .colorWrapper {
      overflow: hidden;
    }

    &::-webkit-scrollbar {
      width: 8px;
      position: absolute;
      left: 0;
      top: -5px;
    }

    &::-webkit-scrollbar-track {
      background: ${COLORS.BG_PAGE};
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${COLORS.BORDERS};
      border-radius: 100px;
    }

    &::-webkit-scrollbar:hover {
      cursor: pointer;
    }

    transform: rotateY(180deg);
  }
`;

export const ColorInputWrapper = styled.div`
  position: relative;
  &:before {
    position: absolute;
    font-size: 14px;
    top: 7px;
    left: 8px;
  }
`;

export const ColorInput = styled(Input)`
  height: 34px;
  max-width: 92px;

  & > div input {
    font-size: 13px !important;
  }
`;

export const Color = styled.div<{ colors: string[] }>`
  min-width: 32px;
  min-height: 32px;
  ${({ colors }) =>
    colors.length && colors.length > 1
      ? css`
          background: linear-gradient(to bottom, ${colors[0]}, ${colors[1]});
        `
      : css`
          background-color: ${colors[0]};
        `}
`;

export const CustomColor = styled.div<{ colors: string[] }>`
  min-width: 32px;
  min-height: 32px;
  ${({ colors }) =>
    colors.length && colors.length > 1
      ? css`
          background: linear-gradient(to bottom, ${colors[0]}, ${colors[1]});
        `
      : css`
          background-color: ${colors[0]};
        `}
`;

export const CustomColorButton = styled.button`
  border: 1px solid ${COLORS.BORDERS};
`;

export const BottomButtons = styled.div`
  background-color: ${COLORS.WHITE};
  padding: 15px 20px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  border-left: 1px solid ${COLORS.BORDERS};
  border-right: 1px solid ${COLORS.BORDERS};
  border-bottom: 1px solid ${COLORS.BORDERS};
  border-radius: 0px 0px 6px 6px !important;
`;

export const IconsWrapper = styled.div<{ currentColor: string }>`
  & .grid {
    width: 335px;
    max-height: 285px;
    overflow-y: scroll;
    margin-top: 15px;
    margin-bottom: 16px;
    padding-right: 10px;

    display: flex;
    flex-direction: row-reverse;
    justify-content: end;
    flex-wrap: wrap;
    gap: 10px;

    & .iconWrapper {
      transform: rotateY(180deg);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      width: 32px;
      height: 32px;
      border-radius: 4px;
      background-color: ${COLORS.BG_PAGE};
      border: 1px solid ${COLORS.BG_PAGE};

      &:hover {
        background-color: ${(props) => props.currentColor};
      }

      &.selected {
        background-color: ${(props) => props.currentColor};
        border: 1px solid ${COLORS.BORDERS};
      }
    }

    &::-webkit-scrollbar {
      width: 8px;
      position: absolute;
      left: 0;
      top: -5px;
    }

    &::-webkit-scrollbar-track {
      background: ${COLORS.BG_PAGE};
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${COLORS.BORDERS};
      border-radius: 100px;
    }

    &::-webkit-scrollbar:hover {
      cursor: pointer;
    }

    transform: rotateY(180deg);
  }
`;

export const IconColorPickerWrapper = styled.div`
  display: flex;
  gap: 10px;

  & > div:first-child {
    margin-top: 20px;
  }
`;

export const SearchInputField = styled(SearchField)`
  height: 34px;

  & > input {
    border-radius: 16px;
    border-color: ${COLORS.BORDERS};
  }
`;

export const UploaderWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 20px;
  height: 348px;

  & > svg {
    width: 52px;
    height: 52px;
  }

  & > span {
    width: 254px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: ${COLORS.ICONS_SECONDARY};
  }
`;

export const FileUploaderInput = styled.input`
  display: none;
`;

export const Editor = styled(AvatarEditor)`
  margin: 5px 0px 10px;
  border-radius: 6px;
`;

export const Footer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;

  div.scale-slider {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 285px;
    gap: 10px;
  }
`;

export const ScaleSlider = styled(Slider)`
  padding: 0px;
  height: auto;
`;

export const ScaleLabel = styled.span`
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.SUBTEXT};
`;

export const ScaleButton = styled(Button)`
  color: ${COLORS.SUBTEXT};
`;

export const SaveButton = styled(Button)`
  width: 66px;
  min-width: 66px;
  margin-left: 20px;
`;

export const PexelsWrapper = styled.div`
  margin-top: 20px;
`;

export const PexelsEmptyWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 20px;
  height: 294px;

  & > svg {
    width: 52px;
    height: 52px;
  }

  & > span {
    width: 254px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: ${COLORS.ICONS_SECONDARY};
  }
`;

export const PexelGrid = styled.div`
  max-height: 304px;
  overflow-y: scroll;
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding-left: 10px;
  direction: rtl;

  & > img {
    width: 100%;
    height: 75px;
    object-fit: cover;
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid transparent;
    box-sizing: border-box;
  }

  & > img:hover {
    border-color: ${COLORS.COMPANY};
  }

  &::-webkit-scrollbar {
    width: 8px;
    position: absolute;
    left: 0;
    top: -5px;
  }

  &::-webkit-scrollbar-track {
    background: ${COLORS.BG_PAGE};
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.BORDERS};
    border-radius: 100px;
  }

  &::-webkit-scrollbar:hover {
    cursor: pointer;
  }
`;

export const PexelsFooter = styled.div`
  font-size: 12px;
  margin: 15px 0 10px;
  color: ${COLORS.SUBTEXT};

  & > a {
    text-decoration: none;
    color: ${PRIMARY_COLOR};
  }
`;

export const PexelImage = styled.img`
  display: inherit;
`;

export const BackButton = styled(Button)`
  padding: 0px;
  min-width: auto;
  color: ${COLORS.SUBTEXT};

  &:hover {
    background-color: transparent !important;
    border: none;
    color: ${COLORS.SUBTEXT} !important;
  }
`;

export const CloseButton = styled(Button)`
  margin-right: 5px;
`;

export const HoverWrapper = styled.div<{ $isUpload?: boolean }>`
  width: 100%;
  height: 178px;
  position: relative;
  margin-bottom: 32px;
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDERS};
  background-color: ${COLORS.BG_LIST};

  & > div:hover {
    background: ${({ $isUpload }) => ($isUpload ? COLORS.BG_PAGE : 'rgb(255, 255, 255, 0.4)')};

    & button {
      opacity: 1;
    }

    & .placeholder {
      opacity: 1;
      font-size: 12px;
      text-align: center;
      color: ${({ $isUpload }) => ($isUpload ? COLORS.SUBTEXT : COLORS.TEXT_MAIN)};
      width: 254px;
      padding: 0 10px;
      margin-top: 8px;
      box-sizing: border-box;
    }
  }
`;

export const Hover = styled.div<{ $isUpload?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${({ $isUpload }) => ($isUpload ? COLORS.BG_LIST : 'transparent')};

  & .uploadButton {
    opacity: ${({ $isUpload }) => ($isUpload ? '1' : '0')};
    margin-top: 32px;
  }

  & .placeholder {
    opacity: 0;
    font-size: 12px;
    text-align: center;
    color: ${COLORS.PLACEHOLDERS};
    width: 254px;
    padding: 0 10px;
    margin-top: 8px;
    box-sizing: border-box;
  }
`;
