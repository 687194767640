import React from 'react';

import styled from 'styled-components';

import type { IThemeValidation } from '~/pages/Reviews/components/JobsValidation/types';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

import { JobProfileSection } from '../JobProfileSection';

const SkillCategoryWrapper = styled.div`
  margin-top: 12px;
`;

const SkillCategoryName = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${COLORS.TEXT_HOVER};
`;

const JobItems = styled.div`
  padding-left: 56px;
`;

export const SkillCategorySection = ({ skillCategory, items }: IThemeValidation['items'][0]) => {
  const getMultiLangString = useMultiLangString();

  return (
    <SkillCategoryWrapper>
      <SkillCategoryName>{getMultiLangString(skillCategory?.name ?? '')}</SkillCategoryName>
      <JobItems>
        {items.map(({ jobProfile, items }) => (
          <JobProfileSection
            key={`${skillCategory?.id}-${jobProfile.id}`}
            jobProfile={jobProfile}
            items={items}
          />
        ))}
      </JobItems>
    </SkillCategoryWrapper>
  );
};
