import React, { memo } from 'react';

import { t } from '@lingui/macro';

import ToolTip from '~/components/Tooltip';

import { FlexRow, TextRow } from './design';

import type { IColumnTable } from '~/@types/table';
import { useMultiLangString } from '~/hooks/useMultiLangString';

import type { IJobProfile } from '@learned/types';
import type { I18n } from '@lingui/core';

export enum JOB_SORT_OPTIONS {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
}

const NameColumn = memo(({ item }: { item: IJobProfile }) => {
  const getMultiLangString = useMultiLangString();
  return (
    <TextRow>
      <FlexRow>
        <ToolTip tooltip={getMultiLangString(item.name)}>
          <span>{getMultiLangString(item.name)}</span>
        </ToolTip>
      </FlexRow>
    </TextRow>
  );
});

export const getColumns = () => {
  const columns: IColumnTable<IJobProfile>[] = [
    {
      name: (i18n: I18n) => i18n._(t`Job`),
      accessor: 'job',
      renderCell: (item) => {
        return <NameColumn item={item} />;
      },
      sortBy: {
        asc: {
          key: JOB_SORT_OPTIONS.NAME_A_Z,
          label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
        },
        desc: {
          key: JOB_SORT_OPTIONS.NAME_Z_A,
          label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
        },
      },
    },
  ];

  return columns;
};
