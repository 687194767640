import React, { ReactNode } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Icon, ICONS } from '~/components/Icon';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';
import { UserAvatar } from '~/components/UserAvatar';
import { getGradient } from '~/pages/ReviewGiveFeedback/utils';

import { FocusAreaContainer, Score, ScoreText, UserAvatarName } from './FocusAreaAnswers.design';
import { NotSharedLabel } from './NotSharedLabel';

import type { IPartialReviewRating } from '../../../types';
import type { IUser } from '@learned/types';

const getRatingDisplay = (rating: IPartialReviewRating, total: number) => {
  const isRatingNA = rating.answer === null;
  if (rating.answer && Number(rating.answer) >= 0) {
    return (
      <>
        <span>{rating.answer}</span>/{total}
      </>
    );
  } else if (isRatingNA) {
    return <span>N/A</span>;
  } else {
    return (
      <>
        <span>-</span>/{total}
      </>
    );
  }
};

function FocusArea({
  rating,
  total,
  showNotSharedLabel,
  children,
}: {
  showNotSharedLabel?: boolean;
  rating: IPartialReviewRating;
  total: number;
  children?: ReactNode;
}) {
  const { i18n } = useLingui();

  return (
    <FocusAreaContainer $answered={!!(rating.answer && Number(rating.answer) > 0)}>
      <UserAvatarName>
        <div>
          {rating.createdBy.id ? (
            <UserAvatar userId={rating.createdBy.id} />
          ) : (
            <UserAvatar user={{ email: rating.createdBy.email! } as IUser} />
          )}{' '}
          <span className="review-type-label">({children})</span>
        </div>
        {showNotSharedLabel && (
          <ToolTip
            placement={TOOLTIP_PLACEMENTS.TOP}
            tooltip={i18n._(t`This input is not yet shared with the employee`)}
          >
            <div>
              <NotSharedLabel />
            </div>
          </ToolTip>
        )}
      </UserAvatarName>
      <Score
        gradient={
          rating.answer && Number(rating.answer) >= 0
            ? getGradient(rating.answer as number, total)
            : undefined
        }
      >
        <Icon icon={ICONS.QUESTION_STAR} />
        <ScoreText $inactive={!(rating.answer && Number(rating.answer) >= 0)}>
          {getRatingDisplay(rating, total)}
        </ScoreText>
      </Score>
    </FocusAreaContainer>
  );
}

export { FocusArea };
