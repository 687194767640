import React from 'react';

import { USER_REVIEW_STATUS } from '@learned/constants';
import { IUserReview } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';

import { HRISSendReport } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/sidebarLeft/sections/Details/components/HRISSendReport';
import { Separator } from '~/pages/Reviews/Modals/ReviewEmployeeOverviewModal/ReviewEmployeeOverviewForm/design';

import useIntegrationSettings from '~/hooks/useIntegrationSettings';

import { Section } from '../components/Section';

const ContentWrapper = styled.div`
  padding: 0 34px 16px 24px;
`;

type SendReportProps = {
  status: USER_REVIEW_STATUS;
  userReviewId: IUserReview['id'];
};

const SendReport = ({ status, userReviewId }: SendReportProps) => {
  const { i18n } = useLingui();

  const { reports, onSendReport } = useIntegrationSettings({
    userReviewId,
  });

  return !isEmpty(reports) ? (
    <div>
      <Separator />
      <Section
        title={i18n._(t`Send report`)}
        description={i18n._(
          t`A PDF of this review report is added to the employee personnel file.`,
        )}
      >
        {reports.map((data, index) => (
          <ContentWrapper key={index}>
            <HRISSendReport
              {...data}
              isEnabled={[USER_REVIEW_STATUS.COMPLETED, USER_REVIEW_STATUS.ARCHIVED].includes(
                status,
              )}
              statusBorder
              onSendReport={onSendReport}
            />
          </ContentWrapper>
        ))}
      </Section>
    </div>
  ) : null;
};

export { SendReport };
