import { TSecondaryHeaderColumn } from '~/components/TableGrid/types';

import { TResultColumn } from '../../../types';

export const createColumnSubHeader = (columns: TResultColumn[]) => {
  const secondaryHeaders: TSecondaryHeaderColumn[] = [];
  const ignoreColumns = ['primaryDimension', 'average'];
  columns.forEach((item) => {
    if (ignoreColumns.includes(item.id)) {
      return;
    } else {
      secondaryHeaders.push({
        title: item.theme.weight ? `${item.theme.weight}%` : '',
        isFixed: false,
        centerAlign: true,
      });
    }
  });
  return secondaryHeaders;
};
