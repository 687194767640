import styled from 'styled-components';

import { Button } from '~/components/Buttons';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DraftLabel = styled.span`
  width: 30px;
  height: 18px;
  padding: 1px 2px;
  border-radius: 3px;
  border: solid 1px ${COLORS.ACCENT_INFO};
  background-color: ${COLORS.STATUS_PREVIOUS_LIGHT};
  font-size: 12px;
  text-align: center;
`;

export const VisibleStatusContainer = styled.div`
  width: 100%;
  height: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;

  span {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export const StyledButton = styled(Button)`
  min-width: 18px;
  min-height: 18px;

  &:not(:disabled) {
    &:hover {
      background-color: transparent;
      border-color: transparent;
      color: ${COLORS.TEXT_MAIN};
    }
    &:active {
      background-color: transparent;
      border-color: transparent;
      color: ${COLORS.TEXT_MAIN};
    }
  }

  svg {
    min-width: 14px;
    min-height: 14px;
    height: 14px;
    width: 14px;
  }
`;

export const Option = styled.div<{ isErrorMessage?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  background-color: ${COLORS.WHITE};
  color: ${COLORS.ICONS_PRIMARY};
  padding: 10px 18px;
  user-select: none;
  cursor: pointer;
  z-index: 1000;
  position: absolute;
  right: 15px;
  top: 80px;
  border-radius: 6px;
`;
