import React, { Dispatch, SetStateAction } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { type FieldErrors, type UseFormRegister } from 'react-hook-form';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Button } from '~/components/Buttons/Button';
import { ICONS, Icon } from '~/components/Icon';

import {
  NewReportModal,
  ModalInner,
  ModalTitle,
  CrossButtonCtr,
  ModalBody,
  ModalFooter,
  Field,
} from './LayoutStyles';

import { useOutsideClick } from '~/hooks/useOutsideClick';

import type { TForm } from '../types';

type TProps = {
  setShowSaveModal: Dispatch<SetStateAction<boolean>>;
  onUpdateOrCreateReport: (isUpdate: boolean) => void;
  saveAsLoading: boolean;
  errors: FieldErrors<TForm>;
  register: UseFormRegister<TForm>;
};
const EngagementModal = ({
  setShowSaveModal,
  onUpdateOrCreateReport,
  saveAsLoading,
  errors,
  register,
}: TProps): JSX.Element => {
  const { i18n } = useLingui();
  const baseRef = useOutsideClick<HTMLDivElement>(() => {
    setShowSaveModal(false);
  });
  return (
    <NewReportModal>
      <ModalInner ref={baseRef}>
        <ModalTitle>
          <ModalTitle>{i18n._(t`Create custom report`)}</ModalTitle>
          <CrossButtonCtr onClick={() => setShowSaveModal(false)}>
            <Icon size={12} icon={ICONS.CLOSE} />
          </CrossButtonCtr>
        </ModalTitle>
        <ModalBody>
          <Field
            type="text"
            height="38px"
            label={i18n._(t`Name`)}
            placeholder={i18n._(t`Enter a valid Name`)}
            register={register('newName', {
              required: { value: true, message: i18n._(t`Name is required`) },
              maxLength: {
                value: 50,
                message: i18n._(t`Exceeds maximum length of 50 characters`),
              },
              minLength: {
                value: 3,
                message: i18n._(t`Requires a minimum of 3 characters`),
              },
              pattern: {
                value: /^[^\s]+(?:$|.*[^\s]+$)/,
                message: i18n._(t`Leading or trailing whitespace not allowed`),
              },
            })}
            error={errors.newName?.message}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            label={i18n._(t`Cancel`)}
            variant={ButtonVariant.SECONDARY}
            onClick={() => setShowSaveModal(false)}
            size={ButtonSize.MEDIUM}
          />
          <Button
            label={i18n._(t`Save`)}
            variant={ButtonVariant.PRIMARY}
            isLoading={saveAsLoading}
            onClick={() => {
              onUpdateOrCreateReport(false);
            }}
            size={ButtonSize.MEDIUM}
          />
        </ModalFooter>
      </ModalInner>
    </NewReportModal>
  );
};

export { EngagementModal };
