import { useMemo } from 'react';

import { isRequiredRatedQuestion } from '~/pages/Reviews/utils';

import type { IReviewIndividualForm } from '../types';
import type { UseFormReturn } from 'react-hook-form';

interface UseReviewProps {
  formMethods: UseFormReturn<IReviewIndividualForm>;
}

export const useCustomValidations = ({ formMethods }: UseReviewProps) => {
  const { watch } = formMethods;
  const watchReviewThemes = watch('reviewThemes');

  const reviewThemes = useMemo(() => {
    let isInvalid = false;

    watchReviewThemes?.forEach(({ questions, themeWeight }) => {
      const isWeighted = themeWeight && themeWeight > 0;
      // if all questions have problem
      const hasProblem = isWeighted
        ? questions.every((question) => !isRequiredRatedQuestion(question))
        : false;

      if (hasProblem) {
        isInvalid = true;
      }
    });

    return isInvalid;
  }, [watchReviewThemes]);

  // Only adds an error when there is an error in customValidations
  const customErrors = useMemo(() => {
    let errors: {
      reviewThemes?: boolean;
    } = {};
    if (reviewThemes) {
      errors = {
        ...errors,
        reviewThemes,
      };
    }

    return errors;
  }, [reviewThemes]);

  return {
    customErrors,
  };
};
