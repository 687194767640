import React from 'react';

import { REVIEW_QUESTION_TYPES, REVIEW_RATING_TYPE } from '@learned/constants';

import { TSecondaryHeaderColumn } from '~/components/TableGrid/types';
import {
  CoachCircle,
  PeerCircle,
  SelfCircle,
} from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/Questions/SkillAnswers/design';

import { ColumnPosition } from '~/@types/table';

import { TResultColumn } from '../../../types';
import { TableColumnSecondTitleContainer } from '../design';

const prepareSortByOptions = (
  questionType: REVIEW_QUESTION_TYPES,
  accessor: string,
  columnId: string,
) => {
  // only show sorting options for skill/rating questions
  if (
    ![
      REVIEW_QUESTION_TYPES.SKILL_CATEGORY,
      REVIEW_QUESTION_TYPES.CUSTOM_SKILL,
      REVIEW_QUESTION_TYPES.RATING,
    ].includes(questionType)
  ) {
    return undefined;
  }

  return {
    asc: {
      key: `${columnId} ${accessor}__asc`,
      label: 'Low - high',
    },
    desc: {
      key: `${columnId} ${accessor}__desc`,
      label: 'High - low',
    },
  };
};

export const createColumnSubHeader = (columns: TResultColumn[]) => {
  const secondaryHeaders: TSecondaryHeaderColumn[] = [];
  const ignoreColumns = ['primaryDimension'];
  columns.forEach((item) => {
    if (ignoreColumns.includes(item.id)) {
      return;
    }
    // goal-plan question does not have reviewer type
    else if (item.questionType === REVIEW_QUESTION_TYPES.GOAL_PLAN) {
      secondaryHeaders.push({
        title: '',
        columnPosition: ColumnPosition.RIGHT,
        centerAlign: true,
        isFixed: false,
      });
    } else {
      if (item.showCoach) {
        secondaryHeaders.push({
          title: () => {
            return (
              <TableColumnSecondTitleContainer>
                <CoachCircle />
                <span>{REVIEW_RATING_TYPE.COACH}</span>
              </TableColumnSecondTitleContainer>
            );
          },
          isFixed: item.id === 'average',
          columnPosition: item.id === 'average' ? ColumnPosition.RIGHT : undefined,
          centerAlign: true,
          accessor: `${item.id} value`,
          sortBy: prepareSortByOptions(
            item.questionType as REVIEW_QUESTION_TYPES,
            'value',
            item.id,
          ),
        });
      }
      if (item.showPeer) {
        secondaryHeaders.push({
          title: () => {
            return (
              <TableColumnSecondTitleContainer>
                <PeerCircle />
                <span>{REVIEW_RATING_TYPE.PEER}</span>
              </TableColumnSecondTitleContainer>
            );
          },
          columnPosition: item.id === 'average' ? ColumnPosition.RIGHT : undefined,
          isFixed: item.id === 'average',
          centerAlign: true,
          accessor: `${item.id} peer`,
          sortBy: prepareSortByOptions(item.questionType as REVIEW_QUESTION_TYPES, 'peer', item.id),
        });
      }
      if (item.showSelf) {
        secondaryHeaders.push({
          title: () => {
            return (
              <TableColumnSecondTitleContainer>
                <SelfCircle />
                <span>{REVIEW_RATING_TYPE.SELF}</span>
              </TableColumnSecondTitleContainer>
            );
          },
          columnPosition: item.id === 'average' ? ColumnPosition.RIGHT : undefined,
          isFixed: item.id === 'average',
          centerAlign: true,
          accessor: `${item.id} self`,
          sortBy: prepareSortByOptions(item.questionType as REVIEW_QUESTION_TYPES, 'self', item.id),
        });
      }
    }
  });
  return secondaryHeaders;
};
