import React, { MouseEvent, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE, REVIEW_QUESTION_TYPES } from '@learned/constants';
import {
  IMultiLangString,
  IReviewQuestion,
  IReviewQuestionCustomSkillSettings,
  IReviewQuestionDefaultData,
} from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import size from 'lodash/size';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { type FieldArrayWithId } from 'react-hook-form';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { IconNames } from '~/components/IconOld';
import Dot6Icon from '~/components/Icons/Dot6';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { CheckBox } from '~/components/TableList/components/CheckBox';
import { SelectedHeader } from '~/components/TableList/components/SelectedHeader';
import { IGeneralForm, PopulatedQuestion } from '~/pages/ReviewTemplateSetup/types';
import { QuestionPreviewModal } from '~/pages/ReviewThemeSetup/components/QuestionPreviewModal';
import { ISelectedSkill, type ISkillCategory } from '~/pages/ReviewThemeSetup/types';

import {
  AddQuestionHover,
  Button,
  ButtonText,
  CardWrapper,
  CreateNewQuestionWrapper,
  HoverIcon,
  ItemsContainer,
  MultiSelectCheckBoxWrapper,
  SelectedHeaderContainer,
  SmallButton,
  SmallButtonContainer,
  SmallButtonText,
  StyledPreviewQuestionCard,
  SubTitle,
} from './design';
import { Footer } from './Footer';

import useBoolState from '~/hooks/useBoolState';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';

import { ISelectedQuestion } from '..';

function QuestionsList({
  isLoading = false,
  invalidOptionalQuestions,
  items,
  createNewQuestion,
  importQuestions,
  languageState,
  onReorderQuestions,
  setEditQuestion,
  removeQuestion,
  setSelectedSkills,
  autosave,
  skillCategories,
  selectedQuestions,
  handleSelectAllQuestions,
  handleQuestionSelect,
  checkIsItemSelected,
  questionIdsList,
  handleMultipleDeleteConfirm,
}: {
  isLoading?: boolean;
  questionIdsList: FieldArrayWithId<IGeneralForm, 'questions', 'id'>[];
  invalidOptionalQuestions?: Record<string, PopulatedQuestion>;
  items: (IReviewQuestion & {
    themeName?: IMultiLangString;
    themeIcon: IconNames;
    themeIconColor: string;
  })[];
  createNewQuestion: (index?: number) => void;
  importQuestions: (index?: number) => void;
  languageState: ILanguageStateReturn;
  onReorderQuestions: (oldIndex: number, _newIndex: number) => void;
  setEditQuestion: (questionId: string) => void;
  setSelectedSkills: (skills: ISelectedSkill[]) => void;
  removeQuestion: (index: number, item: IReviewQuestion) => void;
  skillCategories: ISkillCategory[];
  autosave: () => void;
  selectedQuestions: ISelectedQuestion[];
  handleSelectAllQuestions: () => void;
  handleQuestionSelect: (id: string) => void;
  checkIsItemSelected: (questionId?: string) => boolean;
  handleMultipleDeleteConfirm: () => void;
}) {
  const { i18n } = useLingui();

  const [previewQuestion, setPreviewQuestion] = useState<
    | (IReviewQuestion & {
        themeName?: IMultiLangString;
        themeIcon: IconNames;
        themeIconColor: string;
      })
    | undefined
  >();
  const [isDragging, setIsDragging] = useState(false);
  const getString = useMultiLangString();
  const $showWarningModal = useBoolState();

  return (
    <>
      <SubTitle>
        <Trans>
          Import questions from themes and change the order of the questions by dragging them.
        </Trans>
      </SubTitle>
      <ItemsContainer>
        <SelectedHeaderContainer>
          {selectedQuestions.length > 0 ? (
            <SelectedHeader
              isLoading={isLoading}
              multiSelect={{
                checkedCount: selectedQuestions.length,
                isAllChecked: questionIdsList.length === selectedQuestions.length,
                onCheckAll: handleSelectAllQuestions,
                onSelectItem: handleQuestionSelect,
                isItemChecked: checkIsItemSelected,
                onDelete: $showWarningModal.on,
              }}
            />
          ) : (
            <div>
              <MultiSelectCheckBoxWrapper onClick={handleSelectAllQuestions}>
                <CheckBox
                  isHovered
                  isSelected={questionIdsList.length === selectedQuestions.length}
                />

                <span>
                  <Trans>Question</Trans>
                </span>
              </MultiSelectCheckBoxWrapper>
            </div>
          )}
        </SelectedHeaderContainer>
        <DragDropContext
          onDragEnd={(result) => {
            setIsDragging(false);
            if (!result.destination) {
              return;
            }
            onReorderQuestions(result.source.index, result.destination.index);
            autosave();
          }}
          onDragStart={() => void setIsDragging(true)}
        >
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {items.map((item, index) => {
                  return (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <>
                          <CardWrapper
                            isDragging={isDragging}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <HoverIcon isDragging={isDragging} isActive={snapshot.isDragging}>
                              <div {...provided.dragHandleProps}>
                                <Dot6Icon size={32} />
                              </div>
                            </HoverIcon>
                            <StyledPreviewQuestionCard
                              key={index}
                              skillCategory={
                                item.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY
                                  ? getString(
                                      skillCategories.find(
                                        (sc) => sc.value === item.settings.skillCategory,
                                      )?.label || '',
                                    )
                                  : undefined
                              }
                              showCheckbox
                              onSelect={() => handleQuestionSelect(item.id)}
                              isSelected={checkIsItemSelected(item.id)}
                              showTemplates={false}
                              onClickTitle={() => setPreviewQuestion(item)}
                              themeName={item.themeName}
                              themeIcon={item.themeIcon}
                              themeIconColor={item.themeIconColor}
                              languageState={languageState}
                              $isError={!!invalidOptionalQuestions?.[item.id]}
                              index={index}
                              type={item.type}
                              name={Object.entries(item.name).map(([locale, value]) => ({
                                locale,
                                value,
                              }))}
                              isActive={snapshot.isDragging}
                              evaluators={
                                // @ts-ignore
                                (item.settings as IReviewQuestionDefaultData).evaluators || []
                              }
                            >
                              <Footer
                                onDelete={(e: MouseEvent) => {
                                  e.stopPropagation();
                                  removeQuestion(index, item);
                                }}
                                onEdit={async (e: MouseEvent) => {
                                  e.stopPropagation();
                                  const isConfirmed = await confirm({
                                    type: CONFIRMATION_MODAL_TYPE.WARNING,
                                    title: i18n._(t`Be aware!`),
                                    description: i18n._(
                                      t`Changing this question will affect all review templates that use this question.`,
                                    ),
                                  });
                                  if (isConfirmed) {
                                    setEditQuestion(item.id);
                                  }
                                }}
                              />
                            </StyledPreviewQuestionCard>
                          </CardWrapper>

                          {size(items) - 1 > index && (
                            <AddQuestionHover isDragging={isDragging}>
                              <SmallButtonContainer>
                                <SmallButton
                                  onClick={(e) => {
                                    createNewQuestion(index);
                                    e.preventDefault();
                                  }}
                                >
                                  <Icon icon={ICONS.ADD_PLUS} size={ICON_SIZES.SMALL} />
                                  <SmallButtonText>
                                    <Trans>Create Question</Trans>
                                  </SmallButtonText>
                                </SmallButton>
                                <SmallButton
                                  onClick={(e) => {
                                    importQuestions(index + 1);
                                    e.preventDefault();
                                  }}
                                >
                                  <Icon icon={ICONS.IMPORT} size={ICON_SIZES.SMALL} />
                                  <SmallButtonText>
                                    <Trans>Import Question</Trans>
                                  </SmallButtonText>
                                </SmallButton>
                              </SmallButtonContainer>
                            </AddQuestionHover>
                          )}
                        </>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <CreateNewQuestionWrapper>
          <Button
            onClick={(e) => {
              createNewQuestion(size(items));
              e.preventDefault();
            }}
          >
            <Icon icon={ICONS.ADD_PLUS} size={ICON_SIZES.SMALL} />
            <ButtonText>
              <Trans>Create Question</Trans>
            </ButtonText>
          </Button>
          <Button
            onClick={(e) => {
              importQuestions(size(items));
              e.preventDefault();
            }}
          >
            <Icon icon={ICONS.IMPORT} size={ICON_SIZES.SMALL} />
            <ButtonText>
              <Trans>Import Question</Trans>
            </ButtonText>
          </Button>
        </CreateNewQuestionWrapper>
      </ItemsContainer>
      {!!previewQuestion && (
        <QuestionPreviewModal
          question={previewQuestion}
          // @ts-ignore
          evaluators={previewQuestion.settings.evaluators || []}
          onClose={() => setPreviewQuestion(undefined)}
          themeName={getString(previewQuestion.themeName || '')}
          themeIcon={previewQuestion.themeIcon}
          iconColor={previewQuestion.themeIconColor}
          onEdit={async () => {
            const isConfirmed = await confirm({
              type: CONFIRMATION_MODAL_TYPE.WARNING,
              title: i18n._(t`Be aware!`),
              description: i18n._(
                t`Changing this question will affect all review templates that use this question.`,
              ),
            });
            if (isConfirmed) {
              setPreviewQuestion(undefined);
              setSelectedSkills(
                (previewQuestion?.settings as IReviewQuestionCustomSkillSettings)?.skills || [],
              );
              setEditQuestion(previewQuestion.id);
            }
          }}
        />
      )}
      {$showWarningModal.value && selectedQuestions.length > 0 && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.WARNING}
          title={i18n._(t`Warning`)}
          description={i18n._(
            t`Are you sure you want to remove this question(s) from the template?`,
          )}
          cancelButton={i18n._(t`Cancel`)}
          submitButton={i18n._(t`Continue`)}
          onClose={$showWarningModal.off}
          onSubmit={handleMultipleDeleteConfirm}
        />
      )}
    </>
  );
}

export { QuestionsList };
