import { useEffect, useState } from 'react';

import { IReviewTheme } from '@learned/types';
import { useLingui } from '@lingui/react';
import { TSecondaryHeaderColumn } from 'src/components/TableGrid/types';

import { isFirst } from '~/pages/Reports/common';

import { IColumnTable } from '~/@types/table';
import useDebounce from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import { getReviewResults, TGetReviewResults } from '~/services/reviews/reports/results';
import { processRows } from '~/utils/reports';

import { TReviewResultRow, TReviewTableData } from '../../types';
import { createColumns } from '../ResultsQuestionsTable/utils/createColumns';
import { createColumnSubHeader } from '../ResultsQuestionsTable/utils/createColumnSubHeader';

export const useReviewResultQuestions = () => {
  const { pagination, changePagination } = usePagination(50); // initial number of items per page
  const [filters, setFilters] = useState<{ search: string; themes: IReviewTheme[] }>({
    search: '',
    themes: [],
  });
  const [getRatingsDataPayload, setGetRatingsDataPayload] = useState<TGetReviewResults>();
  const [totalCount, setTotalCount] = useState(0);
  const [columns, setColumns] = useState<IColumnTable<any>[]>([]);
  const [sortBy, setSortBy] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const [secondaryHeaderColumns, setSecondaryHeaderColumns] = useState<TSecondaryHeaderColumn[]>();
  const [heatMapData, setHeatMapData] = useState<TReviewResultRow[]>([]);

  const debouncedGetRatingsDataPayload = useDebounce(getRatingsDataPayload, 500);

  const { i18n } = useLingui();
  // on first render
  // on filters change
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setValues(signal);

    return () => {
      controller.abort(); // cancel the request on component unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(debouncedGetRatingsDataPayload)]);

  const setValues = async (signal?: AbortSignal) => {
    await fetchData(signal);
  };

  const fetchData = async (signal?: AbortSignal) => {
    if (!debouncedGetRatingsDataPayload) {
      return;
    }
    try {
      setIsLoading(true);
      const { options, sorting, filters, reviewId, measure } = debouncedGetRatingsDataPayload;
      const response = await getReviewResults(
        {
          filters,
          options,
          sorting,
          reviewId,
          measure,
        },
        { signal },
      );

      const heatmapData = response.data as TReviewTableData;

      setTotalCount(heatmapData.total || 0);

      const hasSecondaryColumn = heatmapData.columns?.filter(
        (item) => item.id === 'secondaryDimension',
      );
      const heatMapRows: TReviewResultRow[] = heatmapData.rows.map((item, i, arr) => {
        return {
          ...item,
          showPrimary: hasSecondaryColumn.length === 0 ? true : isFirst(arr, i),
          nestedLevel: 0, // that is what we are using to nest the data
        };
      });

      const processedHeatMapRows: TReviewResultRow[] = [];

      processRows(heatMapRows, 0, processedHeatMapRows);

      setHeatMapData(processedHeatMapRows);
      setSecondaryHeaderColumns(createColumnSubHeader(heatmapData.columns));
      setColumns(createColumns(heatmapData.columns, i18n));
    } catch (ex) {
      setTotalCount(0);
      setHeatMapData([]);
      setColumns([]);
      setSecondaryHeaderColumns([]);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    columns,
    debouncedGetRatingsDataPayload,
    filters,
    heatMapData,
    isLoading,
    isFiltersVisible,
    pagination,
    secondaryHeaderColumns,
    setColumns,
    setFilters,
    setHeatMapData,
    setIsFiltersVisible,
    setSecondaryHeaderColumns,
    sortBy,
    setSortBy,
    totalCount,
    setTotalCount,
    setIsLoading,
    changePagination,
    setGetRatingsDataPayload,
    getRatingsDataPayload,
  };
};
