import styled from 'styled-components';

import { TableGrid } from '~/components/TableGrid';

import { COLORS } from '~/styles';

export const StyledTableGrid = styled(TableGrid)`
  margin-top: 23px;

  table tbody tr {
    height: 100%;
  }

  table tbody tr td {
    height: 100%;
    vertical-align: top;
    position: relative;
  }

  table tbody tr td:not(:last-child) > div {
    position: absolute;
    height: calc(100% - 3px);
    width: calc(100% - 3px);
  }
`;

export const NameRow = styled.div<{
  maxWidth?: string;
}>`
  display: flex;
  align-items: start;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: ${COLORS.BG_LIST};
  padding: 17px 14px;
  max-width: ${({ maxWidth }) => maxWidth || 'unset'};
  border-radius: 6px;
  height: 100%;
  color: ${COLORS.TEXT_MAIN};
  font-size: 14px;
  word-break: break-word;
`;

export const FocusAreaName = styled(NameRow)`
  align-items: center;
`;

export const LevelLabel = styled(NameRow)`
  & > span {
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
