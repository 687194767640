import styled, { css } from 'styled-components';

import { Icon } from '~/components/Icon';

import { COLORS } from '~/styles';

export const Text = styled.span<{ hasError?: boolean }>`
  margin-right: 16px;
  ${(props) =>
    props.hasError
      ? css`
          color: ${COLORS.ACCENT_ERROR};
        `
      : css`
          color: ${COLORS.ICONS_PRIMARY};
        `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px ${COLORS.BORDERS};
  padding-top: 20px;
  padding-bottom: 10px;
`;

export const IconContainer = styled.div<{ starSize: number; isPreviousAnswer?: boolean }>`
  display: flex;
  position: relative;
  width: ${(props) => props.starSize}px;
  height: ${(props) => props.starSize}px;
  padding: 2px;

  ${({ isPreviousAnswer }) =>
    isPreviousAnswer &&
    css`
      div.history-label {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        text-align: center;
        transform: translate(0, -20px);
        width: 100%;
        background-color: ${COLORS.BG_ELEMENTS};
        border-radius: 6px 6px 0 0;
        font-weight: 600;
        font-size: 12px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      background-color: ${COLORS.LIST_ITEM};
    `}
`;

export const FocusAreaRatings = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StarContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const StyledIcon = styled(Icon)<{ isSelected: boolean }>`
  ${(props) => css`
    fill: ${props.isSelected ? COLORS.COMPANY : COLORS.INACTIVE};
    color: ${props.isSelected ? COLORS.COMPANY : COLORS.INACTIVE};
  `}

  :hover {
    fill: ${COLORS.COMPANY};
    color: ${COLORS.COMPANY};

    ${(props) => css`
      opacity: ${props.isSelected ? 1 : 0.5};
    `}
  }

  cursor: pointer;
`;
