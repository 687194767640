import { I18n } from '@lingui/core';

export enum SETTING_TYPES {
  VIEWERS = 'viewers',
  SUBTYPE = 'type',
}

export interface DropdownItem {
  id: SETTING_TYPES;
  label: (i18n: I18n) => string;
}

export interface DropdownSubItem {
  id: SETTING_TYPES;
  label: string;
  tooltip?: string;
}
