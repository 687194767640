export const NO_ACCOUNT = '/no_account';
export const TOO_MANY_REQUESTS = '/too_many_requests';
export const DISABLED_ACCOUNT = '/disabled_account';
export const ERROR = '/error';
export const PERMISSION_DENIED = '/permission_denied';
export const REGISTER = '/register';
export const COMPANY_CREATE_SUCCESS = '/create-company-success';
export const NOT_FOUND = '/404';
export const ACCOUNT = '/account';
export const LOGOUT = '/logout';
export const LOGIN = '/login';
export const MAINTENANCE_MODE = '/maintenance-mode';

export const HOME = '/';
export const ADMIN = '/admin';
