import React, { useEffect } from 'react';

import { REVIEW_STATUS, REVIEW_VERSION } from '@learned/constants';
import { IReviewTemplate } from '@learned/types';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Switch from '~/components/Switch';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';
import { areWeightedThemesValid } from '~/pages/Reviews/utils';

import { isOldReview } from '~/utils/reviewUtils';

import {
  Separator,
  SubSectionWrapper,
  SubTitle,
  ToggleContainer,
  ToggleRow,
  ToggleText,
} from '../../design';

import type { GeneralSettingsType, IReviewIndividualForm } from '../../types';
import type { I18n } from '@lingui/core';
import type { UseFormReturn } from 'react-hook-form';

interface GeneralSettingsProps {
  formMethods: UseFormReturn<IReviewIndividualForm>;
  reviewTemplates: IReviewTemplate[];
  version?: REVIEW_VERSION;
  isEdit?: boolean;
}

type ToggleItem = {
  key: GeneralSettingsType;
  text: (i18n: I18n) => string;
  tooltip: (i18n: I18n) => string;
};

const TOGGLE_LIST: Array<ToggleItem> = [
  {
    key: 'isDigitalSign',
    text: (i18n: I18n) => i18n._(t`Digitally sign the review reports`),
    tooltip: (i18n: I18n) =>
      i18n._(
        t`Participants need to sign the review and receive a reminder 7 days before the end date`,
      ),
  },
  {
    key: 'isShowOverallRating',
    text: (i18n: I18n) => i18n._(t`Show the overall rating of the employee in the review report`),
    tooltip: (i18n: I18n) =>
      i18n._(
        t`Besides ratings per question, the review report will also contain an additional section with average ratings per theme and an overall rating`,
      ),
  },
  {
    key: 'isShowPreviousScore',
    text: (i18n: I18n) => i18n._(t`Show the previous scores`),
    tooltip: (i18n: I18n) =>
      i18n._(
        t`When answering the review questions and viewing the review report the previous answers to this question are shown.`,
      ),
  },
];

interface TooltipCondition {
  condition?: boolean;
  message: string;
}

const GeneralSettings = ({
  formMethods,
  reviewTemplates,
  version,
  isEdit,
}: GeneralSettingsProps) => {
  const { watch, setValue } = formMethods;
  const { i18n } = useLingui();

  const onChangeToggle = (key: GeneralSettingsType) => {
    setValue(`settings.${key}`, !watch(`settings.${key}`), { shouldDirty: true });
  };

  const watchReviewState = watch('status');

  const isPublished = watchReviewState !== REVIEW_STATUS.DRAFT;
  const savedStartDate = new Date(watch('fetchedReview').settings.startDate);
  const today = new Date();

  const isDigitalSignDisabled = isPublished && savedStartDate && savedStartDate < today;

  const watchDigitalSign = watch('settings.isDigitalSign');

  const watchReviewTemplate = watch('reviewTemplate');

  const selectedTemplate = reviewTemplates.find((template) => template.id === watchReviewTemplate);

  const isWeightedThemesValid = areWeightedThemesValid(selectedTemplate);

  const isShowPreviousScoreDisabled = () => {
    if (!watchDigitalSign) {
      return [REVIEW_STATUS.ARCHIVED].includes(watchReviewState);
    } else {
      // not possible to navigate to the edit view while in the archived or signing mode. so handling that case seemed not necessary
      return [REVIEW_STATUS.ARCHIVED, REVIEW_STATUS.COMPLETED].includes(watchReviewState);
    }
  };

  const isPrevScoreDisabled = isShowPreviousScoreDisabled();

  function getTooltipMessage(item: ToggleItem, i18n: I18n): string {
    const conditions: TooltipCondition[] = [
      {
        condition: item.key === 'isShowOverallRating' && isOldReview(version),
        message: i18n._(t`Can only be enabled when reviews use templates with weights`),
      },
      {
        condition: item.key === 'isShowOverallRating' && !isWeightedThemesValid,
        message: i18n._(t`The selected template does not contain weighted themes`),
      },
      {
        condition: item.key === 'isShowPreviousScore' && isOldReview(version),
        message: i18n._(t`This functionality cannot be enabled for old reviews`),
      },
      // when the review version is 3.0, but the isShowPreviousScore is disabled for other reasons,
      {
        condition:
          item.key === 'isShowPreviousScore' && !isOldReview(version) && isPrevScoreDisabled,
        message: i18n._(t`It is no longer possible to change this setting`),
      },
    ];

    // Find the first matching condition
    const matchedCondition = conditions.find(({ condition }) => condition);
    return matchedCondition ? matchedCondition.message : item.tooltip(i18n);
  }

  interface GetCheckedValueParams {
    item: ToggleItem;
    watch: UseFormReturn<IReviewIndividualForm>['watch'];
  }

  const getCheckedValue = ({ item, watch }: GetCheckedValueParams): boolean => {
    if (item.key === 'isShowOverallRating' && !isWeightedThemesValid) {
      return false;
    }
    return watch(`settings.${item.key}`);
  };

  useEffect(() => {
    if (!isWeightedThemesValid) {
      setValue('settings.isShowOverallRating', false);
    }
  }, [isWeightedThemesValid, setValue]);

  return (
    <SubSectionWrapper>
      <SubTitle>
        <Trans>General</Trans>
      </SubTitle>
      <Separator />
      <ToggleContainer>
        {TOGGLE_LIST.map((item) => (
          <ToolTip
            key={item.key}
            disabled={
              !isDigitalSignDisabled ||
              (isEdit && item.key === 'isDigitalSign') ||
              isPrevScoreDisabled
            }
            tooltip={i18n._(
              t`It is not possible to change this setting after the review cycle has started`,
            )}
          >
            <ToggleRow key={item.key}>
              <span>
                <Switch
                  onChange={() => onChangeToggle(item.key)}
                  checked={getCheckedValue({ item, watch })}
                  disabled={
                    isDigitalSignDisabled ||
                    (isEdit && item.key === 'isDigitalSign') ||
                    (item.key === 'isShowOverallRating' && isOldReview(version)) ||
                    (item.key === 'isShowOverallRating' && !isWeightedThemesValid) ||
                    (item.key === 'isShowPreviousScore' &&
                      (isOldReview(version) || isPrevScoreDisabled))
                  }
                />
              </span>
              <ToolTip
                tooltip={getTooltipMessage(item, i18n)}
                placement={TOOLTIP_PLACEMENTS.BOTTOM}
              >
                <ToggleText>{item.text(i18n)}</ToggleText>
              </ToolTip>
            </ToggleRow>
          </ToolTip>
        ))}
      </ToggleContainer>
    </SubSectionWrapper>
  );
};

export { GeneralSettings };
