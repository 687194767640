import React, { ReactNode } from 'react';

import { VIRTUAL_GOAL_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';

import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import Tooltip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import {
  RowItemWrapper,
  CollapseIconWrapper,
  GoalName,
  GoalIconWrapper,
  InfoColumn,
  RightContainer,
  HiddenIconWrapper,
} from '../design';

import type { IGoal } from '@learned/types';

interface IProps {
  children: ReactNode;
  name: string;
  rowIcon: ICONS;
  iconColor: string;
  handleCollapse?: () => void;
  onClick?: () => void;
  isCollapsed?: boolean;
  item: IGoal;
  isDashboardItem?: boolean;
  isPublicPage?: boolean;
}

const GoalRow = ({
  children,
  name,
  rowIcon,
  iconColor,
  handleCollapse,
  onClick,
  isCollapsed,
  item,
  isDashboardItem = false,
  isPublicPage = false,
}: IProps) => {
  const isChildren = !isEmpty(item.children);
  const isActivities = !isEmpty(item.activities);
  const isHidden = item.type === VIRTUAL_GOAL_TYPES.HIDDEN;

  const { i18n } = useLingui();

  return (
    <RowItemWrapper isDashboardItem={isDashboardItem}>
      <InfoColumn isDashboardItem={isDashboardItem} isPublicPage={isPublicPage}>
        {!isDashboardItem && (
          <CollapseIconWrapper onClick={handleCollapse} rotate={isCollapsed}>
            {(isChildren || isActivities) && <Icon icon={ICONS.CHEVRON} size={ICON_SIZES.SMALL} />}
          </CollapseIconWrapper>
        )}
        {(!isDashboardItem || isHidden) && (
          <GoalIconWrapper>
            {isHidden ? (
              <Tooltip
                tooltip={i18n._(t`You’re not allowed to see this because of privacy settings.`)}
                placement={TOOLTIP_PLACEMENTS.BOTTOM}
              >
                <HiddenIconWrapper>
                  <Icon icon={rowIcon} size={ICON_SIZES.MEDIUM} color={iconColor} />
                </HiddenIconWrapper>
              </Tooltip>
            ) : (
              <Icon icon={rowIcon} size={ICON_SIZES.MEDIUM} color={iconColor} onClick={onClick} />
            )}
          </GoalIconWrapper>
        )}
        {isHidden ? (
          <GoalName onClick={() => {}} isHidden>
            <Tooltip
              tooltip={i18n._(t`You’re not allowed to see this because of privacy settings.`)}
              placement={TOOLTIP_PLACEMENTS.BOTTOM}
            >
              <span>{name}</span>
            </Tooltip>
          </GoalName>
        ) : (
          <GoalName isDashboardItem={isDashboardItem} onClick={onClick}>
            <Tooltip tooltip={name}>
              <span>{name}</span>
            </Tooltip>
          </GoalName>
        )}
      </InfoColumn>
      <RightContainer isPublicPage={isPublicPage} onClick={onClick}>
        {children}
      </RightContainer>
    </RowItemWrapper>
  );
};

export { GoalRow };
