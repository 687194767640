import React, { ReactNode } from 'react';

import { ICON_SIZES, ICONS } from '~/components/Icon';

import { Background, StyledLabel, StyledIcon } from './design';

import { COLORS } from '~/styles';

export interface LabelProps {
  color?: string;
  backgroundColor?: string;
  backgroundByPercentage?: number;
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  icon?: ICONS | undefined;
  noBorder?: boolean;
}

const Label = ({
  color = COLORS.SUBTEXT,
  backgroundColor = 'transparent',
  backgroundByPercentage,
  disabled = false,
  children,
  className,
  icon,
  noBorder = false,
}: LabelProps) => {
  return (
    <StyledLabel
      color={color}
      backgroundColor={!backgroundByPercentage ? backgroundColor : 'transparent'}
      disabled={disabled}
      className={className}
      noBorder={noBorder}
    >
      {backgroundByPercentage && (
        <Background backgroundByPercentage={backgroundByPercentage} color={color} />
      )}
      {icon && (
        <StyledIcon icon={icon} size={ICON_SIZES.SMALL} color={disabled ? COLORS.SUBTEXT : color} />
      )}
      {children}
    </StyledLabel>
  );
};

export default Label;
