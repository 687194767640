import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Dropdown } from '~/components/Dropdown';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import { IMultiSelectOption } from '.';

export interface IFilters {
  isShowFilters: boolean;
  search: string;
  setSearch: (value: string) => void;
  onChangeFilter: (key: string, value: any) => void;
  resetFilters: () => void;
  jobFamilies?: IMultiSelectOption[];
  jobLevels?: IMultiSelectOption[];
}

const Filters = ({
  filters,
  jobFamilies,
  jobLevels,
}: {
  filters: IFilters;
  jobFamilies: IMultiSelectOption[];
  jobLevels: IMultiSelectOption[];
}) => {
  const getMultiLangString = useMultiLangString();
  const { i18n } = useLingui();
  return (
    <>
      <Dropdown
        placeholder={i18n._(t`Job family`)}
        items={jobFamilies}
        selectedItems={filters.jobFamilies || []}
        onChange={(selectedItems?: IMultiSelectOption[]) => {
          filters.onChangeFilter('jobFamilies', selectedItems || []);
        }}
        stringifyItem={(item) => item.name}
        isSingleSelect={false}
        skipSort={true}
      />

      <Dropdown
        placeholder={i18n._(t`Job level`)}
        items={jobLevels}
        selectedItems={filters.jobLevels || []}
        onChange={(selectedItems?: IMultiSelectOption[]) => {
          return filters.onChangeFilter('jobLevels', selectedItems || []);
        }}
        stringifyItem={(item) => getMultiLangString(item.name ?? '')}
        isSingleSelect={false}
        skipSort={true}
      />
    </>
  );
};

export { Filters };
