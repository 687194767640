import React, { useEffect, useMemo, useState } from 'react';

import {
  CONFIRMATION_MODAL_TYPE,
  SURVEY_STATUS,
  SURVEY_TEMPLATE_STATUSES,
} from '@learned/constants';
import { ISurvey } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import qs from 'qs';
import { useForm, type UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import styled from 'styled-components';

import { Button } from '~/components/Buttons/Button';
import { ButtonSize, ButtonVariant } from '~/components/Buttons/types';
import DashboardHeader from '~/components/DashboardHeader';
import { MultiLangComponent } from '~/components/Dropdown/MultiLangualDropdown';
import { FillOutSurvey } from '~/components/FillOutSurvey';
import { ICONS } from '~/components/Icon';
import { LastSaved } from '~/components/LastSaved';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { SideBar } from '~/components/SideBar';
import { useSectionState } from '~/components/SideBar/SectionStateHook';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { resolver } from '~/pages/SurveyTemplateUpdate/validation';

import { General, Questions, ThemeWeight } from './components';
import { ActionItemBlock, Actions } from './design';

import routes from '~/constants/routes';
import { useAutoSaveState } from '~/hooks/useAutoSaveState';
import { useFromQuery } from '~/hooks/useFromQuery';
import { useLanguageState } from '~/hooks/useLanguageState';
import { checkModuleSurvey } from '~/selectors/baseGetters';
import {
  deleteSurveyTemplates,
  getSurveyTemplate,
  updateSurveyTemplate,
} from '~/services/surveyTemplates';
import { generateEvenThemeWeights } from '~/utils/generateEvenThemeWeights';
import { turnArrayIntoMultiLang, turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import type { IGeneralForm, ITheme, PopulatedQuestion, PopulatedSurveyTemplate } from './types';
import type { IMultiLangString, ISurveyTemplate } from '@learned/types';

const Wrapper = styled.div`
  display: flex;
  gap: 151px;
  margin-top: 49px;
`;

const SurveyTemplateUpdate = () => {
  const { i18n } = useLingui();
  const { goBack } = useFromQuery({ includeHash: true });
  const { addToast } = useToasts();
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const params: Record<string, string | undefined> = useParams();
  const languageState = useLanguageState();
  const history = useHistory();
  const isModuleSurveyEnabled = useSelector(checkModuleSurvey);

  const [oldStatus, setOldStatus] = useState<SURVEY_TEMPLATE_STATUSES>();
  const isCreatingNew = query.isCreatingNew;
  const [showPreview, setShowPreview] = useState(false);
  const [editQuestion, setEditQuestion] = useState<string | undefined>();
  const [isDeletingTemplate, setIsDeletingTemplate] = useState(false);
  const [questions, setQuestions] = useState<Record<PopulatedQuestion['id'], PopulatedQuestion>>(
    {},
  );
  const {
    lastSavedTime,
    lastSavedStatus,
    lastSavedErrorMessage,
    setLastSaveSuccess,
    setLastSaveError,
  } = useAutoSaveState({ errorMessage: i18n._(t`Please fill all obligated fields`) });
  const generalFormMethods: UseFormReturn<IGeneralForm> = useForm<
    IGeneralForm,
    { companyPrimaryLanguage: string }
  >({
    mode: 'all',
    resolver,
    context: { companyPrimaryLanguage: languageState.companyPrimaryLanguage.locale },
    defaultValues: {
      questions: [],
      themeWeights: [],
      status: SURVEY_TEMPLATE_STATUSES.draft,
    },
  });
  const {
    trigger,
    getValues,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = generalFormMethods;
  const sectionState = useSectionState([
    {
      title: i18n._(t`General`),
      fields: [i18n._(t`Template Name`), i18n._(t`Description`)],
      isTouched: true,
    },
    {
      title: i18n._(t`Questions`),
    },
    {
      title: i18n._(t`Theme weights`),
    },
  ]);

  useEffect(() => {
    if (!isModuleSurveyEnabled) {
      history.push(routes.HOME);
    }
  }, [isModuleSurveyEnabled, history]);

  useEffect(() => {
    sectionState.setErrorSection(0, !!errors?.name || !!errors?.description);
    // eslint-disable-next-line
  }, [errors?.name, errors?.description]);

  useEffect(() => {
    sectionState.setErrorSection(1, !!errors?.questions);
    // eslint-disable-next-line
  }, [errors?.questions]);

  useEffect(() => {
    sectionState.setErrorSection(2, !!errors?.themeWeights);
    // eslint-disable-next-line
  }, [errors?.themeWeights]);

  const surveyTemplateId = params.surveyTemplateId as string;
  const themesMap = useMemo(() => {
    const themesMap: Record<string, ITheme> = {};
    Object.values(questions).forEach((q) => {
      if (!q.theme) {
        return;
      }

      themesMap[q.theme] = {
        id: q.theme,
        name: q.themeName as IMultiLangString,
        icon: q.themeIcon,
        iconColor: q.themeIconColor,
      };
    });

    return themesMap;
  }, [questions]);

  const goToEditQuestionModal = async (questionId: string) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Be aware`),
      description: i18n._(t`Changing this question will affect all surveys that use this question`),
    });
    if (isConfirmed) {
      sectionState.setCurrentSection(1);
      setEditQuestion(questionId);
      setShowPreview(false);
    }
  };

  const fetchTemplate = async () => {
    if (!surveyTemplateId) {
      return;
    }
    const { data } = await getSurveyTemplate(surveyTemplateId, {
      populate: ['questions'],
      joinToQuestions: ['themeName', 'themeIcon'],
    });
    const surveyTemplate: PopulatedSurveyTemplate = data.surveyTemplate;
    return surveyTemplate;
  };

  const handleFetchedTemplate = async (surveyTemplate: PopulatedSurveyTemplate) => {
    const templateQuestions: Record<PopulatedQuestion['id'], PopulatedQuestion> = {};
    surveyTemplate.questions.forEach((q) => {
      templateQuestions[q.id] = q;
    });
    setQuestions(templateQuestions);
    setOldStatus(surveyTemplate.status);

    const name = turnMultiLangIntoArray(surveyTemplate.name, languageState.companyLanguages);
    const description = turnMultiLangIntoArray(
      surveyTemplate.description,
      languageState.companyLanguages,
    );
    const questions = surveyTemplate.questions.map((q) => ({ questionId: q.id }));
    const themeWeights = Object.entries(surveyTemplate.themeWeights).map(([themeId, weight]) => ({
      themeId,
      weight,
    }));

    generalFormMethods.setValue('name', name);
    generalFormMethods.setValue('description', description);
    generalFormMethods.setValue('questions', questions);
    generalFormMethods.setValue('themeWeights', themeWeights);
    generalFormMethods.setValue('status', surveyTemplate.status);

    await trigger();
  };

  useEffect(() => {
    let mounted = true;
    const safeFetchTemplate = async () => {
      const surveyTemplate = await fetchTemplate();
      if (mounted && surveyTemplate) {
        await handleFetchedTemplate(surveyTemplate);
        if (query.questionId) {
          goToEditQuestionModal(query.questionId as string);
        }
      }
    };

    safeFetchTemplate();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, [surveyTemplateId]);

  const refreshTemplate = async () => {
    const surveyTemplate = await fetchTemplate();
    if (surveyTemplate) {
      await handleFetchedTemplate(surveyTemplate);
    }
  };

  const nameInPrimaryLanguage = (generalFormMethods.watch('name') || [])?.find(
    (name) => name.locale === languageState.companyPrimaryLanguage.locale,
  )?.value;

  const createPreview = () => {
    const values = getValues();
    const name = turnArrayIntoMultiLang(values.name);
    const fieldQuestions = values.questions
      .map((field) => questions[field.questionId])
      .filter((q) => q);

    return { name, questions: fieldQuestions, survey: { status: SURVEY_STATUS.ACTIVE } as ISurvey };
  };

  if (showPreview) {
    return (
      <>
        <FillOutSurvey
          onEdit={goToEditQuestionModal}
          isPreview
          surveyTask={createPreview()}
          goBack={() => setShowPreview(false)}
        />
      </>
    );
  }

  const onSuccess = async (data: IGeneralForm, isPublishing = false, isAutoSave = false) => {
    const transformedData = {
      name: turnArrayIntoMultiLang(data.name.filter((langValue) => langValue.value !== '')),
      description: turnArrayIntoMultiLang(
        data.description.filter((langValue) => langValue.value !== ''),
      ),
      themeWeights: data.themeWeights.reduce(
        (acc: ISurveyTemplate['themeWeights'], themeWeight) => {
          acc[themeWeight.themeId] = themeWeight.weight;
          return acc;
        },
        {},
      ),
      status: isPublishing
        ? SURVEY_TEMPLATE_STATUSES.active
        : (data.status as SURVEY_TEMPLATE_STATUSES),
      questions: data.questions.map((q) => q.questionId),
    };
    try {
      const result = await updateSurveyTemplate(surveyTemplateId, transformedData);
      if (result.code === 200) {
        setLastSaveSuccess();
        if (isPublishing) {
          routes.SURVEYS_OVERVIEW.go({}, { hash: 'templates' });
        }
        if (!isAutoSave && isPublishing) {
          addToast({ title: i18n._(t`Template published`), type: TOAST_TYPES.SUCCESS });
          goBack();
        } else if (
          !isAutoSave &&
          (data.status as SURVEY_TEMPLATE_STATUSES) === SURVEY_TEMPLATE_STATUSES.active
        ) {
          addToast({ title: i18n._(t`Template saved`), type: TOAST_TYPES.INFO });
          goBack();
        } else if (!isAutoSave) {
          addToast({ title: i18n._(t`Template saved as draft`), type: TOAST_TYPES.INFO });
          goBack();
        }
      } else {
        setLastSaveError();
      }
    } catch {
      return;
    }
  };

  const deleteTemplate = async () => {
    const result = await deleteSurveyTemplates([surveyTemplateId]);
    if (result.code === 200) {
      routes.SURVEYS_OVERVIEW.go({}, { hash: 'templates' });
      addToast({ title: i18n._(t`Template deleted`), type: TOAST_TYPES.INFO });
    }
  };

  const onFail = (isAutosave = false) => {
    if (!isAutosave) {
      sectionState.setTriedToSubmit();
      addToast({
        title: i18n._(t`Warning`),
        subtitle: i18n._(t`Please fill all obligated fields`),
        type: TOAST_TYPES.INFO,
      });
      setTimeout(() => sectionState.goToFirstErrorSection(), 100);
    }
    setValue('status', oldStatus);
    trigger();
  };

  const onAutosave = async (e?: React.BaseSyntheticEvent) => {
    const submit = handleSubmit(
      (data) => {
        return onSuccess(data, false, true);
      },
      () => onFail(true),
    );
    return submit(e);
  };

  const onSave = async (e?: React.BaseSyntheticEvent) => {
    const submit = handleSubmit(
      (data) => {
        return onSuccess(data, false);
      },
      () => onFail(),
    );

    return submit(e);
  };

  const onPublish = async (e?: React.BaseSyntheticEvent) => {
    setValue('status', SURVEY_TEMPLATE_STATUSES.active);
    const submit = handleSubmit(
      (data) => {
        return onSuccess(data, true);
      },
      () => onFail(),
    );

    return submit(e);
  };
  const status = watch('status');

  const equalizeWeights = (themeIds?: string[]) => {
    themeIds = themeIds ?? Object.keys(themesMap);
    const newThemeWeights = generateEvenThemeWeights(themeIds);
    const themeWeights = Object.entries(newThemeWeights).map(([themeId, weight]) => ({
      themeId,
      weight,
    }));

    setValue('themeWeights', themeWeights);
    trigger('themeWeights');
  };

  return (
    <>
      {isModuleSurveyEnabled && (
        <>
          <DashboardHeader
            title={`${
              isCreatingNew ? i18n._(t`Creating a new template`) : i18n._(t`Edit template`)
            }: ${nameInPrimaryLanguage}`}
            onBack={async () => {
              await onSave();
              goBack();
            }}
            actions={
              <Actions>
                <LastSaved
                  time={lastSavedTime}
                  status={lastSavedStatus}
                  errorMessage={lastSavedErrorMessage}
                />
                <ActionItemBlock>
                  <MultiLangComponent languageState={languageState} />
                </ActionItemBlock>
                <ActionItemBlock>
                  <Tooltip size={TOOLTIP_SIZES.BIG} disabled={false} tooltip={i18n._(t`Delete`)}>
                    <span>
                      <Button
                        label=""
                        size={ButtonSize.MEDIUM}
                        variant={ButtonVariant.ICON_DELETE}
                        onClick={() => setIsDeletingTemplate(true)}
                      />
                    </span>
                  </Tooltip>
                  <Tooltip size={TOOLTIP_SIZES.BIG} disabled={false} tooltip={i18n._(t`Preview`)}>
                    <span>
                      <Button
                        label=""
                        icon={ICONS.SHOW}
                        size={ButtonSize.MEDIUM}
                        variant={ButtonVariant.ICON}
                        onClick={() => setShowPreview(true)}
                      />
                    </span>
                  </Tooltip>
                  <Tooltip
                    size={TOOLTIP_SIZES.BIG}
                    disabled={false}
                    tooltip={
                      status === SURVEY_TEMPLATE_STATUSES.draft
                        ? i18n._(t`Save draft`)
                        : i18n._(t`Save`)
                    }
                  >
                    <span>
                      <Button
                        label=""
                        icon={ICONS.SAVE}
                        size={ButtonSize.MEDIUM}
                        variant={ButtonVariant.ICON}
                        onClick={onSave}
                      />
                    </span>
                  </Tooltip>
                </ActionItemBlock>
                {status !== SURVEY_TEMPLATE_STATUSES.active && (
                  <ActionItemBlock>
                    <Button
                      label={i18n._(t`Publish`)}
                      size={ButtonSize.MEDIUM}
                      variant={ButtonVariant.PRIMARY}
                      onClick={onPublish}
                    />
                  </ActionItemBlock>
                )}
              </Actions>
            }
          />
          <Wrapper>
            <SideBar
              sections={sectionState.sections}
              currentSection={sectionState.currentSection}
              setCurrentSection={sectionState.setCurrentSection}
              hideErrorState={!sectionState.triedToSubmit}
            />
            {sectionState.currentSection === 0 && (
              <General
                languageState={languageState}
                formMethods={generalFormMethods}
                setCurrentSection={sectionState.setCurrentSection}
                triedToSubmit={sectionState.triedToSubmit}
                autosave={onAutosave}
              />
            )}
            {sectionState.currentSection === 1 && (
              <Questions
                questions={questions}
                languageState={languageState}
                formMethods={generalFormMethods}
                setCurrentSection={sectionState.setCurrentSection}
                setQuestions={setQuestions}
                surveyTemplateId={surveyTemplateId}
                refreshTemplate={refreshTemplate}
                questionIdToOpen={editQuestion}
                setEditQuestion={setEditQuestion}
                autosave={onAutosave}
                themeIds={Object.keys(themesMap)}
                equalizeWeights={equalizeWeights}
              />
            )}
            {sectionState.currentSection === 2 && (
              <ThemeWeight
                formMethods={generalFormMethods}
                themesMap={themesMap}
                sectionState={sectionState}
                onSubmit={onPublish}
                autosave={onAutosave}
                equalizeWeights={equalizeWeights}
              />
            )}
          </Wrapper>
          {isDeletingTemplate && (
            <ConfirmationModal
              type={CONFIRMATION_MODAL_TYPE.DELETE}
              title={i18n._(t`Delete template?`)}
              description={i18n._(
                t`Deleting this template will delete it from the survey report. You can no longer view the data for this theme and the related question. This action cannot be undone.`,
              )}
              onClose={() => setIsDeletingTemplate(false)}
              onSubmit={deleteTemplate}
            />
          )}
        </>
      )}
    </>
  );
};

export { SurveyTemplateUpdate };
