import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';

import { QuestionStatusWrapper } from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import { IconStatusType } from '../../types';
import { checkQuestionStatus } from '../../utils';
import { IconStatus } from '../IconStatus';

import type { IReviewQuestion, IUserReviewQuestion } from '@learned/types';

interface QuestionStatusProps {
  question: IReviewQuestion | IUserReviewQuestion;
  hasWarning?: boolean;
  prepareQuestionToEdit: (questionId: string) => Promise<void>;
  showEditButton?: boolean;
}

export const QuestionStatus = ({
  question,
  hasWarning,
  prepareQuestionToEdit,
  showEditButton,
}: QuestionStatusProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  const { isRatingBased, defaultScale, isRequired, includesCoachReview } =
    checkQuestionStatus(question);

  const requirements = [
    { title: i18n._(t`Rated question`), value: isRatingBased },
    { title: i18n._(t`Default rating scale`), value: defaultScale },
    { title: i18n._(t`Requires an answer`), value: isRequired },
    { title: i18n._(t`In coach review`), value: includesCoachReview },
  ];

  return (
    <QuestionStatusWrapper>
      <div className="name">{getMultiLangString(question.name)}</div>
      {requirements.map((item) => (
        <div key={`${item.title}-${question.id}`} className="requirement">
          <div className="title">{item.title}</div>
          <IconStatus
            status={
              item.value
                ? IconStatusType.SUCCESS
                : hasWarning
                ? IconStatusType.WARNING
                : IconStatusType.ERROR
            }
          />
        </div>
      ))}
      {showEditButton && (
        <Button
          type="button"
          className="editQuestion"
          icon={ICONS.EDIT_PENCIL}
          variant={ButtonVariant.SECONDARY}
          label={i18n._(t`Edit question`)}
          onClick={() => prepareQuestionToEdit(question.id)}
        />
      )}
    </QuestionStatusWrapper>
  );
};
