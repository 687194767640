import styled, { css } from 'styled-components';

import { Icon } from '~/components/Icon';

import { COLORS } from '~/styles';

const StyledLabel = styled.label<{
  color?: string;
  backgroundColor?: string;
  disabled?: boolean;
  noBorder?: boolean;
}>`
  height: 20px;
  border-radius: 3px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.13px;
  font-size: 12px;
  padding: 1px 6px 0px;
  text-align: center;
  color: ${({ disabled, color }) => (disabled ? COLORS.SUBTEXT : color)};
  background-color: ${({ disabled, backgroundColor }) =>
    disabled ? COLORS.INACTIVE : backgroundColor};

  ${({ noBorder, disabled, color }) =>
    !noBorder &&
    css`
      border: solid 1px ${disabled ? COLORS.SUBTEXT : color};
    `}

  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 0 4px;
`;

const Background = styled.div<{
  disabled?: boolean;
  color: string;
  backgroundByPercentage: number;
}>`
  position: absolute;
  color: ${({ disabled, color }) => (disabled ? COLORS.SUBTEXT : color)};
  background-color: ${({ disabled, color }) => (disabled ? COLORS.SUBTEXT : color)};
  opacity: ${({ backgroundByPercentage }) => backgroundByPercentage / 100};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const StyledIcon = styled(Icon)`
  box-sizing: border-box;
`;

export { StyledIcon, Background, StyledLabel };
