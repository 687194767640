import styled, { css } from 'styled-components';

import { StyledSecondaryButton } from '~/components/Buttons/design';
import { Dropdown } from '~/components/Dropdown';
import { Select } from '~/components/Dropdown/design';
import { TableList } from '~/components/TableList';
import { SortMenu, StyledTop, TableWrapper } from '~/components/TableList/design';

import { COLORS } from '~/styles';

import type { ISkillCategory } from '@learned/types';

export const Wrapper = styled.div<{ minHeight?: number }>`
  display: flex;
  gap: 151px;
  margin-top: 49px;

  ${(props) =>
    props.minHeight &&
    css`
      min-height: ${props.minHeight + 270}px;
    `}
`;

export const FakeInputLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: ${COLORS.BG_ELEMENTS};
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  padding-left: 7.6px;
  padding-right: 7.6px;
  color: ${COLORS.ICONS_PRIMARY};
  height: 28px;
`;

export const FakeInput = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 317px;
  box-sizing: border-box;

  padding: 4px 9px;

  border-radius: 6px;
  border: solid 1px ${COLORS.BORDERS};
  background-color: ${COLORS.WHITE};
`;

export const Section = styled.div<{ $marginTop: number }>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.$marginTop}px;
  gap: 8px;
`;

export const CombinedSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 35px;
`;

export const StyledDropdown = styled(Dropdown<ISkillCategory>)<{ $error: boolean }>`
  min-width: 317px;
  min-height: 38px;

  ${Select} {
    min-width: 318px;
    min-height: 38px;
    ${(props) =>
      props.$error &&
      css`
        border: solid 1px ${COLORS.ACCENT_ERROR};
      `}
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 32px 40px;
  box-sizing: border-box;
  align-self: start;
`;

export const Label = styled.label`
  color: ${COLORS.TEXT_HOVER};
  font-size: 14px;
  letter-spacing: -0.16px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Title = styled.h2`
  font-size: 26px;
  color: ${COLORS.TEXT_HOVER};
  font-weight: normal;
  margin-top: 0;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  line-height: 1.43;
  color: ${COLORS.ICONS_PRIMARY};
  margin-bottom: 32px;
`;

export const Levels = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;

  h2 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.86;
    margin: 0;
    margin-bottom: 7px;
    color: ${COLORS.TEXT_HOVER};
  }
`;

export const JobProfilesLevels = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  margin-bottom: 40px;
`;

export const SummarySection = styled.div<{ noGap?: boolean }>`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.noGap
      ? css`
          gap: 0;
        `
      : css`
          gap: 32px;
        `}
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;

  border-bottom: solid 1px ${COLORS.BORDERS};
  justify-content: space-between;

  ${StyledSecondaryButton} {
    margin-bottom: 8px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.86;
    color: ${COLORS.TEXT_HOVER};
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
  justify-content: center;
`;

export const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: end;

  color: ${COLORS.SUBTEXT};
  line-height: 1.2;

  svg {
    margin-bottom: 2px;
  }
`;

export const Card = styled.div`
  border-radius: 10px;
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 32px 40px;
  box-sizing: border-box;
`;

export const SkillTitleAndCategory = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SkillDetails = styled.div`
  margin-bottom: 46px;
`;

export const StyledTableList = styled(TableList)`
  margin: 0;
  padding: 0;

  ${TableWrapper} {
    padding: 0;
  }

  ${StyledTop} {
    padding-left: 0;
    padding-top: 18px;
  }

  ${SortMenu} {
    width: fit-content;
    & .item {
      padding: 4px;
    }
  }
`;

export const JobProfilesByLevel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  h2 {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.86;
    margin: 0;
    color: ${COLORS.TEXT_HOVER};
  }
`;

export const SkillDescriptionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 17px;

  ${Label} {
    margin-bottom: -5px;
  }
`;

export const ActionContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 11px;
`;

export const FocusAreaHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  & .buttons {
    margin-top: 3px;
    display: flex;
    gap: 10px;
  }
`;

export const GenerateLucaContainer = styled.div`
  margin: 0 33px;

  svg {
    height: 30px;
    width: 30px;
  }
`;
