import React, { useEffect } from 'react';

import { t } from '@lingui/macro';
import { Trans, useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import size from 'lodash/size';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useFieldArray, type UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';

import { ButtonShadow } from '~/components/Button';
import Dot6Icon from '~/components/Icons/Dot6';
import TrashIcon from '~/components/Icons/Trash';
import { Input } from '~/components/Input';

import type { IQuestionForm } from '~/@types/question';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { COLORS } from '~/styles';

interface IOptionsProps {
  formMethods: UseFormReturn<IQuestionForm>;
  languageState: ILanguageStateReturn;
  isPreloaded?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;

  & .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const Header = styled.div`
  display: flex;
  color: ${COLORS.BLACK};
  gap: 30px;
  font-size: 14px;
`;

const Row = styled.div`
  display: flex;
  align-items: start;
  color: var(--company-color);
`;

const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  height: 38px;

  &.delete {
    cursor: pointer;
  }
  &.disabled {
    color: ${COLORS.BORDER_HARD};
    cursor: default;
  }
`;

const InputGroups = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 8px;
`;

const AddOption = styled(ButtonShadow)`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 32px;
  padding: 8px 14px 7px;
  &.selected {
    background-color: var(--company-color);
    color: ${COLORS.WHITE};
  }
  margin-left: auto;
  margin-right: 24px;
`;

const Options = ({ isPreloaded, languageState, formMethods }: IOptionsProps) => {
  const { i18n } = useLingui();
  const { control, register, resetField } = formMethods;
  const { fields, append, move, remove } = useFieldArray({
    name: 'options',
    control,
  });
  const languages = languageState.companyLanguages.filter(({ locale }) =>
    languageState.languages.find((language) => language.locale === locale),
  );

  useEffect(() => {
    if (!isPreloaded) {
      resetField('options', {
        defaultValue: [],
      });

      [true, true].forEach(() => {
        append(
          {
            label: languages.map(({ locale }) => ({ locale, value: '' })),
          },
          { shouldFocus: false },
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Header>
        <div>
          <Trans id="Options" />
        </div>
      </Header>
      <DragDropContext
        onDragEnd={(result) => {
          move(result.source.index, result?.destination?.index || 0);
        }}
      >
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {/* @ts-ignore */}
              {fields.map(({ id, icon, label }, index) => (
                <Draggable key={id} draggableId={id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Row key={`traffic-light-${icon}-${index}`}>
                        <IconWrapper>
                          <Dot6Icon size={32} />
                        </IconWrapper>
                        <InputGroups>
                          {label.map(({ locale }, i) => {
                            const isLocale = languages.find((lang) => lang.locale === locale);
                            return (
                              isLocale && (
                                <div className="inputWrapper" key={`${icon}-${locale}`}>
                                  <Input
                                    name={`options.${index}.label.${i}.value`}
                                    width="100%"
                                    height="38px"
                                    register={register(`options.${index}.label.${i}.value`, {
                                      required: true,
                                    })}
                                    placeholder={i18n._(t`item ${index + 1}`)}
                                    leftIcon={
                                      size(languages) > 1
                                        ? getUnicodeFlagIcon(
                                            locale.substring(locale.indexOf('_') + 1),
                                          )
                                        : undefined
                                    }
                                  />
                                </div>
                              )
                            );
                          })}
                        </InputGroups>
                        <IconWrapper
                          className={size(fields) > 2 ? 'delete' : 'disabled'}
                          onClick={() => size(fields) > 2 && remove(index)}
                        >
                          <TrashIcon size={16} />
                        </IconWrapper>
                      </Row>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div>
        <AddOption
          type="button"
          onClick={() =>
            append({
              label: languages.map(({ locale }) => ({ locale, value: '' })),
            })
          }
        >
          <Trans id="+ Add option" />
        </AddOption>
      </div>
    </Wrapper>
  );
};

export { Options };
