import React, { useMemo } from 'react';

import isNil from 'lodash/isNil';

import { Container, CounterBadge, TextContainer, Title } from './design';
import { ValidationMessageStatus } from './types';

import { COLORS } from '~/styles';

export interface ValidationMessageProps {
  message: string;
  status?: ValidationMessageStatus;
  fixedCount?: number;
  totalCount?: number;
  showCounterBadge?: boolean;
}

export const ValidationMessage = ({
  message,
  status = ValidationMessageStatus.ERROR,
  fixedCount,
  totalCount,
  showCounterBadge = true,
}: ValidationMessageProps) => {
  const { color, background } = useMemo(() => {
    switch (status) {
      case ValidationMessageStatus.SUCCESS:
        return { color: COLORS.ACCENT_SUCCESS, background: COLORS.ACCENT_SUCCESS_LIGHT };
      case ValidationMessageStatus.ERROR:
      default:
        return { color: COLORS.ACCENT_ERROR, background: COLORS.ERROR_LIGHT };
    }
  }, [status]);

  return (
    <Container color={color} background={background}>
      <TextContainer>
        <Title>
          <span>{message}</span>
        </Title>
        {!isNil(fixedCount) && !isNil(totalCount) && showCounterBadge && (
          <CounterBadge>
            ({fixedCount}/{totalCount})
          </CounterBadge>
        )}
      </TextContainer>
    </Container>
  );
};
