import styled, { css } from 'styled-components';

import { ButtonShadow, ButtonWhite } from '~/components/Button';
import { PreviewQuestionCard } from '~/components/Question';

import { COLORS } from '~/styles';

export const SubTitle = styled.span`
  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${COLORS.DROPDOWN_ICON};
`;

export const SmallButtonText = styled.span`
  display: none;
  white-space: nowrap;
  padding-left: 16px;
  padding-right: 16px;
`;

export const SmallButton = styled(ButtonShadow)`
  display: flex;
  align-items: center;
  border-radius: 0;
  padding: 13px;
  max-height: 40px;
  box-sizing: border-box;
  border: solid 1px ${COLORS.TAB_WRAPPER_GRAY};
  box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);

  :first-child {
    border-radius: 6px 6px 0 0;
  }

  :last-child {
    border-radius: 0 0 6px 6px;
  }

  :hover {
    background-color: ${COLORS.BG_PAGE};
  }
`;

export const SmallButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  :hover {
    & ${SmallButtonText} {
      display: flex;
    }
  }
`;

export const AddQuestionHover = styled.div<{ isDragging: boolean }>`
  position: relative;
  height: 12px;
  cursor: pointer;
  border-radius: 4px 0 0 4px;
  margin-left: 20px;
  margin-right: 20px;

  & ${SmallButtonContainer} {
    position: absolute;
    display: none;
    left: 100%;
    top: -16px;
  }

  &:hover {
    ${(props) => !props.isDragging && `background-color: ${COLORS.BG_PAGE};`}

    & ${SmallButtonContainer} {
      display: ${(props) => (props.isDragging ? 'none' : 'flex')};
    }
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 46px;
`;

export const CreateNewQuestionWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  background-color: ${COLORS.BG_PAGE};
  border-radius: 6px;
  padding: 7px 0;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 12px;
`;

export const Button = styled(ButtonWhite)`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  padding-right: 32px;
  height: 38px;
  min-width: 138px;
  border-radius: 6px;
  border: 1px solid ${COLORS.BORDER_HARD};
  font-size: 12px;
`;

export const StyledPreviewQuestionCard = styled(PreviewQuestionCard)<{ $isError?: boolean }>`
  width: auto;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  padding: 8px 22px 8px 22px;

  ${({ $isError }) =>
    $isError
      ? css`
          border: solid 1px ${COLORS.ACCENT_ERROR};
        `
      : ''}
`;

export const HoverIcon = styled.div<{ isActive: boolean; isDragging: boolean }>`
  position: absolute;
  display: flex;
  visibility: ${(props) => (props.isActive ? 'visible' : 'hidden')};
  align-items: center;
  left: -13px;
  height: 100%;

  :hover {
    visibility: ${(props) => (props.isDragging ? 'hidden' : 'visible')};
  }
`;

export const ButtonText = styled.span`
  font-size: 12px;
`;

export const CardWrapper = styled.div<{ isDragging: boolean }>`
  position: relative;

  &:hover {
    & ${HoverIcon} {
      visibility: ${(props) => (props.isDragging ? 'hidden' : 'visible')};
    }

    & ${StyledPreviewQuestionCard} {
      ${(props) => props.isDragging && `background-color: ${COLORS.WHITE};`}
    }
  }
`;

export const SelectedHeaderContainer = styled.div`
  margin-bottom: 12px;
`;

export const MultiSelectCheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  padding-left: 24px;

    
  svg {
    stroke: ${COLORS.BORDERS};
    width: 20px;
    height: 20px;
  }

  span {
    align-content: center;
    height: 100%;
    padding: 4px 8px;
    text-transform: uppercase;
    overflow: hidden;
    color: ${COLORS.LABEL_GRAY}
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
  }
`;
