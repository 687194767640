import React, { useEffect, useState } from 'react';

import { ASSET_PICKER_TABS } from '@learned/constants';
import { t } from '@lingui/macro';
import nanoId from 'nanoid';

import { ButtonSize, ButtonVariant, Button } from '~/components/Buttons';
import { ICONS, ICON_SIZES } from '~/components/Icon';
import Modal from '~/components/Modal';

import { ColorPicker } from './components/ColorPicker';
import { IconPicker } from './components/IconPicker';
import { ImageUploader } from './components/ImageUploader';
import { PexelsUploader } from './components/PexelsUploader';
import { Header, Tabs, DeleteWrapper, Title, CloseButton } from './design';

import { uploadSingle } from '~/services/files';
import { COLORS } from '~/styles';

import type { I18n } from '@lingui/core';

export interface IHeaderTab {
  key: ASSET_PICKER_TABS;
  label: (i18n: I18n) => string;
}

export interface IAsset {
  color?: string | null;
  asset?: string | null;
  type?: ASSET_PICKER_TABS | null;
}

export enum ImageAspectRatio {
  WIDE_SCREEN = 'wide-screen',
}

export const IMAGE_ASPECT_RATIO_CONFIG: Record<
  ImageAspectRatio,
  {
    aspectRatio: string;
    width: number;
    height: number;
    borderRadius: number; // setting it to 0 will disable crop circle
  }
> = {
  [ImageAspectRatio.WIDE_SCREEN]: {
    aspectRatio: '16:9',
    width: 730,
    height: 411,
    borderRadius: 0,
  },
};

export interface IAssetPickerModalProps {
  onClose: () => void;
  onDelete?: () => void;
  onSelectAsset: (asset: IAsset) => void;
  tabs: ASSET_PICKER_TABS[];
  imageSrc?: string;
  imageAspectRatio?: ImageAspectRatio;
}

const modalContentStyle = {
  padding: '8px 16px',
  overflow: 'visible',
};

const modalHeaderStyle = {
  padding: '20px 40px',
  fontSize: '26px',
  fontWeight: 'normal',
};

const headerTabs = [
  {
    key: ASSET_PICKER_TABS.ICON,
    label: (i18n: I18n) => i18n._(t`Icon`),
  },
  {
    key: ASSET_PICKER_TABS.COLOR,
    label: (i18n: I18n) => i18n._(t`Color`),
  },
  {
    key: ASSET_PICKER_TABS.UPLOAD_IMAGE,
    label: (i18n: I18n) => i18n._(t`Upload image`),
  },
  {
    key: ASSET_PICKER_TABS.STOCKPHOTOS,
    label: (i18n: I18n) => i18n._(t`Stockphotos`),
  },
];

const AssetPickerModal = ({
  onClose,
  onDelete,
  onSelectAsset,
  tabs,
  imageSrc,
  imageAspectRatio,
}: IAssetPickerModalProps) => {
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);
  const [modalSize, setModalSize] = useState(420);
  const [availableTabs, setAvailableTabs] = useState<IHeaderTab[]>([]);
  const [image, setImage] = useState(imageSrc);
  const [removePexel, setRemovePexel] = useState<boolean>(false);

  const handleDelete = () => {
    setImage('');
    setRemovePexel(true);
    onDelete?.();
  };

  useEffect(() => {
    // set available tabs based on props
    const availableTabs = headerTabs
      .filter((tab) => tabs.includes(tab.key))
      .sort((a, b) => tabs.indexOf(a.key) - tabs.indexOf(b.key));
    setAvailableTabs(availableTabs);
    // eslint-disable-next-line
  }, [tabs]);

  useEffect(() => {
    // resize modal based on selected tab
    if (selectedTab === ASSET_PICKER_TABS.ICON) {
      setModalSize(470);
    } else if (imageAspectRatio === ImageAspectRatio.WIDE_SCREEN) {
      setModalSize(762);
    } else {
      setModalSize(420);
    }
  }, [imageAspectRatio, selectedTab]);

  useEffect(() => {
    if (availableTabs.length) {
      setSelectedTab(selectedTab ?? availableTabs[0]?.key);
    }
  }, [selectedTab, availableTabs]);

  const onChangeTab = (key: string) => {
    setSelectedTab(key);
  };

  const onChangeColor = (color: string) => {
    onSelectAsset({ color, asset: null, type: ASSET_PICKER_TABS.COLOR });
  };

  const onUploadImage = (image: string) => {
    onSelectAsset({ color: null, asset: image, type: ASSET_PICKER_TABS.UPLOAD_IMAGE });
  };

  const onChangeIcon = (color: string, icon: string) => {
    onSelectAsset({ color, asset: icon, type: ASSET_PICKER_TABS.ICON });
  };

  const onUploadPexel = (image: string) => {
    onSelectAsset({ color: null, asset: image, type: ASSET_PICKER_TABS.STOCKPHOTOS });
  };

  const onUploadAsset = async (file: File) => {
    const name = nanoId();
    const imageUrl = await uploadSingle(file, name);
    return imageUrl;
  };

  return (
    <Modal
      onClose={onClose}
      showDivider={false}
      width={modalSize}
      minWidth={modalSize}
      contentStyles={modalContentStyle}
      headerStyles={modalHeaderStyle}
      borderRadius={6}
      hideFooter
      isHideHeader
      isCloseOnOutsideClick
    >
      <Header>
        {image ? (
          <>
            <CloseButton
              size={ButtonSize.MEDIUM}
              icon={ICONS.CLOSE}
              variant={ButtonVariant.ICON}
              onClick={onClose}
            />
            <Title>Edit profile picture</Title>
          </>
        ) : (
          <Tabs
            selectedTab={selectedTab as string}
            handleChangeTab={onChangeTab}
            tabs={availableTabs}
          />
        )}
        <DeleteWrapper>
          <Button
            variant={ButtonVariant.ICON_DELETE}
            size={ButtonSize.MEDIUM}
            onClick={handleDelete}
            iconSize={ICON_SIZES.MEDIUM}
            color={COLORS.ACCENT_ERROR}
          />
        </DeleteWrapper>
      </Header>

      {ASSET_PICKER_TABS.COLOR === selectedTab && (
        <ColorPicker onClose={onClose} onChangeColor={onChangeColor} toggleColorTray={false} />
      )}

      {ASSET_PICKER_TABS.ICON === selectedTab && (
        <IconPicker onClose={onClose} isColorPickerEnabled onChangeIcon={onChangeIcon} />
      )}

      {ASSET_PICKER_TABS.UPLOAD_IMAGE === selectedTab && (
        <ImageUploader
          uploadEndPoint={onUploadAsset}
          onUploadImage={onUploadImage}
          imageSrc={image}
          onClose={onClose}
          imageAspectRatio={imageAspectRatio}
        />
      )}

      {ASSET_PICKER_TABS.STOCKPHOTOS === selectedTab && (
        <PexelsUploader
          uploadEndPoint={onUploadAsset}
          onUploadImage={onUploadPexel}
          onClose={onClose}
          imageAspectRatio={imageAspectRatio}
          removePexel={removePexel}
          setRemovePexel={setRemovePexel}
        />
      )}
    </Modal>
  );
};

export { AssetPickerModal };
