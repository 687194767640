import React, { useMemo } from 'react';

import { MultiSelectDropdown } from '~/components/Dropdown/components/MultiSelectDropdown';
import { SingleSelectDropdown } from '~/components/Dropdown/components/SingleSelectDropdown';

import { DropDownWrapper, Error } from './design';
import { sortItems } from './utils';

import type { DropdownProps } from './types';

function Dropdown<T = unknown>(props: DropdownProps<T>) {
  return useMemo(
    () => <DropdownTemp {...props} />,
    // eslint-disable-next-line
    [JSON.stringify(props)],
  );
}

function DropdownTemp<T = unknown>({ items, ...props }: DropdownProps<T>) {
  const { stringifyItem, error, isSingleSelect, skipSort } = props;

  const renderItem = (item: T) =>
    stringifyItem ? stringifyItem(item) : (item as unknown as string);

  items = !skipSort ? sortItems(items, { stringifyItem }) : items;

  return (
    <DropDownWrapper error={error}>
      {isSingleSelect ? (
        <SingleSelectDropdown<T> {...props} renderItem={renderItem} items={items} />
      ) : (
        <MultiSelectDropdown<T> {...props} renderItem={renderItem} items={items} />
      )}
      {error && <Error className={'error-message'}>{error}</Error>}
    </DropDownWrapper>
  );
}

export { Dropdown, DropdownTemp };
