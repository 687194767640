import { useAsyncFetch } from '~/hooks/useAsyncFetch';
import { getJobProfiles } from '~/services/jobProfiles';
import { getSkillCategories } from '~/services/skillCategories';

import type { IJobProfile, ISkillCategory } from '@learned/types';

/**
 * A custom hook that fetches skill categories and job profiles.
 * @returns {{isLoading: boolean, skillCategories: ISkillCategory[]}}
 */
const useSkillCategories = () => {
  const [skillCategories, isLoading] = useAsyncFetch<ISkillCategory[]>({
    fetch: async () => {
      const response = await getSkillCategories();
      return Object.values(response);
    },
    initialState: [],
  });

  return { skillCategories, isLoading };
};

const useJobProfiles = (filters = {}) => {
  const [jobProfiles, isLoading] = useAsyncFetch<IJobProfile[]>({
    fetch: async () => {
      const response = await getJobProfiles(filters);
      return Object.values(response);
    },
    initialState: [],
  });

  return { jobProfiles, isLoading };
};

export { useSkillCategories, useJobProfiles };
