import React from 'react';

import { THColumn } from '~/components/TableGrid/components/Header/THColumn';
import { TD } from '~/components/TableGrid/design';

import { RatingsGridPDFWrapper } from './design';

import { IColumnTable } from '~/@types/table';

import { IChildTransformed, IRowTransformed } from '../../types';

interface IRatingsGridPDFProps {
  items: (IRowTransformed | IChildTransformed)[];
  columns: IColumnTable<any>[];
}

const RatingsGridPDF: React.FC<IRatingsGridPDFProps> = ({ items, columns }) => {
  return (
    <RatingsGridPDFWrapper>
      <table>
        <thead>
          <tr>
            {columns.map((column) => (
              <THColumn key={column.accessor} column={column} />
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item) => {
            return (
              <tr key={item.id}>
                {columns.map((column, index) => {
                  return <TD key={index}>{column?.renderCell?.(item)}</TD>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </RatingsGridPDFWrapper>
  );
};

export default RatingsGridPDF;
