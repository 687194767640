import React, { useEffect, useState } from 'react';

import { IReviewInvitationTemplate } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { MultiLangComponent } from '~/components/Dropdown/MultiLangualDropdown';
import Editor from '~/components/Editor';
import { Input } from '~/components/Input';
import Modal from '~/components/Modal';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import { Label } from '~/pages/ReviewTemplateSetup/sections/General/design';

import {
  ActionContainer,
  Footer,
  Header,
  MessageContainer,
  MessageItemContainer,
  Title,
} from './design';

import { useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import {
  createReviewInvitationTemplate,
  editReviewInvitationTemplate,
} from '~/services/reviewInvitationTemplates';

type InvitationMessagesEditProps = {
  closeModal?: () => void;
  value?: IReviewInvitationTemplate;
};

const InvitationMessagesEdit = ({ closeModal, value }: InvitationMessagesEditProps) => {
  const { i18n } = useLingui();
  const { addToast } = useToasts();
  const getMultiLangString = useMultiLangString();

  const languageState = useLanguageState();

  const [isEdit, setIsEdit] = useState(false);
  const { control, handleSubmit, setValue, reset, watch } = useForm<
    Pick<IReviewInvitationTemplate, 'name' | 'description'>
  >({
    defaultValues: {
      name: '',
      description: {},
    },
  });

  useEffect(() => {
    if (value) {
      setIsEdit(true);
      setValue('name', value?.name);
      setValue('description', value?.description);
    } else {
      setIsEdit(false);
      reset();
    }
  }, [value, setValue, reset]);

  const onSubmitForm: SubmitHandler<
    Pick<IReviewInvitationTemplate, 'name' | 'description'>
  > = async (data) => {
    if (isEmpty(value)) {
      await onCreate(data);
    } else {
      await onEdit(value?.id, data);
    }
  };

  const onCreate = async (item: Pick<IReviewInvitationTemplate, 'name' | 'description'>) => {
    await createReviewInvitationTemplate(item);
    addToast({ title: i18n._(t`Invitation message created!`), type: TOAST_TYPES.SUCCESS });
    closeModal?.();
  };

  const onEdit = async (
    id: IReviewInvitationTemplate['id'],
    item: Pick<IReviewInvitationTemplate, 'name' | 'description'>,
  ) => {
    await editReviewInvitationTemplate(id, item);
    closeModal?.();
  };

  return (
    <Modal
      showDivider={false}
      centerModal
      contentStyles={{ padding: '24px 32px', overflow: 'visible' }}
      borderRadius={6}
      hideFooter
      isHideHeader
    >
      <Header>
        <Title>
          {isEdit ? i18n._(t`Edit invitation message`) : i18n._(t`Create invitation message`)}
        </Title>
        <ActionContainer>
          <MultiLangComponent languageState={languageState} />
          <Button size={ButtonSize.MEDIUM} onClick={closeModal} variant={ButtonVariant.CLOSE} />
        </ActionContainer>
      </Header>

      <Label>{i18n._(t`Name`)}</Label>
      <Controller
        control={control}
        rules={{ required: true }}
        render={({ field }) => {
          return (
            <Input
              placeholder={i18n._('Example: Annual review')}
              width="266px"
              value={getMultiLangString(field.value)}
              maxLength={50}
              onChange={(event) => {
                field.onChange(event.target.value);
              }}
            />
          );
        }}
        name={'name'}
      />

      <MessageContainer>
        <Label>{i18n._(t`Message`)}</Label>

        {languageState.languages &&
          languageState.languages.map((lang, index) => {
            return (
              <MessageItemContainer key={`invitation-${lang.locale}-${index}`}>
                <Controller
                  control={control}
                  render={({ field }) => {
                    return (
                      <Editor
                        value={field.value}
                        onChange={(args: string) => {
                          field.onChange(args);
                        }}
                        small
                        leftIcon={getUnicodeFlagIcon(
                          lang.locale.substring(lang.locale.indexOf('_') + 1),
                        )}
                        placeholder={i18n._(t`Write the invitation message here..`)}
                        compact
                      />
                    );
                  }}
                  name={`description.${lang.locale}`}
                />
              </MessageItemContainer>
            );
          })}
      </MessageContainer>

      <Footer>
        <Button
          label={i18n._(t`Cancel`)}
          onClick={closeModal}
          type="button"
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
        />
        <Button
          label={i18n._(t`Save`)}
          type="button"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          onClick={handleSubmit(onSubmitForm)}
          disabled={
            every(Object(watch('description')), (item) => isEmpty(item)) || isEmpty(watch('name'))
          }
        />
      </Footer>
    </Modal>
  );
};

export { InvitationMessagesEdit };
