import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { CreateGoalButton } from '~/components/CreateGoalButton';
import GoalsBlock from '~/components/GoalsBlock';
import OverviewHeading from '~/components/OverviewHeading';
import BaseLayout from '~/layouts/BaseLayout';

import { INSTRUCTIONS } from '~/constants/instructions';
import getInstructionUrl from '~/utils/getInstructionUrl';

const Goals = () => {
  const { i18n } = useLingui();

  return (
    <div>
      {/* @ts-ignore */}
      <OverviewHeading
        title={i18n._(t`Goals`)}
        smallWidth
        instructionsUrl={getInstructionUrl(INSTRUCTIONS.HOW_GOALS_WORK_USER)}
        instructions={i18n._(t`How do goals work`)}
      >
        <CreateGoalButton />
      </OverviewHeading>
      <BaseLayout smallWidth maxWidth={1056}>
        {/* @ts-ignore */}
        <GoalsBlock showMultiSelect />
      </BaseLayout>
    </div>
  );
};

export { Goals };
