import React, { useEffect } from 'react';

import { JOB_TEMPLATE_STATUS, SKILL_CATEGORIES_ID } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import { useForm } from 'react-hook-form';

import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { SUPER_ADMIN_LANGUAGES } from '~/constants/languages';
import { useFromQuery } from '~/hooks/useFromQuery';
import { useGenericLanguageState } from '~/hooks/useLanguageState';
import { LS_KEYS } from '~/hooks/useLocalStorage';
import { createJobTemplateSuperAdmin } from '~/services/jobTemplates';
import {
  deleteJobTemplatesPendingSuperAdmin,
  getJobTemplatePendingSuperAdmin,
} from '~/services/jobTemplatesPending';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';

import { SuperAdminJobTemplateForm } from '../SuperAdminJobTemplateForm';
import { useSkillCategories } from '../SuperAdminJobTemplateForm/hooks/useSkillCategories';
import {
  convertSkillsTemplateFormToAPIData,
  removeEmptyValues,
} from '../SuperAdminJobTemplateForm/utils';
import { resolver } from '../SuperAdminJobTemplateForm/validations';

import type { IGeneralForm } from '../SuperAdminJobTemplateForm/types';
import type { IJobTemplatePending } from '@learned/types';

const SuperAdminCreateJobTemplate = () => {
  const { i18n } = useLingui();
  const { goBack } = useFromQuery({ includeHash: true });
  const { addToast } = useToasts();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });

  const languageState = useGenericLanguageState({
    defaultLanguages: SUPER_ADMIN_LANGUAGES,
    primaryLanguage: SUPER_ADMIN_LANGUAGES[0],
    localStorageKey: LS_KEYS.LS_SUPER_ADMIN_LANGUAGES,
  });

  const generalFormMethods = useForm<IGeneralForm>({
    mode: 'all',
    resolver,
    defaultValues: {
      name: languageState.defaultLanguages.map(({ locale }) => ({ locale, value: '' })),
      description: languageState.defaultLanguages.map(({ locale }) => ({ locale, value: '' })),
      aliases: [],
    },
  });
  const { setValue, reset, getValues, watch } = generalFormMethods;
  const jobTemplateName =
    watch('name').find(({ locale }) => locale === languageState.primaryLanguage.locale)?.value ||
    watch('name').find(({ value }) => value)?.value;

  const title = i18n._(t`Create a job: ${jobTemplateName}`);

  const { skillCategories } = useSkillCategories();

  useEffect(() => {
    let skills = {};

    skillCategories.forEach((category) => {
      const skillLevelsLength = category.skillLevels?.length || 0;
      const defaultFocusAreaLevel = [
        SKILL_CATEGORIES_ID.COMPETENCIES,
        SKILL_CATEGORIES_ID.KPIS,
      ].includes(category.type as SKILL_CATEGORIES_ID)
        ? 1
        : skillLevelsLength >= 3
        ? 3
        : skillLevelsLength;

      skills = {
        ...skills,
        [category.id]: {
          defaultFocusAreaLevel,
          isDefaultFocusAreaLevelEnabled: true,
          skills: [],
        },
      };
    });

    setValue('skills', skills);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillCategories]);

  const createFromJobTemplatePendingId = query?.createFromJobTemplatePendingId as
    | string
    | undefined;

  useEffect(() => {
    let mounted = true;
    const fetchJobTemplatePending = async () => {
      if (!createFromJobTemplatePendingId) {
        return;
      }

      const result = await getJobTemplatePendingSuperAdmin(createFromJobTemplatePendingId);

      const jobTemplatePending = result.data.jobTemplatePending as IJobTemplatePending;

      if (mounted && !isEmpty(jobTemplatePending)) {
        reset({
          ...getValues(),
          name: languageState.defaultLanguages.map(({ locale }) => ({
            locale,
            value: jobTemplatePending.name[locale] ?? '',
          })),
        });
      }
    };

    fetchJobTemplatePending();

    return () => void (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFromJobTemplatePendingId]);

  const onSubmit = async (data: IGeneralForm & { status: JOB_TEMPLATE_STATUS }) => {
    try {
      const predicate = ({ value, locale }: { value: string; locale: string }) =>
        value !== '' || locale === '_id';
      const result = await createJobTemplateSuperAdmin({
        name: convertLanguageValue(data.name.filter(predicate)),
        skills: convertSkillsTemplateFormToAPIData(data.skills),
        description: {
          default: convertLanguageValue(data.description.filter(predicate)),
        },
        aliases: data.aliases.map(({ _id, ...alias }) => removeEmptyValues(alias)),
        decisionLevel: data.decisionLevel,
        status: data.status,
        jobFamily: data.jobFamily,
        coverImage: data.coverImage,
        jobTemplatePendingId: createFromJobTemplatePendingId,
      });

      if (result.code === 200 && result.data.jobTemplate.status === JOB_TEMPLATE_STATUS.PUBLISHED) {
        addToast({
          title: i18n._(t`Success`),
          subtitle: i18n._(t`Job template published`),
          type: TOAST_TYPES.SUCCESS,
        });
      } else if (
        result.code === 200 &&
        result.data.jobTemplate.status === JOB_TEMPLATE_STATUS.DRAFT
      ) {
        addToast({
          title: i18n._(t`Success`),
          subtitle: i18n._(t`Job template saved as draft`),
          type: TOAST_TYPES.SUCCESS,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      if (createFromJobTemplatePendingId) {
        await deleteJobTemplatesPendingSuperAdmin([createFromJobTemplatePendingId]);
      }
      goBack();
    }
  };

  return (
    <SuperAdminJobTemplateForm
      title={title}
      onSubmit={onSubmit}
      formMethods={generalFormMethods}
      languageState={languageState}
    />
  );
};

export { SuperAdminCreateJobTemplate };
