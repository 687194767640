import { useCallback, useEffect, useState } from 'react';

import { SKILL_TEMPLATE_SORT_OPTIONS } from '@learned/constants';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { WatchSkill } from '~/components/Modals/AddSkillModal/types';

import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import getLang from '~/selectors/getLang';
import { getSkills } from '~/services/skills';
import { listSkillTemplates } from '~/services/skillTemplates';

import type { IMultiLangString, ISkill, ISkillCategory, ISkillTemplate } from '@learned/types';

type UseAddSkillModalProps = {
  skillCategoryId: string;
  skillCategoryTemplateId?: string;
  initSource?: ISkillTemplate | ISkill;
  jobTemplateId?: string;
  jobSkillTemplates?: ISkillTemplate[];
  watchSkills?: WatchSkill[];
  selectedSkills?: (ISkill | ISkillTemplate)[];
  skillCategoryType?: ISkillCategory['type'];
};

const useAddSkillModal = ({
  skillCategoryTemplateId,
  skillCategoryId,
  initSource,
  jobSkillTemplates = [],
  watchSkills = [],
  selectedSkills = [],
  skillCategoryType,
}: UseAddSkillModalProps) => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const [isSelectLevelAndFocusAreasVisible, setIsSelectLevelAndFocusAreasVisible] = useState(
    !!initSource,
  );
  const search = useDebounce(searchInputValue, 300);

  const getMultiLangString = useMultiLangString();
  const lang = useSelector(getLang);

  const [companySkills, setCompanySkills] = useState<ISkill[]>([]);
  const [templateSkills, setTemplateSkills] = useState<(ISkill | ISkillTemplate)[]>([]);

  const [skillNames, setSkillNames] = useState<(ISkillTemplate | ISkill)[]>();
  const [source, setSource] = useState<ISkillTemplate | ISkill | undefined>(initSource);

  const [allCompanySkills, setAllCompanySkills] = useState<ISkill[]>([]);

  const [loading, setLoading] = useState(false);

  const [resultInOtherCategories, setResultInOtherCategories] = useState<ISkill[]>([]);

  const $refreshCompanySkills = useBoolState(false);

  const $isSearchLoading = useBoolState(false);

  const [allSkills, setAllSkills] = useState<(ISkill | ISkillTemplate)[]>([]);

  useEffect(() => {
    const fetchAllSkills = async () => {
      const allSkills = await getSkills({
        // @ts-ignore
        hideDeleted: true,
        hideDraft: true,
      });

      setAllSkills(Object.values(allSkills.data.skills) as ISkill[]);
    };

    fetchAllSkills();
  }, [$refreshCompanySkills.value]);

  useEffect(() => {
    const fetchCompanySkills = async () => {
      setLoading(true);
      // get company skills
      const companySkillsRes = await getSkills({
        hideDeleted: true,
        hideDraft: true,
        // @ts-ignore
        categories: [skillCategoryId],
      });

      setAllCompanySkills(Object.values(companySkillsRes.data.skills) as ISkill[]);

      if (source?.id && companySkillsRes.data.skills[source.id]) {
        setSource(companySkillsRes.data.skills[source.id]);
      }
      setLoading(false);
    };
    fetchCompanySkills();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillCategoryId, $refreshCompanySkills.value]);

  useEffect(() => {
    const fetchSkills = async () => {
      setTemplateSkills([]);

      // Get skills for this category from company skills
      const companiesFiltered = allCompanySkills.filter(
        (companySkill) =>
          !watchSkills.some(
            (watchSkill) =>
              watchSkill.skill === companySkill.id ||
              (watchSkill.skillTemplate === companySkill.skillTemplate &&
                jobSkillTemplates?.some(
                  (jobTemplate) =>
                    getMultiLangString(companySkill.name) === getMultiLangString(jobTemplate.name),
                )),
          ),
      );

      // Get recommended skills from job template
      if (jobSkillTemplates) {
        const jobTemplatesFiltered: (ISkill | ISkillTemplate)[] = jobSkillTemplates.filter(
          (item) => {
            const templateNameLower = getMultiLangString(item.name).toLowerCase().trim();

            const isSelected = selectedSkills?.some(
              (selectedSkill) =>
                getMultiLangString(selectedSkill.name).toLowerCase().trim() === templateNameLower,
            );

            const isCompanySkill = companiesFiltered.some(
              (companySkill) =>
                getMultiLangString(companySkill.name).toLowerCase().trim() === templateNameLower,
            );

            return item.skillCategory === skillCategoryTemplateId && !isSelected && !isCompanySkill;
          },
        );
        if (searchInputValue) {
          const searchTemplateSkills = templateSkills.filter((templateSkill) => {
            return getLowerTrimString(templateSkill.name).includes(search.toLowerCase().trim());
          });

          setTemplateSkills(searchTemplateSkills);
        } else {
          setTemplateSkills(jobTemplatesFiltered);
        }
      }

      setCompanySkills(companiesFiltered);
    };

    fetchSkills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    skillCategoryId,
    skillCategoryTemplateId,
    jobSkillTemplates,
    watchSkills,
    selectedSkills,
    getMultiLangString,
    allCompanySkills,
    search,
    $refreshCompanySkills.value,
  ]);

  // Function to convert IMultiLangString to lowercase and trim it
  const getLowerTrimString = useCallback(
    (skillName: IMultiLangString) => getMultiLangString(skillName).toLowerCase().trim(),
    [getMultiLangString],
  );

  // Search Bar Functionality
  useEffect(() => {
    const fetchSearchSkillTemplates = async () => {
      $isSearchLoading.on();
      // STEP 1: Search on all company skills
      const searchSkills = isEmpty(search)
        ? allCompanySkills
        : allCompanySkills.filter((companySkill) => {
            return getLowerTrimString(companySkill.name).includes(search.toLowerCase().trim());
          });

      // STEP 2: If not found on company skills, search on super admin skills
      if (searchSkills.length) {
        setSkillNames(searchSkills);
        // it doesn't search on skill templates for custom skill categories
      } else if (skillCategoryType) {
        const response = await listSkillTemplates(
          { search, categoryId: skillCategoryTemplateId },
          { skip: 0, limit: 20, sortBy: SKILL_TEMPLATE_SORT_OPTIONS.NAME_A_Z },
          lang,
        );

        const existingSkills = (response.data.skillTemplates as ISkillTemplate[]).filter(
          (skillTemplate) =>
            !watchSkills.some((item) => {
              return skillTemplate.id === item.skillTemplate;
            }),
        );

        if (existingSkills.length === 0) {
          // filter values according to the search term
          const searchFilteredSkills = allSkills.filter((skill: ISkill | ISkillTemplate) =>
            getLowerTrimString(skill.name).includes(search.toLowerCase().trim()),
          );

          // filter out results in the same category
          const filteredSkillsInSameCategory = searchFilteredSkills.filter(
            (skill) => skill.skillCategory !== skillCategoryId,
          );

          setResultInOtherCategories(Object.values(filteredSkillsInSameCategory) as ISkill[]);
        } else {
          setResultInOtherCategories([]);
        }

        setSkillNames(existingSkills);
      }
      $isSearchLoading.off();
    };

    fetchSearchSkillTemplates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allCompanySkills,
    companySkills,
    getLowerTrimString,
    getMultiLangString,
    lang,
    search,
    skillCategoryId,
    skillCategoryTemplateId,
    skillCategoryType,
    watchSkills,
    allSkills,
  ]);

  useEffect(() => {
    if (!searchInputValue) {
      setSkillNames(undefined);
    }
  }, [searchInputValue]);

  return {
    skillNames,
    setSearchInputValue,
    searchInputValue,
    companySkills,
    templateSkills,
    isSelectLevelAndFocusAreasVisible,
    setIsSelectLevelAndFocusAreasVisible,
    source,
    setSource,
    loading,
    setLoading,
    resultInOtherCategories,
    refreshCompanySkills: $refreshCompanySkills.toggle,
    isSearchLoading: $isSearchLoading,
  };
};

export { useAddSkillModal };
