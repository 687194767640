import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import FocusLayout from '~/layouts/FocusLayout';
import { ReviewTemplateSetup } from '~/pages/ReviewTemplateSetup';
import { ReviewTemplateView } from '~/pages/ReviewTemplateView';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('reviewTemplates', {
  routesPrivate: {
    admin: [
      <PrivateRoute
        key={routes.REVIEW_TEMPLATE_CREATE.routePath(ROLES.ADMIN)}
        exact
        path={routes.REVIEW_TEMPLATE_CREATE.routePath(ROLES.ADMIN)}
        component={ReviewTemplateSetup}
        allowedRoles={[ROLES.ADMIN]}
        layout={FocusLayout}
      />,
      <PrivateRoute
        key={routes.REVIEW_TEMPLATE_UPDATE.routePath(ROLES.ADMIN)}
        exact
        path={routes.REVIEW_TEMPLATE_UPDATE.routePath(ROLES.ADMIN)}
        component={ReviewTemplateSetup}
        allowedRoles={[ROLES.ADMIN]}
        layout={FocusLayout}
      />,
    ],
    user: [
      <PrivateRoute
        key={routes.REVIEW_TEMPLATE_VIEW.routePath(ROLES.USER)}
        exact
        path={routes.REVIEW_TEMPLATE_VIEW.routePath(ROLES.USER)}
        component={ReviewTemplateView}
        allowedRoles={[ROLES.USER]}
        layout={FocusLayout}
      />,
    ],
  },
});
