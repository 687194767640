import React from 'react';

import { ICONS } from '~/components/Icon';
import Label from '~/components/Label';

import { RotateIcon180, Wrapper } from './design';

import { COLORS } from '~/styles';

import { IconStatusType } from '../../types';

interface IconStatusProps {
  status: IconStatusType;
}

export const IconStatus = ({ status }: IconStatusProps) => {
  return (
    <Wrapper>
      {status === IconStatusType.SUCCESS && (
        <Label
          className="status"
          color={COLORS.ACCENT_SUCCESS}
          icon={ICONS.CHECKMARK}
          backgroundByPercentage={30}
        />
      )}
      {status === IconStatusType.WARNING && (
        <Label
          className="status"
          color={COLORS.ACCENT_WARNING}
          icon={ICONS.WARNING_2}
          backgroundByPercentage={10}
        />
      )}
      {status === IconStatusType.ERROR && (
        <RotateIcon180>
          <Label
            className="status"
            color={COLORS.ACCENT_ERROR}
            icon={ICONS.INFO_3}
            backgroundByPercentage={30}
          />
        </RotateIcon180>
      )}
    </Wrapper>
  );
};
