import styled from 'styled-components';

import { COLORS } from '~/styles';

export const PlaceholderWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 236px;

  div.img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 160px;
  }

  img {
    width: 160px;
    height: auto;
  }

  span {
    color: ${COLORS.PLACEHOLDERS};
  }
`;
