import { useState, useMemo } from 'react';

import {
  CONFIRMATION_MODAL_TYPE,
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import type { PopulatedReviewTemplate } from '~/pages/ReviewTemplateView/types';
import {
  IThemeQuestionDefaultData,
  IQuestionForm,
  ISelectedSkill,
  ISkill,
} from '~/pages/ReviewThemeSetup/types';
import {
  convertQuestionOptions,
  getEvaluators,
  getLabelsForAvailableLanguages,
  getRatingLabels,
} from '~/pages/ReviewThemeSetup/utils';

import useBoolState from '~/hooks/useBoolState';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import type {
  IReviewQuestion,
  IReviewQuestionCustomSkillSettings,
  IReviewQuestionDefaultData,
  IReviewQuestionGoalPlanSettings,
  IReviewQuestionSkillCategorySettings,
  ISkillCategory,
  WithPartial,
} from '@learned/types';

interface UseQuestionProps {
  languageState: ILanguageStateReturn;
  template?: PopulatedReviewTemplate;
  updateQuestion: (
    question: Omit<WithPartial<IReviewQuestion, 'name' | 'type'>, 'company' | 'meta'>,
  ) => void;
  skillCategories: Record<string, ISkillCategory>;
}

export const useEvaluatorsAndThemes = ({
  languageState,
  updateQuestion,
  template,
  skillCategories,
}: UseQuestionProps) => {
  const { i18n } = useLingui();
  const [skillModalSkills, setSkillModalSkills] = useState<ISkill[]>([]);
  const [loading, setLoading] = useState(false);
  const [editQuestion, setEditQuestion] = useState<IQuestionForm | null | undefined>(undefined);
  const [selectedTheme, setSelectedTheme] = useState<string | undefined>(undefined);
  const $isSkillModalOpen = useBoolState(false);
  const [selectedSkills, setSelectedSkills] = useState<ISelectedSkill[]>([]);
  const [selectedSkill, setSelectedSkill] = useState<ISkill | null>(null);

  const preparedSkillCategories = useMemo(() => {
    return Object.values(skillCategories).map((skillCategory) => {
      const category = skillCategory as {
        id: string;
        name: Record<string, string>;
        skillLevels: Record<string, string>[];
        type: string;
      };
      return {
        value: category.id,
        label: category.name,
        levels: category.skillLevels?.length,
        type: category.type,
      };
    });
  }, [skillCategories]);

  const handleSubmit = (e: IQuestionForm) => {
    setLoading(true);
    const evaluators = Object.keys(e.settings.evaluators)
      .map((key: string) =>
        e.settings?.evaluators?.[key as keyof typeof e.settings.evaluators] ? key : null,
      )
      .filter(Boolean);
    const questionToEdit = {
      id: e.id as string,
      type: e.type.key as REVIEW_QUESTION_TYPES,
      theme: selectedTheme === 'OTHER' ? '' : (selectedTheme as string),
      name: convertLanguageValue(e.name),
      description: convertLanguageValue(e.description),
      settings: {
        options: e?.settings?.isManualScale ? getRatingLabels(languageState, e?.options) : null,
        evaluators,
        isCommentsAllowed: e.settings.isCommentsAllowed,
        isCommentsObligated: e.settings.isCommentsObligated,
        isAnswerObligated: !e.settings.isAnswerObligated,
        isMeasurementReversed: e.settings.isMeasurementReversed,
        isManualScale: e.settings.isManualScale,
        skillCategory:
          e.type.key === REVIEW_QUESTION_TYPES.SKILL_CATEGORY && e.skillOrKpiCategory?.id,
        skills: e.type.key === REVIEW_QUESTION_TYPES.CUSTOM_SKILL && e.settings.skills,
        subTypes: e.settings.subTypes,
      },
    };

    if (editQuestion) {
      updateQuestion(questionToEdit);
      setEditQuestion(undefined);
      setLoading(false);
    }
  };

  const handleSelectedSkillSubmit = (selectedSkill: ISelectedSkill) => {
    setSelectedSkills([
      ...selectedSkills.filter(({ skill }: ISelectedSkill) => skill !== selectedSkill.skill),
      selectedSkill,
    ]);
    $isSkillModalOpen?.toggle();
  };

  const prepareQuestionToEdit = async (questionId: string) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Be aware!`),
      description: i18n._(
        t`Changing this question will affect all review themes and templates that use this question.`,
      ),
    });
    if (!isConfirmed) {
      return;
    }
    const question = template?.questions.find((q) => q.id === questionId);
    if (!question) {
      return;
    }
    const nameLabels = turnMultiLangIntoArray(question.name, languageState.companyLanguages);
    const descriptionLabels = turnMultiLangIntoArray(
      question.description || {},
      languageState.companyLanguages,
    );
    setEditQuestion({
      id: question.id,
      name: getLabelsForAvailableLanguages(languageState, nameLabels),
      description: getLabelsForAvailableLanguages(languageState, descriptionLabels),
      theme: question?.theme,
      // @ts-ignore
      type: question?.type,
      settings: {
        isCommentsAllowed:
          (question.settings as IThemeQuestionDefaultData)?.isCommentsAllowed ?? false,
        isCommentsObligated:
          (question.settings as IThemeQuestionDefaultData)?.isCommentsObligated ?? false,
        isMeasurementReversed:
          (question.settings as IThemeQuestionDefaultData)?.isMeasurementReversed ?? false,
        isManualScale: (question.settings as IThemeQuestionDefaultData)?.isManualScale ?? false,
        evaluators: getEvaluators(
          (question.settings as IThemeQuestionDefaultData)
            .evaluators as REVIEW_QUESTION_EVALUATORS[],
        ),
        skillCategory:
          (question.settings as IReviewQuestionSkillCategorySettings)?.skillCategory || '',
        skills: (question.settings as IReviewQuestionCustomSkillSettings)?.skills,
        isAnswerObligated:
          !(question.settings as IReviewQuestionDefaultData)?.isAnswerObligated || false,
        subTypes: (question.settings as IReviewQuestionGoalPlanSettings)?.subTypes || undefined,
      },
      options: convertQuestionOptions(question, languageState.companyLanguages),
    });
    if (question.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL) {
      setSelectedSkills(question.settings.skills);
    }
  };

  return {
    loading,
    prepareQuestionToEdit,
    editQuestion,
    setEditQuestion,
    handleSelectedSkillSubmit,
    handleSubmit,
    $isSkillModalOpen,
    preparedSkillCategories,
    selectedSkills,
    setSelectedSkills,
    selectedTheme,
    setSelectedTheme,
    selectedSkill,
    setSelectedSkill,
    skillModalSkills,
    setSkillModalSkills,
  };
};
