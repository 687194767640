import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const Title = styled.h1`
  font-size: 26px;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin: 0;
`;

export const TitleBlock = styled.div`
  display: flex;
  height: min-content;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const Subtitle = styled.span`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};

  margin-top: 7px;
`;

export const LabelContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3px;
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 425px;
  border-radius: 6px;
  overflow: hidden;
  background-color: ${COLORS.BG_ELEMENTS};
  margin-top: 36px;

  & img {
    width: 100%;
    visibility: hidden;
  }
  & img[alt] {
    text-indent: 50%;
    font-size: 32px;
  }
`;

export const SectionTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.86;
  color: ${COLORS.TEXT_HOVER};
`;

export const InfoIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 22px;
`;

export const Description = styled.div`
  margin-top: 24px;
  font-size: 14px;
  line-height: 1.86;
  color: ${COLORS.TEXT_MAIN};
  border-bottom: solid 1px ${COLORS.BORDERS};
  padding-bottom: 18px;
`;

export const CoverImage = styled.img`
  width: 670.3px;
  height: 329px;
  border-radius: 6px;
  margin-top: 36px;
`;

export const JobRequirements = styled.div`
  margin-top: 31px;
  border-bottom: solid 1px ${COLORS.BORDERS};
  padding-bottom: 31px;
`;

export const Container = styled.div`
  margin-top: 31px;
  border-bottom: solid 1px ${COLORS.BORDERS};
  padding-bottom: 31px;
`;

export const JobMatrix = styled.div`
  margin-top: 31px;
`;

export const RecommendedCareerPath = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 53px;
  padding-top: 39px;
  border-top: solid 1px ${COLORS.BORDERS};
`;

export const RoleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3px;
`;

export const HeaderBlock = styled.div`
  padding: 19px 16px;
  border-radius: 6px;
  background-color: ${COLORS.BG_PAGE};
  font-size: 12px;
  font-weight: 600;
  color: ${COLORS.SUBTEXT};
  margin-bottom: 3px;
  maring-top: 5px;
`;

export const RoleBlock = styled.div<{ isCurrentRole: boolean }>`
  padding: 23px 16px 23px 16px;
  border-radius: 6px;
  background-color: ${COLORS.BG_LIST};

  color: ${COLORS.TEXT_MAIN};
  font-size: 12px;
  ${(props) =>
    props.isCurrentRole
      ? css`
          font-weight: 600;
        `
      : css`
          font-weight: normal;
        `};
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;
