import AddPlus from '~/assets/3.0/add-plus.svg';
import AddUser from '~/assets/3.0/add-user.svg';
import Agenda from '~/assets/3.0/agenda.svg';
import AiColoredIcon from '~/assets/3.0/ai-color.svg';
import AiIcon from '~/assets/3.0/ai.svg';
import Archive2 from '~/assets/3.0/archive-2.svg';
import Archive from '~/assets/3.0/archive.svg';
import ArrowLeftDouble from '~/assets/3.0/arrow-left-double.svg';
import ArrowRightDouble from '~/assets/3.0/arrow-right-double.svg';
import AvatarSingle from '~/assets/3.0/avatar-single.svg';
import Back from '~/assets/3.0/back.svg';
import Calendar from '~/assets/3.0/calender.svg';
import Calibrate from '~/assets/3.0/calibrate.svg';
import Career from '~/assets/3.0/career.svg';
import Chat from '~/assets/3.0/chat.svg';
import Checkbox2 from '~/assets/3.0/checkbox-2.svg';
import CheckboxChecked from '~/assets/3.0/checkbox-checked.svg';
import Checkbox from '~/assets/3.0/checkbox.svg';
import Checkmark from '~/assets/3.0/checkmark.svg';
import Chevron from '~/assets/3.0/chevron.svg';
import Close2 from '~/assets/3.0/close-2.svg';
import CloseAll from '~/assets/3.0/close-all.svg';
import Close from '~/assets/3.0/close.svg';
import Coach from '~/assets/3.0/coach.svg';
import CollapseMenu from '~/assets/3.0/collapse-menu.svg';
import CollapseWindow from '~/assets/3.0/collapse-window.svg';
import Comment from '~/assets/3.0/comment.svg';
import CompanySettings from '~/assets/3.0/company-settings.svg';
import Conversations from '~/assets/3.0/conversations.svg';
import CSV from '~/assets/3.0/csv.svg';
import CustomSkill from '~/assets/3.0/custom-skill.svg';
import Declined from '~/assets/3.0/declined.svg';
import DeleteBinFilled from '~/assets/3.0/delete-bin-filled.svg';
import DeleteBin from '~/assets/3.0/delete-bin.svg';
import Details from '~/assets/3.0/details.svg';
import Development from '~/assets/3.0/development.svg';
import Dropdown from '~/assets/3.0/dropdown.svg';
import Duplicate from '~/assets/3.0/duplicate.svg';
import EditPencil from '~/assets/3.0/edit-pencil.svg';
import Education from '~/assets/3.0/education.svg';
import Employee from '~/assets/3.0/employee.svg';
import ErrorSign from '~/assets/3.0/error.svg';
import Expand from '~/assets/3.0/expand.svg';
import Expired from '~/assets/3.0/expired.svg';
import Export from '~/assets/3.0/export.svg';
import ExternalLink from '~/assets/3.0/external-link.svg';
import Favorite from '~/assets/3.0/favorite.svg';
import Filter from '~/assets/3.0/filter.svg';
import Folder from '~/assets/3.0/folder.svg';
import Goals from '~/assets/3.0/goals.svg';
import GoogleLogo from '~/assets/3.0/google-logo.svg';
import GrabGrid from '~/assets/3.0/grab-grid.svg';
import Gridview from '~/assets/3.0/grid-view.svg';
import HalfPrivate from '~/assets/3.0/half-private.svg';
import Heart from '~/assets/3.0/heart.svg';
import Help from '~/assets/3.0/help.svg';
import Hide from '~/assets/3.0/hide.svg';
import History from '~/assets/3.0/history.svg';
import Home from '~/assets/3.0/home.svg';
import Flag from '~/assets/3.0/icon-flag.svg';
import Import from '~/assets/3.0/import.svg';
import InProgress from '~/assets/3.0/in-progress.svg';
import Incognito from '~/assets/3.0/incognito.svg';
import Info2 from '~/assets/3.0/info-2.svg';
import Info3 from '~/assets/3.0/info-3.svg';
import Info from '~/assets/3.0/info.svg';
import Integration from '~/assets/3.0/integration.svg';
import Invalid from '~/assets/3.0/invalid.svg';
import Invites from '~/assets/3.0/invites.svg';
import JobFamily from '~/assets/3.0/job-group.svg';
import JobLevel from '~/assets/3.0/job-level.svg';
import JobMatrix from '~/assets/3.0/job-matrix.svg';
import KebabMenu from '~/assets/3.0/kebab-menu.svg';
import LearningActivity from '~/assets/3.0/learning-activity.svg';
import Learning from '~/assets/3.0/learning.svg';
import ListView from '~/assets/3.0/list-view.svg';
import Lock from '~/assets/3.0/lock.svg';
import Log from '~/assets/3.0/log.svg';
import Logout from '~/assets/3.0/logout.svg';
import Luca from '~/assets/3.0/luca.svg';
import Mail from '~/assets/3.0/mail.svg';
import Members from '~/assets/3.0/members.svg';
import Message from '~/assets/3.0/message.svg';
import Money from '~/assets/3.0/money.svg';
import MultiSelect from '~/assets/3.0/multi-select.svg';
import NextSteps from '~/assets/3.0/next-steps.svg';
import Next from '~/assets/3.0/next.svg';
import NominatePeer from '~/assets/3.0/nominate-peers-review.svg';
import Notebook from '~/assets/3.0/notebook.svg';
import Notifications from '~/assets/3.0/notifications.svg';
import OutLookLogo from '~/assets/3.0/office-365.svg';
import OpenAll from '~/assets/3.0/open-all.svg';
import OpenInNew from '~/assets/3.0/open-in-new.svg';
import Organisation from '~/assets/3.0/organisation.svg';
import Partial from '~/assets/3.0/partial.svg';
import PathSymbolInactive from '~/assets/3.0/path-symbol-inactive.svg';
import PDF from '~/assets/3.0/pdf.svg';
import Peer from '~/assets/3.0/peer.svg';
import Phone from '~/assets/3.0/phone.svg';
import PlanGoals from '~/assets/3.0/plan-goals.svg';
import Plan from '~/assets/3.0/plan.svg';
import Plus from '~/assets/3.0/plus.svg';
import PulseSurvey from '~/assets/3.0/pulse-survey.svg';
import QuestionStar from '~/assets/3.0/question-star.svg';
import RadioButtonSelected from '~/assets/3.0/radio-button-selected.svg';
import RadioButton from '~/assets/3.0/radio-button.svg';
import Ranking from '~/assets/3.0/ranking.svg';
import Rating from '~/assets/3.0/rating.svg';
import Refresh from '~/assets/3.0/refresh.svg';
import Reports from '~/assets/3.0/reports.svg';
import Resize from '~/assets/3.0/resize.svg';
import Resume from '~/assets/3.0/resume.svg';
import Roadmap from '~/assets/3.0/roadmap.svg';
import RowColumn from '~/assets/3.0/row-column.svg';
import Save from '~/assets/3.0/save.svg';
import Search from '~/assets/3.0/search.svg';
import SecondaryRole from '~/assets/3.0/secondary-role.svg';
import Send from '~/assets/3.0/send.svg';
import Settings from '~/assets/3.0/settings.svg';
import Shape from '~/assets/3.0/shape.svg';
import ShowHalf from '~/assets/3.0/show-half.svg';
import Show from '~/assets/3.0/show.svg';
import Signatures from '~/assets/3.0/signatures.svg';
import SingleSelect from '~/assets/3.0/single-select.svg';
import SkillCategory from '~/assets/3.0/skill-category.svg';
import Skill from '~/assets/3.0/skill.svg';
import Smiley from '~/assets/3.0/smiley.svg';
import SortDown from '~/assets/3.0/sort-down.svg';
import SortUp from '~/assets/3.0/sort-up.svg';
import Star from '~/assets/3.0/star.svg';
import SubtractMinus from '~/assets/3.0/subtract-minus.svg';
import Summary from '~/assets/3.0/summary.svg';
import Superadmin from '~/assets/3.0/superadmin.svg';
import Surveys from '~/assets/3.0/surveys.svg';
import Team from '~/assets/3.0/team.svg';
import TextAnswer from '~/assets/3.0/text-answer.svg';
import Thumb from '~/assets/3.0/thumb.svg';
import Time from '~/assets/3.0/time.svg';
import Todo from '~/assets/3.0/to-do.svg';
import TraditionalSurvey from '~/assets/3.0/traditional-survey.svg';
import Traffic from '~/assets/3.0/traffic-light.svg';
import Type from '~/assets/3.0/type.svg';
import Unarchive from '~/assets/3.0/unarchive.svg';
import Undo from '~/assets/3.0/undo.svg';
import Unlock from '~/assets/3.0/unlock.svg';
import Upcoming from '~/assets/3.0/upcoming.svg';
import Upload from '~/assets/3.0/upload.svg';
import UserCheck from '~/assets/3.0/user_check.svg';
import Users from '~/assets/3.0/users.svg';
import Valid from '~/assets/3.0/valid.svg';
import ViewColumns from '~/assets/3.0/view-columns.svg';
import Warning2 from '~/assets/3.0/warning-2.svg';
import Warning from '~/assets/3.0/warning.svg';
import WorkExperience from '~/assets/3.0/work-experience.svg';
import WorkHistory from '~/assets/3.0/work-history.svg';
import Work from '~/assets/3.0/work.svg';
import Draggable from '~/assets/hand.svg';

export enum ICONS {
  ADD_PLUS = 'add-plus',
  ADD_USER = 'add-user',
  AGENDA = 'agenda',
  ARCHIVE = 'archive',
  ARCHIVE_2 = 'archive-2',
  AVATAR_SINGLE = 'avatar-single',
  BACK = 'back',
  CALENDAR = 'calendar',
  CAREER = 'career',
  CHAT = 'chat',
  CHECKBOX = 'checkbox',
  CHECKBOX_2 = 'checkbox-2',
  CHECKBOX_CHECKED = 'checkbox-checked',
  CHECKMARK = 'checkmark',
  CHEVRON = 'chevron',
  CSV = 'csv',
  CLOSE_2 = 'close-2',
  CLOSE = 'close',
  COLLAPSE_MENU = 'collapse-menu',
  COLLAPSE_WINDOW = 'collapse-window',
  COMMENT = 'comment',
  COMPANY_SETTINGS = 'company-settings',
  CONVERSATIONS = 'conversations',
  DELETE_BIN = 'delete-bin',
  DETAILS = 'details',
  DELETE_BIN_FILLED = 'delete-bin-filled',
  DEVELOPMENT = 'development',
  DROPDOWN = 'dropdown',
  DUPLICATE = 'duplicate',
  EDIT_PENCIL = 'edit-pencil',
  EDUCATION = 'education',
  EXPAND = 'expand',
  ERROR_SIGN = 'error',
  EXPORT = 'export',
  EXTERNAL_LINK = 'external-link',
  IMPORT = 'import',
  FAVORITE = 'favorite',
  FILTER = 'filter',
  PDF = 'pdf',
  GOALS = 'goals',
  GRAB_GRID = 'grab-grid',
  GRID_VIEW = 'grid-view',
  HELP = 'help',
  HIDE = 'hide',
  HOME = 'home',
  HALF_PRIVATE = 'half-private',
  HISTORY = 'history',
  INCOGNITO = 'incognito',
  FOLDER = 'folder',
  INFO = 'info',
  INFO_2 = 'info-2',
  INFO_3 = 'info-3',
  INTEGRATION = 'integration',
  INVALID = 'invalid',
  INVITES = 'invites',
  JOB_MATRIX = 'job-matrix',
  KEBAB_MENU = 'kebab-menu',
  LEARNING = 'learning',
  LEARNING_ACTIVITY = 'learning-activity',
  LIST_VIEW = 'list-view',
  LOCK = 'lock',
  LOG = 'log',
  LOGOUT = 'logout',
  MEMBERS = 'members',
  MESSAGE = 'message',
  MONEY = 'money',
  MULTI_SELECT = 'multi-select',
  NEXT = 'next',
  NEXT_STEPS = 'next-steps',
  NOMINATE_PEER = 'nominatePeer',
  GOOGLE_LOGO = 'google-logo',
  NOTEBOOK = 'notebook',
  NOTIFICATIONS = 'notifications',
  ORGANISATION = 'organisation',
  OPEN_IN_NEW = 'open-in-new',
  OUTLOOK_LOGO = 'outlook-logo',
  PATH_SYMBOL_INACTIVE = 'path-symbol-inactive',
  PLAN = 'plan',
  PULSE_SURVEY = 'pulse-survey',
  PARTIAL = 'partial',
  PLUS = 'plus',
  RADIO_BUTTON = 'radio-button',
  RADIO_BUTTON_SELECTED = 'radio-button-selected',
  RANKING = 'ranking',
  REFRESH = 'refresh',
  REPORTS = 'reports',
  RESIZE = 'resize',
  RESUME = 'resume',
  ROADMAP = 'roadmap',
  LUCA = 'luca',
  ROW_COLUMN = 'row-column',
  SAVE = 'save',
  SEARCH = 'search',
  SETTINGS = 'settings',
  SHOW = 'show',
  SHOW_HALF = 'show_half',
  SIGNATURES = 'signatures',
  SHAPE = 'shape',
  SINGLE_SELECT = 'single-select',
  SKILL_CATEGORY = 'skill-category',
  SMILEY = 'smiley',
  SORT_DOWN = 'sort-down',
  SORT_UP = 'sort-up',
  STAR = 'star',
  SUPERADMIN = 'superadmin',
  SURVEYS = 'surveys',
  TEAM = 'team',
  TYPE = 'type',
  THUMB = 'thumb',
  UNARCHIVE = 'unarchive',
  TEXT_ANSWER = 'text-answer',
  TIME = 'time',
  TODO = 'to-do',
  TRADITIONAL_SURVEY = 'traditional-survey',
  TRAFFIC = 'traffic',
  UNDO = 'undo',
  UNLOCK = 'unlock',
  UPCOMING = 'upcoming',
  UPLOAD = 'upload',
  USER_CHECK = 'user_check',
  USERS = 'users',
  VALID = 'valid',
  WARNING = 'warning',
  WARNING_2 = 'warning-2',
  IN_PROGRESS = 'in-progress',
  SUBTRACT_MINUS = 'SubtractMinus',
  SUMMARY = 'SUMMARY',
  ARROW_DOUBLE_LEFT = 'arrow-double-left',
  ARROW_DOUBLE_RIGHT = 'arrow-double-right',
  MAIL = 'mail',
  PHONE = 'phone',
  SECONDARY_ROLE = 'secondary-role',
  JOB_LEVEL = 'job-level',
  JOB_FAMILY = 'job-family',
  WORK_EXPERIENCE = 'work-experience',
  HEART = 'heart',
  DRAGGABLE = 'draggable',
  RATING = 'rating',
  CUSTOM_SKILL = 'custom-skill',
  SKILL = 'skill',
  PLAN_GOALS = 'plan-goals',
  PEER = 'peer',
  EMPLOYEE = 'employee',
  COACH = 'coach',
  QUESTION_STAR = 'question-star',
  OPEN_ALL = 'open-all',
  CLOSE_ALL = 'close-all',
  VIEW_COLUMNS = 'view-columns',
  DECLINED = 'declined',
  EXPIRED = 'expired',
  CALIBRATE = 'calibrate',
  WORK = 'work',
  WORK_HISTORY = 'work_history',
  FLAG = 'flag',
  AI_ICON = 'ai',
  AI_COLORED = 'ai-color',
  SEND = 'send',
}

export const IconList = {
  [ICONS.ADD_PLUS]: AddPlus,
  [ICONS.ADD_USER]: AddUser,
  [ICONS.CHEVRON]: Chevron,
  [ICONS.AGENDA]: Agenda,
  [ICONS.ARCHIVE]: Archive,
  [ICONS.ARCHIVE_2]: Archive2,
  [ICONS.AVATAR_SINGLE]: AvatarSingle,
  [ICONS.BACK]: Back,
  [ICONS.CALENDAR]: Calendar,
  [ICONS.CAREER]: Career,
  [ICONS.CHAT]: Chat,
  [ICONS.CHECKBOX]: Checkbox,
  [ICONS.CHECKBOX_2]: Checkbox2,
  [ICONS.CHECKBOX_CHECKED]: CheckboxChecked,
  [ICONS.CHECKMARK]: Checkmark,
  [ICONS.CLOSE_2]: Close2,
  [ICONS.CLOSE]: Close,
  [ICONS.CSV]: CSV,
  [ICONS.FOLDER]: Folder,
  [ICONS.COLLAPSE_MENU]: CollapseMenu,
  [ICONS.COLLAPSE_WINDOW]: CollapseWindow,
  [ICONS.COMMENT]: Comment,
  [ICONS.COMPANY_SETTINGS]: CompanySettings,
  [ICONS.CONVERSATIONS]: Conversations,
  [ICONS.DELETE_BIN]: DeleteBin,
  [ICONS.DETAILS]: Details,
  [ICONS.DELETE_BIN_FILLED]: DeleteBinFilled,
  [ICONS.GOOGLE_LOGO]: GoogleLogo,
  [ICONS.DEVELOPMENT]: Development,
  [ICONS.DROPDOWN]: Dropdown,
  [ICONS.DUPLICATE]: Duplicate,
  [ICONS.EDIT_PENCIL]: EditPencil,
  [ICONS.EDUCATION]: Education,
  [ICONS.ERROR_SIGN]: ErrorSign,
  [ICONS.EXPAND]: Expand,
  [ICONS.EXPORT]: Export,
  [ICONS.EXTERNAL_LINK]: ExternalLink,
  [ICONS.IMPORT]: Import,
  [ICONS.FAVORITE]: Favorite,
  [ICONS.FILTER]: Filter,
  [ICONS.PDF]: PDF,
  [ICONS.GOALS]: Goals,
  [ICONS.GRID_VIEW]: Gridview,
  [ICONS.GRAB_GRID]: GrabGrid,
  [ICONS.HELP]: Help,
  [ICONS.HIDE]: Hide,
  [ICONS.OUTLOOK_LOGO]: OutLookLogo,
  [ICONS.INCOGNITO]: Incognito,
  [ICONS.HOME]: Home,
  [ICONS.HISTORY]: History,
  [ICONS.INFO]: Info,
  [ICONS.INFO_2]: Info2,
  [ICONS.INFO_3]: Info3,
  [ICONS.INTEGRATION]: Integration,
  [ICONS.INVALID]: Invalid,
  [ICONS.INVITES]: Invites,
  [ICONS.IN_PROGRESS]: InProgress,
  [ICONS.JOB_MATRIX]: JobMatrix,
  [ICONS.KEBAB_MENU]: KebabMenu,
  [ICONS.LEARNING]: Learning,
  [ICONS.LEARNING_ACTIVITY]: LearningActivity,
  [ICONS.LIST_VIEW]: ListView,
  [ICONS.LOCK]: Lock,
  [ICONS.LOGOUT]: Logout,
  [ICONS.LOG]: Log,
  [ICONS.MEMBERS]: Members,
  [ICONS.MESSAGE]: Message,
  [ICONS.MONEY]: Money,
  [ICONS.MULTI_SELECT]: MultiSelect,
  [ICONS.NEXT]: Next,
  [ICONS.NEXT_STEPS]: NextSteps,
  [ICONS.NOMINATE_PEER]: NominatePeer,
  [ICONS.TYPE]: Type,
  [ICONS.NOTEBOOK]: Notebook,
  [ICONS.NOTIFICATIONS]: Notifications,
  [ICONS.ORGANISATION]: Organisation,
  [ICONS.OPEN_IN_NEW]: OpenInNew,
  [ICONS.PATH_SYMBOL_INACTIVE]: PathSymbolInactive,
  [ICONS.PLAN]: Plan,
  [ICONS.PLUS]: Plus,
  [ICONS.PULSE_SURVEY]: PulseSurvey,
  [ICONS.PARTIAL]: Partial,
  [ICONS.RADIO_BUTTON]: RadioButton,
  [ICONS.RADIO_BUTTON_SELECTED]: RadioButtonSelected,
  [ICONS.RANKING]: Ranking,
  [ICONS.REFRESH]: Refresh,
  [ICONS.REPORTS]: Reports,
  [ICONS.RESIZE]: Resize,
  [ICONS.RESUME]: Resume,
  [ICONS.ROADMAP]: Roadmap,
  [ICONS.LUCA]: Luca,
  [ICONS.ROW_COLUMN]: RowColumn,
  [ICONS.SAVE]: Save,
  [ICONS.SEARCH]: Search,
  [ICONS.SETTINGS]: Settings,
  [ICONS.SHOW]: Show,
  [ICONS.SHOW_HALF]: ShowHalf,
  [ICONS.SIGNATURES]: Signatures,
  [ICONS.SHAPE]: Shape,
  [ICONS.SINGLE_SELECT]: SingleSelect,
  [ICONS.SKILL_CATEGORY]: SkillCategory,
  [ICONS.SMILEY]: Smiley,
  [ICONS.SORT_DOWN]: SortDown,
  [ICONS.SORT_UP]: SortUp,
  [ICONS.STAR]: Star,
  [ICONS.SUPERADMIN]: Superadmin,
  [ICONS.SURVEYS]: Surveys,
  [ICONS.TEAM]: Team,
  [ICONS.TEXT_ANSWER]: TextAnswer,
  [ICONS.TIME]: Time,
  [ICONS.TODO]: Todo,
  [ICONS.TRADITIONAL_SURVEY]: TraditionalSurvey,
  [ICONS.TRAFFIC]: Traffic,
  [ICONS.UNARCHIVE]: Unarchive,
  [ICONS.UNDO]: Undo,
  [ICONS.UNLOCK]: Unlock,
  [ICONS.UPCOMING]: Upcoming,
  [ICONS.UPLOAD]: Upload,
  [ICONS.USER_CHECK]: UserCheck,
  [ICONS.USERS]: Users,
  [ICONS.VALID]: Valid,
  [ICONS.WARNING]: Warning,
  [ICONS.WARNING_2]: Warning2,
  [ICONS.SUBTRACT_MINUS]: SubtractMinus,
  [ICONS.SUMMARY]: Summary,
  [ICONS.ARROW_DOUBLE_LEFT]: ArrowLeftDouble,
  [ICONS.ARROW_DOUBLE_RIGHT]: ArrowRightDouble,
  [ICONS.MAIL]: Mail,
  [ICONS.PHONE]: Phone,
  [ICONS.SECONDARY_ROLE]: SecondaryRole,
  [ICONS.JOB_LEVEL]: JobLevel,
  [ICONS.JOB_FAMILY]: JobFamily,
  [ICONS.WORK_EXPERIENCE]: WorkExperience,
  [ICONS.HALF_PRIVATE]: HalfPrivate,
  [ICONS.HEART]: Heart,
  [ICONS.DRAGGABLE]: Draggable,
  [ICONS.RATING]: Rating,
  [ICONS.CUSTOM_SKILL]: CustomSkill,
  [ICONS.SKILL]: Skill,
  [ICONS.PLAN_GOALS]: PlanGoals,
  [ICONS.PEER]: Peer,
  [ICONS.EMPLOYEE]: Employee,
  [ICONS.COACH]: Coach,
  [ICONS.QUESTION_STAR]: QuestionStar,
  [ICONS.OPEN_ALL]: OpenAll,
  [ICONS.CLOSE_ALL]: CloseAll,
  [ICONS.VIEW_COLUMNS]: ViewColumns,
  [ICONS.DECLINED]: Declined,
  [ICONS.EXPIRED]: Expired,
  [ICONS.CALIBRATE]: Calibrate,
  [ICONS.THUMB]: Thumb,
  [ICONS.WORK_HISTORY]: WorkHistory,
  [ICONS.WORK]: Work,
  [ICONS.FLAG]: Flag,
  [ICONS.AI_ICON]: AiIcon,
  [ICONS.AI_COLORED]: AiColoredIcon,
  [ICONS.SEND]: Send,
};
