import { useState, useEffect } from 'react';

import { TSecondaryHeaderColumn } from 'src/components/TableGrid/types';

import { IColumnTable } from '~/@types/table';
import useDebounce from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import { getReviewResults, TGetReviewResults } from '~/services/reviews/reports/results';
import { processRows } from '~/utils/reports';

import { TReviewResultRow, TReviewTableData } from '../../types';
import { createColumns } from '../ResultsAverageTable/utils/createColumns';
import { createColumnSubHeader } from '../ResultsAverageTable/utils/createColumnSubHeader';

export const useReviewResultAverage = () => {
  const [filters, setFilters] = useState({ search: '' });
  const [getRatingsDataPayload, setGetRatingsDataPayload] = useState<TGetReviewResults>();
  const [totalCount, setTotalCount] = useState(0);
  const { pagination, changePagination } = usePagination(50); // initial number of items per page
  const [columns, setColumns] = useState<IColumnTable<any>[]>([]);
  const [sortBy, setSortBy] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [secondaryHeaderColumns, setSecondaryHeaderColumns] = useState<TSecondaryHeaderColumn[]>();
  const [heatMapData, setHeatMapData] = useState<TReviewResultRow[]>([]);

  const debouncedGetRatingsDataPayload = useDebounce(getRatingsDataPayload, 500);

  const fetchData = async (signal?: AbortSignal) => {
    if (!debouncedGetRatingsDataPayload) {
      return;
    }
    try {
      setIsLoading(true);

      const { options, sorting, filters, reviewId, measure } = debouncedGetRatingsDataPayload;

      const response = await getReviewResults(
        {
          filters,
          options,
          sorting,
          reviewId,
          measure,
        },
        { signal },
      );

      const heatmapData = response.data as TReviewTableData;
      setTotalCount(heatmapData.total || 0);

      const heatMapRows: TReviewResultRow[] = heatmapData.rows.map((item) => {
        return {
          ...item,
          showPrimary: true,
          nestedLevel: 0, // that is what we are using to nest the data
        };
      });

      const processedHeatMapRows: TReviewResultRow[] = [];

      processRows(heatMapRows, 0, processedHeatMapRows);

      setHeatMapData(processedHeatMapRows);
      setColumns(createColumns(heatmapData.columns));
      setSecondaryHeaderColumns(createColumnSubHeader(heatmapData.columns));
    } catch (ex) {
      setTotalCount(0);
      setHeatMapData([]);
      setColumns([]);
    } finally {
      setIsLoading(false);
    }
  };

  const setValues = async (signal?: AbortSignal) => {
    await fetchData(signal);
  };

  // on first render
  // on filters change
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setValues(signal);

    return () => {
      controller.abort(); // cancel the request on component unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(debouncedGetRatingsDataPayload)]);

  return {
    filters,
    setFilters,
    getRatingsDataPayload,
    setGetRatingsDataPayload,
    totalCount,
    pagination,
    changePagination,
    columns,
    sortBy,
    setSortBy,
    isLoading,
    secondaryHeaderColumns,
    heatMapData,
    setHeatMapData,
  };
};
