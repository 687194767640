import styled from 'styled-components';

import { COLORS } from '~/styles';

export const InformationBarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 12px 0;
  white-space: nowrap;

  span.review-name {
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  span.review-start-date {
    color: ${COLORS.SUBTEXT};
    font-style: italic;
    font-size: 12px;
  }
`;
