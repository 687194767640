import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { CONFIRMATION_MODAL_TYPE, GOAL_SORT_OPTIONS, GOAL_STATUSES_NEW } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { ButtonVariant } from '~/components/Buttons';
import { GoalTabs, TABS_ENUM } from '~/components/GoalsBlock/components/GoalTabs';
import { ICONS } from '~/components/Icon';
import { GoalsSettingsModal } from '~/components/Modals/GoalsSettingsModal';
import PaginationBar from '~/components/PaginationBar';
import BoxWithBorder from '~/components/UI/BoxWithBorder';

import { EditGoalModal } from './components/EditGoalModal';
import { SETTING_TYPES } from './components/EditGoalModal/types';
import {
  GoalOverviewContainer,
  PaginationBarWrapper,
  RowItem,
  Section,
  SectionCtr,
  StyledTop,
} from './design';
import GoalItems from './sections/GoalItems';

import { GOAL_TYPES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import {
  checkModuleGoalsInOrganisation,
  checkModuleGoalsInTeam,
  getSettingsRole,
  getTeams,
  getUser,
  getUsers,
} from '~/selectors/baseGetters';
import { getGoalCyclesByIds } from '~/services/goalCycles';
import * as goalsService from '~/services/goals';
import { getTeamsByIds } from '~/services/teams';
import { isUserCanEditGoal } from '~/utils/isUserCanEditGoal';

import {
  AutocompleteFilterGoalCycles,
  AutocompleteFilterMembers,
  AutocompleteFilterTeams,
} from '../AutoCompleteDropdownFilters';
import { CreateGoalButton } from '../CreateGoalButton';
import { Dropdown } from '../Dropdown';
import { ConfirmationModal } from '../Modals/ConfirmationModal';
import { TOAST_TYPES, useToasts } from '../Toast';

import type { IGoal, IGoalCycle, ITeam, IUser } from '@learned/types';
import type { I18n } from '@lingui/core';

const PAGE_SIZE = 50;
const PUBLIC_PAGE_SIZE = 10;

const getDefaultPagination = (isPublicPage = false) => ({
  skip: 0,
  limit: isPublicPage ? PUBLIC_PAGE_SIZE : PAGE_SIZE,
  index: 1,
});

// for public profile page, by default select all statuses, except ARCHIVED
const defaultStatuses = Object.values(GOAL_STATUSES_NEW).filter(
  (status) => ![GOAL_STATUSES_NEW.ARCHIVED].includes(status),
);

const defaultRequestData = (hash: string, isPublicPage = false) => ({
  currentTab: hash || TABS_ENUM.MY_GOALS,
  isMyGoalsOnly: hash === TABS_ENUM.MY_GOALS,

  // filters
  types: [],
  goalCycles: [],
  statuses: defaultStatuses,
  teams: [],
  owners: [],
  search: '',
  // pagination
  ...getDefaultPagination(isPublicPage),
});

type Item = {
  id: string;
  label: (i18n: I18n) => string;
};

const STATUS_ITEMS: Array<Item> = [
  { id: GOAL_STATUSES_NEW.TODO, label: (i18n: I18n) => i18n._(t`To do`) },
  { id: GOAL_STATUSES_NEW.IN_PROGRESS, label: (i18n: I18n) => i18n._(t`In progress`) },
  { id: GOAL_STATUSES_NEW.COMPLETED, label: (i18n: I18n) => i18n._(t`Completed`) },
  { id: GOAL_STATUSES_NEW.PROBLEM, label: (i18n: I18n) => i18n._(t`Problem`) },
  { id: GOAL_STATUSES_NEW.DRAFT, label: (i18n: I18n) => i18n._(t`Draft`) },
  { id: GOAL_STATUSES_NEW.ARCHIVED, label: (i18n: I18n) => i18n._(t`Archived`) },
];

// default selected status items should be the same as defaultStatuses
// TODO we need to fix this in the future, so we have one state for this
const defaultSelectedStatusItems = STATUS_ITEMS.filter((item) =>
  defaultStatuses.includes(item.id as GOAL_STATUSES_NEW),
);

enum WARNING_TYPE {
  ARCHIVE,
  UNARCHIVE,
  UPDATE_VIEWERS,
  UPDATE_SUBTYPE,
}

interface IProps {
  isPublicPage: boolean;
  forUser: IUser;
  currentUserId?: string;
  showMultiSelect?: boolean;
}

// !IMPORTANT: We use it for Goals page and PublicProfile: Goals tab
const GoalsBlock = ({ isPublicPage, forUser, currentUserId, showMultiSelect }: IProps) => {
  // split localstorage for goals page and user public goals page
  const LS_GOALS_KEY = isPublicPage ? 'goalsFilters' : 'userPublicGoalsFilters';

  const { addToast } = useToasts();
  const { i18n } = useLingui();
  const location = useLocation();
  const hash = isPublicPage
    ? location.hash.slice(1) === 'goals'
      ? TABS_ENUM.MY_GOALS
      : location.hash.slice(1) // temp solution to fix 2 tabs with hash
    : location.hash.slice(1);
  const [goals, setGoals] = useState([]);
  const [total, setTotal] = useState(0);
  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);
  const $loading = useBoolState(true);
  const $openAll = useBoolState(false);
  const [requestFilters, setRequestFilters] = useState(defaultRequestData(hash, isPublicPage));

  const $isSettingsModalVisible = useBoolState(false);
  const users = useSelector(getUsers);
  const user = useSelector(getUser);
  const teams = useSelector(getTeams);
  const userRole = useSelector(getSettingsRole);
  const isAdmin = user.isAdmin;

  const isTeamGoalsEnabled = useSelector(checkModuleGoalsInTeam);
  const isCompantGoalsEnabled = useSelector(checkModuleGoalsInOrganisation);

  const TYPE_ITEMS: Array<Item> = [
    { id: GOAL_TYPES.PERSONAL.key, label: GOAL_TYPES.PERSONAL.title },
    isTeamGoalsEnabled && { id: GOAL_TYPES.TEAM.key, label: GOAL_TYPES.TEAM.title },
    isCompantGoalsEnabled && { id: GOAL_TYPES.COMPANY.key, label: GOAL_TYPES.COMPANY.title },
  ].filter(Boolean) as Array<Item>;

  const [selectedWarningType, setSelectedWarningType] = useState<WARNING_TYPE>();
  const [selectedGoals, setSelectedGoals] = useState<IGoal[]>([]);
  const [selectedTypeItems, setSelectedTypeItems] = useState<Item[]>([]);
  const [selectedStatusItems, setSelectedStatusItems] = useState<Item[]>(
    defaultSelectedStatusItems,
  );
  const [settingsUpdateParams, setSettingsUpdateParams] = useState<Record<string, string>>();

  const [sort, setSort] = useState<GOAL_SORT_OPTIONS>(GOAL_SORT_OPTIONS.TYPE_A_Z);

  // state for teams filter (by coaches and/or members in selected teams) (only for individual tab)
  const [teamsFilter, setTeamsFilter] = useState([]);

  const debRequestFilters = useDebounce(requestFilters, 500); // to send request not immediately, but with delay
  const isIndividualTab = !hash || hash === TABS_ENUM.MY_GOALS;

  const $openDeleteConfirmationModal = useBoolState();
  const $openWarningModal = useBoolState();
  const $openEditModal = useBoolState();

  const warningModalData = useMemo(() => {
    if (selectedWarningType === WARNING_TYPE.ARCHIVE) {
      return {
        title: i18n._(t`Archive goals?`),
        description: i18n._(
          t`Are you sure you want to archive the selected goals? It is not possible to update the progress of archived goals.`,
        ),
      };
    } else if (selectedWarningType === WARNING_TYPE.UNARCHIVE) {
      return {
        title: i18n._(t`Unarchive goals?`),
        description: i18n._(t`Are you sure you want to unarchive the selected goals?`),
      };
    } else if (selectedWarningType === WARNING_TYPE.UPDATE_SUBTYPE) {
      return {
        title: i18n._(t`Edit goals?`),
        description: i18n._(t`Are you sure you want to change the subtype of the selected goals?`),
      };
    } else if (selectedWarningType === WARNING_TYPE.UPDATE_VIEWERS) {
      return {
        title: i18n._(t`Edit goals?`),
        description: i18n._(
          t`Are you sure you want to change the privacy of the selected goals? This affects who is allowed to view the goals.`,
        ),
      };
    }
  }, [selectedWarningType, i18n]);

  const resetFilters = async () => {
    setRequestFilters({ ...defaultRequestData(hash, isPublicPage) });
    setTeamsFilter([]);
    setSelectedTypeItems([]);
    setSelectedStatusItems(defaultSelectedStatusItems);
    localStorage.removeItem(LS_GOALS_KEY);
  };

  // init
  // pre-select first goal cycles or apply filters from localstorage
  // @ts-ignore
  useEffect(() => {
    // get filters from localStorage
    const localStorageData = localStorage.getItem(LS_GOALS_KEY);
    const isLocalStorageData = !isEmpty(localStorageData);

    const loadStorageData = async () => {
      const parsedData = JSON.parse(localStorageData || '');
      const { goalCycles, owners, team } = parsedData.filters || {};

      const allTeams = [];
      if (!isEmpty(team)) {
        allTeams.push(team.id);
      }
      if (!isEmpty(parsedData.teams)) {
        allTeams.push(...parsedData.teams.map(({ id }: { id: string }) => id));
      }

      const [fetchedGoalCyles, fetchedTeams] = await Promise.all([
        (!isEmpty(goalCycles) &&
          (
            await getGoalCyclesByIds(goalCycles.map(({ id }: { id: string }) => id))
          ).data.goalCycles) ||
          {},
        (!isEmpty(allTeams) && (await getTeamsByIds(allTeams)).data.teams) || {},
      ]);

      if (parsedData.filters) {
        // Keep only existing goal cycles
        if (!isEmpty(goalCycles)) {
          parsedData.filters.goalCycles = goalCycles.filter(
            ({ id }: { id: string }) => fetchedGoalCyles[id],
          );
        }

        // Keep only existing owners users
        if (!isEmpty(owners)) {
          parsedData.filters.owners = owners.filter(({ id }: { id: string }) => users[id]);
        }

        setRequestFilters(parsedData.filters);
      }

      if (!isEmpty(parsedData.teams)) {
        // Keep only existing teams
        parsedData.teams = parsedData.teams.filter(({ id }: { id: string }) => fetchedTeams[id]);
        setTeamsFilter(parsedData.teams);
      }

      if (!isEmpty(parsedData?.filters?.types)) {
        setSelectedTypeItems(
          TYPE_ITEMS.filter((item) => parsedData?.filters?.types.includes(item.id)),
        );
      }

      if (!isEmpty(parsedData?.filters?.statuses)) {
        setSelectedStatusItems(
          STATUS_ITEMS.filter((item) => parsedData?.filters?.statuses.includes(item.id)),
        );
      }
    };

    if (isLocalStorageData) {
      loadStorageData();
    }

    return () => false;
    // eslint-disable-next-line
  }, []);

  // fetch goals
  // @ts-ignore
  useEffect(() => {
    let isMounted = true;

    const fetchGoals = async () => {
      $loading.on();

      setSelectedGoals([]);

      // fetch goals
      // @ts-ignore
      const response = await goalsService.getGoals(prepareFetchBody());

      if (isMounted) {
        setGoals(response.data);
        setTotal(response.total);
        $loading.off();
      }
    };

    fetchGoals();

    return () => (isMounted = false);
    // eslint-disable-next-line
  }, [debRequestFilters, sort, hash, forUser]);

  const prepareFetchBody = () => {
    const types = !isEmpty(selectedTypeItems) ? selectedTypeItems.map((t) => t.id) : [];
    if (types.includes(GOAL_TYPES.PERSONAL.key)) {
      types.push(GOAL_TYPES.BUSINESS.key);
    }
    return {
      limit: requestFilters.limit,
      skip: requestFilters.skip,
      types,
      isMyGoalsOnly: hash === TABS_ENUM.MY_GOALS || !!isPublicPage,
      isGetProgress: true,
      search: requestFilters.search,
      goalCycles: requestFilters.goalCycles.map((c: IGoalCycle) => c.id),
      statuses: requestFilters.statuses,
      teams: !isEmpty(requestFilters.teams) ? requestFilters.teams.map((t: ITeam) => t.id) : [],
      owners:
        hash !== TABS_ENUM.MY_GOALS
          ? !isEmpty(requestFilters.owners)
            ? requestFilters.owners.map((o: IUser) => o.id)
            : []
          : [],
      join: ['children'],

      // public pages
      forUser: isPublicPage ? forUser.id : '',
      sort,
    };
  };

  const updateRequestFilters = (
    data: {
      types?: string[];
      statuses?: string[];
      owners?: IUser[];
      goalCycles?: IGoalCycle[];
      index?: number;
      skip?: number;
      limit?: number;
      teams?: ITeam[];
      search?: string;
    } = {},
  ) => {
    if (
      !isEmpty(data.types) &&
      data.types?.includes(GOAL_TYPES.PERSONAL.key) &&
      /* @ts-ignore */
      !requestFilters.types.includes(GOAL_TYPES.BUSINESS.key)
    ) {
      data = { ...data, types: [...data.types, GOAL_TYPES.BUSINESS.key] };
    }
    if (
      !isEmpty(data.types) &&
      !data.types?.includes(GOAL_TYPES.PERSONAL.key) &&
      /* @ts-ignore */
      requestFilters.types.includes(GOAL_TYPES.BUSINESS.key)
    ) {
      data.types = data.types?.filter((t) => t !== GOAL_TYPES.BUSINESS.key);
    }
    // @ts-ignore
    setRequestFilters((prev) => {
      const isPagination = data.limit || data.skip || data.index;
      const updatedFilters = isPagination
        ? { ...prev, ...data }
        : { ...prev, ...getDefaultPagination(isPublicPage), ...data };
      // save filters to localstorage
      localStorage.setItem(
        LS_GOALS_KEY,
        JSON.stringify({
          filters: updatedFilters,
          teams: teamsFilter,
        }),
      );

      return updatedFilters;
    });
  };

  /* @ts-ignore */
  const onChangeTeamsFilter = (teams) => {
    updateRequestFilters({ teams });
  };

  const refreshGoals = () => {
    updateRequestFilters({ ...requestFilters, ...getDefaultPagination(isPublicPage) });
  };

  const onDownloadCSV = async () => {
    setIsDownloadingCSV(true);
    await goalsService.downloadGoalCSV({ ...prepareFetchBody(), limit: 0, skip: 0 }); // we don't need pagination for the csv
    setIsDownloadingCSV(false);
  };

  const handleChangeTab = (key: string) => {
    window.location.hash = key;
    $loading.on();
  };

  const onPageChangeClick = async ({ index, skip }: { index: number; skip: number }) => {
    updateRequestFilters({ index, skip });
  };

  const handleChangeItemsPerPage = ({ limit }: { limit: number }) => {
    updateRequestFilters({ limit });
  };

  const getIsFiltered = () => {
    return (
      !isEmpty(selectedTypeItems) ||
      !isEmpty(requestFilters.goalCycles) ||
      !isEmpty(requestFilters.statuses) ||
      !isEmpty(requestFilters.teams) ||
      !isEmpty(requestFilters.owners) ||
      !isEmpty(requestFilters)
    );
  };

  const getFilterCount = () => {
    let count = 0;

    if (!isEmpty(selectedTypeItems)) {
      count++;
    }
    if (!isEmpty(requestFilters.goalCycles)) {
      count++;
    }
    if (!isEmpty(requestFilters.statuses)) {
      count++;
    }
    if (!isEmpty(requestFilters.teams)) {
      count++;
    }
    if (!isEmpty(requestFilters.owners) && hash !== TABS_ENUM.MY_GOALS) {
      count++;
    }
    return count;
  };

  const renderFilters = () => {
    return (
      <SectionCtr>
        <RowItem>
          <Dropdown
            placeholder={i18n._(t`All: Types`)}
            items={TYPE_ITEMS}
            selectedItems={selectedTypeItems}
            stringifyItem={(item) => item.label(i18n)}
            onChange={(value) => {
              setSelectedTypeItems(value as Item[]);
              updateRequestFilters({ types: value.map((item) => item.id) });
            }}
          />
        </RowItem>
        <RowItem>
          <AutocompleteFilterGoalCycles
            selectedItems={requestFilters.goalCycles}
            onChange={(value: IGoalCycle[]) => updateRequestFilters({ goalCycles: value })}
            placeholder={i18n._(t`Timeframe`)}
          />
        </RowItem>
        {isTeamGoalsEnabled && (
          <RowItem>
            <AutocompleteFilterTeams
              selectedItems={requestFilters.teams}
              onChange={(teams) => onChangeTeamsFilter(teams)}
              placeholder={i18n._(t`Teams`)}
            />
          </RowItem>
        )}
        <RowItem>
          <Dropdown
            placeholder={i18n._(t`Status`)}
            items={STATUS_ITEMS}
            selectedItems={selectedStatusItems}
            stringifyItem={(item) => item.label(i18n)}
            onChange={(value) => {
              setSelectedStatusItems(value);
              updateRequestFilters({ statuses: value.map((item) => item.id) });
            }}
          />
        </RowItem>
        {hash !== TABS_ENUM.MY_GOALS && !isPublicPage && (
          <RowItem>
            <AutocompleteFilterMembers
              placeholder={i18n._(t`Owner(s)`)}
              selectedItems={requestFilters.owners}
              onChange={(owners) => updateRequestFilters({ owners })}
            />
          </RowItem>
        )}
      </SectionCtr>
    );
  };

  const onCheckAll = () => {
    setSelectedGoals((previousState) => {
      if (
        goals.filter((goal) => isUserAllowedToSelectGoal(goal))?.length === previousState.length
      ) {
        return [];
      }
      return [...goals.filter((goal) => isUserAllowedToSelectGoal(goal))];
    });
  };

  const onSelectItem = (item: IGoal) => {
    if (isUserAllowedToSelectGoal(item)) {
      setSelectedGoals((previousState) => {
        const isAlreadySelected = previousState.find(({ id }) => id === item.id);
        if (isAlreadySelected) {
          return previousState.filter(({ id }) => id !== item.id);
        }
        return [...previousState, item];
      });
    }
  };

  const isItemChecked = (item: IGoal) => {
    return !!selectedGoals.find(({ id }) => item.id === id);
  };

  const isUserAllowedToSelectGoal = (item: IGoal) => {
    if (isAdmin) {
      return true;
    } else {
      return !!isUserCanEditGoal({ goal: item, user, teams });
    }
  };

  const handleWarningModalConfirmation = async () => {
    $loading.on();
    let title;

    const goalIdsToUpdate = selectedGoals.map(({ id }) => id);

    if (selectedWarningType === WARNING_TYPE.ARCHIVE) {
      await goalsService.archiveGoals(goalIdsToUpdate);
      title = i18n._(t`Goals archived`);
    } else if (selectedWarningType === WARNING_TYPE.UNARCHIVE) {
      await goalsService.unarchiveGoals(goalIdsToUpdate);
      title = i18n._(t`Goals unarchived`);
    } else if (
      !isEmpty(settingsUpdateParams) &&
      (selectedWarningType === WARNING_TYPE.UPDATE_SUBTYPE ||
        selectedWarningType === WARNING_TYPE.UPDATE_VIEWERS)
    ) {
      const params = {
        [settingsUpdateParams.setting]: settingsUpdateParams.value,
      };
      await goalsService.updateGoals(
        selectedGoals.map(({ id }) => id),
        params,
        userRole,
      );
      title = i18n._(t`Goals edited`);
      $openEditModal.off();
    }

    resetFilters();
    setSelectedGoals([]);
    addToast({
      title,
      type: TOAST_TYPES.SUCCESS,
    });

    setSelectedWarningType(undefined);
    $openWarningModal.off();
  };

  const handleEditClick = (update: Record<string, string>) => {
    if (update.setting === SETTING_TYPES.VIEWERS) {
      setSelectedWarningType(WARNING_TYPE.UPDATE_VIEWERS);
    } else if (update.setting === SETTING_TYPES.SUBTYPE) {
      setSelectedWarningType(WARNING_TYPE.UPDATE_SUBTYPE);
    }
    setSettingsUpdateParams(update);
    $openWarningModal.on();
  };

  const onDelete = async () => {
    $loading.on();
    await goalsService.deleteGoals(selectedGoals.map(({ id }) => id));

    resetFilters();
    setSelectedGoals([]);
    addToast({
      title: i18n._(t`Goals deleted`),
      type: TOAST_TYPES.SUCCESS,
    });
    $openDeleteConfirmationModal.off();
  };

  const isAllallowedGoalsSelected = useCallback(() => {
    return (
      selectedGoals?.length > 0 &&
      goals?.filter((goal) => isUserAllowedToSelectGoal(goal))?.length === selectedGoals?.length
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goals, selectedGoals?.length]);

  return (
    <GoalOverviewContainer isPublicPage={isPublicPage}>
      {!isPublicPage && (
        <GoalTabs
          currentTab={(hash as TABS_ENUM) || TABS_ENUM.MY_GOALS}
          handleChangeTab={handleChangeTab}
        />
      )}
      {/* @ts-ignore */}
      <BoxWithBorder $noBorder $noShadow>
        <div>
          <StyledTop
            filterComponents={renderFilters()}
            resetFilters={resetFilters}
            isToggleHideFilterVisible
            filters={{
              search: requestFilters.search,
              setSearch: (value) => updateRequestFilters({ search: value }),
              filterCount: getFilterCount() !== 0 ? getFilterCount() : undefined,
            }}
            thirdButton={
              isAdmin &&
              !isPublicPage && {
                label: i18n._(t`Settings`),
                onClick: $isSettingsModalVisible.on,
                icon: ICONS.SETTINGS,
              }
            }
            secondaryButton={
              !isPublicPage
                ? {
                    label: i18n._(t`Export CSV`),
                    onClick: onDownloadCSV,
                    icon: ICONS.EXPORT,
                    isLoading: isDownloadingCSV,
                    disabled: isEmpty(goals),
                  }
                : undefined
            }
            actionButton={
              !isPublicPage
                ? {
                    label: $openAll.value ? i18n._(t`Close all`) : i18n._(t`Open all`),
                    onClick: $openAll.toggle,
                    variant: ButtonVariant.TEXT_PRIMARY,
                  }
                : undefined
            }
            fourthButton={
              isPublicPage &&
              currentUserId && <CreateGoalButton preselectedUsers={[currentUserId]} />
            }
          />

          <Section>
            {/* @ts-ignore */}
            <GoalItems
              showMultiSelect={showMultiSelect}
              items={goals}
              loading={$loading.value}
              isOpenAll={$openAll.value}
              refreshGoals={refreshGoals}
              isIndividualTab={isIndividualTab}
              sort={sort}
              setSort={(value) => setSort(value as GOAL_SORT_OPTIONS)}
              resetFilters={resetFilters}
              isFiltered={getIsFiltered()}
              isPublicPage={isPublicPage}
              multiSelect={{
                checkedCount: selectedGoals.length,
                isAllChecked: isAllallowedGoalsSelected(),
                onCheckAll,
                onSelectItem,
                isItemChecked,
                ...(isAdmin && { onEdit: $openEditModal.on }),
                onUnarchive: () => {
                  $openWarningModal.on();
                  setSelectedWarningType(WARNING_TYPE.UNARCHIVE);
                },
                onArchive: () => {
                  $openWarningModal.on();
                  setSelectedWarningType(WARNING_TYPE.ARCHIVE);
                },
                onDelete: $openDeleteConfirmationModal.on,
                isUserAllowedToSelectGoal,
              }}
            />
          </Section>
          <PaginationBarWrapper>
            <PaginationBar
              pagination={{
                skip: requestFilters.skip,
                limit: requestFilters.limit,
                index: requestFilters.index,
              }}
              changePagination={onPageChangeClick}
              changePageSize={handleChangeItemsPerPage}
              count={total}
              noShadow
              noBorder
              noTopBorder
              showCount
              itemLabel={i18n._(t`Goals`)}
            />
          </PaginationBarWrapper>
        </div>
        {$isSettingsModalVisible.value && isAdmin && (
          <GoalsSettingsModal onClose={$isSettingsModalVisible.off} />
        )}
        {$openDeleteConfirmationModal.value && (
          <ConfirmationModal
            title={i18n._(t`Delete goals?`)}
            description={i18n._(
              t`Are you sure you want to delete the selected goals? This change cannot be undone.`,
            )}
            type={CONFIRMATION_MODAL_TYPE.DELETE}
            onSubmit={onDelete}
            onClose={$openDeleteConfirmationModal.off}
          />
        )}
        {$openWarningModal.value && (
          <ConfirmationModal
            title={warningModalData?.title}
            description={warningModalData?.description}
            type={CONFIRMATION_MODAL_TYPE.WARNING}
            onSubmit={handleWarningModalConfirmation}
            onClose={() => {
              $openWarningModal.off();
              setSelectedWarningType(undefined);
            }}
          />
        )}
        {$openEditModal.value && (
          <EditGoalModal
            goals={selectedGoals}
            onClose={$openEditModal.off}
            onSubmit={handleEditClick}
            isLoading={$loading.value}
          />
        )}
      </BoxWithBorder>
    </GoalOverviewContainer>
  );
};

export default GoalsBlock;
