import React from 'react';

import { Trans } from '@lingui/macro';

import { Button, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';

import { HeaderActions, SaveButton, Title, TitleWrapper, Wrapper } from './design';

interface IProps {
  handleEdit?: () => void;
  onClose?: () => void;
  showEditButton?: boolean;
  isDeletedGoal?: boolean;
}

export const Header = ({ handleEdit, onClose, showEditButton = false, isDeletedGoal }: IProps) => {
  return (
    <Wrapper isBorderBottom={!isDeletedGoal}>
      <TitleWrapper>
        <Title>
          <Trans>Goal preview</Trans>
        </Title>
        <HeaderActions>
          {showEditButton && !isDeletedGoal && (
            <>
              {handleEdit && (
                <SaveButton
                  icon={ICONS.EDIT_PENCIL}
                  variant={ButtonVariant.ICON}
                  onClick={handleEdit}
                />
              )}
            </>
          )}

          <Button iconSize={ICON_SIZES.LARGE} variant={ButtonVariant.CLOSE} onClick={onClose} />
        </HeaderActions>
      </TitleWrapper>
    </Wrapper>
  );
};
