import React, { useEffect, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import size from 'lodash/size';
import { useFieldArray, type UseFormReturn } from 'react-hook-form';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { Input } from '~/components/Input';
import type { IQuestionForm, IRatingLabel } from '~/pages/ReviewThemeSetup/types';
import { handleEnterKeyPressOnFields } from '~/pages/ReviewThemeSetup/utils';

import {
  AddOption,
  IconWrapper,
  IndexWrapper,
  InputGroups,
  InputLabels,
  RatingLabels,
  Row,
  Wrapper,
} from './design';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';

interface IOptionsProps {
  formMethods: UseFormReturn<IQuestionForm>;
  languageState: ILanguageStateReturn;
  ratingLabels: IRatingLabel[];
}

const Options = ({ languageState, formMethods, ratingLabels }: IOptionsProps) => {
  const { i18n } = useLingui();
  const [allowCustomizing, setAllowCustomizing] = useState(false);
  const { control, register, watch } = formMethods;
  const { fields, append, remove } = useFieldArray({
    name: 'options',
    control,
  });
  const languages = languageState.companyLanguages.filter(({ locale }) =>
    languageState.languages.find((language) => language.locale === locale),
  );
  const defaultScaleWatch = watch('settings.isManualScale');

  useEffect(() => {
    setAllowCustomizing(defaultScaleWatch ?? false);
  }, [defaultScaleWatch]);

  const handleAddOption = () => {
    if (fields.length < 10) {
      append({
        label: languages.map(({ locale }) => ({ locale, value: '' })),
      });
    }
  };

  const getRatingValues = ({
    locale,
    position,
    index,
    icon,
  }: {
    locale: string;
    position: number;
    index: number;
    icon?: string;
  }) => {
    return allowCustomizing ? (
      <div className="inputWrapper" key={`${icon}-${locale}`}>
        <Input
          name={`options.${position}.label.${index}.value`}
          width="100%"
          height="38px"
          register={register(`options.${position}.label.${index}.value`, {
            required: true,
          })}
          error={!!formMethods.formState.errors?.options?.[position]?.label?.[index]?.value}
          placeholder={i18n._(t`item ${position + 1}`)}
          leftIcon={
            size(languages) > 1
              ? getUnicodeFlagIcon(locale.substring(locale.indexOf('_') + 1))
              : undefined
          }
          onKeyDownCapture={handleEnterKeyPressOnFields}
        />
      </div>
    ) : (
      <InputLabels>
        <span>
          {size(languages) > 1
            ? getUnicodeFlagIcon(locale.substring(locale.indexOf('_') + 1))
            : undefined}
        </span>
        {ratingLabels.at(position)?.name[locale]}
      </InputLabels>
    );
  };

  return (
    <Wrapper>
      <RatingLabels>
        <span>
          <Trans>Rating</Trans>
        </span>
        <span>
          <Trans>Label</Trans>
        </span>
      </RatingLabels>
      {fields.map(({ icon, label }, index) => (
        <Row key={`traffic-light-${icon}-${index}`}>
          <IndexWrapper>{index + 1}.</IndexWrapper>
          <InputGroups>
            {label?.map(({ locale }, i) => {
              const isLocale = languages.find((lang) => lang.locale === locale);
              return isLocale && getRatingValues({ locale, position: index, index: i, icon });
            })}
          </InputGroups>
          {allowCustomizing && (
            <IconWrapper
              className={size(fields) > 2 && allowCustomizing ? 'delete' : 'disabled'}
              onClick={() => size(fields) > 2 && allowCustomizing && remove(index)}
            >
              <Icon icon={ICONS.DELETE_BIN} size={ICON_SIZES.MEDIUM} />
            </IconWrapper>
          )}
        </Row>
      ))}
      {allowCustomizing && fields.length < 10 && (
        <div>
          <AddOption type="button" onClick={handleAddOption}>
            <Trans id="+ Add option" />
          </AddOption>
        </div>
      )}
    </Wrapper>
  );
};

export { Options };
