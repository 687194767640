import styled from 'styled-components';

import { COLORS } from '~/styles';

export const QuestionSeparator = styled.hr`
  border: 1px solid ${COLORS.BORDERS};
  z-index: 10;
  width: 100%;
  margin-top: 12px;
`;
