import React from 'react';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { Container, Description, IconContainer, TextContainer, Title } from './design';

import { COLORS } from '~/styles';

interface ErrorBannerProps {
  title: string;
  description: string;
  marginBottom?: string;
}

export const ErrorBanner = ({ title, description, marginBottom }: ErrorBannerProps) => {
  return (
    <Container marginBottom={marginBottom}>
      <IconContainer>
        <Icon
          className="rotate"
          icon={ICONS.INFO_3}
          size={ICON_SIZES.SMALL}
          color={COLORS.TOOLTIP_BACKGROUND}
        />
      </IconContainer>
      <TextContainer>
        <Title>
          <span>{title}</span>
        </Title>
        {description && (
          <Description>
            <span>{description}</span>
          </Description>
        )}
      </TextContainer>
    </Container>
  );
};
