import React, { useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  REVIEW_TYPES,
  USER_REVIEW_STATUS,
  TASK_TYPE,
  USER_REVIEW_PEER_TYPE,
  ROLES,
} from '@learned/constants';
import {
  IUserReview,
  ITask,
  JOIN_USER_REVIEW_BY_ID,
  IPeer,
  IUser,
  type IUserConnection,
} from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import routes from '~/constants/routes';
import { useFromQuery } from '~/hooks/useFromQuery';
import { useLanguageState } from '~/hooks/useLanguageState';
import { getCompanyConnections, getUser, getActiveAndInactiveUsers } from '~/selectors/baseGetters';
import {
  deleteUserReviewById,
  getUserReview,
  updatePeers,
  updateCoaches,
  updateGuests,
} from '~/services/userReviews';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import type { IReviewEmployeeOverviewForm } from '../types';
import type { UseFormReturn } from 'react-hook-form';

interface UseReviewProps {
  formMethods: UseFormReturn<IReviewEmployeeOverviewForm>;
  userReviewId: IUserReview['id'];
  onClose: (isRefresh?: boolean) => void;
  handlePostUpdateOperations?: () => void;
}

export const useUserReview = ({
  formMethods,
  userReviewId,
  onClose,
  handlePostUpdateOperations,
}: UseReviewProps) => {
  const { addToast } = useToasts();
  const { i18n } = useLingui();
  const history = useHistory();
  const query = useFromQuery({ includeHash: true });
  const { setValue } = formMethods;
  const languageState = useLanguageState(true);
  const user = useSelector(getUser);
  const users = useSelector(getActiveAndInactiveUsers);
  const companyConnections = useSelector(getCompanyConnections);
  const [isLoading, setIsLoading] = useState(false);
  const [showNominatePeersModal, setShowNominatePeersModal] = React.useState(false);
  const [showSelectCoachesModal, setShowSelectCoachesModal] = React.useState(false);
  const [showSelectGuestsModal, setShowSelectGuestsModal] = React.useState(false);

  const [item, setItem] = useState<IUserReview>();

  const findEmployeeConnection = (connections: Record<string, IUserConnection>) => {
    return Object.values(connections).find(
      (connection) => connection.user === item?.createdFor,
    ) as IUserConnection;
  };

  const employeeConnection = item && findEmployeeConnection(companyConnections);

  const isAdmin = user?.isAdmin;
  const isCreator = item && user && item?.createdBy === user.id;
  const isEmployee = item && user && item?.createdFor === user.id;
  const isEmployeeHasCoachRole = employeeConnection?.roles.includes(ROLES.COACH);
  const isInputCoach = item && user && item.coaches.includes(user.id);
  const isGuest = item && user && item.guests.includes(user.id);
  const isDigitalSign = item?.settings?.isDigitalSign;
  const isSigned =
    item && item.signatures.find((signature) => signature && user && signature.userId === user.id);
  const isReviewCycleType = item && item.type === REVIEW_TYPES.REVIEW_CYCLE;
  const isDraft = item?.status === USER_REVIEW_STATUS.DRAFT;
  const isUpcoming = item?.status === USER_REVIEW_STATUS.PUBLISHED;
  const isCompleted = item?.status === USER_REVIEW_STATUS.COMPLETED;
  const isSigning = item?.status === USER_REVIEW_STATUS.SIGNING;
  const isArchived = item?.status === USER_REVIEW_STATUS.ARCHIVED;

  const isAllowToEditSummaryOrNextSteps = !isSigned && !isArchived && !isCompleted;
  const isReviewBlockedForChangesWithoutUpcoming =
    isDraft || isArchived || (isDigitalSign && (isSigning || isCompleted));
  const isReviewBlockedForChanges =
    isDraft || isUpcoming || isArchived || (isDigitalSign && (isSigning || isCompleted));

  // DELETE
  const isAllowToDelete = isReviewCycleType && (isAdmin || isCreator) && !isReviewBlockedForChanges;
  const isAllowToDeletePeers = (isAdmin || isCreator) && !isReviewBlockedForChanges;
  const isAllowToDeleteCoaches =
    (isAdmin || isCreator) && !isReviewBlockedForChangesWithoutUpcoming;
  const isAllowToDeleteGuests = (isAdmin || isCreator) && !isReviewBlockedForChangesWithoutUpcoming;

  // ADD
  const isAllowToAddPeers = (isAdmin || isCreator) && !isReviewBlockedForChanges;
  const isAllowToAddCoaches = (isAdmin || isCreator) && !isReviewBlockedForChangesWithoutUpcoming;
  const isAllowToAddGuests = (isAdmin || isCreator) && !isReviewBlockedForChangesWithoutUpcoming;

  // EDIT
  const isShowToEdit =
    (isAdmin || isCreator) &&
    item &&
    [
      USER_REVIEW_STATUS.PUBLISHED,
      USER_REVIEW_STATUS.ACTIVE,
      USER_REVIEW_STATUS.COMPLETED,
    ].includes(item.status);
  const isDisableToEdit = isReviewCycleType;
  const isAllowToEdit = isShowToEdit && !isDisableToEdit;

  // ARCHIVE
  const isAllowToArchive =
    (isAdmin || isCreator || isInputCoach) &&
    item &&
    [USER_REVIEW_STATUS.ACTIVE, USER_REVIEW_STATUS.SIGNING, USER_REVIEW_STATUS.COMPLETED].includes(
      item.status,
    );

  // UNARCHIVE
  const isAllowToUnarchive =
    (isAdmin || isCreator || isInputCoach) && item && USER_REVIEW_STATUS.ARCHIVED === item.status;

  // EXPORT PDF
  const isAllowToExportPDF =
    item &&
    [
      USER_REVIEW_STATUS.ACTIVE,
      USER_REVIEW_STATUS.SIGNING,
      USER_REVIEW_STATUS.COMPLETED,
      USER_REVIEW_STATUS.ARCHIVED,
    ].includes(item.status);

  // DIGITAL SIGN
  const isShowToSign =
    item &&
    item.settings.isDigitalSign &&
    !isSigned &&
    (isEmployee || isInputCoach || isGuest) &&
    [USER_REVIEW_STATUS.ACTIVE, USER_REVIEW_STATUS.SIGNING].includes(item.status);
  const isDisableToSignEmployee =
    item && item.coaches && item?.signatures && item.coaches.length !== item?.signatures.length;
  const isDisableToSign = isEmployee && !isSigned && isDisableToSignEmployee;

  const fetchUserReview = async () => {
    const result = await getUserReview(userReviewId, {
      join: [
        JOIN_USER_REVIEW_BY_ID.TASKS,
        JOIN_USER_REVIEW_BY_ID.EMPLOYEE_TASKS_IN_OTHER_USER_REVIEWS,
      ],
    });
    const userReview: IUserReview & { tasks: ITask[]; employeeTasksInOtherUserReviews: ITask[] } =
      result.data[API_RETURN_FIELDS.USER_REVIEW];
    setItem(userReview);
    return userReview;
  };

  const setFormValues = async () => {
    setIsLoading(true);
    const review = await fetchUserReview();
    const employeeId = review.createdFor;
    const employeeDeleted = { id: employeeId, firstName: i18n._(t`Deleted user`) };
    const employee = users[employeeId] || employeeDeleted;

    setValue('name', turnMultiLangIntoArray(review.name, languageState.companyLanguages));
    setValue('status', review.status);
    const tasksSelf = review.tasks.filter(
      (task) =>
        [TASK_TYPE.REVIEW_SELF_EVALUATE, TASK_TYPE.REVIEW_PEER_NOMINATE].includes(task.type) &&
        task.userTo.id &&
        employeeId &&
        task.userTo.id === employeeId,
    );
    const tasksReceivedPeers = review.tasks.filter(
      (task) =>
        [TASK_TYPE.REVIEW_PEER_EVALUATE].includes(task.type) &&
        task.userFrom &&
        employeeId &&
        task.userFrom === employeeId,
    );

    const tasksReceivedCoaches = review.tasks.filter(
      (task) =>
        [TASK_TYPE.REVIEW_COACH_EVALUATE].includes(task.type) &&
        task.userFrom &&
        employeeId &&
        task.userFrom === employeeId,
    );
    const taskNominatePeers =
      review.tasks.find((task) => [TASK_TYPE.REVIEW_PEER_NOMINATE].includes(task.type)) || null;

    const tasksProvidedPeers = review.employeeTasksInOtherUserReviews.filter((task) =>
      [TASK_TYPE.REVIEW_PEER_EVALUATE].includes(task.type),
    );

    const tasksProvidedCoaches = review.employeeTasksInOtherUserReviews.filter((task) =>
      [TASK_TYPE.REVIEW_COACH_EVALUATE].includes(task.type),
    );

    setValue('tasksSelf', tasksSelf);
    setValue('taskNominatePeers', taskNominatePeers);
    setValue('tasksReceivedPeers', tasksReceivedPeers);
    setValue('tasksReceivedCoaches', tasksReceivedCoaches);
    setValue('tasksProvidedPeers', tasksProvidedPeers);
    setValue('tasksProvidedCoaches', tasksProvidedCoaches);
    setValue('employeeId', employeeId);
    setValue('employee', employee);
    setValue('guests', review.guests);
    setValue('coaches', review.coaches);
    setValue('signatures', review.signatures);
    setValue('settings', review.settings);
    setIsLoading(false);
  };

  const goToReviews = () => {
    query.goBack();
  };

  const onEdit = () => {
    if (isAllowToEdit) {
      const pathSelf = routes.UPDATE_REVIEW_SELF.build(
        { companyId: undefined, teamId: undefined, role: ROLES.USER },
        // @ts-ignore
        { reviewId: item.review, isBackPath: true },
      );
      const pathIndividual = routes.UPDATE_REVIEW_INDIVIDUAL.build(
        {
          role: ROLES.USER,
          companyId: undefined,
          teamId: undefined,
        },
        {
          isBackPath: true,
          // @ts-ignore
          reviewId: item.review,
        },
      );

      history.push(item.type === REVIEW_TYPES.SELF ? pathSelf : pathIndividual);
    }
  };

  const openNominatePeersModal = () => {
    setShowNominatePeersModal(true);
  };

  const closeNominatePeersModal = () => {
    setShowNominatePeersModal(false);

    // refresh data
    setFormValues();
  };

  const openSelectCoachesModal = () => {
    setShowSelectCoachesModal(true);
  };

  const closeSelectCoachesModal = () => {
    setShowSelectCoachesModal(false);

    // refresh data
    setFormValues();
  };

  const openSelectGuestsModal = () => {
    setShowSelectGuestsModal(true);
  };

  const closeSelectGuestsModal = () => {
    setShowSelectGuestsModal(false);

    // refresh data
    setFormValues();
  };

  const onDelete = async () => {
    if (isAllowToDelete) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        title: i18n._(t`Delete review?`),
        description: i18n._(
          t`Are you sure you want to delete this employee from the review cycle? The review, including provided input, will be lost. This cannot be undone.`,
        ),
      });

      if (isConfirmed) {
        await deleteUserReviewById(userReviewId);

        addToast({
          title: i18n._(t`Review deleted`),
          type: TOAST_TYPES.INFO,
        });

        onClose(true);
      }
    }
  };

  const onDeleteCoach = async (task: ITask) => {
    if (isAllowToDeleteCoaches) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        title: i18n._(t`Delete coach?`),
        description: i18n._(
          t`Are you sure you want to delete this coach from the review? Provided input will be lost. This cannot be undone.`,
        ),
      });

      if (isConfirmed) {
        await updateCoaches(userReviewId, {
          delete: [task.userTo?.id as string],
        });

        addToast({
          title: i18n._(t`Review coach deleted`),
          type: TOAST_TYPES.INFO,
        });

        // refresh data
        await setFormValues();
      }
    }
  };

  const onDeleteGuest = async (guestId: string) => {
    if (isAllowToDeleteCoaches) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        title: i18n._(t`Delete guest?`),
        description: i18n._(t`Are you sure you want to delete this guest from the review?`),
      });

      if (isConfirmed) {
        await updateGuests(userReviewId, {
          delete: [guestId],
        });

        addToast({
          title: i18n._(t`Review guest deleted`),
          type: TOAST_TYPES.INFO,
        });

        // refresh data
        handlePostUpdateOperations && handlePostUpdateOperations();
        await setFormValues();
      }
    }
  };

  const onDeletePeer = async (task: ITask) => {
    if (isAllowToDeletePeers) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        title: i18n._(t`Delete peer?`),
        description: i18n._(
          t`Are you sure you want to delete this peer from the review? Provided input will be lost. This cannot be undone.`,
        ),
      });

      if (isConfirmed) {
        const isPeerOutside = task.userTo?.email;
        await updatePeers(userReviewId, {
          delete: [
            {
              type: isPeerOutside ? USER_REVIEW_PEER_TYPE.EMAIL : USER_REVIEW_PEER_TYPE.USER,
              value: task.userTo?.email || task.userTo?.id,
            } as IPeer,
          ],
        });

        addToast({
          title: i18n._(t`Review peer deleted`),
          type: TOAST_TYPES.INFO,
        });

        // refresh data
        await setFormValues();
      }
    }
  };

  const onAddCoaches = async (coaches: IUser[]) => {
    if (isAllowToAddCoaches) {
      await updateCoaches(userReviewId, {
        add: coaches.map((coach) => coach.id),
      });

      addToast({
        title: i18n._(t`Review coaches added`),
        type: TOAST_TYPES.INFO,
      });

      // refresh data
      await setFormValues();
    }
  };

  const onAddGuests = async (guests: IUser[]) => {
    if (isAllowToAddGuests) {
      await updateGuests(userReviewId, {
        add: guests.map((guest) => guest.id),
      });

      addToast({
        title: i18n._(t`Review guests added`),
        type: TOAST_TYPES.INFO,
      });

      // refresh data
      handlePostUpdateOperations && handlePostUpdateOperations();
      await setFormValues();
    }
  };

  useEffect(() => {
    setFormValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReviewId]);

  return {
    isLoading,
    // delete
    isAllowToDelete,
    isAllowToDeletePeers,
    isAllowToDeleteCoaches,
    isAllowToDeleteGuests,
    // add
    isAllowToAddPeers,
    isAllowToAddCoaches,
    isAllowToAddGuests,
    // edit
    isAllowToEdit,
    isShowToEdit,
    isDisableToEdit,
    // archive
    isAllowToArchive,
    isAllowToUnarchive,
    // export PDF
    isAllowToExportPDF,
    // sign
    isShowToSign,
    isDisableToSign,
    goToReviews,
    onEdit,
    onDelete,
    onDeletePeer,
    onDeleteCoach,
    onDeleteGuest,
    isAllowToEditSummaryOrNextSteps,
    showNominatePeersModal,
    openNominatePeersModal,
    closeNominatePeersModal,
    showSelectCoachesModal,
    openSelectCoachesModal,
    closeSelectCoachesModal,
    onAddCoaches,
    showSelectGuestsModal,
    openSelectGuestsModal,
    closeSelectGuestsModal,
    onAddGuests,
    isEmployeeHasCoachRole,
  };
};
