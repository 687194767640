import React from 'react';

import { GOAL_SORT_OPTIONS } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import { IMultiSelect, SelectedHeader } from '~/components/SelectedHeader';
import { CheckBox } from '~/components/SelectedHeader/components/CheckBox';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { Header } from '~/components/TableList/components/Header';
import { TableWrapper } from '~/components/TableList/design';
import { TablePlaceholder } from '~/components/TablePlaceholder';

import type { IColumnTable } from '~/@types/table';

import GoalTreeItem from '../components/GoalTreeItem';
import { GoalGridWrapper, GoalItemsWrapper, ListHeader, ListHeaderContainer } from '../design';

import type { IGoal } from '@learned/types';

interface IProps {
  items?: IGoal[];
  loading: boolean;
  isOpenAll: boolean;
  refreshGoals: () => void;
  isIndividualTab: boolean;
  onGoalClick: (goal: string) => void;
  placeholderSubTitle?: string;
  sort: string;
  setSort: (sort: string) => void;
  resetFilters: () => void;
  isFiltered: boolean;
  isPublicPage?: boolean;
  multiSelect: IMultiSelect;
  showMultiSelect?: boolean;
}

const GoalItems = ({
  items,
  loading,
  isOpenAll,
  refreshGoals,
  isIndividualTab,
  onGoalClick,
  sort,
  setSort,
  resetFilters,
  isFiltered,
  isPublicPage,
  multiSelect,
  showMultiSelect,
}: IProps) => {
  const { i18n } = useLingui();

  const renderListHeader = () => {
    const COLUMNS: IColumnTable[] = [
      {
        accessor: 'type',
        name: i18n._(t`Type`),
        minWidth: '15px',
        sortBy: {
          asc: {
            key: GOAL_SORT_OPTIONS.TYPE_A_Z,
            label: (i18n: I18n) => i18n._(t`Ascending`),
          },
          desc: {
            key: GOAL_SORT_OPTIONS.TYPE_Z_A,
            label: (i18n: I18n) => i18n._(t`Descending`),
          },
        },
      },
      {
        accessor: 'name',
        name: i18n._(t`Name`),
        maxWidth: '380px',
        sortBy: {
          asc: {
            key: GOAL_SORT_OPTIONS.NAME_A_Z,
            label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
          },
          desc: {
            key: GOAL_SORT_OPTIONS.NAME_Z_A,
            label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
          },
        },
      },
      {
        accessor: 'owner',
        name: i18n._(t`Owner(s)`),
        minWidth: '85px',
      },
      {
        accessor: 'progress',
        name: i18n._(t`Progress`),
        maxWidth: '96px',
        sortBy: {
          asc: {
            key: GOAL_SORT_OPTIONS.PROGRESS_A_Z,
            label: (i18n: I18n) => i18n._(t`Ascending`),
          },
          desc: {
            key: GOAL_SORT_OPTIONS.PROGRESS_Z_A,
            label: (i18n: I18n) => i18n._(t`Descending`),
          },
        },
      },
      {
        accessor: 'status',
        name: i18n._(t`Status`),
        minWidth: '20px',
        sortBy: {
          asc: {
            key: GOAL_SORT_OPTIONS.STATUS_A_Z,
            label: (i18n: I18n) => i18n._(t`Ascending`),
          },
          desc: {
            key: GOAL_SORT_OPTIONS.STATUS_Z_A,
            label: (i18n: I18n) => i18n._(t`Descending`),
          },
        },
      },
    ].filter((column) => !isPublicPage || column.accessor !== 'owner');
    return (
      <TableWrapper $isPointer>
        <ListHeader>
          <Header
            columns={COLUMNS}
            sortProps={{
              sortBy: sort,
              handleSortBy: (value) => setSort(value as string),
            }}
          />
        </ListHeader>
      </TableWrapper>
    );
  };

  return (
    <GoalItemsWrapper>
      {multiSelect.checkedCount > 0 && showMultiSelect ? (
        <SelectedHeader multiSelect={multiSelect} />
      ) : (
        <ListHeaderContainer>
          {showMultiSelect && (
            <CheckBox
              isHovered
              isSelected={multiSelect.isAllChecked}
              onChange={multiSelect.onCheckAll}
            />
          )}
          {renderListHeader()}
        </ListHeaderContainer>
      )}

      <GoalGridWrapper>
        <ShowSpinnerIfLoading loading={loading}>
          {!isEmpty(items) ? (
            items?.map((item, index) => (
              <GoalTreeItem
                key={index}
                item={item}
                refreshGoals={refreshGoals}
                isOpenAll={isOpenAll}
                isIndividualTab={isIndividualTab}
                isUpdateGoalProgress={true}
                isUpdateActivityProgress={false}
                activitiesReadonly={true}
                onGoalClick={onGoalClick}
                isPublicPage={isPublicPage}
                isGoalSelected={multiSelect.isItemChecked(item)}
                onSelectItem={multiSelect.onSelectItem}
                showMultiSelect={showMultiSelect}
                isUserAllowedToSelectGoal={multiSelect.isUserAllowedToSelectGoal}
              />
            ))
          ) : (
            <TablePlaceholder
              isLoading={loading}
              isFiltered={isFiltered}
              noResultText={i18n._(t`This search did not produce any results..`)}
              emptyStateText={i18n._(t`No goals yet`)}
              isAllDone={false}
              onResetSearch={resetFilters}
            />
          )}
        </ShowSpinnerIfLoading>
      </GoalGridWrapper>
    </GoalItemsWrapper>
  );
};

GoalItems.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  isTeamTab: PropTypes.bool,
  isOpenAll: PropTypes.bool,
};

export default GoalItems;
