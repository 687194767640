import React, { useEffect } from 'react';

import { Trans } from '@lingui/macro';
import { Controller, useForm, type UseFormSetValue } from 'react-hook-form';

import { CommentSection, NAOption } from './Components';
import AllAnswers, { ALL_ANSWERS_TAB } from './Components/AllAnswers';
import AllPreviousAnswers from './Components/AllPreviousAnswers';
import { RatingAnswers } from './Components/RatingAnswers';
import { RatingLabelPlaceholder } from './Components/RatingLabelPlaceholder';
import { RatingLabel } from './Components/RatingQuestionLabel';
import {
  ContentWrapper,
  QuestionHeader,
  QuestionWrapper,
  RatingLabelContainer,
  RatingQuestionLabel,
} from './design';

import useBoolState from '~/hooks/useBoolState';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';

import { IQuestionDefaultData } from '../../types';
import { WithComments } from '../QuestionView';

import type {
  ILatestPreviousRatingResponse,
  IUserReviewQuestionRating,
  IAllPreviousRatingResponse,
} from '@learned/types';

interface IQuestionViewTextProps {
  questionData: IUserReviewQuestionRating;
  languageState: ILanguageStateReturn;
  question: IQuestionDefaultData;
  onChange?: (data: {
    questionId: string;
    answer?: number | null; // null when NA enabled
    comment?: string;
    isNotApplicable?: boolean;
  }) => void;
  defaultValues: IQuestionRatingForm;
  canAnswer: boolean;
  hasError?: boolean;
  hasCommentError?: boolean;
  showOtherRatings?: boolean;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
  isDashboard?: boolean;
  isShowPreviousScore?: boolean;
  previousRating?: ILatestPreviousRatingResponse;
  allPreviousRatings?: IAllPreviousRatingResponse[];
}

export interface IQuestionRatingForm extends WithComments {
  answer: string | null;
  isNotApplicable: boolean;
}

const onRatingLabelChange = (
  canAnswer: boolean,
  isSelected: boolean,
  index: number,
  setValue: UseFormSetValue<IQuestionRatingForm>,
  questionId: string,
  onChange?: (data: {
    questionId: string;
    answer?: number | null; // null when NA enabled
    comment?: string;
    isNotApplicable?: boolean;
  }) => void,
) => {
  if (!canAnswer) {
    return undefined;
  }
  setValue('answer', !isSelected ? index.toString() : null);
  onChange?.({
    questionId,
    answer: !isSelected ? index : -1,
  });
};

export const RatingQuestion = ({
  question,
  questionData,
  defaultValues,
  onChange,
  canAnswer,
  hasError,
  hasCommentError,
  showOtherRatings,
  useMultiLangString,
  isDashboard,
  isShowPreviousScore = false,
  previousRating,
  allPreviousRatings,
}: IQuestionViewTextProps) => {
  const formMethods = useForm<IQuestionRatingForm>({ defaultValues });
  const { control, watch, setValue } = formMethods;
  const notApplicableWatch = watch('isNotApplicable');
  const $isNotApplicable = useBoolState(notApplicableWatch);
  const displayAnswers = !!(
    question.otherCoachRatings?.length ||
    question.otherPeerRatings?.length ||
    question.otherSelfRating
  );

  useEffect(() => {
    $isNotApplicable.set(notApplicableWatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notApplicableWatch]);

  const options = questionData?.settings?.options ?? [];

  const isAnswerObligated = questionData?.settings?.isAnswerObligated || false;
  const isPreviouslySkipped = previousRating?.rating.answer === null && isShowPreviousScore;

  const previousRatingIndex = isShowPreviousScore ? previousRating?.rating.answer || -1 : -1;

  return (
    <QuestionWrapper $isDashboard={isDashboard}>
      <ContentWrapper className={$isNotApplicable.value ? 'disabled' : ''}>
        <QuestionHeader>
          {canAnswer && (
            <RatingQuestionLabel hasError={hasError}>
              <Trans>Select your answer</Trans>
            </RatingQuestionLabel>
          )}
          {canAnswer && (!isAnswerObligated || isPreviouslySkipped) && (
            <Controller
              name="isNotApplicable"
              control={control}
              render={({ field }) => (
                <NAOption
                  previousRatingTooltip={
                    isPreviouslySkipped ? previousRating?.userReview.name : undefined
                  }
                  isChecked={field.value}
                  onChange={(value: boolean) => {
                    field.onChange(value);
                    onChange?.({
                      questionId: questionData.id,
                      isNotApplicable: value,
                      answer: value ? null : -1, // if NA enable -> we change answer to null, if disable -> set -1
                    });
                  }}
                  isAnswerObligated={isAnswerObligated}
                />
              )}
            />
          )}
        </QuestionHeader>
        <RatingLabelContainer>
          <Controller
            name="answer"
            control={control}
            render={({ field }) => (
              <>
                {options.length <= 5 ? (
                  options.map((option, index) => {
                    index += 1;
                    const isSelected = !!(field.value && Number(field.value) === index);
                    const isPreviousRating = previousRatingIndex === index;
                    return (
                      <RatingLabel
                        previousRating={isPreviousRating ? previousRating : undefined}
                        canAnswer={canAnswer}
                        option={option}
                        key={index}
                        isSelected={isSelected}
                        useMultiLangString={useMultiLangString}
                        onChange={() => {
                          onRatingLabelChange(
                            canAnswer,
                            isSelected,
                            index,
                            setValue,
                            questionData.id,
                            onChange,
                          );
                        }}
                      />
                    );
                  })
                ) : options.length > 5 ? (
                  <>
                    {options.map((_, index) => {
                      index += 1;
                      const isSelected = !!(field.value && Number(field.value) === index);
                      const isPreviousRating = previousRatingIndex === index;
                      if (index === 1) {
                        return (
                          <RatingLabel
                            previousRating={isPreviousRating ? previousRating : undefined}
                            canAnswer={canAnswer}
                            key={index}
                            option={options[0]}
                            isSelected={isSelected}
                            useMultiLangString={useMultiLangString}
                            onChange={() => {
                              onRatingLabelChange(
                                canAnswer,
                                isSelected,
                                index,
                                setValue,
                                questionData.id,
                                onChange,
                              );
                            }}
                          />
                        );
                      } else if (index === options.length) {
                        return (
                          <RatingLabel
                            previousRating={isPreviousRating ? previousRating : undefined}
                            canAnswer={canAnswer}
                            key={index}
                            option={options[options.length - 1]}
                            isSelected={isSelected}
                            useMultiLangString={useMultiLangString}
                            onChange={() => {
                              onRatingLabelChange(
                                canAnswer,
                                isSelected,
                                index,
                                setValue,
                                questionData.id,
                                onChange,
                              );
                            }}
                          />
                        );
                      }
                      return (
                        <RatingLabelPlaceholder
                          isPreviousAnswer={previousRatingIndex === index}
                          canAnswer={canAnswer}
                          key={index}
                          index={index}
                          options={options}
                          option={options[index - 1]}
                          isSelected={isSelected}
                          useMultiLangString={useMultiLangString}
                          onChange={() => {
                            onRatingLabelChange(
                              canAnswer,
                              isSelected,
                              index,
                              setValue,
                              questionData.id,
                              onChange,
                            );
                          }}
                        />
                      );
                    })}
                  </>
                ) : null}
              </>
            )}
          />
        </RatingLabelContainer>
      </ContentWrapper>
      {canAnswer && questionData?.settings?.isCommentsAllowed && (
        <CommentSection
          formMethods={formMethods}
          isRequired={!$isNotApplicable.value && questionData?.settings?.isCommentsObligated}
          onChange={(comment) => onChange?.({ questionId: questionData.id, comment })}
          hasError={hasCommentError}
        />
      )}

      <AllAnswers
        isAllAnswersEnabled={Boolean(showOtherRatings && displayAnswers)}
        isPreviousAnswersEnabled={isShowPreviousScore}
      >
        {(activeTab: ALL_ANSWERS_TAB) => (
          <>
            {activeTab === ALL_ANSWERS_TAB.ALL_ANSWERS && (
              <RatingAnswers
                question={question}
                useMultiLangString={useMultiLangString}
                ratingOptions={questionData?.settings?.options}
                openFirstAnswerByDefault={!canAnswer}
              />
            )}
            {activeTab === ALL_ANSWERS_TAB.PREVIOUS_ANSWERS && (
              <AllPreviousAnswers
                reviewQuestionId={question.question.reviewQuestion}
                useMultiLangString={useMultiLangString}
                allPreviousRatings={allPreviousRatings}
                userReviewQuestions={[questionData]}
              />
            )}
          </>
        )}
      </AllAnswers>
    </QuestionWrapper>
  );
};
