/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';

import { ASSET_PICKER_TABS } from '@learned/constants';

import { ICONS } from '~/components/Icon';
import { ImageWithAlt } from '~/components/ImageWithAlt';
import { AssetPickerModal, type ImageAspectRatio } from '~/components/Modals/AssetPickerModal';
import { Hover, HoverWrapper } from '~/components/Modals/AssetPickerModal/design';

import UploadContentWrapper from './UploadContentWrapper';

import { Image } from '../../design';

interface ImageWrapperProps {
  image?: string;
  onChange: (image: string) => void;
  onDelete?: () => void;
  alt: string;
  imageAspectRatio?: ImageAspectRatio;
}

const ImageWrapper = ({ image, onChange, alt, onDelete, imageAspectRatio }: ImageWrapperProps) => {
  const [isAssetPickerModalVisible, setIsAssetPickerModalVisible] = useState(false);

  return (
    <>
      {image ? (
        <HoverWrapper>
          <Hover>
            <UploadContentWrapper
              label={'Edit'}
              icon={ICONS.EDIT_PENCIL}
              handleOnClick={() => setIsAssetPickerModalVisible(true)}
              description={'Files: JPG, PNG, max 2MB Ratio 16:9 (1920x1080px)'}
            />
          </Hover>
          <Image onClick={() => setIsAssetPickerModalVisible(true)} cursor="pointer">
            <ImageWithAlt src={image} alt={alt} />
          </Image>
        </HoverWrapper>
      ) : (
        <HoverWrapper $isUpload>
          <Hover $isUpload>
            <UploadContentWrapper
              label={'Upload'}
              handleOnClick={() => setIsAssetPickerModalVisible(true)}
              description={'Recommended: JPG, PNG, max 2MB Ratio 16:9 (1920x1080px)'}
            />
          </Hover>
        </HoverWrapper>
      )}
      {isAssetPickerModalVisible && (
        <AssetPickerModal
          onClose={() => setIsAssetPickerModalVisible(false)}
          onDelete={onDelete}
          onSelectAsset={({ asset }) => asset && onChange(asset)}
          tabs={[ASSET_PICKER_TABS.UPLOAD_IMAGE, ASSET_PICKER_TABS.STOCKPHOTOS]}
          imageSrc={image}
          imageAspectRatio={imageAspectRatio}
        />
      )}
    </>
  );
};

export { ImageWrapper };
