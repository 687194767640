import styled from 'styled-components';

import { COLORS } from '~/styles';

const Wrapper = styled.div<{ minSize: string }>`
  background-color: ${COLORS.WHITE};
  padding: 24px 32px;
  border-radius: 8px;
  min-width: ${({ minSize }) => minSize};
`;

const Header = styled.div`
  padding-bottom: 8px;
`;

const Title = styled.div`
  font-size: 26px;
  color: ${COLORS.TEXT_HOVER};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Subtitle = styled.div`
  font-size: 18px;
  color: ${COLORS.SUBTEXT};
`;

const BodyArea = styled.div`
  margin-top: 16px;
`;

const SecondaryTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${COLORS.TEXT_HOVER};
`;

const InputCtr = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 8px;
`;

const ButtonCtr = styled.div<{ isSpaceBetween: boolean }>`
  display: flex;
  gap: 8px;
  margin-top: 32px;
  justify-content: ${({ isSpaceBetween }) => (isSpaceBetween ? 'space-between' : 'flex-end')};
`;

const IconCtr = styled.div`
  cursor: pointer;
`;

export { Wrapper, Header, Title, Subtitle, BodyArea, SecondaryTitle, InputCtr, ButtonCtr, IconCtr };
