import React, { useEffect, useMemo, useState } from 'react';

import {
  FocusAreaType,
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
} from '@learned/constants';
import {
  IFocusArea,
  IGeneratedPopulatedFocusArea,
  ILanguageValue,
  IMultiLangString,
  IReviewTheme,
} from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { IconOld } from '~/components/IconOld';
import Modal from '~/components/Modal';
import RickTextView from '~/components/RickTextView';
import ShowMore from '~/components/ShowMore';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import ToolTip from '~/components/Tooltip';
import { PlanGoalQuestion } from '~/pages/ReviewGiveFeedback/components/Questions/PlanGoalQuestion';
import { RatingQuestion } from '~/pages/ReviewGiveFeedback/components/Questions/RatingQuestion';
import { SkillQuestion } from '~/pages/ReviewGiveFeedback/components/Questions/SkillQuestion';
import { TextAreaQuestion } from '~/pages/ReviewGiveFeedback/components/Questions/TextAreaQuestion';
import type {
  IQuestionCustomSkillData,
  IQuestionDefaultData,
  IQuestionSkillCategoryData,
} from '~/pages/ReviewGiveFeedback/types';
import {
  HeaderWrapper,
  Title,
  QuestionTitle,
} from '~/pages/ReviewThemeSetup/components/QuestionModal/design';
import { ISelectedFocusArea } from '~/pages/ReviewThemeSetup/types';

import {
  Footer,
  IconButtons,
  InfoRow,
  Separator,
  QuestionBody,
  Section,
  ThemeContainer,
  Description,
  Wrapper,
  IconContainer,
} from './design';

import { QUESTION_TYPES } from '~/constants/questionsTypes';
import { useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getReviewTheme } from '~/services/reviewThemes';
import { getSkillCategories } from '~/services/skillCategories';
import { getSkillsById } from '~/services/skills';
import { COLORS } from '~/styles';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

type QuestionPreviewModalProps = {
  question: any;
  onClose: () => void;
  themeName: any;
  onEdit?: () => void;
  themeIcon?: any;
  iconColor?: string | null;
  themeId?: string | null;
  evaluators: REVIEW_QUESTION_EVALUATORS[];
  isTheme?: boolean;
};

const QuestionPreviewModal = ({
  question,
  onClose,
  themeName,
  themeIcon,
  iconColor,
  onEdit,
  themeId,
  evaluators,
  isTheme,
}: QuestionPreviewModalProps) => {
  const { i18n } = useLingui();
  const languageState = useLanguageState();
  const getString = useMultiLangString();
  const [answer, setAnswer] = useState<string | number | null>('');
  const [theme, setTheme] = useState<IReviewTheme>();
  const [loading, setLoading] = useState(true);
  const [relevantFocusAreas, setRelevantFocusAreas] = useState<
    IQuestionCustomSkillData['subQuestions'] | IQuestionSkillCategoryData['subQuestions']
  >([]);
  const [skillName, setSkillName] = useState(undefined);
  const currentCompany = useSelector(getCurrentCompany);
  const [skillTitle, setSkillTitle] = useState<string>('');

  const {
    products: {
      performance: {
        settings: {
          labels: { ratingLabels, skillLabels },
        },
      },
    },
  } = currentCompany;

  const QUESTION_TYPE_INFORMATION: { [key: string]: { title: string; icon: JSX.Element } } = {
    [QUESTION_TYPES.RATING]: {
      title: i18n._(t`Rating`),
      icon: <Icon icon={ICONS.RATING} />,
    },
    [QUESTION_TYPES.SKILL_CATEGORY]: {
      title: skillTitle,
      icon: <Icon icon={ICONS.SKILL} />,
    },
    [QUESTION_TYPES.CUSTOM_SKILL]: {
      title: i18n._(t`Custom skill`),
      icon: <Icon icon={ICONS.CUSTOM_SKILL} />,
    },
    [QUESTION_TYPES.GOAL_PLAN]: {
      title: i18n._(t`Plan goals`),
      icon: <Icon icon={ICONS.PLAN_GOALS} />,
    },
    [QUESTION_TYPES.TEXT]: {
      title: i18n._(t`Text answer`),
      icon: <Icon icon={ICONS.TEXT_ANSWER} />,
    },
  };

  useEffect(() => {
    const fetch = async () => {
      const [themeFetch, skillsFetch, skillCategory] = await Promise.all([
        themeId && getReviewTheme(themeId),
        question?.settings?.skills?.length &&
          getSkillsById([question.settings.skills[0].skill], ['focusAreas', 'categories']),
        !question.skillCategory &&
          question.settings.skillCategory &&
          getSkillCategories([question.settings.skillCategory]),
      ]);
      if (themeFetch) {
        const {
          data: { reviewTheme },
        } = themeFetch;
        setTheme(reviewTheme);
      }
      if (
        question.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY ||
        question.type.key === REVIEW_QUESTION_TYPES.SKILL_CATEGORY
      ) {
        let potentialName = '';
        if (skillCategory) {
          // @ts-ignore
          potentialName = skillCategory.name || Object.values(skillCategory)?.[0].name;
        } else if (question.skillCategory) {
          potentialName = question.skillCategory.name;
        } else if (question.skillOrKpiCategory) {
          potentialName = question.skillOrKpiCategory.value;
        }
        setSkillTitle(getString(potentialName));
        const options =
          question.settings.options ||
          (question.skillOrKpiCategory?.value === 'KPIs' || question.skillCategory?.type === 'kpis'
            ? ratingLabels
            : skillLabels
          ).map((i: { name: IMultiLangString }) => ({ label: i.name }));
        const focusAreas:
          | IQuestionCustomSkillData['subQuestions']
          | IQuestionSkillCategoryData['subQuestions'] = [];
        focusAreas.push(
          {
            question: {
              ...question,
              // @ts-ignore
              name: question.name.length ? turnArrayIntoMultiLang(question.name) : question.name,
              // @ts-ignore
              id: 'id1',
              // @ts-ignore
              settings: {
                ...question.settings,
                options,
                focusArea: {
                  name: i18n._(t`Focus area 1`),
                },
              },
            },
          },
          {
            question: {
              ...question,
              // @ts-ignore
              name: question.name.length ? turnArrayIntoMultiLang(question.name) : question.name,
              // @ts-ignore
              id: 'id2',
              // @ts-ignore
              settings: {
                ...question.settings,
                options,
                focusArea: {
                  name: i18n._(t`Focus area 2`),
                },
              },
            },
          },
          {
            question: {
              ...question,
              // @ts-ignore
              name: question.name.length ? turnArrayIntoMultiLang(question.name) : question.name,
              // @ts-ignore
              id: 'id3',
              // @ts-ignore
              settings: {
                ...question.settings,
                options,
                focusArea: {
                  name: i18n._(t`Focus area 3`),
                },
              },
            },
          },
        );
        setRelevantFocusAreas(focusAreas);
      }
      if (skillsFetch) {
        const options =
          question.settings.options ||
          skillLabels.map((i: { name: IMultiLangString }) => ({ label: i.name }));
        const skill = skillsFetch[0];
        const focusAreas:
          | IQuestionCustomSkillData['subQuestions']
          | IQuestionSkillCategoryData['subQuestions'] = [];
        const questionName = getString(
          question.name.length ? turnArrayIntoMultiLang(question.name) : question.name,
        );
        setSkillName(skill.name);
        question.settings.skills[0].selectedFocusAreas.forEach(
          ({ type, level, focusArea }: ISelectedFocusArea) => {
            switch (type) {
              case FocusAreaType.SELECT_ALL: {
                skill.focusAreas.map((i: IGeneratedPopulatedFocusArea) =>
                  i.values.map((j: IFocusArea) =>
                    focusAreas.push({
                      question: {
                        ...question,
                        // @ts-ignore
                        name: questionName,
                        // @ts-ignore
                        id: j.id,
                        // @ts-ignore
                        settings: {
                          ...question.settings,
                          options,
                          focusArea: {
                            ...j,
                          },
                        },
                      },
                    }),
                  ),
                );
                break;
              }
              case FocusAreaType.SELECT_LEVEL: {
                skill.focusAreas
                  .filter((i: IGeneratedPopulatedFocusArea) => i.level === level)
                  .map((i: IGeneratedPopulatedFocusArea) =>
                    i.values.map((j: IFocusArea) =>
                      focusAreas.push({
                        question: {
                          ...question,
                          // @ts-ignore
                          name: questionName,
                          // @ts-ignore
                          id: j.id,
                          // @ts-ignore
                          settings: {
                            ...question.settings,
                            options,
                            focusArea: {
                              ...j,
                            },
                          },
                        },
                      }),
                    ),
                  );
                break;
              }
              case FocusAreaType.SELECT_FOCUS_AREA: {
                skill.focusAreas
                  .find((i: IGeneratedPopulatedFocusArea) => {
                    return i.level === level;
                  })
                  .values.map((i: IGeneratedPopulatedFocusArea) => {
                    if (i.id === focusArea) {
                      focusAreas.push({
                        question: {
                          ...question,
                          // @ts-ignore
                          id: i.id,
                          // @ts-ignore
                          name: questionName,
                          // @ts-ignore
                          settings: {
                            ...question.settings,
                            options,
                            focusArea: {
                              ...i,
                            },
                          },
                        },
                      });
                    }
                  });
                break;
              }
            }
          },
        );
        setRelevantFocusAreas(focusAreas);
      }
      setLoading(false);
    };
    fetch();
    // eslint-disable-next-line
  }, [themeId, question, skillLabels]);

  const getQuestionPreview = () => {
    switch (question.type.key || question.type) {
      case REVIEW_QUESTION_TYPES.CUSTOM_SKILL:
      case REVIEW_QUESTION_TYPES.SKILL_CATEGORY: {
        return (
          <SkillQuestion
            isDashboard
            useMultiLangString={useMultiLangString}
            // @ts-ignore
            onChange={undefined}
            languageState={languageState}
            canAnswer={true}
            focusAreaQuestions={relevantFocusAreas}
            defaultValues={{
              answers: [],
              isNotApplicable: false,
              comment: '',
            }}
            hasError={false}
            hasCommentError={false}
            showOtherRatings={false}
          />
        );
      }
      case REVIEW_QUESTION_TYPES.GOAL_PLAN: {
        return (
          <PlanGoalQuestion
            isPreview
            userFrom={undefined}
            subTypes={question.settings.subTypes}
            setGoalsPlanned={undefined}
            goalsPlanned={[]}
            userReview={undefined}
          />
        );
      }
      case REVIEW_QUESTION_TYPES.TEXT: {
        return (
          <TextAreaQuestion
            onChange={(e) => {
              setAnswer(e.answer || '');
            }}
            onBlur={() => undefined}
            question={question as IQuestionDefaultData}
            languageState={languageState}
            // @ts-ignore
            questionData={question}
            canAnswer={true}
            defaultValues={{
              answer: answer as string,
              isNotApplicable: false,
            }}
            hasError={false}
            showOtherRatings={false}
            useMultiLangString={useMultiLangString}
          />
        );
      }
      case REVIEW_QUESTION_TYPES.RATING: {
        const options = question.options
          ? question.options.map(({ label }: { label: ILanguageValue[] }) => ({
              label: turnArrayIntoMultiLang(label),
            }))
          : question.settings.options ||
            ratingLabels.map((i: { name: IMultiLangString }) => ({ label: i.name }));
        return (
          <RatingQuestion
            onChange={(e) => {
              setAnswer(e.answer || '');
            }}
            question={
              {
                ...question,
                settings: {
                  ...question.settings,
                  options,
                },
              } as IQuestionDefaultData
            }
            canAnswer={true}
            languageState={languageState}
            // @ts-ignore
            questionData={{
              ...question,
              settings: {
                ...question.settings,
                options,
              },
            }}
            defaultValues={{
              answer: answer as string,
              comment: '',
              isNotApplicable: false,
            }}
            hasError={false}
            hasCommentError={false}
            showOtherRatings={false}
            useMultiLangString={useMultiLangString}
            isDashboard={true}
          />
        );
      }
    }
    return undefined;
  };

  const description = useMemo(() => {
    return getString(
      question?.description?.length
        ? turnArrayIntoMultiLang(question.description)
        : question?.description || '',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(question?.description)]);

  return (
    <Modal
      isHideHeader
      hideFooter
      onClose={onClose}
      showDivider={false}
      centerModal
      width={750}
      maxHeight={772}
      borderRadius={10}
      contentStyles={{ padding: '0', overflow: 'auto' }}
    >
      <ShowSpinnerIfLoading loading={loading}>
        <Wrapper>
          <HeaderWrapper>
            <Title>{i18n._(t`Preview`)}</Title>
            <IconButtons>
              {onEdit && (
                <Button
                  iconSize={ICON_SIZES.LARGE}
                  label={''}
                  size={ButtonSize.MEDIUM}
                  variant={ButtonVariant.ICON}
                  icon={ICONS.EDIT_PENCIL}
                  onClick={onEdit}
                />
              )}
              <Button
                label={''}
                size={ButtonSize.MEDIUM}
                iconSize={ICON_SIZES.LARGE}
                variant={ButtonVariant.ICON}
                icon={ICONS.CLOSE}
                onClick={onClose}
              />
            </IconButtons>
          </HeaderWrapper>
          <InfoRow>
            <Section>
              {QUESTION_TYPE_INFORMATION[question.type.key || question.type].title}
              {QUESTION_TYPE_INFORMATION[question.type.key || question.type].icon}
            </Section>
            {!isTheme && (
              <>
                |
                <Section>
                  <ThemeContainer
                    big
                    // @ts-ignore
                    color={
                      typeof iconColor === 'string' || theme
                        ? ((theme?.iconColor || iconColor)?.split('-') as string[])
                        : ['#f7f9ff', '#ebf1fe']
                    }
                  >
                    {!!(themeIcon || theme) && (
                      <IconOld
                        className="icon"
                        name={theme?.icon || themeIcon}
                        color={COLORS.MIDDLE_GRAY}
                        width={24}
                        height={24}
                      />
                    )}
                  </ThemeContainer>
                  {theme ? getString(theme?.name || '') : themeName}
                </Section>
              </>
            )}
            |
            <Section>
              {evaluators.includes(REVIEW_QUESTION_EVALUATORS.EMPLOYEE) && (
                <ToolTip tooltip={i18n._(t`Self review`)}>
                  <IconContainer>
                    <Icon size={25} color={COLORS.DROPDOWN_ICON} icon={ICONS.EMPLOYEE} />
                  </IconContainer>
                </ToolTip>
              )}
              {evaluators.includes(REVIEW_QUESTION_EVALUATORS.COACH) && (
                <ToolTip tooltip={i18n._(t`Coach review`)}>
                  <IconContainer>
                    <Icon size={25} color={COLORS.DROPDOWN_ICON} icon={ICONS.COACH} />
                  </IconContainer>
                </ToolTip>
              )}
              {evaluators.includes(REVIEW_QUESTION_EVALUATORS.PEER) && (
                <ToolTip tooltip={i18n._(t`Peer review`)}>
                  <IconContainer>
                    <Icon size={25} color={COLORS.DROPDOWN_ICON} icon={ICONS.PEER} />
                  </IconContainer>
                </ToolTip>
              )}
            </Section>
          </InfoRow>
          <Separator />
          <QuestionBody>
            <QuestionTitle>
              {(question.type.key || question.type) === REVIEW_QUESTION_TYPES.SKILL_CATEGORY
                ? i18n._(t`Skill/KPI`)
                : getString(
                    skillName ||
                      (question.name.length
                        ? turnArrayIntoMultiLang(question.name)
                        : question.name),
                  )}
            </QuestionTitle>
            {!isEmpty(description) && (
              <Description>
                <ShowMore showMoreClassName="showMore" className="showMore" maxHeight={50}>
                  <RickTextView html={description} />
                </ShowMore>
              </Description>
            )}
            {getQuestionPreview()}
          </QuestionBody>
          <Separator />
          <Footer>
            <Button
              label={i18n._(t`Close preview`)}
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.SECONDARY}
              onClick={onClose}
            />
          </Footer>
        </Wrapper>
      </ShowSpinnerIfLoading>
    </Modal>
  );
};

export { QuestionPreviewModal };
