import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Dropdown } from '~/components/Dropdown';
import { DateOption } from '~/pages/Conversations/components/CalendarDropdown';

import { JOB_STATUS_OPTIONS } from '~/constants/jobStatusProvider';
import { IMultiSelectOption } from '~/constants/userReviews';

export interface IFilters {
  createdBy: IMultiSelectOption[];
  isShowFilters: boolean;
  search: string;
  setSearch: (value: string) => void;
  onChangeFilter: (key: string, value: any) => void;
  resetFilters: () => void;
  selectedStatus: string | null;
  selectedDateOption?: DateOption;
  statuses: IMultiSelectOption[];
}

const Filters = ({ filters }: { filters: IFilters }) => {
  const { i18n } = useLingui();
  return (
    <>
      <Dropdown
        placeholder={i18n._(t`Status`)}
        items={JOB_STATUS_OPTIONS}
        selectedItems={filters.statuses || []}
        onChange={(selectedItems?: IMultiSelectOption[]) => {
          filters.onChangeFilter('statuses', selectedItems || []);
        }}
        stringifyItem={(item) => item.translated(i18n)}
        isSingleSelect={false}
        skipSort={true}
      />
    </>
  );
};

export { Filters };
