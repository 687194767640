import React, { useEffect, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import values from 'lodash/values';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Modal from '~/components/Modal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TableList } from '~/components/TableList';

import { createColumns } from './columns';

import { IJobProfile } from '~/constants/jobProfiles';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { usePagination } from '~/hooks/usePagination';
import { COLORS } from '~/styles';

const headerStyles = {
  padding: '20px 32px',
  fontSize: '22px',
  fontWeight: '600',
  lineHeight: '1.18',
  letterSpacing: '-0.24px',
};

const modalContentStyle = {
  flex: '1',
  padding: '0px',
};

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 32px 0 64px;
  gap: 16px;
  padding: 0px 48px;
`;

type AddRoleModalProps = {
  onClose: () => void;
  jobProfiles: { [key: string]: IJobProfile };
  save: (roles: string[]) => void;
  modalTitle?: string;
  confirmText?: string;
  isLoading?: boolean;
  isSingleSelect?: boolean;
};

const Content = styled.div`
  flex: 1;
  border-radius: 10px;
  padding: 4px 0 20px;
  box-sizing: border-box;
`;

const HeaderTitle = styled.div`
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.24px;
  color: ${COLORS.TEXT_HOVER};
  padding: 20px 48px 0px 48px;
`;

const AddRoleModal = ({
  jobProfiles,
  onClose,
  save,
  modalTitle,
  confirmText,
  isLoading,
  isSingleSelect,
}: AddRoleModalProps) => {
  const { i18n } = useLingui();
  const $isLoading = useBoolState(false);
  const [roles, setRoles] = useState<{ [key: string]: IJobProfile }>(jobProfiles);
  const [selectedRoles, setSelectedRoles] = useState<{ [key: string]: IJobProfile }>({});
  const [search, setSearch] = useState('');
  const { pagination, changePagination } = usePagination(10);
  const [totalRoles, setTotalRoles] = useState(Object.values(roles).length);

  const selectedRoleIds = values(selectedRoles)
    .map((a) => a.id)
    .filter((a) => a);
  const isAllChecked = !Object.values(roles).find((role) => {
    return !selectedRoleIds.includes(role.id);
  });

  useEffect(() => {
    const newRoles: { [key: string]: IJobProfile } = {};
    Object.values(jobProfiles).filter((role) => {
      if (role.name.toLowerCase().includes(search.toLowerCase())) {
        newRoles[role.id] = role;
      }
    });
    setRoles(newRoles);
    setTotalRoles(Object.values(newRoles).length);
  }, [search, jobProfiles]);

  const onCheckAll = () => {
    if (isAllChecked) {
      setSelectedRoles({});
    } else {
      setSelectedRoles(roles);
    }
  };

  const onCheckRole = (role: IJobProfile) => {
    if (isSingleSelect) {
      setSelectedRoles({ [role.id]: role });
      return;
    }
    if (selectedRoles[role.id]) {
      delete selectedRoles[role.id];
      setSelectedRoles({ ...selectedRoles });
    } else {
      setSelectedRoles({ ...selectedRoles, [role.id]: role });
    }
  };

  const previewRole = (role: IJobProfile) => {
    routes.JOB_PROFILE.go(
      {},
      {
        roleId: role.id,
        isBackPath: true,
      },
    );
  };

  const onPageChangeClick = async ({
    index,
    skip,
    limit,
  }: {
    index: number;
    skip: number;
    limit: number;
  }) => {
    const newPagination = {
      ...pagination,
      limit: limit || pagination.limit,
      skip,
      index,
    };
    changePagination(newPagination);
  };

  return (
    <Modal
      title={modalTitle ? modalTitle : i18n._(t`Add role to profile`)}
      onClose={onClose}
      minWidth={1000}
      width={1000}
      minHeight="600px"
      headerStyles={headerStyles}
      contentStyles={modalContentStyle}
      showDivider={false}
      hideFooter
      isHideHeader
    >
      <Content>
        <ShowSpinnerIfLoading loading={$isLoading.value}>
          <HeaderTitle>
            <Trans>Add role to profile</Trans>
          </HeaderTitle>
          <>
            <TableList
              data={values(roles).splice(pagination.skip, pagination.limit)}
              columns={createColumns({ previewRole })}
              multiSelectProps={{
                isMultiSelectVisible: true,
                isSelectedCountVisible: !isSingleSelect,
                isSelectAllVisible: !isSingleSelect,
                isSingleSelect,
                multiSelect: {
                  checkedCount: Object.values(selectedRoles).length,
                  isAllChecked,
                  onCheckAll,
                  onSelectItem: onCheckRole,
                  isItemChecked: (role) => {
                    return !!selectedRoles[role.id];
                  },
                },
              }}
              filtersProps={{
                isFiltered: !!search.length,
                filters: {
                  search,
                  setSearch,
                },
              }}
              placeholderProps={{
                noResultText: i18n._(t`No roles match your criteria`),
              }}
              paginationProps={{
                pagination,
                changePagination: onPageChangeClick,
                totalCount: totalRoles,
              }}
            />
          </>
        </ShowSpinnerIfLoading>
      </Content>

      <Footer>
        <Button
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          label={i18n._(t`Cancel`)}
          onClick={onClose}
          disabled={isLoading}
        />
        <Button
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          label={confirmText ? confirmText : i18n._(t`Add role`)}
          onClick={() => {
            save(Object.values(selectedRoles).map((i) => i.id));
            onClose();
          }}
          isLoading={isLoading}
          disabled={isLoading || !Object.values(selectedRoles).length}
        />
      </Footer>
    </Modal>
  );
};

export { AddRoleModal };
