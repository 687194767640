import React, { useEffect, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';
import ColoredRow from '~/components/ColoredRow';
import { Icon, ICONS } from '~/components/Icon';
import IconButton from '~/components/IconButton';
import IconMenu from '~/components/IconMenu';
import ExternalLinkIcon from '~/components/Icons/ExternalLink';
import { ActivityModal } from '~/components/Modals/ActivityModal';
import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';
import ToolTip from '~/components/Tooltip';
import UserAvatar from '~/components/UI/Avatar';
import { Header4Dark } from '~/components/UI/Typographics/headers';
import RTFeedbackCard from '~/pages/RTFeedbacks/components/RTFeedbackCard';
import RTFeedbackName from '~/pages/RTFeedbacks/components/RTFeedbackName';

import CommentsBlock from './CommentsBlock';
import GoalPreview from './GoalPreview';

import DownArrowIcon from '~/assets/ic-keyboard-arrow-down.svg';
import UpArrowIcon from '~/assets/ic-keyboard-arrow-up.svg';
import chatIcon from '~/assets/main-menu-icons/chat-1.svg';

import { ROLES, TALKING_POINT_TYPES } from '~/constants';
import ROUTES from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { getSelectedRole } from '~/selectors/baseGetters';
import { getRTFeedbackForTalkingPoint } from '~/services/RTFeedbacks';
import { updateUserActivityProgress } from '~/services/userActivities';
import { COLOR_PALETTE, COLORS } from '~/styles';

const CheckBoxWrapper = styled.div`
  display: flex;
  margin-left: 13px;
  margin-right: 13px;
`;

const ActionCol = styled.div`
  width: 24px;
`;

const FeedbackWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSvgIcon = styled(SvgIcon)`
  right: 10px;
  margin-right: 4px;
  &:hover {
    cursor: pointer;
  }
`;

const Box = styled.div`
  border: 1px solid ${COLORS.BORDER_LIGHT};
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  width: 100%;
`;

const NameField = styled(Header4Dark)`
  display: flex;
  align-items: center;
  margin: 0;
  color: ${(props) =>
    props.$isActive ? 'var(--company-color)' : props.$isDone ? '#99a5be' : COLOR_PALETTE.BLACK};
  text-decoration: ${(props) => props.$isDone && 'line-through'};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  word-break: break-word;
  cursor: pointer;
`;

const CommentsBox = styled.div`
  display: flex;
  align-items: center;
  margin: 0 2px 0 12px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: black;
`;

const TPTypeLink = styled.div`
  align-items: center;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  height: 26px;
  box-sizing: border-box;
  background-color: ${(props) => props.$color};
  color: ${COLOR_PALETTE.WHITE};
  margin-left: 15px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;

  // triangle
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 10px 8px 0;
    border-color: transparent ${(props) => props.$color} transparent transparent;
    position: relative;
    left: -10px;
  }
`;

const Actions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 24px;
  > * {
    margin-right: 16px;
  }
`;

const Line = styled.div`
  border-top: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  margin: 0 -24px;
`;

const RTFBWrapper = styled.div`
  margin-top: 16px;
`;

const LinkButton = styled(IconButton)``;

const TPLabel = styled.div`
  margin-right: 8px;
`;

const TalkingPointContent = styled.div`
  border-radius: 6px;
  padding: 0 24px 24px;
`;

const StyledLinkContainer = styled.div`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: black;
`;

function getPointLink(point, role, currentUserId, commonTeam) {
  if (role !== ROLES.COACH && currentUserId !== point.targetUser && !commonTeam) {
    return null;
  }
  const updatedRole = role === ROLES.USER && commonTeam ? ROLES.COACH : role;

  switch (point.type) {
    case TALKING_POINT_TYPES.GOALS.key: {
      return ROUTES.GOAL.build(
        { role: ROLES.USER },
        { isBackPath: true, goalId: point.target?.id },
      );
    }
    case TALKING_POINT_TYPES.REVIEWS.key: {
      return ROUTES.USER_REVIEW_DASHBOARD.build(
        { role: ROLES.USER },
        { isBackPath: true, userReviewId: point.target?.id },
      );
    }
    case TALKING_POINT_TYPES.PATHS.key: {
      if (updatedRole === ROLES.USER) {
        return ROUTES.USER_PATH.build(
          { role: updatedRole },
          { isBackPath: true, pathId: point.target?.id },
        );
      }
      return ROUTES.USER_PATH_SUPERVISION_USER.build(
        { role: updatedRole, ...(commonTeam && { teamId: commonTeam.id }) },
        { isBackPath: true, pathId: point.target?.id, userId: point.targetUser },
      );
    }
  }
  return null;
}

const TalkingPoint = ({
  isDone,
  isCanCheck,
  isCanDelete,
  isCanComments,
  point,
  currentUserId,
  writingComment,
  isPdfView = false,
  coachTeams = [],
  $conversation,
  dragHandleProps,
  isTargetAvailable,
}) => {
  const $collapsed = useBoolState(false);
  const $activityModal = useBoolState();
  const currentRole = useSelector(getSelectedRole);
  const [RTFB, setRTFB] = useState(undefined);
  const $isExpectingRTFB = useBoolState(false);
  const { i18n } = useLingui();

  const onChangeCheck = $conversation.setTalkingPointState;
  const deleteTalkingPoint = $conversation.deleteUserTalkingPoint;
  const deleteComment = $conversation.deleteTalkingComment;
  const updateComment = $conversation.updateTalkingPointComment;
  const addComment = $conversation.addTalkingPointComment;

  const menu = [
    $conversation.isCanCopy && {
      label: t`Copy to next 1-1`,
      action: () => $conversation.openCopyTalkingPointModal(point.id),
    },
    isCanDelete && {
      label: t`Delete`,
      action: () => deleteTalkingPoint(point.id),
    },
  ].filter((i) => i);
  const onCreateTalkingPoint = (text) => {
    writingComment.off();
    addComment(point.id, text);
  };

  useEffect(() => {
    if (point.type === TALKING_POINT_TYPES.RTFEEDBACK.key) {
      const fetch = async () => {
        $isExpectingRTFB.on();
        const fetchedRTFB = await getRTFeedbackForTalkingPoint(point.target?.id);
        setRTFB(fetchedRTFB);
      };
      fetch();
    }
    // eslint-disable-next-line
  }, []);

  const commonTeam =
    !isEmpty(coachTeams) &&
    (coachTeams || []).find((t) => t.members && t.members.includes(point.targetUser));
  const pointLink = getPointLink(point, currentRole, currentUserId, commonTeam);
  const getRTFBName = () => {
    return (
      <RTFeedbackName
        RTFeedback={RTFB}
        TPUser={point.createdBy}
        isTPTitle
        isDone={isDone}
        isActive={$collapsed.value}
      />
    );
  };

  const talkingPointColors =
    point && point.type && Object.values(TALKING_POINT_TYPES).find((t) => t.key === point.type);

  const renderPreview = () => {
    switch (point.type) {
      case TALKING_POINT_TYPES.GOALS.key:
        return isTargetAvailable && <GoalPreview point={point} />;
      default:
        return null;
    }
  };

  const onSaveActivity = async (update = {}, { id }) => {
    await updateUserActivityProgress(id, update);

    $activityModal.off();
  };

  return (
    <Box>
      <ColoredRow
        height={56}
        styles={{ marginBottom: 0, borderTop: `1px solid ${COLORS.BG_PAGE}` }}
        rowColor={COLOR_PALETTE.WHITE}
        rowStartColor={talkingPointColors?.color}
        hideShadow
        infoColStyles={{ margin: 0 }}
        {...dragHandleProps}
      >
        <CheckBoxWrapper>
          <CheckBox
            size={24}
            checked={isDone}
            isDefaultColor={false}
            disabled={!isCanCheck}
            onChange={(_e, val) => onChangeCheck(point.id, val)}
          />
        </CheckBoxWrapper>
        <NameField
          $isDone={isDone}
          onClick={(isCanComments || point.comments.length) && $collapsed.toggle}
          $isActive={$collapsed.value}
        >
          {point.type === TALKING_POINT_TYPES.RTFEEDBACK.key
            ? !isEmpty(RTFB)
              ? getRTFBName()
              : ''
            : point.name}
        </NameField>
        {!isPdfView && (
          <CommentsBox onClick={$collapsed.toggle}>
            <StyledSvgIcon
              url={$collapsed.value ? UpArrowIcon : DownArrowIcon}
              width="14px"
              height="14px"
            />
          </CommentsBox>
        )}
        {pointLink &&
          (isTargetAvailable ? (
            <StyledLink to={pointLink}>
              <TPTypeLink $color={talkingPointColors.color}>
                <TPLabel>{point.type}</TPLabel>
                <LinkButton size={24} noBorder color={COLOR_PALETTE.WHITE}>
                  <ExternalLinkIcon size={16} fill={COLOR_PALETTE.WHITE} />
                </LinkButton>
              </TPTypeLink>
            </StyledLink>
          ) : (
            <StyledLinkContainer>
              <ToolTip
                tooltip={i18n._(
                  t`The ${
                    _.endsWith(point.type, 's') ? _.slice(point.type, 0, -1).join('') : point.type
                  } does not exist anymore!`,
                )}
              >
                <TPTypeLink $color={talkingPointColors.color}>
                  <TPLabel>{`${point.type}`}</TPLabel>
                </TPTypeLink>
              </ToolTip>
            </StyledLinkContainer>
          ))}
        {point.type === TALKING_POINT_TYPES.RTFEEDBACK.key &&
          (isTargetAvailable ? (
            <FeedbackWrapper>
              <TPTypeLink $color={talkingPointColors.color}>
                <TPLabel>
                  <Trans>feedback</Trans>
                </TPLabel>
              </TPTypeLink>
            </FeedbackWrapper>
          ) : (
            <FeedbackWrapper>
              <ToolTip tooltip={i18n._(t`The feedback does not exist anymore!`)}>
                <TPTypeLink $color={talkingPointColors.color}>
                  <TPLabel>
                    <Trans>feedback</Trans>
                  </TPLabel>
                </TPTypeLink>
              </ToolTip>
            </FeedbackWrapper>
          ))}
        {point.type === TALKING_POINT_TYPES.ACTIVITIES.key &&
          (currentRole === ROLES.COACH || currentUserId === point.targetUser || commonTeam) &&
          (isTargetAvailable ? (
            <StyledLinkContainer>
              <TPTypeLink $color={talkingPointColors.color}>
                <TPLabel>{point.type}</TPLabel>
                <LinkButton
                  size={24}
                  noBorder
                  onClick={$activityModal.on}
                  color={COLOR_PALETTE.WHITE}
                >
                  <ExternalLinkIcon size={16} fill={COLOR_PALETTE.WHITE} />
                </LinkButton>
              </TPTypeLink>
            </StyledLinkContainer>
          ) : (
            <StyledLinkContainer>
              <ToolTip tooltip={i18n._(t`The activity does not exist anymore!`)}>
                <TPTypeLink $color={talkingPointColors.color}>
                  <TPLabel>{point.type}</TPLabel>
                </TPTypeLink>
              </ToolTip>
            </StyledLinkContainer>
          ))}
        <Actions>
          {!!point.comments.length && (
            <CommentsBox>
              <StyledSvgIcon url={chatIcon} width={'20px'} height={'20px'} />
              {point.comments.length}
            </CommentsBox>
          )}
          <UserAvatar size={32} userId={point.createdBy} />
          {$conversation.isCanCopy || isCanDelete ? (
            <IconMenu items={menu} iconColor="var(--company-color)" />
          ) : (
            <ActionCol />
          )}
        </Actions>
      </ColoredRow>
      {($collapsed.value || (isPdfView && (RTFB || !isEmpty(point.comments)))) && (
        <TalkingPointContent>
          {renderPreview()}
          {RTFB && (
            <RTFBWrapper>
              <RTFeedbackCard
                RTFeedback={RTFB}
                isNoCardPadding
                TPUser={point.createdBy}
                onRemoveRTFeedback={() => setRTFB(undefined)}
                onShareFeedback={({ feedback }) =>
                  setRTFB({
                    ...RTFB,
                    feedback,
                  })
                }
              />
            </RTFBWrapper>
          )}
          {$isExpectingRTFB.value && !RTFB && (
            <Placeholder
              title={i18n._(t`No feedback available`)}
              subTitle={i18n._(t`Perhaps someone deleted it after talking point was created?`)}
              Icon={() => <Icon icon={ICONS.CHAT} width={50} height={50} color={COLORS.BG_PAGE} />}
            />
          )}
          <Line />
          <CommentsBlock
            isCanComments={isCanComments}
            comments={point.comments}
            pointId={point.id}
            deleteComment={deleteComment}
            updateComment={updateComment}
            writingComment={writingComment}
            onCreateTalkingPoint={onCreateTalkingPoint}
          />
        </TalkingPointContent>
      )}

      {$activityModal.value && (
        <ActivityModal
          activityId={point.target?.id}
          onClose={$activityModal.off}
          onSaveActivity={onSaveActivity}
          isUserActivity
        />
      )}
    </Box>
  );
};

export default React.memo(TalkingPoint);
