import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import AvatarEditor from 'react-avatar-editor';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import { ImageAspectRatio, IMAGE_ASPECT_RATIO_CONFIG } from '~/components/Modals/AssetPickerModal';

import useDebounce from '~/hooks/useDebounce';
import { searchPexels } from '~/services/pexels';
import { COLORS } from '~/styles';

import {
  SearchInputField,
  PexelsWrapper,
  PexelsEmptyWrapper,
  PexelGrid,
  PexelImage,
  Editor,
  Footer,
  ScaleButton,
  ScaleSlider,
  ScaleLabel,
  SaveButton,
  BackButton,
  PexelsFooter,
} from '../design';

import type { IPexel } from '@learned/types';

const initialOptions = {
  skip: 0,
  limit: 18,
};

const sliderRailStyle = {
  height: '2px',
};

const sliderTrackStyle = {
  height: '2px',
  borderRadius: 'none',
  backgroundColor: `${COLORS.TEXT_MAIN}`,
};

const sliderHandleStyle = {
  width: '15px',
  height: '15px',
  border: `6px solid ${COLORS.TEXT_MAIN}`,
  marginTop: '-6px',
};

interface IStockPhotosProps {
  uploadEndPoint?: (file: File) => Promise<{ url: string }>;
  onUploadImage: (imageUrl: string) => void;
  onClose: () => void;
  imageAspectRatio?: ImageAspectRatio;
  removePexel?: boolean;
  setRemovePexel?: (remove: boolean) => void;
}

const PexelsUploader = ({
  uploadEndPoint,
  onUploadImage,
  onClose,
  imageAspectRatio,
  removePexel,
  setRemovePexel,
}: IStockPhotosProps) => {
  const { i18n } = useLingui();
  const editorRef = useRef<AvatarEditor | null>(null);
  const pexelGridRef = useRef<HTMLDivElement | null>(null);
  const [pexels, setPexels] = useState<IPexel[]>([]);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 500);
  const [options, setOptions] = useState<{ skip: number; limit: number }>(initialOptions);
  const [selectedPexel, setSelectedPexel] = useState<string>('');
  const [pexelImageScale, setPexelImageScale] = useState(1.1);
  const [scaleLabel, setScaleLabel] = useState(10);

  // search
  useEffect(() => {
    async function fetch() {
      const response = await searchPexels(debouncedSearch, options);
      setPexels(response?.data?.pexels ?? []);
    }

    fetch();
    // eslint-disable-next-line
  }, [debouncedSearch]);

  useEffect(() => {
    if (removePexel) {
      setSelectedPexel('');
      setRemovePexel?.(false);
    }
    // eslint-disable-next-line
  }, [removePexel]);

  // paginate
  useEffect(() => {
    async function fetch() {
      const response = await searchPexels(debouncedSearch, options);
      setPexels(pexels.concat(response?.data?.pexels ?? []));
    }

    fetch();
    // eslint-disable-next-line
  }, [options]);

  // infinite scroll
  useEffect(() => {
    const currentRef = pexelGridRef.current;
    const handleScroll = () => {
      if (currentRef) {
        const { scrollTop, scrollHeight, clientHeight } = currentRef;

        if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
          setOptions((prevOptions) => ({
            ...prevOptions,
            skip: prevOptions.skip + prevOptions.limit,
          }));
        }
      }
    };

    // Add the scroll event listener to the PexelGrid component
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      // Remove the event listener when the component is unmounted
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [pexels, selectedPexel]);

  const onSliderChange = (value: number) => {
    setPexelImageScale(value);
    setScaleLabel(Math.round((value - 1) * 100));
  };

  const onZoomIn = () => {
    if (pexelImageScale < 2) {
      const newScale = pexelImageScale + 0.1;
      setPexelImageScale(newScale);
      setScaleLabel(Math.round((newScale - 1) * 100));
    }
  };

  const onZoomOut = () => {
    if (pexelImageScale > 1) {
      const newScale = pexelImageScale - 0.1;
      setPexelImageScale(newScale);
      setScaleLabel(Math.round((newScale - 1) * 100));
    }
  };

  const handleSave = () => {
    const editor = editorRef.current;
    if (editor) {
      const canvas = editor.getImageScaledToCanvas();
      canvas.toBlob(async (blob: Blob | null) => {
        if (blob) {
          const formData = new FormData();
          formData.append('image', blob, 'updatedImage.png');

          const pexelEntry = formData.get('image');
          const updatedUser = uploadEndPoint && (await uploadEndPoint(pexelEntry as File));
          onUploadImage(updatedUser?.url as string);
          onClose();
        }
      }, 'image/png');
    }
  };

  const renderPexelsUploader = () => {
    return (
      <PexelsEmptyWrapper>
        <Icon icon={ICONS.SEARCH} size={ICON_SIZES.LARGE} />
        <span>
          <Trans>Type something in search field to view pexels</Trans>
        </span>
      </PexelsEmptyWrapper>
    );
  };

  const renderPexels = () => {
    return (
      <>
        <PexelGrid ref={pexelGridRef}>
          {pexels.map((pexel: IPexel, index: number) => (
            <PexelImage
              key={'pexel' + index}
              src={pexel.tiny}
              alt={'pexel' + index}
              onClick={() => setSelectedPexel(pexel.original)}
            />
          ))}
        </PexelGrid>
        <PexelsFooter>
          <Trans>Stockphoto’s provided by</Trans>{' '}
          <a href="https://www.pexels.com" target="_blank" rel="noopener noreferrer">
            pexels
          </a>
        </PexelsFooter>
      </>
    );
  };

  const renderImageEditor = () => {
    return (
      <>
        <Editor
          image={selectedPexel as string}
          width={imageAspectRatio ? IMAGE_ASPECT_RATIO_CONFIG[imageAspectRatio]?.width : 390}
          height={imageAspectRatio ? IMAGE_ASPECT_RATIO_CONFIG[imageAspectRatio]?.height : 200}
          border={0}
          color={[255, 255, 255, 0.5]}
          scale={pexelImageScale}
          rotate={0}
          borderRadius={
            imageAspectRatio ? IMAGE_ASPECT_RATIO_CONFIG[imageAspectRatio]?.borderRadius : 0
          }
          ref={editorRef}
          crossOrigin="anonymous"
        />
        <Footer>
          <div className="scale-slider">
            <ScaleButton
              icon={ICONS.SUBTRACT_MINUS}
              variant={ButtonVariant.ICON}
              size={ButtonSize.MEDIUM}
              onClick={onZoomOut}
            />
            <ScaleSlider
              value={pexelImageScale}
              onChange={(value: number) => onSliderChange(value)}
              disabled={false}
              min={1}
              max={2}
              step={0.1}
              trackStyle={sliderTrackStyle}
              railStyle={sliderRailStyle}
              handleStyle={sliderHandleStyle}
            />
            <ScaleButton
              icon={ICONS.ADD_PLUS}
              variant={ButtonVariant.ICON}
              size={ButtonSize.MEDIUM}
              onClick={onZoomIn}
            />
            <ScaleLabel>{scaleLabel}%</ScaleLabel>
          </div>
          <SaveButton
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            label={i18n._(t`save`)}
            onClick={handleSave}
          />
        </Footer>
      </>
    );
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <PexelsWrapper>
      {selectedPexel === '' ? (
        <SearchInputField
          placeholder={i18n._(t`Search`)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeSearch(e)}
          value={search}
        />
      ) : (
        <BackButton
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          icon={ICONS.BACK}
          iconSize={ICON_SIZES.SMALL}
          label={i18n._(t`Back`)}
          onClick={() => setSelectedPexel('')}
        />
      )}

      {pexels.length > 0
        ? selectedPexel === ''
          ? renderPexels()
          : renderImageEditor()
        : renderPexelsUploader()}
    </PexelsWrapper>
  );
};

export { PexelsUploader };
