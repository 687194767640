import React from 'react';

import { USER_REVIEW_HRIS_REPORT_STATUS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { format } from 'date-fns';

import { Button, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import Label, { LabelProps } from '~/components/Label';
import Tooltip from '~/components/Tooltip';

import { Container, Content, DisableText, Header, LabelContainer, Title } from './design';

import { COLORS } from '~/styles';

export const SEND_REPORT_STATUS: Record<USER_REVIEW_HRIS_REPORT_STATUS, LabelProps> = {
  [USER_REVIEW_HRIS_REPORT_STATUS.FAILED]: {
    icon: ICONS.ERROR_SIGN,
    noBorder: true,
    children: <Trans>Failed</Trans>,
    color: COLORS.ACCENT_ERROR,
    backgroundColor: COLORS.FAILED_BACKGROUND,
  },
  [USER_REVIEW_HRIS_REPORT_STATUS.IN_PROGRESS]: {
    icon: ICONS.IN_PROGRESS,
    noBorder: true,
    children: <Trans>In progress</Trans>,
    color: COLORS.STATUS_IN_PROGRESS,
    backgroundColor: COLORS.IN_PROGRESS_LIGHT,
  },
  [USER_REVIEW_HRIS_REPORT_STATUS.NOT_SENT]: {
    icon: ICONS.TODO,
    noBorder: true,
    children: <Trans>Not Sent</Trans>,
    color: COLORS.STATUS_ACTIVE,
    backgroundColor: COLORS.TODO_BLUE_LIGHT,
  },
  [USER_REVIEW_HRIS_REPORT_STATUS.SENT]: {
    icon: ICONS.CHECKMARK,
    noBorder: true,
    children: <Trans>Sent</Trans>,
    color: COLORS.STATUS_COMPLETED,
    backgroundColor: COLORS.ACCENT_SUCCESS_LIGHT,
  },
};

export const getLabelProps = (status: USER_REVIEW_HRIS_REPORT_STATUS, withBorder: boolean) => {
  const props = { ...SEND_REPORT_STATUS[status] };
  if (!withBorder) {
    props.backgroundColor = undefined;
  }
  return props;
};

export type HRISSendReportProps = {
  id: string;
  name: string;
  status: USER_REVIEW_HRIS_REPORT_STATUS;
  sentOn?: Date;
  onSendReport?(id: string): void;
  isEnabled?: boolean;
  message?: string;
  statusBorder?: boolean;
  isIntegrationActive?: boolean;
};

export const HRISSendReport = ({
  id,
  name,
  status,
  sentOn,
  isEnabled = false,
  message,
  onSendReport,
  statusBorder = false,
  isIntegrationActive = false,
}: HRISSendReportProps) => {
  const { i18n } = useLingui();

  const statusTooltip =
    status === USER_REVIEW_HRIS_REPORT_STATUS.SENT && sentOn
      ? i18n._(t`Sent on: ${format(sentOn, 'dd-MM-yyyy')}`)
      : status === USER_REVIEW_HRIS_REPORT_STATUS.FAILED && message;

  return (
    <Container>
      <Header>
        <Tooltip tooltip={name}>
          <Title>{i18n._(t`Send report to ${name}`)}</Title>
        </Tooltip>
        {isEnabled && isIntegrationActive && (
          <Tooltip tooltip={statusTooltip}>
            <LabelContainer>
              <Label {...getLabelProps(status, statusBorder)} noBorder={!statusBorder}>
                {SEND_REPORT_STATUS[status].children}
              </Label>
            </LabelContainer>
          </Tooltip>
        )}
        {(!isEnabled || !isIntegrationActive) && (
          <LabelContainer>
            <DisableText>
              {!isIntegrationActive
                ? i18n._(t`This integration is inactive. Check its status on the settings page`)
                : i18n._(t`Only for completed or archived review`)}
            </DisableText>
          </LabelContainer>
        )}
      </Header>
      <Content>
        <Tooltip
          tooltip={
            !isEnabled && i18n._(t`The report can be sent when the review is completed or archived`)
          }
        >
          <div>
            <Button
              variant={ButtonVariant.SECONDARY}
              label={i18n._(t`Send report`)}
              icon={ICONS.SEND}
              disabled={!isEnabled || status === USER_REVIEW_HRIS_REPORT_STATUS.IN_PROGRESS}
              onClick={() => onSendReport?.(id)}
            />
          </div>
        </Tooltip>
      </Content>
    </Container>
  );
};
