import React from 'react';

import { REVIEW_QUESTION_TYPES } from '@learned/constants';
import { IMultiLangString } from '@learned/types';

import convertToTimeString from '~/utils/convertToTimeString';

import { RatingAnswers } from '../../RatingAnswers';
import { QuestionSeparator } from '../components/design';
import { ReviewInformationBar } from '../components/ReviewInformationBar';
import { IAllPreviousAnswersProps } from '../types';

interface IAllRatingAnswersProps extends IAllPreviousAnswersProps<REVIEW_QUESTION_TYPES.RATING> {
  ratingOptions: {
    label: IMultiLangString;
  }[];
}

const AllRatingAnswers: React.FC<IAllRatingAnswersProps> = ({
  previousUserReviewWithQuestionAndAnswers,
  ratingOptions,
  useMultiLangString,
}) => {
  const getMultiLangString = useMultiLangString();
  return (
    <>
      {previousUserReviewWithQuestionAndAnswers.map((userReview, i) => {
        return (
          <div key={i}>
            <ReviewInformationBar
              reviewName={getMultiLangString(userReview.reviewName)}
              reviewStartDate={convertToTimeString(userReview.reviewStartDate)}
            />
            <RatingAnswers
              question={userReview.questionWithAnswers}
              key={i}
              openFirstAnswerByDefault={false}
              ratingOptions={ratingOptions}
              useMultiLangString={useMultiLangString}
            />
            <QuestionSeparator />
          </div>
        );
      })}
    </>
  );
};

export default AllRatingAnswers;
