import React from 'react';

import { REVIEW_VERSION } from '@learned/constants';
import { Trans } from '@lingui/macro';
import { type UseFormReturn } from 'react-hook-form';

import type { ISectionState } from '~/components/SideBar/SectionStateHook';

import { GeneralSettings } from './components/GeneralSettings';
import { NotificationSettings } from './components/NotificationSettings';
import { PrivacySettings } from './components/PrivacySettings';
import { StepFooter } from './components/StepFooter';
import { Form, Title } from './design';

import type { IReviewCycleForm } from './types';
import type { IReviewTemplate } from '@learned/types';

interface StepSettingsProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewCycleForm>;
  reviewTemplates: IReviewTemplate[];
  version?: REVIEW_VERSION;
}

const StepSettings = ({
  formMethods,
  sectionState,
  reviewTemplates,
  version,
}: StepSettingsProps) => {
  return (
    <>
      <Form>
        <Title>
          <Trans>Settings</Trans>
        </Title>
        <GeneralSettings
          formMethods={formMethods}
          reviewTemplates={reviewTemplates}
          version={version}
        />
        <NotificationSettings formMethods={formMethods} />
        <PrivacySettings formMethods={formMethods} />
        <StepFooter
          onNext={() => sectionState.setCurrentSection(++sectionState.currentSection)}
          onPrev={() => sectionState.setCurrentSection(--sectionState.currentSection)}
        />
      </Form>
    </>
  );
};

export { StepSettings };
