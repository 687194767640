import React from 'react';

import styled from 'styled-components';

import { ColorByPercentage } from '~/components/TableGrid';

import { COLORS } from '~/styles';

const CellBlock = styled.div<{ onClick?: () => void }>`
  height: 56px;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

  &:hover {
    font-weight: bold;
  }

  span.denominator-section {
    color: ${COLORS.SUBTEXT};
  }
`;

const NoColor = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

type TCellProps = {
  value: number;
  children?: React.ReactNode;
  noColor?: boolean;
  onClick?: () => void;
  extraClassNamesWhenNoColors?: string[];
  ratingLabelsCount?: number;
};

function Cell({
  value,
  noColor = false,
  onClick,
  children,
  extraClassNamesWhenNoColors,
  ratingLabelsCount = 100,
}: TCellProps) {
  return (
    <CellBlock onClick={onClick}>
      {noColor ? (
        // at this moment, we need to highlight only when no heatmap colors are needed,
        // so that the extra classes will be added only for the NoColor component
        <NoColor className={`cell ${extraClassNamesWhenNoColors?.join(' ')}`}>{children}</NoColor>
      ) : (
        <ColorByPercentage value={(value * 100) / ratingLabelsCount}>{children}</ColorByPercentage>
      )}
    </CellBlock>
  );
}

export { Cell };
