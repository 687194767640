import styled from 'styled-components';

import { DatePicker } from '~/components/DatePicker';

import { COLORS } from '~/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${COLORS.BG_LIST};
  border-radius: 6px;
  padding: 16px;
`;

export const DateContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const DateSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledDatePicker = styled(DatePicker)`
  input {
    border: solid 1px ${COLORS.BORDERS};
    font-size: 14px;
    line-height: 1.86;
    color: ${COLORS.TEXT_HOVER};

    ::placeholder {
      color: ${COLORS.PLACEHOLDERS};
      opacity: 0.6;
      font-size: 14px;
    }
  }
`;

export const JobTitle = styled.div`
  color: ${COLORS.TEXT_HOVER};
  font-size: 16px;
  line-height: 24px;
`;

export const RadioButtonWrapper = styled.div`
  display: flex;
  font-size: 14px;
  align-content: center;
  gap: 16px;

  span {
    height: 100%;
    align-content: center;
    colors: ${COLORS.TEXT_MAIN};
  }

  div {
    height: 20px;
    width: 20px;
    border-radius: 999px;
    background: white;
  }

  button {
    margin: 0;
  }
`;
