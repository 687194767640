import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 51px;
`;

export const Content = styled.div<{ hasScrollbar?: boolean }>`
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  overflow-y: auto;
  margin-top: 19px;
  ${({ hasScrollbar }) =>
    hasScrollbar &&
    css`
      padding-right: 20px;
    `}
`;

export const Subtitle = styled.div`
  font-size: 16px;
  color: ${COLORS.SUBTEXT};
  line-height: 24px;
  letter-spacing: -0.16px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SecondaryTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_HOVER};
`;

export const Title = styled.div`
  color: ${COLORS.TEXT_HOVER};
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 26px;
  line-height: 40px;
  letter-spacing: -0.29px;
`;
