import React, { MouseEventHandler } from 'react';

import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { Cell } from '~/pages/Reports/Components/Cell';
import CollapseIndicator from '~/pages/Reports/Components/IconWrapper';
import { EmptyCell, Nester } from '~/pages/Reports/engagement/tabs/CustomStyles';
import { MENU_SIZE } from '~/pages/Reports/options';
import { TEngagementData } from '~/pages/Reports/types';

import { ColumnPosition } from '~/@types/table';
import { COLORS } from '~/styles';

import { TResultColumn } from '../../../types';
import { CellContentWrapper, RegularCell, RegularCellContainer } from '../design';
import FirstColumnHeaderCell from '../FirstColumnHeaderCell';
import OtherColumnHeaderCell from '../OtherColumnHeaderCell';

export const createColumns = (columns: TResultColumn[]) => {
  const tableColumns = columns.map((heatmapColumn) => {
    if (heatmapColumn.id === 'primaryDimension') {
      return {
        ...heatmapColumn,
        id: heatmapColumn.id,
        name: () => {
          return <FirstColumnHeaderCell column={heatmapColumn} />;
        },
        accessor: `${heatmapColumn.id}`,
        renderCell: (
          cell: TEngagementData & { userReviewId?: string },
          onClick: MouseEventHandler<HTMLDivElement> | undefined,
        ) => {
          const displayName = cell.name;
          return (
            <RegularCellContainer
              className="cell rounded-corners"
              clickable={!!onClick}
              onClick={() => {
                /* @ts-ignore */
                onClick ? onClick(cell) : {};
              }}
              {...(cell.id === 'companyAverage' && { backgroundColor: COLORS.BG_ELEMENTS })}
            >
              <Tooltip
                tooltip={cell.userReviewId ? `Click to open report of ${displayName}` : displayName}
                size={TOOLTIP_SIZES.BIG}
              >
                <RegularCell mWidth={`${MENU_SIZE.LEFT_FULL_WIDTH}px`}>
                  <CellContentWrapper
                    isClickable={
                      (cell.id !== 'companyAverage' && !cell.children?.length) ||
                      (cell.children && cell.children?.length > 0)
                    }
                    role="button"
                    tabIndex={0}
                  >
                    <Nester nestLevel={cell.nestedLevel} />
                    <div className={'cell-dimension-section'}>
                      {cell.children && cell.children.length > 0 && (
                        <CollapseIndicator isCollapsed={cell.isCollapsed} disablePointerEvents />
                      )}
                      <span className="text-content-wrapper">{displayName}</span>
                    </div>
                  </CellContentWrapper>
                </RegularCell>
              </Tooltip>
            </RegularCellContainer>
          );
        },
        isFixed: true,
        centerAlign: false,
        position: ColumnPosition.LEFT,
        maxWidth: '200px',
        minWidth: '40px',
        padding: '7px 20px',
        showHeaderTooltip: true,
        sortBy: {
          asc: {
            key: `${heatmapColumn.id}__asc`,
            label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
          },
          desc: {
            key: `${heatmapColumn.id}__desc`,
            label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
          },
        },
      };
    }
    return {
      ...heatmapColumn,
      id: heatmapColumn.id,
      name: () => {
        return <OtherColumnHeaderCell column={heatmapColumn} />;
      },
      accessor: `${heatmapColumn.id}`,
      // render main table cell (middle table)
      renderCell: (row: TEngagementData) => {
        let rlcEnabled = false;
        // rcd dashboard is a performance graph. so that skipping report type check
        const cellValue = row.cells.find((c) => c.measure === heatmapColumn.id);
        if (cellValue?.rlc) {
          rlcEnabled = true;
        }

        if (!cellValue?.value || cellValue.value < 1) {
          return (
            <EmptyCell className="cell">
              <></>
            </EmptyCell>
          );
        }

        return (
          <Cell
            onClick={() => {}}
            value={
              cellValue?.rlc
                ? (100 / cellValue?.rlc) * cellValue?.value || 0
                : cellValue?.value || 0
            }
            noColor={false}
          >
            {rlcEnabled ? (
              <>
                {cellValue.value}
                <span className="denominator-section">/{cellValue?.rlc}</span>
              </>
            ) : (
              `${cellValue.value}%`
            )}
          </Cell>
        );
      },
      isFixed: heatmapColumn.id === 'average' || heatmapColumn.id === 'benchmark',
      position: ColumnPosition.RIGHT,
      maxWidth: '20px',
      minWidth: '110px',
      padding: '7px',
      centerAlign: true,
      showHeaderTooltip: true,
    };
  });

  return tableColumns;
};
