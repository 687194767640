import React, { useEffect, useState } from 'react';

import { SKILL_CATEGORIES_ID } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isNil from 'lodash/isNil';
import qs from 'qs';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { useFromQuery } from '~/hooks/useFromQuery';
import { useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { createJobProfiles } from '~/services/jobProfiles';
import { getJobTemplate } from '~/services/jobTemplates';
import { setJobProfiles } from '~/store/jobProfiles/actions';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { turnArrayIntoMultiLang, turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import { JobForm } from '../JobForm';
import { useJobFamilies } from '../JobForm/hooks/useJobFamilies';
import { useSkillCategories } from '../JobForm/hooks/useSkillCategories';
import { resolver } from '../JobForm/validations';
import {
  convertSkillsTemplateAPIDataToFormData,
  convertSkillsTemplateFormToAPIData,
  prepareVirtualJobLevelGroupId,
} from '../utils';

import type { IGeneralForm } from '../JobForm/types';
import type { IJobTemplate } from '@learned/types';

const JobCreate = () => {
  const { i18n } = useLingui();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { goBack } = useFromQuery({ includeHash: true });
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const languageState = useLanguageState();

  const getMultiLangString = useMultiLangString();

  const [jobTemplate, setJobTemplate] = useState<IJobTemplate>();
  const [isLoading, setIsLoading] = useState(true);
  const { jobFamilies } = useJobFamilies();

  const formMethods = useForm<IGeneralForm>({
    mode: 'all',
    resolver,
    defaultValues: {
      name: languageState.companyLanguages.map(({ locale }) => ({ locale, value: '' })),
      description: languageState.companyLanguages.map(({ locale }) => ({ locale, value: '' })),
      recommendedJobs: [],
    },
  });
  const { setValue, watch } = formMethods;

  const jobName = turnArrayIntoMultiLang(watch('name') || []);

  const { skillCategories, isLoading: skillCategoriesIsLoading } = useSkillCategories();

  useEffect(() => {
    let skills = {};

    skillCategories.forEach((category) => {
      const skillLevelsLength = category.skillLevels?.length || 0;

      const defaultFocusAreaLevel = [
        SKILL_CATEGORIES_ID.COMPETENCIES,
        SKILL_CATEGORIES_ID.KPIS,
      ].includes(category.type as SKILL_CATEGORIES_ID)
        ? 1
        : skillLevelsLength >= 3
        ? 3
        : skillLevelsLength;

      skills = {
        ...skills,
        [category.id]: {
          defaultFocusAreaLevel,
          isDefaultFocusAreaLevelEnabled: false,
          skills: [],
        },
      };
    });

    setValue('skills', skills);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillCategories]);

  const createFromJobTemplateId = query?.createFromJobTemplateId as string | undefined;
  const initName = query?.name as string | undefined;

  const fetchJobTemplate = async (templateId: string, mounted: boolean) => {
    try {
      const result = await getJobTemplate(templateId, {
        options: { populate: ['skillTemplate'] },
      });

      const jobTemplate = result.data.jobTemplate as IJobTemplate;
      if (mounted && result.code === 200) {
        setJobTemplate(jobTemplate);

        const jobFamily = jobFamilies.filter(
          (jobFamily) => jobFamily?.jobFamilyTemplate === jobTemplate.jobFamily,
        )?.[0];

        setValue('jobFamily', jobFamily?.id as string);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let mounted = true;

    if (createFromJobTemplateId) {
      setIsLoading(true);
      fetchJobTemplate(createFromJobTemplateId, mounted);
    } else {
      if (initName && languageState.companyPrimaryLanguage.locale) {
        setValue(
          'name',
          turnMultiLangIntoArray(
            { [languageState.companyPrimaryLanguage.locale]: initName },
            languageState.companyLanguages,
          ),
        );
      }
      setIsLoading(false);
    }

    return () => void (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createFromJobTemplateId,
    initName,
    languageState.companyLanguages,
    languageState.companyPrimaryLanguage.locale,
  ]);

  useEffect(() => {
    if (jobTemplate) {
      setValue(
        'skills',
        convertSkillsTemplateAPIDataToFormData(jobTemplate.skills, skillCategories),
      );
      setValue('name', turnMultiLangIntoArray(jobTemplate.name, languageState.companyLanguages));
      setValue(
        'description',
        turnMultiLangIntoArray(jobTemplate.description.default, languageState.companyLanguages),
      );
      setValue('coverImage', jobTemplate?.coverImage);
      setValue('decisionLevel', jobTemplate.decisionLevel);

      if (!query?.jobFamilyId) {
        const jobFamily = jobFamilies.filter(
          (jobFamily) => jobFamily?.jobFamilyTemplate === jobTemplate.jobFamily,
        )?.[0];

        setValue('jobFamily', jobFamily?.id as string);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    jobFamilies,
    jobTemplate,
    languageState.companyLanguages,
    skillCategories,
    query.jobFamilyId,
  ]);

  useEffect(() => {
    if (query?.jobLevelGroupId && query?.levelTo) {
      setValue('jobLevelGroup', {
        groupId: query?.jobLevelGroupId as string,
        level: Number(query?.levelTo as string),
        id: prepareVirtualJobLevelGroupId({
          id: query?.jobLevelGroupId as string,
          level: Number(query.levelTo),
        }),
      });
    }
  }, [query?.jobLevelGroupId, query.levelTo, setValue]);

  useEffect(() => {
    if (query?.jobFamilyId) {
      setValue('jobFamily', query.jobFamilyId as string);
    }
  }, [query?.jobFamilyId, jobFamilies, setValue]);

  const onSubmit = async (data: IGeneralForm & { published?: boolean }) => {
    try {
      const predicate = ({ value, locale }: { value: string; locale: string }) =>
        value !== '' || locale === '_id';
      let jobLevelGroup;

      if (data.jobLevelGroup?.groupId && !isNil(data.jobLevelGroup?.level)) {
        jobLevelGroup = {
          id: data.jobLevelGroup?.groupId,
          level: data.jobLevelGroup?.level,
        };
      }

      const result = await createJobProfiles({
        name: convertLanguageValue(data.name.filter(predicate)),
        skills: convertSkillsTemplateFormToAPIData(data.skills),
        description: convertLanguageValue(data.description),
        decisionLevel: data.decisionLevel,
        jobFamily: data.jobFamily,
        jobTemplate: jobTemplate?.id,
        careerLevel: data.careerLevel,
        educationLevel: data.educationLevel,
        coverImage: data.coverImage,
        published: data.published,
        recommendedJobs: data.recommendedJobs.map(({ id }) => id),
        jobLevelGroup,
      });

      if (result.published) {
        addToast({
          title: i18n._(t`Success`),
          subtitle: i18n._(t`Job profile published`),
          type: TOAST_TYPES.SUCCESS,
        });
      } else if (result.published === false) {
        addToast({
          title: i18n._(t`Success`),
          subtitle: i18n._(t`Job profile saved as draft`),
          type: TOAST_TYPES.SUCCESS,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      // Clear the job profiles store to force a reload of the job matrix
      dispatch(setJobProfiles({}));
      goBack();
    }
  };

  return (
    <JobForm
      title={i18n._(t`Create a job: ${getMultiLangString(jobName)}`)}
      onSubmit={onSubmit}
      formMethods={formMethods}
      languageState={languageState}
      jobTemplate={jobTemplate}
      skillCategories={skillCategories}
      isLoading={isLoading || skillCategoriesIsLoading}
    />
  );
};

export { JobCreate };
