import React from 'react';

import { IMultiLangString } from '@learned/types';
import { Trans } from '@lingui/macro';

import { Icon, ICONS, ICON_SIZES } from '~/components/Icon';
import Tooltip, { TOOLTIP_PLACEMENTS, TOOLTIP_SIZES } from '~/components/Tooltip';

import { PreviousAnswerIndicatorWrapper } from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

const PreviousAnswerIndicator = ({ tooltipName }: { tooltipName?: IMultiLangString }) => {
  const getMultiLangString = useMultiLangString();
  return (
    <Tooltip
      tooltip={<ToolTipText text={tooltipName ? getMultiLangString(tooltipName) : ''} />}
      size={TOOLTIP_SIZES.BIG}
      placement={TOOLTIP_PLACEMENTS.BOTTOM}
    >
      <PreviousAnswerIndicatorWrapper>
        <>
          <span className="icon-container">
            <Icon icon={ICONS.HISTORY} size={ICON_SIZES.SMALL} color={COLORS.TIPPY_BACKGROUND} />
          </span>
          <Trans>
            <div>Previous answer: N/A</div>
          </Trans>
        </>
      </PreviousAnswerIndicatorWrapper>
    </Tooltip>
  );
};

export default PreviousAnswerIndicator;

const ToolTipText = ({ text }: { text: string }) => {
  return (
    <div>
      <div> Previous review </div>
      <div> {text} </div>
    </div>
  );
};
