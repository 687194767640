import styled from 'styled-components';

import { COLORS } from '~/styles';

const ThemeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 46px;
  margin: 8px 0;
`;

const ThemeIcon = styled.div<{ gradient: string[] }>`
  border-radius: 6px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  background-image: linear-gradient(
    to bottom,
    ${({ gradient }) => (gradient ? `${gradient[0]}, ${gradient[1]}` : '#f7f9ff, #ebf1fe')}
  );
`;

const ThemeInfo = styled.div`
  flex: 1;
  flex-direction: column;
  margin: 0 24px 0 16px;
  display: flex;
  overflow: hidden;
  justify-content: center;
`;

const ThemeName = styled.div`
  text-overflow: ellipsis;
  color: ${COLORS.TEXT_HOVER};
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  overflow: hidden;
  line-height: 20px;
`;

const NumberOfQuestions = styled.div`
  overflow: hidden;
  color: ${COLORS.SUBTEXT};
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

const ThemeWeight = styled.div`
  width: 38px;
  margin-right: 48px;
  display: flex;
  align-items: center;
`;

const MainInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  margin-top: 16px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
`;

const LeftSide = styled.div`
  flex: 1;
`;

const RightSide = styled.div`
  width: 86px;
`;

const ThemesListWrap = styled.div`
  padding-bottom: 22px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export {
  ThemeWrapper,
  ThemeIcon,
  ThemeInfo,
  ThemeName,
  NumberOfQuestions,
  ThemeWeight,
  MainInfoRow,
  LeftSide,
  RightSide,
  ThemesListWrap,
};
