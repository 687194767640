import type { ICareerPlan, IJobProfile, IUser, IUserReview } from '@learned/types';

// These types will be updated in the future

export type IEmployee = IUser & {
  jobProfiles: IJobProfile[];
  teams: string[];
  guests: string[];
  coaches: string[];
  // all available job profiles for the user
  availableJobProfiles: IJobProfile[];
  // all available coaches for the user
  availableCoaches: string[];
  // all available careerPlans for the user
  availableCareerPlans: ICareerPlan[];
  careerPlans?: ICareerPlan[];
  userReview?: IUserReview['id'];
  careerPlanIds?: ICareerPlan['id'][];
};

// These types will be updated in the future

export enum SORT_OPTIONS {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
}
export interface IAssignEmployeeForm {
  employees: {
    employeeId: string;
    startDate?: Date;
    endDate?: Date;
    isPrimary: boolean;
  }[];
}
