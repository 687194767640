import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Dropdown } from '~/components/Dropdown';
import { ICONS, ICON_SIZES } from '~/components/Icon';
import Switch from '~/components/Switch';

import { LANGUAGES } from '~/constants';

import {
  ButtonsWrapper,
  FormWrapper,
  Subtitle,
  Title,
  SectionWrapper,
  Label,
  Description,
} from '../design';

import type { IRegisterWithCompanyForm } from '../types';
import type { I18n } from '@lingui/core';

const SwitchWrapper = styled.span`
  display: flex;
  align-items: center;
  text-align: center;
`;
interface ILanguageSectionProps {
  formMethods: UseFormReturn<IRegisterWithCompanyForm>;
}

const LANGUAGE_ITEMS = {
  [LANGUAGES.EN]: {
    id: LANGUAGES.EN,
    key: LANGUAGES.EN,
    name: LANGUAGES.EN,
    translated: (i18n: I18n) => i18n._(t`🇬🇧 English`),
  },
  [LANGUAGES.NL]: {
    id: LANGUAGES.NL,
    key: LANGUAGES.NL,
    name: LANGUAGES.NL,
    translated: (i18n: I18n) => i18n._(t`🇳🇱 Dutch`),
  },
};

const LanguageSection = ({ formMethods }: ILanguageSectionProps) => {
  const { i18n } = useLingui();
  const { watch, setValue, control } = formMethods;

  return (
    <SectionWrapper>
      <Title>
        <Trans>Create your account</Trans>
      </Title>
      <Subtitle>
        <Trans>Step 2/5</Trans>
      </Subtitle>
      <FormWrapper>
        <Controller
          name="lang"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <div>
              <Label marginBottom="4px">
                <Trans>System Language</Trans>
              </Label>
              <Dropdown
                className="dropdown"
                isSingleSelect
                placeholder={i18n._(t`System Language`)}
                items={Object.values(LANGUAGE_ITEMS)}
                onChange={(v) => {
                  // @ts-ignore
                  onChange(v.key);
                }}
                selectedItem={LANGUAGE_ITEMS?.[value || LANGUAGES.EN]}
                stringifyItem={(item) => item.translated(i18n)}
              />
              <Description marginTop="10px" whiteSpace="normal">
                <Trans>This will be the default language of the platform</Trans>
              </Description>
            </div>
          )}
        />
        <Controller
          name="includeDemoContent"
          control={control}
          defaultValue={true}
          render={({ field: { onChange, value } }) => (
            <div>
              <SwitchWrapper>
                <Switch onChange={onChange} checked={value} def />
                <Label>
                  <Trans>Demo Content</Trans>
                </Label>
              </SwitchWrapper>
              <Description marginTop="10px" whiteSpace="normal">
                <Trans>Fill your environment with example members, reviews and surveys</Trans>
              </Description>
            </div>
          )}
        />

        <ButtonsWrapper>
          <Button
            type="button"
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.NAVIGATION_PRIMARY}
            label={i18n._(t`Next`)}
            disabled={!watch('lang')?.length}
            onClick={() => setValue('step', 3)}
          />
          <Button
            className="back"
            label={i18n._(t`Back`)}
            type="button"
            variant={ButtonVariant.TEXT_PRIMARY}
            icon={ICONS.BACK}
            size={ButtonSize.MEDIUM}
            iconSize={ICON_SIZES.SMALL}
            onClick={() => setValue('step', 1)}
          />
        </ButtonsWrapper>
      </FormWrapper>
    </SectionWrapper>
  );
};

export { LanguageSection };
