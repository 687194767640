import React from 'react';

import { REVIEW_AVERAGE_SCORE_COLUMNS, REVIEW_AVERAGE_SCORE_ROW_TYPE } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';

import { Cell } from './Cell';
import { CountLabel, RatingHeader } from './design';
import { FirstCellQuestion } from './FirstCellQuestion';
import { FirstCellTheme } from './FirstCellTheme';

import { ColumnPosition } from '~/@types/table';

import { CoachCircle, PeerCircle, SelfCircle } from '../../../Questions/SkillAnswers/design';
import { ICell, IChildTransformed, IColumn, IRow, IRowTransformed } from '../../types';

const createFirstColumn = () => {
  return {
    id: 'theme',
    name: (i18n: I18n) => i18n._(t`Theme`),
    accessor: 'theme',
    isFixed: true,
    position: ColumnPosition.LEFT,
    maxWidth: '388px',
    minWidth: '327px',
    showHeaderTooltip: true,
    renderCell: (item: IRowTransformed | IChildTransformed, onClick: () => void) => {
      return item.type === REVIEW_AVERAGE_SCORE_ROW_TYPE.THEME ? (
        <FirstCellTheme item={item as IRowTransformed} onClick={onClick} />
      ) : (
        <FirstCellQuestion item={item as IChildTransformed} onClick={onClick} />
      );
    },
  };
};

const getColumnLabel = (column: IColumn & { count?: number }) => {
  switch (column.id) {
    case REVIEW_AVERAGE_SCORE_COLUMNS.COACH:
      return (
        <RatingHeader>
          <CoachCircle />
          <Trans>{column.name}</Trans>
          <CountLabel>{Number(column.count) > 1 ? ` (${column.count})` : ''}</CountLabel>
        </RatingHeader>
      );
    case REVIEW_AVERAGE_SCORE_COLUMNS.PEER:
      return (
        <RatingHeader>
          <PeerCircle />
          <Trans>{column.name}</Trans>
          <CountLabel>{Number(column.count) > 1 ? ` (${column.count})` : ''}</CountLabel>
        </RatingHeader>
      );
    case REVIEW_AVERAGE_SCORE_COLUMNS.SELF:
      return (
        <RatingHeader>
          <SelfCircle />
          <Trans>{column.name}</Trans>
        </RatingHeader>
      );
    default:
      return column.name;
  }
};

const createOtherColumns = (columns: IColumn[]) => {
  return columns.map((column, index) => {
    const isWeightColumn = column.id === REVIEW_AVERAGE_SCORE_COLUMNS.WEIGHT;
    return {
      id: column.id,
      name: getColumnLabel(column),
      accessor: column.id,
      renderCell: (row: IRow) => {
        const cell = row.cells?.find((cell) => cell.columnId === column.id) as ICell;
        const cellIndex = row.cells?.findIndex((cell) => cell.columnId === column.id);

        return (
          <Cell
            noColor={column.id !== REVIEW_AVERAGE_SCORE_COLUMNS.COACH}
            ratingLabelsCount={index === 0 ? undefined : Number(cell?.all)}
            value={cell?.value}
            isWeightCell={isWeightColumn}
            isLast={cellIndex === row.cells.length - 1 && Number(row.id) === 1}
          />
        );
      },
      isFixed: false,
      maxWidth: isWeightColumn ? '64px' : '116px',
      minWidth: isWeightColumn ? '64px' : '92px',
      padding: isWeightColumn ? '7px 0' : '7px 10px',
      centerAlign: true,
    };
  });
};

const createColumns = (columns: IColumn[]) => {
  return [createFirstColumn(), ...createOtherColumns(columns)];
};

export { createColumns };
