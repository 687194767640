import React, { useEffect, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE, FocusAreaType } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isNumber } from 'lodash';
import flatten from 'lodash/flatten';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import uniqBy from 'lodash/uniqBy';
import nanoid from 'nanoid';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { MultiLayerList } from '~/components/MultiLayerList';
import RickTextView from '~/components/RickTextView';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import type { ISelectedFocusArea, ISelectedSkillTemplate } from '~/@types/job';
import useBoolState from '~/hooks/useBoolState';
import { useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { updateSkill } from '~/services/skills';

import { ConfirmationModal } from '../../ConfirmationModal';
import { CreateFocusModal } from '../components/CreateFocusModal';
import { EditDeleteFocusMenu } from '../components/EditDeleteFocusMenu';
import {
  Title,
  Header,
  Actions,
  Subtitle,
  Footer,
  TitleContainer,
  CancelAndStepper,
  StepWrapper,
  Step,
  SkillDescWrapper,
  SeeMoreBtn,
  SkillDesc,
  HeaderActionContainer,
  MultiLayerListWrapper,
} from '../design';

import type { IForm, SelectLevelAndFocusAreasProps } from '../types';
import type { IMultiLangString, ISkill, ISkillTemplate } from '@learned/types';

type PopulatedFocusAreas = {
  name: string;
  values: { id: string; name: IMultiLangString }[];
  showValues: boolean;
};

function SelectLevelAndFocusAreas<T extends IForm>({
  onClose,
  skillCategoryId,
  skillCategoryLevels = [],
  source,
  setIsSelectLevelAndFocusAreasVisible,
  formMethods,
  setSkillTemplates,
  setSkills,
  jobName,
  skillDesc,
  refreshCompanySkills,
  selectedSkill,
  setLoading,
  setSkillsList,
}: SelectLevelAndFocusAreasProps<T>) {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const [focusAreas, setFocusAreas] = useState<PopulatedFocusAreas[]>([]);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedLevels, setSelectedLevels] = useState<string[]>([]);
  const { setValue, watch } = formMethods;
  const $seeMoreToggle = useBoolState(false);
  const $isShowAddFocusModal = useBoolState(false);
  const $isShowWarningModal = useBoolState(false);
  const $isShowDeleteWarningModal = useBoolState(false);
  const languageState = useLanguageState();
  const [createFocusAreaLevel, setCreateFocusAreaLevel] = useState<{
    index: number;
    level: string;
  }>();

  const [editingFocusArea, setEditingFocusArea] = useState<{
    name: IMultiLangString;
    _id: string;
    values?: { id: string; name: IMultiLangString }[];
  }>();

  const { addToast } = useToasts();

  const [deleteFocusArea, setDeleteFocusArea] = useState<{ id: string; name: string }>();

  const selectedFocusAreas =
    // @ts-ignore
    (watch(`skills.${skillCategoryId}.skills`) as ISelectedSkillTemplate['skills']).find((item) =>
      [item.skill, item.skillTemplate].includes(source.id),
    )?.selectedFocusAreas;

  useEffect(() => {
    const focusAreasIds: string[] = [];
    const levelIds: string[] = [];

    selectedFocusAreas?.forEach((item) => {
      if (
        item.type === FocusAreaType.SELECT_FOCUS_AREA &&
        !isEmpty(item.focusArea) &&
        !isNil(item.focusArea) &&
        !isNil(item.level) &&
        item.level < skillCategoryLevels.length
      ) {
        focusAreasIds.push(...item.focusArea);
      } else if (
        item.type === FocusAreaType.SELECT_LEVEL &&
        !isNil(item.level) &&
        item.level < skillCategoryLevels.length
      ) {
        levelIds.push(item.level.toString());
        focusAreasIds.push(...source.focusAreas[item.level].values.map(({ id }) => id));
      } else if (item.type === FocusAreaType.SELECT_ALL) {
        const focusAreas = source.focusAreas.filter((fa) => fa.level < skillCategoryLevels.length);
        levelIds.push(...focusAreas.map(({ level }) => level.toString()));
        focusAreasIds.push(...flatten(focusAreas.map(({ values }) => values.map(({ id }) => id))));
      }
    });
    setSelectedValues(focusAreasIds);
    setSelectedLevels(levelIds);
  }, [focusAreas, selectedFocusAreas, source.focusAreas, skillCategoryLevels]);

  useEffect(() => {
    setFocusAreas(
      source.focusAreas
        .filter((focusArea) => focusArea.level < skillCategoryLevels.length)
        .map((focusArea) => ({
          ...focusArea,
          name: skillCategoryLevels[focusArea.level]
            ? getMultiLangString(skillCategoryLevels[focusArea.level])
            : `Level ${focusArea.level}`,
          showValues: true,
        })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source.focusAreas, skillCategoryLevels]);

  const onSave = () => {
    const currentSkillCategory = selectedSkill?.skillCategory
      ? selectedSkill.skillCategory
      : skillCategoryId;
    let selectedFocusAreas: ISelectedFocusArea[] = [];
    if (selectedValues.length || selectedLevels.length) {
      // select all levels
      if (selectedLevels.length === focusAreas.length) {
        selectedFocusAreas = [{ type: FocusAreaType.SELECT_ALL }];
      } else {
        focusAreas.forEach((focusArea, index) => {
          const ids = focusArea.values?.map(({ id }) => id);
          const commonIds = intersection(ids, selectedValues);

          // select level
          if (selectedLevels.includes(index.toString())) {
            selectedFocusAreas.push({
              type: FocusAreaType.SELECT_LEVEL,
              level: index,
            });
          } else {
            // select focus areas
            if (commonIds.length) {
              selectedFocusAreas.push({
                type: FocusAreaType.SELECT_FOCUS_AREA,
                level: index,
                focusArea: commonIds,
              });
            }
          }
        });
      }
    }

    // @ts-ignore
    if (source?.company) {
      setSkills?.((skill) => uniqBy(skill.concat(source as ISkill), ({ id }) => id));
    } else {
      setSkillTemplates?.((prevSkillTemplates) =>
        uniqBy(prevSkillTemplates.concat(source as ISkillTemplate), ({ id }) => id),
      );
    }
    // @ts-ignore
    setValue(`skills.${currentSkillCategory}.skills`, [
      // @ts-ignore
      ...watch(`skills.${currentSkillCategory}.skills`)
        .filter(
          // @ts-ignore
          ({ skillTemplate, skill }) => ![skill, skillTemplate].includes(source.id),
        )
        .filter(
          // @ts-ignore
          ({ skillTemplate, skill }) =>
            (skillTemplate && (source as ISkill)?.skillTemplate !== skillTemplate) ||
            (skill && (source as ISkill)?.id !== skill),
        ),
      {
        selectedFocusAreas,
        // @ts-ignore
        ...(source?.company ? { skill: source.id } : { skillTemplate: source.id }),
      },
    ]);

    setSkillsList?.((prevSkills) => {
      const index = prevSkills.findIndex((skill) => skill.id === source.id);
      if (index !== -1) {
        prevSkills[index].focusAreas = source.focusAreas;
      }
      return prevSkills;
    });
  };

  const onCreateFocusArea = async (values: { name: IMultiLangString }) => {
    if (createFocusAreaLevel?.index === undefined) {
      return;
    }
    setLoading?.(true);

    const newFocusArea = {
      id: nanoid(),
      name: values.name,
    };

    // Update API
    const updatedFocusAreas = [...source.focusAreas];
    updatedFocusAreas[createFocusAreaLevel.index] = {
      ...updatedFocusAreas[createFocusAreaLevel.index],
      values: [...updatedFocusAreas[createFocusAreaLevel.index].values, newFocusArea],
    };

    await updateSkill({ ...source, focusAreas: updatedFocusAreas });
    refreshCompanySkills?.();

    setLoading?.(false);
  };

  const onUpdateFocusArea = async (values: { name: IMultiLangString }) => {
    if (!editingFocusArea) {
      return;
    }
    setLoading?.(true);

    const updatedFocusArea = {
      id: editingFocusArea._id,
      name: values.name,
    };

    // Update API
    const updatedFocusAreas = source.focusAreas.map((focusArea) => ({
      ...focusArea,
      values: focusArea.values.map((value) =>
        value.id === updatedFocusArea.id ? updatedFocusArea : value,
      ),
    }));

    await updateSkill({ ...source, focusAreas: updatedFocusAreas });
    setSkills?.((prevSkills) => {
      const index = prevSkills.findIndex((skill) => skill.id === source.id);
      if (index !== -1) {
        prevSkills[index].focusAreas = updatedFocusAreas;
      }
      return prevSkills;
    });
    refreshCompanySkills?.();
    setEditingFocusArea(undefined);
    setLoading?.(false);
  };

  const onDeleteFocusArea = async () => {
    if (!deleteFocusArea) {
      return;
    }
    setLoading?.(true);

    // Update API
    const updatedFocusAreas = source.focusAreas.map((focusArea) => ({
      ...focusArea,
      values: focusArea.values.filter((value) => value.id !== deleteFocusArea.id),
    }));

    await updateSkill({ ...source, focusAreas: updatedFocusAreas });

    setSkills?.((prevSkills) => {
      const index = prevSkills.findIndex((skill) => skill.id === source.id);
      if (index !== -1) {
        const level = prevSkills[index].focusAreas.find((fa) =>
          fa.values.some((value) => value.id === deleteFocusArea.id),
        )?.level;

        if (isNumber(level) && prevSkills[index].focusAreas[level].values.length === 1) {
          prevSkills.splice(index, 1);
        } else {
          prevSkills[index].focusAreas = updatedFocusAreas;
        }
      }
      return prevSkills;
    });
    refreshCompanySkills?.();

    setDeleteFocusArea(undefined);
    setLoading?.(false);
  };

  const onEditClick = (id: string, levelId: number) => {
    if (!('company' in source)) {
      addToast({
        title: i18n._(t`Warning`),
        subtitle: i18n._(t`you can adjust focus areas after job creation..`),
        type: TOAST_TYPES.INFO,
      });
    } else {
      setCreateFocusAreaLevel({
        index: levelId,
        level: getMultiLangString(skillCategoryLevels[levelId]),
      });
      focusAreas.forEach((item) => {
        const found = item.values.find((value) => value.id === id);
        if (found) {
          // get the focus area
          const focusArea = source.focusAreas.find(
            (fa) => fa.level === levelId && fa.values.find((v) => v.id === id),
          );
          setEditingFocusArea({ ...found, _id: id, values: focusArea?.values });
        }
      });
      $isShowWarningModal.on();
    }
  };

  return (
    <>
      <Header>
        <TitleContainer>
          <Title>
            <Trans>Add: {getMultiLangString(source?.name ?? '')}</Trans>
          </Title>
          <Subtitle>
            <Trans>
              To skill category: {getMultiLangString(source?.name ?? '')} for {jobName}
            </Trans>
            <br />
            <SkillDescWrapper isShowMore={$seeMoreToggle.value}>
              <SkillDesc isShowMore={$seeMoreToggle.value}>
                <RickTextView html={getMultiLangString(skillDesc ?? '')} />
              </SkillDesc>

              <SeeMoreBtn onClick={$seeMoreToggle.toggle}>
                {$seeMoreToggle.value ? i18n._('Show less') : i18n._('Show more')}
              </SeeMoreBtn>
            </SkillDescWrapper>
          </Subtitle>
        </TitleContainer>
        <HeaderActionContainer>
          <Button size={ButtonSize.MEDIUM} onClick={onClose} variant={ButtonVariant.CLOSE} />
        </HeaderActionContainer>
      </Header>
      <MultiLayerListWrapper>
        <MultiLayerList
          columnName={i18n._(t`Levels & focus areas`)}
          selectedCounterLabel={i18n._(t`selected focus areas`)}
          selectedCounterParentLabel={i18n._(t`selected levels`)}
          counterLabel={i18n._(t`all focus areas`)}
          toggles={{
            hideItems: i18n._(t`Hide focus areas`),
            showItems: i18n._(t`Show focus areas`),
          }}
          data={focusAreas?.map((item, index) => ({
            id: `${index}`,
            name: item.name,
            showSubItems: item.showValues,
            subItems: item.values?.map((value) => ({
              id: value.id,
              name: getMultiLangString(value.name),
            })),
          }))}
          selectedItems={selectedValues}
          setSelectedItems={setSelectedValues}
          selectedParentItems={selectedLevels}
          setSelectedParentItems={setSelectedLevels}
          subItemsFontSize="14px"
          addFunction={(levelId: number) => {
            if (!('company' in source)) {
              addToast({
                title: i18n._(t`Warning`),
                subtitle: i18n._(t`you can adjust focus areas after job creation..`),
                type: TOAST_TYPES.INFO,
              });
            } else {
              setCreateFocusAreaLevel({
                index: levelId,
                level: getMultiLangString(skillCategoryLevels[levelId]),
              });
              $isShowAddFocusModal.on();
            }
          }}
          optionsMenu={(item: string, index: number) => (
            <EditDeleteFocusMenu
              editFunction={onEditClick}
              deleteFunction={(id: string) => {
                if (!('company' in source)) {
                  addToast({
                    title: i18n._(t`Warning`),
                    subtitle: i18n._(t`you can adjust focus areas after job creation..`),
                    type: TOAST_TYPES.INFO,
                  });
                } else {
                  // find the deleting item using id
                  focusAreas.forEach((focusArea) => {
                    const found = focusArea.values.find((value) => value.id === id);
                    if (found) {
                      setDeleteFocusArea({ id, name: getMultiLangString(found.name) });
                    }
                  });
                  $isShowDeleteWarningModal.on();
                }
              }}
              focusArea={item}
              index={index}
            />
          )}
        />
      </MultiLayerListWrapper>
      {$isShowWarningModal.value && (
        <ConfirmationModal
          title={i18n._(t`Warning`)}
          description={
            editingFocusArea?.name
              ? i18n._(
                  `This Change effects all places where ${getMultiLangString(
                    editingFocusArea?.name,
                  )} is used`,
                )
              : ''
          }
          onClose={() => $isShowWarningModal.off()}
          onSubmit={() => {
            $isShowAddFocusModal.on();
            $isShowWarningModal.off();
          }}
          type={CONFIRMATION_MODAL_TYPE.WARNING}
        />
      )}

      {$isShowDeleteWarningModal.value && (
        <ConfirmationModal
          title={i18n._(t`Delete focus area?`)}
          description={i18n._(
            deleteFocusArea?.name
              ? i18n._('This will affect all jobs where this skill and focus area is selected.')
              : '',
          )}
          onClose={() => $isShowDeleteWarningModal.off()}
          onSubmit={() => {
            $isShowDeleteWarningModal.off();
            onDeleteFocusArea();
          }}
          type={CONFIRMATION_MODAL_TYPE.DELETE}
        />
      )}

      {$isShowAddFocusModal.value && !editingFocusArea && (
        <CreateFocusModal
          title={i18n._('Create focus area')}
          onClose={() => $isShowAddFocusModal.off()}
          onCreate={onCreateFocusArea}
          languageState={languageState}
          skillName={i18n._(`For skill level: ${createFocusAreaLevel?.level}`)}
        />
      )}
      {$isShowAddFocusModal.value && editingFocusArea && (
        <CreateFocusModal
          title={<Trans>Edit focus area</Trans>}
          onClose={() => {
            $isShowAddFocusModal.off();
            setEditingFocusArea(undefined);
          }}
          onCreate={onCreateFocusArea}
          languageState={languageState}
          skillName={`For skill level: ${createFocusAreaLevel?.level}`}
          editingFocusArea={editingFocusArea}
          onUpdate={onUpdateFocusArea}
        />
      )}
      <Footer>
        <Actions>
          <CancelAndStepper>
            <Button
              label={i18n._(t`Cancel`)}
              type="button"
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              onClick={onClose}
            />
            <StepWrapper>
              <Trans>
                Step 2 <Step>of 2</Step>
              </Trans>
            </StepWrapper>
          </CancelAndStepper>
          <CancelAndStepper>
            <Button
              label={i18n._(t`Back`)}
              type="button"
              variant={ButtonVariant.TEXT_PRIMARY}
              icon={ICONS.BACK}
              size={ButtonSize.MEDIUM}
              onClick={() => setIsSelectLevelAndFocusAreasVisible(false)}
            />
            <Button
              label={i18n._(t`Add`)}
              type="button"
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              onClick={() => {
                onSave();
                onClose();
              }}
              disabled={!selectedValues.length && !selectedLevels.length}
            />
          </CancelAndStepper>
        </Actions>
      </Footer>
    </>
  );
}

export { SelectLevelAndFocusAreas };
