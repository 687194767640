import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { type UseFormReturn, Controller } from 'react-hook-form';
import styled from 'styled-components';

import Select from '~/components/Select';
import { StepSection } from '~/pages/SurveyUpdate/components';
import type { IGeneralForm } from '~/pages/SurveyUpdate/types';

import {
  SURVEY_FREQUENCY_WITH_NAMES,
  SURVEY_QUESTIONS_PER_PARTICIPANT_WITH_NAMES,
} from '~/constants/survey';
import { useAutoSaveFunc } from '~/hooks/useAutoSave';

import { Label } from '../../design';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 14px;
`;

const Block = styled.div`
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

interface ISectionPulse {
  formMethods: UseFormReturn<IGeneralForm>;
  autosave: useAutoSaveFunc;
}

const SectionPulse = ({ formMethods, autosave }: ISectionPulse) => {
  const { i18n } = useLingui();
  const { register, control } = formMethods;

  return (
    <StepSection title={i18n._(t`Pulse`)}>
      <Wrapper>
        <Block>
          <Label>
            <Trans>Repeat frequency</Trans>
          </Label>
          <Row>
            <Controller
              {...register('pulse.frequency')}
              control={control}
              render={({ field: { value, onChange } }) => (
                /* @ts-ignore */
                <Select
                  value={value}
                  options={SURVEY_FREQUENCY_WITH_NAMES}
                  onChange={(value) => {
                    onChange(value);
                    autosave.run('pulse.frequency');
                  }}
                  width="100%"
                  height="38px"
                />
              )}
            />
          </Row>
        </Block>

        <Block>
          <Label>
            <Trans>Amount of questions per participant</Trans>
          </Label>
          <Row>
            <Controller
              {...register('pulse.questionsPerParticipant')}
              control={control}
              render={({ field: { value, onChange } }) => (
                /* @ts-ignore */
                <Select
                  value={value}
                  options={SURVEY_QUESTIONS_PER_PARTICIPANT_WITH_NAMES}
                  onChange={(value) => {
                    onChange(value);
                    autosave.run('pulse.questionsPerParticipant');
                  }}
                  width="100%"
                  height="38px"
                />
              )}
            />
          </Row>
        </Block>
      </Wrapper>
    </StepSection>
  );
};

export { SectionPulse };
