import React from 'react';

import { REVIEW_QUESTION_TYPES, REVIEW_QUESTION_TYPES_V1 } from '@learned/constants';
import { IReviewTheme, ITask, IUserReview, IUserReviewQuestion, ICompany } from '@learned/types';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';

import CompanyAvatar from '~/components/CompanyAvatar';
import { UserAvatar } from '~/components/UserAvatar';

import AverageScorePDFView from './AverageScorePDFView';
import { PageWrapper } from './design';
import NextStepsPDFView from './NextStepsPDFView';
import SignaturesPDFView from './SignaturesPDFView';
import SummariesPDFView from './SummariesPDFView';

import defaultCompanyAvatar from '~/assets/learned-logo/learned-logo.png';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser } from '~/selectors/baseGetters';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { formatDate } from '~/utils/dates';

import { PlanGoalQuestion } from '../../ReviewGiveFeedback/components/Questions/PlanGoalQuestion';
import { IQuestionDefaultData } from '../../ReviewGiveFeedback/types';
import { isZeroState } from '../../Reviews/DashboardUser/ReviewDashboardUserForm';
import { RatingAnswers } from '../../Reviews/DashboardUser/ReviewDashboardUserForm/components/Questions/RatingAnswers';
import { SkillAnswers } from '../../Reviews/DashboardUser/ReviewDashboardUserForm/components/Questions/SkillAnswers';
import { SkillAnswersV1 } from '../../Reviews/DashboardUser/ReviewDashboardUserForm/components/Questions/SkillAnswersV1';
import { TextAnswers } from '../../Reviews/DashboardUser/ReviewDashboardUserForm/components/Questions/TextAnswers';
import { QuestionWrapper } from '../../Reviews/DashboardUser/ReviewDashboardUserForm/components/QuestionWrapper';
import {
  QuestionListContainer,
  ReviewDashboardTitle,
} from '../../Reviews/DashboardUser/ReviewDashboardUserForm/design';
import {
  IUserReviewQuestionGrouped,
  useReviewRatings,
} from '../../Reviews/DashboardUser/ReviewDashboardUserForm/hooks/useReviewRatings';

interface IProps {
  userReview: IUserReview & {
    tasks: ITask[];
    employeeTasksInOtherUserReviews: ITask[];
    userReviewQuestions: IUserReviewQuestion[];
  };
}

const UserReviewPdfContent: React.FC<IProps> = ({ userReview }) => {
  const currentUser = useSelector(getUser);
  const getMultiLangString = useMultiLangString();
  const currentCompany = useSelector(getCurrentCompany);

  const getQuestion = (
    question: IUserReviewQuestionGrouped,
    questionIndex: number,
    isPDFView = false,
  ) => {
    switch (question.type) {
      case REVIEW_QUESTION_TYPES.TEXT:
        return (
          <TextAnswers
            isPDFView={isPDFView}
            userReview={userReview as IUserReview}
            isZeroState={isZeroState(question)}
            isEditable={() => false}
            question={question as unknown as IQuestionDefaultData}
            onEdit={() => {}}
          />
        );
      case REVIEW_QUESTION_TYPES.RATING:
        return (
          <RatingAnswers
            userReview={userReview as IUserReview}
            isZeroState={isZeroState(question)}
            isEditable={() => false}
            question={question as unknown as IQuestionDefaultData}
            onEdit={() => {}}
            isPDFView={isPDFView}
          />
        );
      case REVIEW_QUESTION_TYPES.GOAL_PLAN:
        return (
          <PlanGoalQuestion
            // @ts-ignore not sure how tasks field was added to user review
            userFrom={userReview?.tasks?.[0]?.userFrom}
            userReview={userReview}
            goalsPlanned={userReview?.goalsPlanned}
            currentUser={currentUser}
            isDashboard
            onEdit={() => {}}
            subTypes={question.settings.subTypes}
            isPDFView={isPDFView}
          />
        );
      case REVIEW_QUESTION_TYPES.SKILL_CATEGORY:
      case REVIEW_QUESTION_TYPES.CUSTOM_SKILL:
        return (
          <SkillAnswers
            isPDFView={isPDFView}
            question={question}
            isZeroState={isZeroState(question)}
            onEdit={() => {}}
            userReview={userReview as IUserReview}
            questionNumber={questionIndex + 1}
          />
        );
      case REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1:
      case REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1:
        return (
          <SkillAnswersV1
            isPDFView={isPDFView}
            question={question}
            isZeroState={isZeroState(question)}
            onEdit={() => {}}
            userReview={userReview as IUserReview}
            questionNumber={questionIndex + 1}
          />
        );
    }
  };

  const { reviewRatings } = useReviewRatings({
    userReview,
  });

  const reviewStartDate = formatDate(
    userReview.settings.startDate as unknown as string,
    'DD-MM-YYYY',
  );

  const getCompanyLogo = (company: ICompany) => {
    if (company.emailLogoEnabled) {
      return company.logoEmailUrl || defaultCompanyAvatar;
    }
    return company.logoUrl || defaultCompanyAvatar;
  };

  const companyLogo = getCompanyLogo(currentCompany);

  return (
    <PageWrapper>
      <div className="secondary-header">
        <CompanyAvatar className="logo" logoUrl={companyLogo} size={46} />
        <div className="review-start-date">{reviewStartDate}</div>
      </div>
      <ReviewDashboardTitle>
        {/* CAUTION: this review id is being used in the PDF generator to target this element */}
        <span id="review-title">
          <Trans>{getMultiLangString(userReview.name)}</Trans>
        </span>

        <UserAvatar userId={userReview.createdFor} />
        {/* TODO: show the end date here, */}
      </ReviewDashboardTitle>
      <QuestionListContainer isPDFView>
        {reviewRatings.map((question, index) => {
          return (
            <QuestionWrapper
              isZeroState={isZeroState(question)}
              key={index}
              isPDFView
              questionNumber={index + 1}
              themeName={getMultiLangString((question.theme as IReviewTheme)?.name)}
              question={question}
            >
              {getQuestion(question, index + 1, true)}
            </QuestionWrapper>
          );
        })}
      </QuestionListContainer>
      <ReviewDashboardTitle>
        <Trans>Conclusions</Trans>
      </ReviewDashboardTitle>
      <AverageScorePDFView userReview={userReview} />
      <SummariesPDFView userReviewId={userReview.id} />
      <NextStepsPDFView userReviewId={userReview.id} />
      {userReview.settings.isDigitalSign && <SignaturesPDFView userReview={userReview} />}
    </PageWrapper>
  );
};

export default UserReviewPdfContent;
