import { JOB_STATUS } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { IMultiSelectOption } from './userReviews';

import { COLORS } from '~/styles';

enum JOB_STATUS_LABELS {
  ACTIVE = 'active',
  PREVIOUS = 'previous',
  AMBITION = 'ambition',
}

const JOB_STATUS_PROVIDER = {
  [JOB_STATUS_LABELS.PREVIOUS]: {
    color: COLORS.STATUS_DRAFT,
    backgroundColor: COLORS.STATUS_DRAFT,
    backgroundByPercentage: 30,
    text: (i18n: I18n) => i18n._(t`Previous`),
  },
  [JOB_STATUS_LABELS.AMBITION]: {
    color: COLORS.STATUS_PUBLISHED,
    backgroundColor: COLORS.STATUS_PUBLISHED,
    backgroundByPercentage: 8,
    text: (i18n: I18n) => i18n._(t`Ambition`),
  },

  [JOB_STATUS_LABELS.ACTIVE]: {
    color: COLORS.STATUS_ACTIVE,
    backgroundColor: COLORS.STATUS_ACTIVE,
    backgroundByPercentage: 14,
    text: (i18n: I18n) => i18n._(t`Active`),
  },
};

const JOB_STATUS_OPTIONS: IMultiSelectOption[] = [
  {
    id: JOB_STATUS.PREVIOUS,
    key: JOB_STATUS.PREVIOUS,
    name: 'Previous',
    translated: (i18n: I18n) => i18n._(t`Previous`),
  },
  {
    id: JOB_STATUS.ACTIVE,
    key: JOB_STATUS.ACTIVE,
    name: 'Active',
    translated: (i18n: I18n) => i18n._(t`Active`),
  },
  {
    id: JOB_STATUS.FUTURE,
    key: JOB_STATUS.FUTURE,
    name: 'Future',
    translated: (i18n: I18n) => i18n._(t`Future`),
  },
];

enum JOB_STATUS_SORT_OPTIONS {
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
}

export { JOB_STATUS_PROVIDER, JOB_STATUS_LABELS, JOB_STATUS_OPTIONS, JOB_STATUS_SORT_OPTIONS };
