/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, SetStateAction, useMemo } from 'react';

import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { StyledTableGrid } from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import { convertSkillsDataToTable } from '../../utils';
import { createSkillCategoryColumns } from '../columns/SkillCategory.columns';

import type { IGeneralForm } from '../types';
import type { ISkill, ISkillCategory, ISkillTemplate } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

const Wrapper = styled.div`
  margin-bottom: 32px;
`;

interface ISkillTemplateTable {
  skillCategoryId?: string;
  skillTemplates: ISkillTemplate[];
  skills: ISkill[];
  setSkills?: Dispatch<SetStateAction<ISkill[]>>;
  formMethods: UseFormReturn<IGeneralForm>;
  setSource?: Dispatch<SetStateAction<ISkillTemplate | ISkill | undefined>>;
  skillCategories: ISkillCategory[];
  isEditMode?: boolean;
}

const SkillTemplateTable = ({
  skillCategoryId,
  skillTemplates,
  skills,
  setSkills,
  formMethods,
  setSource,
  skillCategories,
  isEditMode = false,
}: ISkillTemplateTable) => {
  const { watch } = formMethods;
  const { i18n } = useLingui();

  const watchSkillCategory = watch(`skills.${skillCategoryId}`);
  const getMultiLangString = useMultiLangString();
  const { columns } = createSkillCategoryColumns({ getMultiLangString, isEditMode });

  const data = useMemo(() => {
    const skillCategory = skillCategories.find((item) => item.id === skillCategoryId);
    if (!skillCategory) {
      return [];
    }

    return convertSkillsDataToTable({
      skillTemplates,
      skills,
      setSkills,
      formMethods,
      skillCategory,
      setSource,
      i18n,
    });
  }, [
    JSON.stringify([skillCategoryId, skillTemplates, skills, watchSkillCategory, skillCategories]),
  ]);

  return (
    <Wrapper>
      <StyledTableGrid thColumnPadding="16px 19px" data={data} columns={columns} />
    </Wrapper>
  );
};

export { SkillTemplateTable };
