import React, { useEffect, useMemo } from 'react';

import { REVIEW_STATUS, REVIEW_QUESTION_EVALUATORS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { orderBy } from 'lodash';
import flatten from 'lodash/flatten';
import size from 'lodash/size';
import uniq from 'lodash/uniq';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { Input } from '~/components/Input';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import ToolTip from '~/components/Tooltip';
import { PopulatedReviewTemplate } from '~/pages/ReviewTemplateView/types';

import { StepFooter } from './components/StepFooter';
import {
  Form,
  InputContainer,
  Label,
  Section,
  StyledReviewTemplatesDropdown,
  Title,
  ButtonWrapper,
} from './design';
import { errors as errorTypes } from './hooks/useResolver';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser } from '~/selectors/baseGetters';
import { getSkillCategories } from '~/services/skillCategories';
import { getSkills } from '~/services/skills';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';
import { isNotNil } from '~/utils/typePredicates';

import { ErrorBanner } from '../../components/ErrorBanner';
import { EvaluatorsAndThemes } from '../../components/EvaluatorsAndThemes';
import { ReviewThemePreview } from '../../components/EvaluatorsAndThemes/types';

import type { IReviewSelfForm } from './types';
import type {
  IMultiLangString,
  IReview,
  IReviewTheme,
  IReviewQuestion,
  IReviewTemplate,
  WithEvaluators,
  WithPartial,
} from '@learned/types';

interface StepGeneralProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewSelfForm>;
  languageState: ILanguageStateReturn;
  reviewTemplates: IReviewTemplate[];
  defineSelectedTemplate: (reviewFromProps?: IReview) => IReviewTemplate | undefined;
  onEditReviewTemplate: () => void;
  reviewThemesError?: boolean;
  updateQuestion: (
    question: Omit<WithPartial<IReviewQuestion, 'name' | 'type'>, 'company' | 'meta'>,
  ) => void;
}

const StepGeneral = ({
  sectionState,
  formMethods,
  languageState,
  reviewTemplates,
  defineSelectedTemplate,
  onEditReviewTemplate,
  reviewThemesError,
  updateQuestion,
}: StepGeneralProps) => {
  const { i18n } = useLingui();
  const { control, register, unregister, formState, trigger, watch, setValue } = formMethods;
  const getMultiLangString = useMultiLangString();
  const nameFieldArray = useMultiLangFieldArray({
    name: 'name',
    control,
    unregister,
    languageState,
  });

  const watchReviewTemplate = watch('reviewTemplate');
  const watchReviewThemes = watch('reviewThemes');
  const watchEvaluators = watch('evaluators');
  const isDraft = watch('status') === REVIEW_STATUS.DRAFT;
  const currentUser = useSelector(getUser);

  const setSkillCategories = async (questions: IReviewQuestion[]) => {
    const categories = questions
      // @ts-ignore
      ?.map((question) => question.settings.skillCategory)
      .filter(isNotNil);
    const skillCategories = await getSkillCategories(categories);

    // @ts-ignore
    const result = await getSkills({ categories });
    setValue('skills', Object.values(result.data.skills));
    setValue('skillCategories', skillCategories);
  };

  useEffect(() => {
    let uniqEvaluators: REVIEW_QUESTION_EVALUATORS[] = [];
    let uniqQuestionTypes: IReviewQuestion['type'][] = [];
    if (watchReviewTemplate) {
      const selectedTemplate = defineSelectedTemplate();

      // selectedTemplate could be undefined if the template is draft
      // in this case we to reset reviewTemplate fields
      // otherwise user cannot save the form
      if (!selectedTemplate) {
        setValue('reviewTemplate', undefined);
        setValue('reviewTemplateData', undefined);
        return;
      }

      const themes: Record<IReviewTheme['id'], ReviewThemePreview> = {};

      const questions = selectedTemplate?.questions as unknown as IReviewQuestion[];

      questions?.forEach((question) => {
        if (question.theme && !themes[question.theme]) {
          themes[question.theme] = {
            id: question.theme,
            name: question.themeName,
            icon: question.themeIcon,
            iconColor: question.themeIconColor,
            questions: [question],
            themeWeight: selectedTemplate?.themeWeights[question.theme],
          };
        } else {
          themes[question.theme].questions.push(question);
        }
      });

      setSkillCategories(questions);

      uniqEvaluators = uniq(
        flatten(questions?.map((item) => (item?.settings as WithEvaluators)?.evaluators)),
      );

      uniqQuestionTypes = uniq(questions?.map((item) => item?.type));

      setValue('reviewTemplateData', selectedTemplate);
      setValue('reviewThemes', Object.values(themes));
      setValue(
        'reviewTemplateName',
        turnMultiLangIntoArray(
          (selectedTemplate?.name as IMultiLangString) || '',
          languageState.companyLanguages,
        ),
      );

      trigger('reviewTemplate');
    }

    const evaluators = orderBy(
      uniqEvaluators
        .map((item) => {
          switch (item) {
            case REVIEW_QUESTION_EVALUATORS.EMPLOYEE:
              return {
                value: REVIEW_QUESTION_EVALUATORS.EMPLOYEE,
                icon: ICONS.EMPLOYEE,
                title: i18n._(t`Employees`),
                rank: 1,
              };
            case REVIEW_QUESTION_EVALUATORS.COACH:
              return {
                value: REVIEW_QUESTION_EVALUATORS.COACH,
                icon: ICONS.COACH,
                title: i18n._(t`Coaches`),
                rank: 2,
              };
            case REVIEW_QUESTION_EVALUATORS.PEER:
              return {
                value: REVIEW_QUESTION_EVALUATORS.PEER,
                icon: ICONS.PEER,
                title: i18n._(t`Peers`),
                rank: 3,
              };
            default:
              return null;
          }
        })
        .filter(isNotNil),
      ['rank'],
    );

    setValue('evaluators', evaluators);
    setValue('reviewQuestionTypes', uniqQuestionTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewTemplates, watchReviewTemplate]);

  const reviewTemplateError = useMemo(() => {
    if (formState?.errors?.reviewTemplate?.type === errorTypes.noQuestionsForReviewTemplate) {
      return formState.errors.reviewTemplate.message;
    }

    if (formState.errors.reviewTemplate) {
      return true;
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState?.errors?.reviewTemplate]);

  return (
    <Form>
      <Title marginBottom="16px">
        <Trans>General</Trans>
      </Title>
      {reviewThemesError && isDraft && (
        <ErrorBanner
          title={i18n._(t`Invalid weighted themes.`)}
          description={i18n._(t`To avoid reporting issues these problems need to be fixed.`)}
        />
      )}
      <Section>
        <Label>
          <Trans>Name</Trans>
        </Label>
        <InputContainer width="100%">
          {nameFieldArray.fields.map((field) => (
            <Controller
              key={field.id}
              {...register(`name.${field.index}.value`)}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    error={!!formState.errors?.name}
                    onChange={(args) => {
                      onChange(args);
                      trigger('name');
                    }}
                    maxLength={60}
                    key={field.id}
                    height="38px"
                    leftIcon={
                      size(languageState.languages) > 1
                        ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                        : undefined
                    }
                    placeholder={i18n._(t`Example: Review Q1`)}
                  />
                );
              }}
            />
          ))}
        </InputContainer>
      </Section>
      <Section $marginTop="14px">
        <Label error={!!reviewTemplateError}>
          <Trans>Template</Trans>
        </Label>
        <ButtonWrapper>
          <ToolTip
            tooltip={i18n._(
              t`The selected template cannot be changed after the review has been published`,
            )}
            disabled={isDraft}
          >
            <InputContainer width="100%">
              <Controller
                {...register('reviewTemplate')}
                control={control}
                render={({ field: { onChange } }) => (
                  <StyledReviewTemplatesDropdown
                    className="review-templates-dropdown"
                    placeholder={i18n._(t`Select review template`)}
                    selectedItem={watch('reviewTemplateData')}
                    items={reviewTemplates}
                    onChange={(item) => item && onChange(item.id)}
                    stringifyItem={(item) => getMultiLangString(item.name)}
                    isSingleSelect
                    isSearchable={size(reviewTemplates) > 7}
                    isClickable={isDraft}
                    error={reviewTemplateError}
                  />
                )}
              />
            </InputContainer>
          </ToolTip>
          {currentUser.isAdmin && (
            <Button
              type="button"
              icon={ICONS.EDIT_PENCIL}
              tooltip={
                !isDraft ? i18n._(t`This review is published. The template cannot be edited.`) : ''
              }
              disabled={!watch('reviewTemplateData') || !isDraft}
              label={i18n._(t`Edit`)}
              variant={ButtonVariant.SECONDARY}
              onClick={onEditReviewTemplate}
              size={ButtonSize.MEDIUM}
            />
          )}
        </ButtonWrapper>
      </Section>
      <EvaluatorsAndThemes
        reviewTemplate={watch('reviewTemplateData') as unknown as PopulatedReviewTemplate}
        evaluators={watchEvaluators}
        reviewThemes={watchReviewThemes}
        updateQuestion={updateQuestion}
        skillCategories={watch('skillCategories')}
        showReviewThemes={isDraft}
      />
      <StepFooter
        isPrev={false}
        onNext={() => sectionState.setCurrentSection(++sectionState.currentSection)}
      />
    </Form>
  );
};

export { StepGeneral };
