import styled from 'styled-components';

import { COLORS } from '~/styles';

export const RatingsGridPDFWrapper = styled.div`
  overflow-x: hidden;

  tr:last-of-type {
    td:not(:last-of-type) {
      div {
        background-color: ${COLORS.BG_ELEMENTS};
      }
    }

    td:first-of-type {
      div {
        & button {
          opacity: 0;
        }
      }
    }
  }

  tr:not(:last-of-type) {
    td {
      div {
        background-color: ${COLORS.BG_SWITCH};
      }
    }
  }

  thead tr:first-of-type {
    font-size: 12px;
    text-transform: uppercase;
  }

  th:nth-child(1),
  td:nth-child(1) {
    min-width: 418px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    min-width: 86px;
  }

  tbody {
    div.first-cell-question {
      min-height: 60px;
      height: 100%;
      div {
        overflow: visible;
        text-overflow: unset;
        -webkit-line-clamp: unset;
        padding: 10px 0;
      }
    }

    td {
      position: relative;
    }
    div.cell-block {
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  }
`;
