import { useMemo, useState } from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import { t } from '@lingui/macro';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';
import { useSelector } from 'react-redux';

import { useAsyncFetch } from '~/hooks/useAsyncFetch';
import { getInactiveUsers } from '~/selectors/baseGetters';
import { getCareerPlans, getUserCareerPlans } from '~/services/careerPlans';
import { getJobProfile } from '~/services/jobProfiles';

import type { ICareerPlanPopulated, IJobProfilePopulated } from './types';
import type { ICareerPlan, IUser } from '@learned/types';
import type { I18n } from '@lingui/core';

function useTabs(totalMembers: number) {
  const tabs = useMemo(
    () => ({
      setup: { key: 'setup', label: (i18n: I18n) => i18n._(t`Set up`) },
      members: {
        key: 'members',
        label: (i18n: I18n) => i18n._(t`Employees (${totalMembers})`),
      },
    }),
    [totalMembers],
  );
  const [currentTab, setCurrentTab] = useState(() => tabs.setup.key);

  const onChangeTab = (key: string) => {
    setCurrentTab(tabs[key as keyof typeof tabs].key);
  };

  return { tabs, currentTab, onChangeTab };
}

const useJobProfile = (jobProfileId: string) => {
  const [jobProfile, isLoading] = useAsyncFetch<IJobProfilePopulated | undefined>(
    {
      fetch: async () => {
        const response = await getJobProfile(jobProfileId, [
          'skills',
          'jobLevelGroup',
          'jobFamily',
          'recommendedJobs',
        ]);
        return response.data?.[API_RETURN_FIELDS.JOB_PROFILE];
      },
      initialState: undefined,
    },
    [jobProfileId],
  );

  return { jobProfile, isLoading };
};

const useCareerPlans = (userId: string) => {
  const [careerPlans, isLoading] = useAsyncFetch<ICareerPlan[]>(
    {
      fetch: async () => {
        const items = await getUserCareerPlans(userId);
        return Object.values(items);
      },
      initialState: [],
    },
    [userId],
  );

  return { careerPlans, isLoading };
};

const useMemberCareerplans = ({ jobProfile }: { jobProfile: string }) => {
  const inactiveUsers = useSelector((state) => getInactiveUsers(state));
  const [careerPlans, isLoading, refetch] = useAsyncFetch<ICareerPlanPopulated[]>(
    {
      fetch: async () => {
        const items: (ICareerPlan & { createdFor: IUser })[] = await getCareerPlans(
          { jobProfile },
          { populate: ['createdFor'] },
        );

        return Object.values(items).map((item) => ({
          ...item,
          isInactiveUser: !!inactiveUsers[item.createdFor.id],
          startDate: item.startDate
            ? parse(item.startDate.split('T')[0], 'yyyy-MM-dd', new Date())
            : item.meta.createdDate
            ? parseISO(item.meta.createdDate)
            : undefined,
          endDate: item.endDate
            ? parse(item.endDate.split('T')[0], 'yyyy-MM-dd', new Date())
            : undefined,
        }));
      },
      initialState: [],
    },
    [jobProfile],
  );

  return { careerPlans, isLoading, refetch };
};

export { useTabs, useJobProfile, useCareerPlans, useMemberCareerplans };
