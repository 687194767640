import { useEffect, useState, Dispatch, SetStateAction } from 'react';

import {
  API_RETURN_FIELDS,
  CAREER_PLAN_STATUSES,
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
  REVIEW_STATUS,
  REVIEW_TYPES,
  CONNECTION_STATUSES,
  REVIEW_QUESTION_TYPES_V1,
  USER_REVIEW_STATUS,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isPast from 'date-fns/isPast';
import _, { orderBy, uniqBy } from 'lodash';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { useSelector } from 'react-redux';

import { ICONS } from '~/components/Icon';
import { useInactiveUsers } from '~/pages/Reviews/hooks/useInactiveUsers';
import { useReviewTemplates } from '~/pages/Reviews/hooks/useReviewTemplates';
import { transformToISOString } from '~/pages/Reviews/utils';

import { useReviewTasks } from './useReviewTasks';

import useBoolState from '~/hooks/useBoolState';
import { useLanguageState } from '~/hooks/useLanguageState';
import { getUser, getSettingsRole } from '~/selectors/baseGetters';
import { getCareerPlansAsAdminCoach } from '~/services/careerPlans';
import { deleteReviewById, fetchReviewById, updateReviewById } from '~/services/reviews';
import { getUserReviews } from '~/services/userReviews';
import { getCompanyUsers } from '~/services/users';
import { turnArrayIntoMultiLang, turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';
import { isNotNil } from '~/utils/typePredicates';

import type { IEmployee, IReviewIndividualForm, PopulatedCareerPlan } from '../types';
import type {
  IReview,
  IUser,
  IUserReview,
  IReviewTemplate,
  IReviewQuestion,
  WithEvaluators,
  IMultiLangString,
  IJobProfile,
} from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface UseReviewProps {
  formMethods: UseFormReturn<IReviewIndividualForm>;
  reviewId: IReview['id'];
  setUserId: Dispatch<SetStateAction<string | undefined>>;
}

export const useReview = ({ formMethods, reviewId, setUserId }: UseReviewProps) => {
  const { i18n } = useLingui();
  const {
    setValue,
    watch,
    formState: { dirtyFields },
  } = formMethods;
  const languageState = useLanguageState(true);

  const [item, setItem] = useState<IReview>();
  const user = useSelector(getUser);

  const [isAllowToDelete, setIsAllowToDelete] = useState(false);
  const $isReviewLoading = useBoolState(true);
  const $isReviewSaving = useBoolState(false);

  const userRole = useSelector(getSettingsRole);
  const { setTasksDates, generateDates } = useReviewTasks({ formMethods });

  const { removeInactiveUsers } = useInactiveUsers();
  const { reviewTemplates, setReviewTemplates } = useReviewTemplates();

  const watchEmployees = watch('employees');
  const watchFetchedReview = watch('fetchedReview');

  useEffect(() => {
    setIsAllowToDelete(item && (user.isAdmin || item?.createdBy === user.id));
  }, [item, user]);

  const fetchReview = async () => {
    const result = await fetchReviewById(reviewId);
    const review: IReview = result.data[API_RETURN_FIELDS.REVIEW];
    setItem(review);
    return review;
  };

  const fetchCareerPlans = async () => {
    const res = await getCareerPlansAsAdminCoach(
      { status: CAREER_PLAN_STATUSES.CURRENT },
      { populate: ['jobProfile'] },
      userRole,
    );
    return Object.values(res as Record<string, PopulatedCareerPlan>);
  };

  const fetchCompanyUsers = async (users: string[]): Promise<IUser[]> => {
    const { data } = await getCompanyUsers(
      {
        statuses: [CONNECTION_STATUSES.ACTIVE],
        users: users?.filter(Boolean) || [],
      },
      ['jobProfiles', 'coaches'],
    );

    return Object.values(data?.users ?? {});
  };

  const handleAddEmployees = async (userReviews: IUserReview[]) => {
    const careerPlans = await fetchCareerPlans();
    const users = await fetchCompanyUsers(userReviews.map((u) => u.createdFor));

    const employees: Array<IEmployee> = [];

    // for individual review - always only 1 userReview (logic is copied from EditReviewCycle);
    userReviews.forEach((userReview) => {
      const isDraft = userReview.status === USER_REVIEW_STATUS.DRAFT;
      const employeeId = userReview.createdFor;
      const employee = users.find((user) => user.id === employeeId) as unknown as IEmployee;

      // create populated careerPlans backup
      const careerPlansBackup = Object.values(userReview.backup?.careerPlans || {}).map(
        (careerPlan) => {
          return {
            ...careerPlan,
            jobProfile: userReview.backup?.jobProfiles[careerPlan.jobProfile],
          };
        },
      ) as unknown as PopulatedCareerPlan[];
      const existingCareerPlans = careerPlans?.filter((item) => item.createdFor === employeeId);

      const jobProfilesBackup = Object.values(userReview.backup?.jobProfiles || {});
      const existingJobProfiles = employee?.jobProfiles || [];

      let availableCareerPlans: PopulatedCareerPlan[] = [];
      let availableJobProfiles: IJobProfile[] = [];
      if (isDraft) {
        // existing data from db (draft status)
        availableCareerPlans = existingCareerPlans;
        availableJobProfiles = existingJobProfiles;
      } else {
        // data from backup of user review (published status)
        availableCareerPlans = careerPlansBackup;
        availableJobProfiles = jobProfilesBackup;
      }

      // selected career plans for employee
      const selectedCareerPlans = availableCareerPlans.filter((item) =>
        userReview.careerPlans?.includes(item.id),
      );

      const jobProfileIds = selectedCareerPlans?.map((plan) => plan.jobProfile.id); // plan.jobProfile is populated in individual review
      // to show the info for selected career plans
      const jobProfiles = availableJobProfiles.filter((item) => jobProfileIds.includes(item.id));

      // @ts-ignore
      employees.push({
        ...employee,
        id: userReview.createdFor, // !important we need this in case user does not exist in the company anymore, but he still present in review cycle
        userReview: userReview.id,
        // all available job profiles for employee
        // availableJobProfiles, we do not use this in individual implementation (copied from ReviewCycle)
        // all available career plans for employee
        availableCareerPlans,
        // all available coaches for employee
        availableCoaches: removeInactiveUsers(employee?.coaches),
        // selected coaches
        coaches: removeInactiveUsers(userReview.coaches),
        // selected guests
        guests: removeInactiveUsers(userReview.guests),
        // selected career plans
        careerPlans: selectedCareerPlans,
        // job profiles of selected career plans
        jobProfiles,
      });
    });

    setValue('employees', uniqBy([...watchEmployees, ...employees], 'id'));
  };

  const fetchUserReview = async () => {
    const result = await getUserReviews({ filters: { review: [reviewId] }, options: {} });
    const userReviews = result.data[API_RETURN_FIELDS.USER_REVIEWS] as IUserReview[];
    const userReview: IUserReview = userReviews[0];
    const employee = watch('employees')?.[0];

    if (userReview.createdFor) {
      setValue('userReview', { id: userReview.id, createdFor: userReview.createdFor });
      setUserId(userReview.createdFor);

      await handleAddEmployees(userReviews);
    } else if (!isEmpty(employee)) {
      // create an individual review from member site
      setValue('userReview', { id: userReview.id, createdFor: employee.id });
    }
    return userReview;
  };

  // if backup exist (review not in DRAFT) - we get template from backup
  // because it's not possible to change anymore
  const defineSelectedTemplate = (reviewFromProps?: IReview) => {
    const selectedReviewTemplateId = watch('reviewTemplate');

    const review = reviewFromProps || watch('fetchedReview');
    const isDraft = review.status === REVIEW_STATUS.DRAFT;

    // @ts-ignore
    const reviewTemplateFromBackup = review?.backup?.reviewTemplate;
    // @ts-ignore
    const reviewQuestionsFromBackup = review?.backup?.reviewQuestions;
    // if backup exist (review not in DRAFT) - we get template from backup
    // because it's not possible to change anymore
    const selectedTemplate: IReviewTemplate | undefined = isDraft
      ? reviewTemplates.find((item) => item.id === selectedReviewTemplateId)
      : // get template from backup
        ({
          ...reviewTemplateFromBackup,
          // populate questions from backup
          questions: reviewTemplateFromBackup?.questions
            .map(
              // @ts-ignore
              (questionId) => reviewQuestionsFromBackup[questionId],
            )
            .map((question) => {
              const theme = review.backup?.reviewThemes[question.theme];
              return {
                ...question,
                themeName: theme?.name,
                themeIcon: theme?.icon,
                themeIconColor: theme?.iconColor,
              };
            }),
        } as unknown as IReviewTemplate);

    return selectedTemplate;
  };

  const setFormValues = async () => {
    const review = await fetchReview();
    const userReview = await fetchUserReview();

    setValue('notifications', review.notifications);
    setValue('name', turnMultiLangIntoArray(review.name, languageState.companyLanguages));
    setValue('reviewTemplate', review.reviewTemplate);
    setValue('reviewInvitationTemplate', review.reviewInvitationTemplate);
    setValue('privacy', review.privacy);
    setValue('settings', {
      // @ts-ignore
      isAutoTimeline: true,
      ...review.settings,
      startDate: new Date(review.settings.startDate),
      endDate: review.settings.endDate ? new Date(review.settings.endDate) : null,
      isShowOverallRating: review.settings.isShowOverallRating ?? false,
      isShowPreviousScore: review.settings.isShowPreviousScore ?? true,
    });
    setValue(
      'description',
      turnMultiLangIntoArray(review.description, languageState.companyLanguages),
    );
    setValue('status', review.status);
    setValue('userReview', userReview);
    setValue('fetchedReview', review);
    setTasksDates(review);

    const selectedTemplate = defineSelectedTemplate(review);
    const questions = selectedTemplate?.questions as unknown as IReviewQuestion[];

    const uniqQuestionTypes = uniq(questions?.map((item) => item?.type));
    const uniqEvaluators: REVIEW_QUESTION_EVALUATORS[] = uniq(
      flatten(questions?.map((item) => (item?.settings as WithEvaluators)?.evaluators)),
    );

    // define evaluators
    const evaluators = orderBy(
      uniqEvaluators
        .map((item) => {
          switch (item) {
            case REVIEW_QUESTION_EVALUATORS.EMPLOYEE:
              return {
                value: REVIEW_QUESTION_EVALUATORS.EMPLOYEE,
                icon: ICONS.EMPLOYEE,
                title: i18n._(t`Employees`),
                rank: 1,
              };
            case REVIEW_QUESTION_EVALUATORS.COACH:
              return {
                value: REVIEW_QUESTION_EVALUATORS.COACH,
                icon: ICONS.COACH,
                title: i18n._(t`Coaches`),
                rank: 2,
              };
            case REVIEW_QUESTION_EVALUATORS.PEER:
              return {
                value: REVIEW_QUESTION_EVALUATORS.PEER,
                icon: ICONS.PEER,
                title: i18n._(t`Peers`),
                rank: 3,
              };
            default:
              return null;
          }
        })
        .filter(isNotNil),
      ['rank'],
    );
    setValue('evaluators', evaluators);

    setValue('reviewQuestionTypes', uniqQuestionTypes);

    setValue('reviewTemplateData', selectedTemplate);
    setValue(
      'reviewTemplateName',
      turnMultiLangIntoArray(
        (selectedTemplate?.name as IMultiLangString) || '',
        languageState.companyLanguages,
      ),
    );

    $isReviewLoading.off();
  };

  const saveReview = async (status?: REVIEW_STATUS) => {
    $isReviewSaving.on();
    const evaluators = watch('evaluators').map(
      (evaluator: { value: REVIEW_QUESTION_EVALUATORS; icon: ICONS; title: string }) =>
        evaluator.value,
    );

    const review = {
      name: turnArrayIntoMultiLang(watch('name')),
      notifications: watch('notifications'),
      reviewTemplate: watch('reviewTemplate') || null,
      privacy: watch('privacy'),
      settings: {
        ...watch('settings'),
        startDate: transformToISOString(watch('settings.startDate')),
        endDate: transformToISOString(watch('settings.endDate')),
      },
      status: status ? status : watch('status'),
      description: turnArrayIntoMultiLang(watch('description') || []),
      tasks: {},
      type: REVIEW_TYPES.SELF,
      reviewInvitationTemplate: watch('reviewInvitationTemplate') || null,
    };

    if (evaluators.includes(REVIEW_QUESTION_EVALUATORS.PEER)) {
      // @ts-ignore
      review.tasks.reviewPeerEvaluate = {
        startDate: transformToISOString(watch('tasks.reviewPeerEvaluate.startDate')),
        endDate: transformToISOString(watch('tasks.reviewPeerEvaluate.endDate')),
      };
      // @ts-ignore
      review.tasks.reviewPeerNominate = {
        startDate: transformToISOString(watch('tasks.reviewPeerNominate.startDate')),
        endDate: transformToISOString(watch('tasks.reviewPeerNominate.endDate')),
        description: turnArrayIntoMultiLang(watch('tasks.reviewPeerNominate.description') || []),
      };
    }

    if (evaluators.includes(REVIEW_QUESTION_EVALUATORS.COACH)) {
      // @ts-ignore
      review.tasks.reviewCoachEvaluate = {
        startDate: transformToISOString(watch('tasks.reviewCoachEvaluate.startDate')),
        endDate: transformToISOString(watch('tasks.reviewCoachEvaluate.endDate')),
      };
    }

    if (evaluators.includes(REVIEW_QUESTION_EVALUATORS.EMPLOYEE)) {
      // @ts-ignore
      review.tasks.reviewSelfEvaluate = {
        startDate: transformToISOString(watch('tasks.reviewSelfEvaluate.startDate')),
        endDate: transformToISOString(watch('tasks.reviewSelfEvaluate.endDate')),
      };
    }

    const employee = watch('employees')?.[0];
    const userReview = watch('userReview');

    const editUserReviews = [];
    const addUserReviews = [];
    const deleteUserReviews = [];

    const isCoachesVisible = evaluators.includes(REVIEW_QUESTION_EVALUATORS.COACH);
    const isJobsVisible = watch('reviewQuestionTypes').some((type) =>
      [REVIEW_QUESTION_TYPES.SKILL_CATEGORY, REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1].includes(
        type,
      ),
    );

    if (employee) {
      const coaches = isCoachesVisible ? employee.coaches : [];
      const careerPlans = employee.careerPlans?.map((careerPlan) => careerPlan.id) || [];

      if (userReview?.createdFor && userReview?.createdFor === employee.id) {
        editUserReviews.push({
          id: userReview.id,
          coaches,
          guests: employee.guests,
          careerPlans: isJobsVisible ? careerPlans : [],
        });
      } else {
        deleteUserReviews.push(userReview?.id);
        addUserReviews.push({
          createdFor: employee.id,
          coaches,
          guests: employee.guests,
          careerPlans: isJobsVisible ? careerPlans : [],
        });
      }
    }

    let result;
    try {
      result = await updateReviewById(reviewId, {
        // @ts-ignore
        review,
        editUserReviews: dirtyFields.employees ? editUserReviews : [],
        addUserReviews,
        deleteUserReviews,
      });
    } finally {
      // turn off loading modal if error or success
      $isReviewSaving.off();
    }
    return result;
  };

  const deleteReview = async () => {
    if (item && isAllowToDelete) {
      await deleteReviewById(item.id);
    }
  };

  useEffect(() => {
    setFormValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewId]);

  // set today as startDate when settings.startDate is old and status is draft
  useEffect(() => {
    const startDate = watchFetchedReview?.settings?.startDate;
    if (watchFetchedReview?.status === REVIEW_STATUS.DRAFT) {
      const isStartDateInPast = isPast(new Date(startDate));

      if (isStartDateInPast) {
        const now = new Date();
        setValue('settings.startDate', now);
        generateDates(now);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchFetchedReview?.status, watchFetchedReview?.settings?.startDate]);

  return {
    deleteReview,
    saveReview,
    isAllowToDelete,
    isReviewSaving: $isReviewSaving.value,
    isReviewLoading: $isReviewLoading.value,
    reviewTemplates,
    setReviewTemplates,
    defineSelectedTemplate,
  };
};
