import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';

import {
  Label,
  NoRecommendedSkill,
  RecommendedSkillsActions,
  RecommendedSkillsWrapper,
  ShowMoreButton,
  SkillsSelectorBackground,
  StyledItemButton,
} from '~/components/Modals/AddSkillModal/design';
import { WatchSkill } from '~/components/Modals/AddSkillModal/types';

import useBoolState from '~/hooks/useBoolState';
import { useMultiLangString } from '~/hooks/useMultiLangString';

import type { ISkill, ISkillTemplate } from '@learned/types';

type SkillsSelectorProps = {
  title?: string;
  skills: (ISkill | ISkillTemplate)[];
  onSelectSkill?(skill: ISkill | ISkillTemplate): void;
  watchSkills: WatchSkill[];
  isRecommendedSection?: boolean;
  isCustomSkill?: boolean;
  source?: ISkill | ISkillTemplate;
};

const SkillsSelector = ({
  title,
  skills,
  onSelectSkill,
  watchSkills,
  isRecommendedSection,
  isCustomSkill,
  source,
}: SkillsSelectorProps) => {
  const getMultiLangString = useMultiLangString();
  const { i18n } = useLingui();

  const [filteredSkills, setFilteredSkills] = useState<(ISkill | ISkillTemplate)[]>([]);
  const [shownSkills, setShownSkills] = useState<(ISkill | ISkillTemplate)[]>([]);
  const [offset, setOffset] = useState(0);
  const $isExpanded = useBoolState(true);
  const skillsPerPage = isRecommendedSection ? 5 : isCustomSkill ? 40 : 20;

  useEffect(() => {
    const filteredList = skills.filter(
      (skillTemplate) =>
        !watchSkills.some(
          (item) => skillTemplate.id === item.skillTemplate || skillTemplate.id === item.skill,
        ),
    );
    setFilteredSkills(filteredList);
    setShownSkills(filteredList.slice(0, skillsPerPage)); // Show the first `skillsPerPage` items initially
    setOffset(skillsPerPage); // Set offset to `skillsPerPage`
    $isExpanded.off(); // Reset expanded state on skill changes

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skills, skillsPerPage, watchSkills]);

  const handleShowMoreOrLess = () => {
    if ($isExpanded.value) {
      // Reset to initial number of skills
      setShownSkills(filteredSkills.slice(0, skillsPerPage));
      setOffset(skillsPerPage);
      $isExpanded.off();
    } else {
      // Show the next batch of `skillsPerPage` skills
      const nextOffset = offset + skillsPerPage;
      const nextSkills = filteredSkills.slice(offset, nextOffset);
      setShownSkills((prevShownSkills) => [...prevShownSkills, ...nextSkills]);
      setOffset(nextOffset); // Update the offset
      if (nextOffset >= filteredSkills.length) {
        $isExpanded.on(); // If all skills are shown, mark as expanded
      }
    }
  };
  return !isEmpty(filteredSkills) ? (
    <SkillsSelectorBackground>
      {title && <Label>{title}</Label>}
      <>
        <RecommendedSkillsWrapper
          isRecommendedSection={isRecommendedSection}
          isCustomSkill={isCustomSkill}
        >
          {shownSkills.map((skill) => (
            <StyledItemButton
              key={skill.id}
              onClick={() => {
                onSelectSkill?.(skill);
              }}
              isSelected={skill.id === source?.id}
            >
              {getMultiLangString(skill.name)}
            </StyledItemButton>
          ))}
          {offset < filteredSkills.length && ( // Show the button only if there are more skills to show
            <RecommendedSkillsActions>
              <ShowMoreButton onClick={handleShowMoreOrLess}>{i18n._(t`Show more`)}</ShowMoreButton>
            </RecommendedSkillsActions>
          )}
          {offset >= filteredSkills.length &&
            $isExpanded.value && ( // Show the "Show less" button only if all skills are shown
              <RecommendedSkillsActions>
                <ShowMoreButton onClick={handleShowMoreOrLess}>
                  {i18n._(t`Show less`)}
                </ShowMoreButton>
              </RecommendedSkillsActions>
            )}
        </RecommendedSkillsWrapper>
      </>
    </SkillsSelectorBackground>
  ) : isRecommendedSection ? (
    <SkillsSelectorBackground>
      {title && <Label>{title}</Label>}
      <NoRecommendedSkill>{i18n._(t`No skills found.`)}</NoRecommendedSkill>
    </SkillsSelectorBackground>
  ) : null;
};

export { SkillsSelector };
