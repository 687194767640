import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  padding: 28px 32px;
  display: flex;
  flex-direction: column;
`;

const IconButtons = styled.div`
  flex-direction: row;
  display: flex;
  gap: 16px;
  align-items: center;
  height: 40px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: row;
`;

export const QuestionBody = styled.div`
  max-height: 486px;
  overflow: auto;
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 16px;
`;

const Section = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ThemeContainer = styled.div<{ big?: boolean; color: string[] }>`
  ${({ big }) =>
    big
      ? css`
          width: 21px;
          height: 21px;
          margin: 0 4px 0 0;
          padding: 4px 4px 5px 5px;
        `
      : css`
          width: 20px;
          display: flex;
          height: 20px;
          align-items: center;
          margin-right: 4px;
          justify-content: center;
        `}
  border-radius: 6px;
  background-image: linear-gradient(
    to bottom,
    ${({ color }) => (color ? `${color[0]}, ${color[1]}` : '#f7f9ff, #ebf1fe')}
  );
`;

const Separator = styled.div`
  height: 1px;
  background: rgba(151, 151, 151, 0.1);
  margin: 24px 0 20px;
`;

const Description = styled.div`
  display: flex;
  flex: 1;
  text-align: left;
  flex-direction: column;
  font-size: 14px;
  color: ${COLORS.SUBTEXT};
`;

const IconContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`;

export {
  Wrapper,
  IconButtons,
  Footer,
  InfoRow,
  Section,
  ThemeContainer,
  Separator,
  Description,
  IconContainer,
};
