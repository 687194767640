import React from 'react';

import { REVIEW_QUESTION_TYPES } from '@learned/constants';

import convertToTimeString from '~/utils/convertToTimeString';

import { TextAnswers } from '../../TextAnswers';
import { QuestionSeparator } from '../components/design';
import { ReviewInformationBar } from '../components/ReviewInformationBar';
import { IAllPreviousAnswersProps } from '../types';

const AllTextAnswers: React.FC<IAllPreviousAnswersProps<REVIEW_QUESTION_TYPES.TEXT>> = ({
  previousUserReviewWithQuestionAndAnswers,
  useMultiLangString,
}) => {
  const getMultiLangString = useMultiLangString();
  return (
    <>
      {previousUserReviewWithQuestionAndAnswers.map((userReview, i) => {
        return (
          <div key={i}>
            <ReviewInformationBar
              reviewName={getMultiLangString(userReview.reviewName)}
              reviewStartDate={convertToTimeString(userReview.reviewStartDate)}
            />
            <TextAnswers
              question={userReview.questionWithAnswers}
              useMultiLangString={useMultiLangString}
            />
            <QuestionSeparator />
          </div>
        );
      })}
    </>
  );
};

export default AllTextAnswers;
