import React from 'react';

import { type UseFormReturn } from 'react-hook-form';

import {
  IQuestionForm,
  IRatingLabel,
  ISelectedSkill,
  ISkill,
  ISkillCategory,
} from '~/pages/ReviewThemeSetup/types';

import { useLanguageState } from '~/hooks/useLanguageState';

import { Options } from '../Options';

interface IProps {
  skills?: ISkill[];
  formMethods: UseFormReturn<IQuestionForm>;
  selectedSkills?: ISelectedSkill[];
  setSelectedSkill?: (skill: ISkill) => void;
  setIsSkillModalOpen: (open: boolean) => void;
  skillCategories: ISkillCategory[];
  setCurrentStep?: (step: number) => void;
  setSelectedSkills: (skills: ISelectedSkill[]) => void;
  ratingLabels: IRatingLabel[];
}

const CustomSkill = ({
  formMethods,

  ratingLabels,
}: IProps) => {
  const languageState = useLanguageState();

  return (
    <>
      <Options
        languageState={languageState}
        formMethods={formMethods}
        ratingLabels={ratingLabels}
      />
    </>
  );
};

export { CustomSkill };
