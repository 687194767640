import React, { useEffect } from 'react';

import { IJobProfile } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isNumber } from 'lodash';
import moment from 'moment';
import { type UseFormReturn } from 'react-hook-form';

import { Icon, ICONS } from '~/components/Icon';
import { Input } from '~/components/Input';
import Radio from '~/components/Radio';
import Switch from '~/components/Switch';

import {
  Container,
  DateContainer,
  DateSectionWrapper,
  JobTitle,
  RadioButtonWrapper,
  StyledDatePicker,
} from './design';

import useBoolState from '~/hooks/useBoolState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

import { IAssignJobForm } from '../..';

interface IJobDateSectionProps {
  index?: number;
  formControl: UseFormReturn<IAssignJobForm>;
  jobProfile?: IJobProfile;
  jobProfiles?: IJobProfile[];
  primaryJob: string | null;
  setPrimaryJob: (primaryJob: string | null) => void;
  isGlobalSettings?: boolean;
}

export const JobDateSection = ({
  jobProfile,
  formControl,
  index,
  primaryJob,
  setPrimaryJob,
  isGlobalSettings,
}: IJobDateSectionProps) => {
  const { i18n } = useLingui();
  const { setValue, formState, trigger, watch, getValues } = formControl;
  const getMultiLangString = useMultiLangString();
  const $isShowCalendarForStartDate = useBoolState();
  const $isShowCalendarForEndDate = useBoolState();

  const handleDateChange = (date: Date, isStartDate: boolean) => {
    if (isNumber(index)) {
      const fieldPath = `jobs.${index}.${isStartDate ? 'startDate' : 'endDate'}` as const;
      setValue(fieldPath, date, { shouldDirty: true });

      const dateErrors = formState.errors?.jobs?.[index];
      if (dateErrors?.startDate || dateErrors?.endDate) {
        trigger(`jobs.${index}`);
      } else {
        trigger(fieldPath);
      }
    }
  };

  const getFormattedDateToString = (isStartDate?: boolean) => {
    const startDate = isNumber(index) && watch(`jobs.${index}.startDate`);
    const endDate = isNumber(index) && watch(`jobs.${index}.endDate`);
    const datePattern = 'DD-MM-YYYY';

    if (isStartDate && startDate) {
      return moment(startDate).format(datePattern);
    }

    if (endDate) {
      return moment(endDate).format(datePattern);
    }

    return '';
  };

  useEffect(() => {
    setValue('primaryJob', primaryJob ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryJob]);

  const handleDateModalClose = async (date?: string | null, isStartingDate?: boolean) => {
    if (date) {
      handleDateChange(date as unknown as Date, !!isStartingDate);
    }

    await trigger();

    if (isStartingDate) {
      $isShowCalendarForStartDate.off();
    } else {
      $isShowCalendarForEndDate.off();
    }
  };

  return (
    <Container>
      <JobTitle>
        <Trans>{getMultiLangString(jobProfile?.name ?? '')}</Trans>
      </JobTitle>
      <DateContainer>
        <DateSectionWrapper>
          <Input
            label={i18n._(t`Start date`)}
            placeholder={i18n._(t`Select date`)}
            leftIcon={<Icon icon={ICONS.CALENDAR} />}
            onClick={$isShowCalendarForStartDate.on}
            value={getFormattedDateToString(true)}
            // @ts-ignore
            error={formState.errors?.jobs?.[index]?.startDate?.message}
          />
          {$isShowCalendarForStartDate.value && (
            <StyledDatePicker
              // @ts-ignore
              width={260}
              height="38px"
              onClose={(date) => handleDateModalClose(date, true)}
              placeholder={i18n._(t`Select date`)}
            />
          )}
        </DateSectionWrapper>
        <DateSectionWrapper>
          <Input
            label={i18n._(t`End date`)}
            placeholder={i18n._(t`Select date`)}
            leftIcon={<Icon icon={ICONS.CALENDAR} />}
            onClick={$isShowCalendarForEndDate.on}
            value={getFormattedDateToString()}
            // @ts-ignore
            error={formState.errors?.jobs?.[index]?.endDate?.message}
          />
          {$isShowCalendarForEndDate.value && (
            <StyledDatePicker
              // @ts-ignore
              width={260}
              height="38px"
              placeholder={i18n._(t`Select date`)}
              onClose={(date) => handleDateModalClose(date)}
            />
          )}
        </DateSectionWrapper>
      </DateContainer>
      {isNumber(index) && !isGlobalSettings && (
        <RadioButtonWrapper>
          {getValues('jobs').length > 1 ? (
            <Radio
              checked={getValues('jobs')[index].jobId === primaryJob}
              onChange={() =>
                getValues('jobs')[index].jobId === primaryJob
                  ? setPrimaryJob(null)
                  : setPrimaryJob(getValues('jobs')[index].jobId)
              }
              size={20}
              fill={
                getValues('jobs')[index].jobId === primaryJob
                  ? COLORS.COMPANY
                  : COLORS.ACTIVITY_BORDER
              }
            />
          ) : (
            <Switch
              onChange={(enabled: boolean) =>
                enabled ? setPrimaryJob(getValues('jobs')[0].jobId) : setPrimaryJob(null)
              }
              checked={getValues('jobs')[0].jobId === primaryJob}
            />
          )}
          <span>Primary job</span>
        </RadioButtonWrapper>
      )}
    </Container>
  );
};
