import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { type UseFormReturn } from 'react-hook-form';

import Switch from '~/components/Switch';

import {
  PrivacyDescription,
  Separator,
  SubSectionWrapper,
  SubTitle,
  ToggleContainer,
  ToggleRow,
  ToggleText,
} from '../design';

import type { IReviewSelfForm } from '../types';

interface PrivacySettingsProps {
  formMethods: UseFormReturn<IReviewSelfForm>;
}

const PrivacySettings = ({ formMethods }: PrivacySettingsProps) => {
  const { watch, setValue } = formMethods;
  const { i18n } = useLingui();
  const fieldIdentifier = 'privacy.isAllEmployeeCoachesCanSeeReview';

  const onChangeToggle = () => {
    setValue(fieldIdentifier, !watch(fieldIdentifier), { shouldDirty: true });
  };

  return (
    <SubSectionWrapper>
      <SubTitle>
        <Trans>Privacy</Trans>
      </SubTitle>
      <Separator />
      <PrivacyDescription>
        <Trans>Employees, guests and coaches selected for coach review can view the review.</Trans>
      </PrivacyDescription>
      <ToggleContainer marginTop="12px" marginBottom="-42px">
        <ToggleRow>
          <Switch onChange={onChangeToggle} checked={watch(fieldIdentifier)} />
          <ToggleText>
            {i18n._(
              t`Also allow all current and future coaches of the employee to view the review.`,
            )}
          </ToggleText>
        </ToggleRow>
      </ToggleContainer>
    </SubSectionWrapper>
  );
};

export { PrivacySettings };
