import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import styled, { css } from 'styled-components';

import { Dropdown } from '~/components/Dropdown';
import { Switch } from '~/pages/CompanyHRISIntegrationSettings/components/UpdateIntegrationModal/components/Switch';

import { COLORS } from '~/styles';

import { DocumentCategory, IntegrationModule } from '../../../types';
import { Row, RowElement } from '../design';

export const ColumnRow = styled.div`
  width: 100%;
  flex-direction: column;
  background-color: ${COLORS.BG_SWITCH};
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  font-size: 14px;
`;

const CategoriesContent = styled.div`
  display: flex;
  padding: 0 20px 16px 20px;
  gap: 4px;
  flex-direction: column;
`;

const Label = styled.span<{ enabled?: boolean; error?: boolean }>`
  width: 500px;
  font-size: 14px;
  line-height: 20px;
  ${({ enabled, error }) =>
    enabled
      ? css`
          color: ${error ? COLORS.ACCENT_ERROR : COLORS.TEXT_HOVER};
        `
      : css`
          color: ${COLORS.INACTIVE};
        `}
`;

const Info = styled.span<{ enabled?: boolean }>`
  font-size: 14px;
  line-height: 20px;
  font-style: italic;
  font-weight: 400;
  color: ${COLORS.ICONS_PRIMARY};
`;

const CustomDropdown = styled(Dropdown)`
  max-width: 500px;
`;

type UploadDocumentsProps = {
  integrationName: string;
  label: string;
  integrationModule: IntegrationModule;
  onChange?(value: IntegrationModule): void;
  categories?: DocumentCategory[];
  errorMessage?: string;
};

const UploadDocuments = ({
  integrationName,
  label,
  integrationModule,
  onChange,
  categories = [],
  errorMessage,
}: UploadDocumentsProps) => {
  const { i18n } = useLingui();

  const { enabled = false, automatic = false, documentCategoryId } = integrationModule;

  const [selected, setSelected] = useState<DocumentCategory>();
  const [isAutomatic, setIsAutomatic] = useState(automatic);

  useEffect(() => {
    if (!isEmpty(categories)) {
      setSelected(categories.find((item) => item.id === documentCategoryId));
    }
  }, [categories, documentCategoryId]);

  return enabled ? (
    <ColumnRow>
      <Row>
        <RowElement>{`${i18n._(t`${label}`)} ${integrationName}`}</RowElement>
        <RowElement flexBasis={150}>
          <Switch
            disabled={isEmpty(selected)}
            width={64}
            value={isAutomatic}
            onChange={(value) => {
              setIsAutomatic(value);
              !isEmpty(selected) &&
                onChange?.({
                  documentCategoryId: selected.id,
                  enabled,
                  automatic: value,
                });
            }}
            tooltip={
              isEmpty(selected)
                ? i18n._(
                    t`This settings is only possible when a ${integrationName} category is selected.`,
                  )
                : undefined
            }
          />
        </RowElement>
      </Row>

      <CategoriesContent>
        <Label enabled={!isEmpty(categories)} error={!!errorMessage}>{`${integrationName} ${i18n._(
          t`category`,
        )}`}</Label>
        <CustomDropdown
          isSingleSelect={true}
          selectedItem={selected}
          stringifyItem={(item) => (item as DocumentCategory).name}
          items={categories}
          onChange={(item) => {
            setSelected(item as DocumentCategory);
            onChange?.({
              documentCategoryId: (item as DocumentCategory).id,
              enabled,
              automatic: isAutomatic,
            });
          }}
          isClickable={!isEmpty(categories)}
          selectHeight={'38px'}
          placeholder={
            isEmpty(categories) ? i18n._(t`No category available`) : i18n._(t`Select category`)
          }
        />
        {errorMessage && (
          <Label enabled={!isEmpty(categories)} error={!!errorMessage}>
            {errorMessage}
          </Label>
        )}
        {isEmpty(categories) && (
          <Info>
            {i18n._(
              t`Check in ${integrationName} to see if categories are available. It may take some time before they are visible here.`,
            )}
          </Info>
        )}
      </CategoriesContent>
    </ColumnRow>
  ) : null;
};

export { UploadDocuments };
