import React from 'react';

import { ITask, IUserReview, IUserReviewQuestion } from '@learned/types';
import { Trans } from '@lingui/macro';

import { ICON_SIZES, ICONS } from '~/components/Icon';

import { OverallRating } from './components/OverallRating';
import { RatingsGrid } from './components/RatingsGrid';
import RatingsGridPDF from './components/RatingsGridPDF';
import {
  Content,
  Header,
  RatingsGridTitle,
  Title,
  Wrapper,
  TitleNone,
  TitleText,
  OverallRatingSection,
  ThemeRatingsSection,
  RotatingIcon,
} from './design';
import { useReviewAverageScore } from './hooks/useReviewAverageScore';

import useBoolState from '~/hooks/useBoolState';
import { COLORS } from '~/styles';

interface IScoresProps {
  userReview?: IUserReview & {
    tasks: ITask[];
    employeeTasksInOtherUserReviews: ITask[];
    userReviewQuestions: IUserReviewQuestion[];
  };
  width?: string;
  isRatingGridScrollable?: boolean;
  isPDFView?: boolean;
}

const Scores = ({ userReview, width, isRatingGridScrollable, isPDFView = false }: IScoresProps) => {
  const { items, columns, totalOptions, weightedAverage, onToggleTheme, availableTotalRatings } =
    useReviewAverageScore({
      userReview,
      isPDFView,
    });
  const $isOpened = useBoolState(true);
  const isMoreThanFiveRating = (totalOptions?.length ?? 0) > 5;

  return (
    <Wrapper width={width}>
      <OverallRatingSection>
        <Header>
          <Title>
            <Trans>Overall rating: </Trans>
            {weightedAverage.coach > 0 ? (
              <span>
                {weightedAverage.coach}/{totalOptions?.length}
                <TitleText>
                  <Trans>(based on coach review)</Trans>
                </TitleText>
              </span>
            ) : (
              <TitleNone>
                <Trans>None</Trans>
              </TitleNone>
            )}
          </Title>
        </Header>
        <OverallRating
          availableTotalRatings={availableTotalRatings}
          userReview={userReview}
          weightedAverage={weightedAverage}
          ratingLabels={totalOptions}
          isMoreThanFiveRating={isMoreThanFiveRating}
        />
      </OverallRatingSection>
      <ThemeRatingsSection>
        <RatingsGridTitle onClick={() => $isOpened.toggle()}>
          <RotatingIcon
            size={ICON_SIZES.SMALL}
            icon={ICONS.SORT_DOWN}
            color={COLORS.DROPDOWN_ICON}
            isOpened={$isOpened.value}
          />
          <Title>
            <Trans>Ratings per theme</Trans>
          </Title>
        </RatingsGridTitle>
        {$isOpened.value && (
          <Content isScrollable={isRatingGridScrollable}>
            {isPDFView ? (
              <RatingsGridPDF items={items} columns={columns} />
            ) : (
              <RatingsGrid items={items} columns={columns} onToggleTheme={onToggleTheme} />
            )}
          </Content>
        )}
      </ThemeRatingsSection>
    </Wrapper>
  );
};

export { Scores };
