import React, { useState, useEffect, useRef } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import SearchIcon from '~/components/Icons/Search';
import Modal from '~/components/Modal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TextField } from '~/components/Text';

import ExternalEmailsBlock from './components/ExternalEmailsBlock';
import GoalBlock from './components/GoalBlock';
import MessageBlock from './components/MessageBlock';
import SearchBlock from './components/SearchBlock';
import UserBlock from './components/UserBlock';

import { RT_FEEDBACK_TYPES, NOMINATE_TYPE } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { getUser, getUsers } from '~/selectors/baseGetters';
import { COLOR_PALETTE, COLORS } from '~/styles';
import getUserFullName from '~/utils/getUserFullName';
import isValidEmail from '~/utils/isValidEmail';

const Container = styled.div``;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${COLOR_PALETTE.BLACK};
`;

const SectionContainer = styled.div`
  margin-bottom: 24px;
`;

const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid ${COLOR_PALETTE.GRAY_MIDDLE};
  border-radius: ${(props) => (props.$searching ? '6px 6px 0 0' : '6px')};
  margin-top: 12px;
`;

const StyledSearchIcon = styled(SearchIcon)`
  margin-left: 8px;
`;

const StyledInput = styled(TextField)`
  border: none !important;
  border-radius: 6px;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const TYPE_VALUES = {
  [RT_FEEDBACK_TYPES.ASK_FEEDBACK.key]: {
    title: (i18n) => i18n._(t`Ask feedback`),
    memberTitle: (i18n) => i18n._(t`Member(s)`),
    memberSubtitle: (i18n) => i18n._(t`Select the users you would like to ask for feedback`),
    messageTitle: (i18n) => `${i18n._(t`Message`)}*`,
    externalPeopleTitle: (i18n) => i18n._(t`Ask external people for feedback via email`),
    messageSubtitle: (i18n) =>
      i18n._(t`Explain the situation you would like to receive feedback about`),
    messagePlaceholder: (i18n) => i18n._(t`What do you want feedback about?`),
  },
  [RT_FEEDBACK_TYPES.GIVE_FEEDBACK.key]: {
    title: (i18n) => i18n._(t`Give feedback or a compliment`),
    memberTitle: (i18n) => i18n._(t`Member`),
    memberSubtitle: (i18n) => i18n._(t`Select the member you would like to give feedback`),
    externalPeopleTitle: (i18n) => i18n._(t`Ask external people for feedback via email`),
    messageTitle: (i18n) => i18n._(t`Feedback`),
    messageSubtitle: (i18n) => i18n._(t`Write your compliment or contructive feedback`),
    messagePlaceholder: (i18n) => i18n._(t`Type your feedback here...`),
  },
  [NOMINATE_TYPE.peer]: {
    title: (i18n, reviewName) => i18n._(t`Ask peers for input for your ${reviewName}`),
    memberTitle: (i18n) => i18n._(t`Colleagues`),
    memberSubtitle: (i18n) => i18n._(t`Select the colleagues you would like to ask for input`),
    externalPeopleTitle: (i18n) => i18n._(t`Ask external people for input via email`),
    messageTitle: (i18n) => `${i18n._(t`Message`)}*`,
    messagePlaceholder: (i18n) => i18n._(t`Type here...`),
  },
  [NOMINATE_TYPE.coach]: {
    title: (i18n, reviewName) => i18n._(t`Ask coach for input for your ${reviewName}`),
    memberTitle: (i18n) => i18n._(t`Colleagues`),
    memberSubtitle: (i18n) => i18n._(t`Select the colleagues you would like to ask for input`),
    externalPeopleTitle: (i18n) => i18n._(t`Ask external people for input via email`),
    messageTitle: (i18n) => `${i18n._(t`Message`)}*`,
    messagePlaceholder: (i18n) => i18n._(t`Type here...`),
  },
  [NOMINATE_TYPE.conversationCoach]: {
    title: (i18n, reviewName) => i18n._(t`Ask coaches for input for your ${reviewName}`),
    memberTitle: (i18n) => i18n._(t`Colleagues`),
    memberSubtitle: (i18n) => i18n._(t`Select the colleagues you would like to ask for input`),
    externalPeopleTitle: (i18n) => i18n._(t`Ask external people for input via email`),
    messageTitle: (i18n) => `${i18n._(t`Message`)}*`,
    messagePlaceholder: (i18n) => i18n._(t`Type here...`),
  },
};

// we use this component only for RTFeedbacks
const FeedbackModal = ({
  onClose,
  type,
  goal,
  userId,
  review,
  onSubmit,
  isChooseYourself = false,
}) => {
  const { i18n } = useLingui();
  const users = useSelector(getUsers);
  const currentUser = useSelector(getUser);
  const preselectedUser = userId && users[userId];
  const [selectedUsers, setSelectedUsers] = useState(preselectedUser ? [preselectedUser] : []);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [selectedGoal, setSelectedGoal] = useState(goal || null);
  const $showAddExternalPeople = useBoolState(false);
  const $loading = useBoolState();
  const $isFirstClick = useBoolState();

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setTimeout(() => {
            // we need to delay blur for bubble click event
            if ($isFirstClick.value) {
              $isFirstClick.off();
            }
            setSearchValue('');
          }, 100);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
      // eslint-disable-next-line
    }, [ref, $isFirstClick.value]);
  }
  const ref = useRef(null);
  useOutsideAlerter(ref);

  const isAskFeedback = type === RT_FEEDBACK_TYPES.ASK_FEEDBACK.key;

  const typeValues = TYPE_VALUES[type];

  const close = () => {
    onClose();
  };

  const handleOnSubmit = async () => {
    $loading.on();
    try {
      await onSubmit({
        users: selectedUsers.map((u) => u.id),
        emails: selectedEmails.map((e) => e),
        message,
        ...(selectedGoal && { selectedGoal }),
      });
      onClose();
    } finally {
      $loading.off();
    }
  };

  const changeEmail = (e) => setEmail(e.target.value);

  const changeSearchValue = (e) => {
    $isFirstClick.off();
    setSearchValue(e.target.value);
  };

  const removeEmail = (emailToRemove) => {
    setSelectedEmails(selectedEmails.filter((e) => e !== emailToRemove));
  };

  const isEmailError = (email) => {
    // Used to check whether this email is not already in the list of currently selected users for feedback
    const isEmailAdded = !isEmpty(
      selectedEmails.filter((e) => e.toLowerCase() === email.toLowerCase()),
    );

    // Used to check whether the email address is an actually valid email address
    const isEmailAddressValid = isValidEmail(email);

    // Used to check whether we're not adding ourself to the list of users asking for feedback
    const isEmailSelf = email === currentUser.email;

    // Used to check whether we're not adding email of user, who has account in current company
    const isEmailBelongUserInCompany = Object.values(users)
      .map((u) => u.email)
      .includes(email.toLowerCase());

    return isEmailAdded || !isEmailAddressValid || isEmailSelf || isEmailBelongUserInCompany;
  };

  const renderSearch = () => {
    let filteredUsers = Object.values(users).sort((a, b) =>
      getUserFullName(a).toLowerCase().localeCompare(getUserFullName(b).toLowerCase()),
    );

    // optional to choose yourself in selector (by default - false)
    const isSelfChoose = (user) => isChooseYourself || user.id !== currentUser.id;
    const userSearchResult = !isEmpty(searchValue)
      ? filteredUsers.filter(
          (user) =>
            (getUserFullName(user).toLowerCase().includes(searchValue.toLowerCase()) ||
              user.email.toLowerCase().includes(searchValue.toLowerCase())) &&
            isSelfChoose(user) &&
            !selectedUsers.map((u) => u.id).includes(user.id),
        )
      : $isFirstClick.value
      ? filteredUsers
          .filter((user) => isSelfChoose(user) && !selectedUsers.map((u) => u.id).includes(user.id))
          .slice(0, 10)
      : [];

    return (
      <SearchBlock
        userSearchResult={userSearchResult}
        onClick={(user) => {
          setSearchValue('');
          $isFirstClick.off();
          setSelectedUsers(isAskFeedback ? [...selectedUsers, user] : [user]);
        }}
      />
    );
  };

  const isShowSearchMembers = true; // always true for RTFeedback, before for reviews there was condition
  const isShowExternalEmails = isAskFeedback && !goal;

  return (
    <Modal
      title={typeValues.title(i18n, review?.name)} // review.name only for review related types
      onClose={close}
      width={750}
      footerRight={
        <Button
          disabled={
            !(selectedUsers.length || selectedEmails.length) ||
            (type === NOMINATE_TYPE.peer && isEmpty(message))
          }
          label={i18n._(t`Send`)}
          type="primary"
          onClick={handleOnSubmit}
          loading={$loading.value}
        />
      }
    >
      <ShowSpinnerIfLoading>
        <Container>
          <SectionContainer>
            <Title>{typeValues.memberTitle(i18n)}</Title>
            <Subtitle>{typeValues.memberSubtitle(i18n)}</Subtitle>
            {isShowSearchMembers && (
              <>
                <Wrap ref={ref}>
                  {!preselectedUser && (
                    <InputWrapper $searching={searchValue}>
                      <StyledSearchIcon size={24} fill={COLORS.SUBTEXT} />
                      <StyledInput
                        value={searchValue}
                        onClick={$isFirstClick.on}
                        onChange={changeSearchValue}
                        placeholder={i18n._(t`Search for people`)}
                      />
                    </InputWrapper>
                  )}
                  {(searchValue || $isFirstClick.value) && renderSearch()}
                </Wrap>
                {selectedUsers.map((user) => (
                  <UserBlock
                    key={user.id}
                    userId={user.id}
                    isDelete={!preselectedUser}
                    onClick={() => setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id))}
                  />
                ))}
              </>
            )}
          </SectionContainer>
          {isShowExternalEmails && (
            <ExternalEmailsBlock
              isShowAddExternalPeople={$showAddExternalPeople.value}
              subTitle={typeValues.externalPeopleTitle(i18n)}
              email={email}
              changeEmail={changeEmail}
              isEmailError={isEmailError(email)}
              onAddEmail={() => {
                setSelectedEmails([...selectedEmails, email]);
                setEmail('');
              }}
              removeEmail={removeEmail}
              selectedEmails={selectedEmails}
              onAddExternalPeople={$showAddExternalPeople.on}
            />
          )}
          <MessageBlock
            title={typeValues.messageTitle(i18n)}
            subtitle={typeValues.messageSubtitle ? typeValues.messageSubtitle(i18n) : null}
            placeholder={typeValues.messagePlaceholder(i18n)}
            message={message}
            setMessage={setMessage}
          />
          {isAskFeedback && (
            <GoalBlock
              disabled={!isEmpty(goal)}
              onChange={(e) => {
                setSelectedGoal(e[e.length - 1]);
              }}
              checkedList={[selectedGoal && selectedGoal].filter((i) => i)}
            />
          )}
        </Container>
      </ShowSpinnerIfLoading>
    </Modal>
  );
};

FeedbackModal.propTypes = {
  isChooseYourself: PropTypes.bool,
};

export default FeedbackModal;
