import React from 'react';

import isNil from 'lodash/isNil';
import styled, { css } from 'styled-components';

import { IconOld } from '~/components/IconOld';

import { COLORS } from '~/styles';

const IconPreview = styled.div<{ colors: string[]; size?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => (size ? `${size}px` : '46px')};
  min-width: ${({ size }) => (size ? `${size}px` : '46px')};
  height: ${({ size }) => (size ? `${size}px` : '46px')};
  box-sizing: border-box;
  ${({ colors }) =>
    colors?.length && colors.length > 1
      ? css`
          background: linear-gradient(to bottom, ${colors[0]}, ${colors[1]});
        `
      : css`
          background-color: ${colors[0]};
        `}
  border-radius: 6px;
`;

interface IIconWithGradientBlock {
  iconColor?: string | null;
  icon?: string | null;
  size?: number;
  iconSize?: number;
}

const IconWithGradientBlock = ({ iconColor, icon, size, iconSize }: IIconWithGradientBlock) => {
  return (
    <IconPreview
      colors={!isNil(iconColor) ? iconColor?.split('-') : '#f7f9ff-#ebf1fe'.split('-')}
      size={size}
    >
      {!isNil(icon) && (
        <IconOld
          // @ts-ignore
          name={icon}
          width={iconSize || 26}
          height={iconSize || 26}
          color={COLORS.MIDDLE_GRAY}
        />
      )}
    </IconPreview>
  );
};

export { IconWithGradientBlock };
