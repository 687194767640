import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import { PlaceholderWrapper } from './design';

import noDataImg from '~/assets/no-result.webp';

const EmptyStatePlaceholder = ({ isLoading }: { isLoading: boolean }) => {
  const { i18n } = useLingui();
  return (
    <PlaceholderWrapper>
      <ShowSpinnerIfLoading loading={isLoading}>
        <div className="img-container">
          <img src={noDataImg} alt={i18n._(t`No previous answers`)} width="216px" />
        </div>
        <span className="title">{i18n._(t`No previous answers`)}</span>
      </ShowSpinnerIfLoading>
    </PlaceholderWrapper>
  );
};

export default EmptyStatePlaceholder;
