import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Container = styled.div<{ color: string; background: string }>`
  display: flex;
  align-items: center;
  position: relative;
  min-height: 36px;
  box-sizing: border-box;

  padding: 8px 16px;
  border-radius: 6px;
  background-color: ${COLORS.WHITE};
  border-style: solid;
  border-width: 1px;
  border-color: ${({ color }) => color || 'transparent'};
  background-color: ${({ background }) => background || 'transparent'};
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const Title = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: ${COLORS.TEXT_HOVER};
`;

export const CounterBadge = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.SUBTEXT};
  border-radius: 16px;
  padding: 0 4px;
  font-size: 14px;
`;
