import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { ICONS } from '~/components/Icon';
import { IconWithGradientBlock } from '~/components/IconWithGradientBlock';
import Label from '~/components/Label';
import { ValidationMessage } from '~/components/UI/ValidationMessage';
import { ValidationMessageStatus } from '~/components/UI/ValidationMessage/types';
import { SkillCategorySection } from '~/pages/Reviews/components/JobsValidation/components/SkillCategorySection';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

import type { IThemeValidation } from '../../types';

const NameContainer = styled.div`
  .head {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
  }
  .content {
    padding: 16px 48px 0;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .title {
    font-size: 14px;
    line-height: 1.5;
    color: ${COLORS.TEXT_HOVER};
  }
`;

const ThemeWeightRow = styled.div`
  padding-bottom: 16px;
`;

const RotateIcon180 = styled.div`
  svg {
    transform: rotate(180deg);
  }
`;

const ThemeItems = styled.div``;

const ValidationMessageWrapper = styled.div`
  margin: 12px 0 0 0;
`;

export const ThemeSection = ({
  reviewTheme: { id, name, icon, iconColor },
  items,
}: IThemeValidation) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  return (
    <ThemeWeightRow>
      <NameContainer>
        <div className="head">
          <IconWithGradientBlock icon={icon} iconColor={iconColor} size={40} iconSize={20} />

          <NameWrapper>
            <span className="title">{getMultiLangString(name ?? '')}</span>
          </NameWrapper>
          <RotateIcon180>
            <Label color={COLORS.ACCENT_ERROR} icon={ICONS.INFO_3} backgroundByPercentage={30}>
              <Trans>Problem</Trans>
            </Label>
          </RotateIcon180>
        </div>
        <ValidationMessageWrapper>
          <ValidationMessage
            message={i18n._(t`Please fix the issues for at least 1 job`)}
            fixedCount={0}
            totalCount={1}
            status={ValidationMessageStatus.ERROR}
          />
        </ValidationMessageWrapper>

        <ThemeItems>
          {items.map(({ skillCategory, items }) => (
            <SkillCategorySection
              key={`${id}-${skillCategory?.id}`}
              skillCategory={skillCategory}
              items={items}
            />
          ))}
        </ThemeItems>
      </NameContainer>
    </ThemeWeightRow>
  );
};
