import { joiResolver } from '@hookform/resolvers/joi';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import Joi from 'joi';

const errors = {
  jobStartAfterEndDate: 'jobStartAfterEndDate',
  jobEndBeforeStartDate: 'jobEndBeforeStartDate',
  jobStartDateRequired: 'jobStartDateRequired',
};
export const useResolver = () => {
  const validateJobStartDate = (value: any, helpers: Joi.CustomHelpers<any>) => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    if (!value) {
      return helpers.error(errors.jobStartDateRequired);
    }

    const jobEndDate = helpers.state.ancestors[0].endDate;
    if (jobEndDate && value > jobEndDate) {
      return helpers.error(errors.jobStartAfterEndDate);
    }

    return value;
  };

  const validateJobEndDate = (value: any, helpers: Joi.CustomHelpers<any>) => {
    const jobStartDate = helpers.state.ancestors[0].startDate;

    if (!value) {
      return value;
    }

    if (jobStartDate && value < jobStartDate) {
      return helpers.error(errors.jobEndBeforeStartDate);
    }

    return value;
  };

  const schema = Joi.object({
    jobs: Joi.array()
      .items(
        Joi.object({
          jobId: Joi.string().required(),
          startDate: Joi.custom(validateJobStartDate),
          endDate: Joi.date().custom(validateJobEndDate),
          isPrimary: Joi.boolean().optional(),
        }),
      )
      .min(1),
    primaryJob: Joi.string().allow('').optional(),
  }).options({
    messages: {
      [errors.jobStartAfterEndDate]: i18n._(t`Start date cannot be after the end date`),
      [errors.jobEndBeforeStartDate]: i18n._(t`End date cannot be before the start date`),
      [errors.jobStartDateRequired]: i18n._(t`Start date is required`),
    },
  });
  const resolver = joiResolver(schema);

  return { resolver };
};
