import React from 'react';

import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Controller, type UseFormReturn } from 'react-hook-form';

import { Dropdown } from '~/components/Dropdown';
import type { IQuestionForm } from '~/pages/ReviewThemeSetup/types';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import { AnswerTypeContainer, Label, AnswerTypeWrapper } from '../EditableQuestion/design';

interface IAnswerTypeSelectorProps {
  answersList: { key: string; value: string; id?: string }[];
  formMethods: UseFormReturn<IQuestionForm>;
}

const AnswerTypeSelector = ({ answersList, formMethods }: IAnswerTypeSelectorProps) => {
  const getMultiLangString = useMultiLangString();
  const { control } = formMethods;

  return (
    <AnswerTypeContainer>
      <div>
        <Label>
          <Trans>Answer type</Trans>
        </Label>

        <Controller
          name="type"
          rules={{ required: true }}
          control={control}
          render={({ field: { value, onChange } }) => (
            <AnswerTypeWrapper>
              <Dropdown
                items={answersList}
                placeholder={i18n._(t`Answer type`)}
                isSingleSelect
                onChange={onChange}
                stringifyItem={(item) => getMultiLangString(item || '')}
                selectedItem={value}
                skipSort={true}
              />
            </AnswerTypeWrapper>
          )}
        />
      </div>
    </AnswerTypeContainer>
  );
};

export { AnswerTypeSelector };
