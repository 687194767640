import React, { useEffect, useState } from 'react';

import { GOAL_STATUSES, GOAL_TYPES, API_RETURN_FIELDS } from '@learned/constants';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import Modal from '~/components/Modal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import Tabs from '~/components/Tabs';

import RightColumnContent from './components/RightColumnContent';

import { RT_FEEDBACK_TYPES, TALKING_POINT_TYPES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import {
  checkModuleGoals,
  checkModuleLearning,
  checkModuleReviews,
  checkModuleRTFeedbacks,
  getTeams,
  getUser,
  getUsers,
} from '~/selectors/baseGetters';
import { getTemplatesOld } from '~/services/conversationTemplates';
import { getGoals } from '~/services/goals';
import { getUserReviewsByUser } from '~/services/publicProfile';
import {
  createRTFeedback,
  getAskedRTFeedbacks,
  getProvidedRTFeedbacks,
} from '~/services/RTFeedbacks';
import { getPersonalUserActivities } from '~/services/userActivities';
import { getUserPaths } from '~/services/userPaths';
import { COLOR_PALETTE, COLORS } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const TabsContainer = styled.div`
  overflow: auto;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`;

const SideTabContainer = styled.div`
  padding: 16px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: ${COLOR_PALETTE.DARK_GRAY};
  border-bottom: 1px solid ${COLORS.BG_PAGE};
  ${(props) =>
    props.$isSelected && `color: var(--company-color); background-color: ${COLORS.BG_PAGE};`}
  &:hover {
    color: var(--company-color);
    background-color: ${COLORS.BG_PAGE};
    cursor: pointer;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  height: 600px;
  display: flex;
  border-left: 1px solid ${COLORS.BORDER_HARD};
  overflow: auto;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: row-reverse;
  padding: 24px;
`;

const notCustomTPTypes = Object.values(TALKING_POINT_TYPES)
  .filter(
    (t) => ![TALKING_POINT_TYPES.CUSTOM.key, TALKING_POINT_TYPES.TEMPLATES.key].includes(t.key),
  )
  .map((t) => t.key);

const initialTabs = {
  GENERAL: { label: t`General`, key: 'general' },
};

const SIDE_TAB_KEYS = {
  custom: 'custom',
  templates: 'templates',
  goals: 'goals',
  learningPaths: 'paths',
  learningActivities: 'activities',
  rtFeedback: 'rtFeedback',
  reviews: 'reviews',
};

const sideTabs = (
  generalTab,
  isCoach = false,
  isModuleGoalsEnabled,
  isModuleFeedbackEnabled,
  isModuleLearningEnabled,
  isModuleReviewsEnabled,
) =>
  [
    generalTab && {
      label: (i18n) => i18n._(t`Create new`),
      key: SIDE_TAB_KEYS.custom,
    },
    generalTab && {
      label: (i18n) => i18n._(t`Templates`),
      key: SIDE_TAB_KEYS.templates,
    },
    isCoach &&
      isModuleGoalsEnabled && {
        label: (i18n) => i18n._(t`Goals`),
        key: SIDE_TAB_KEYS.goals,
      },
    isCoach &&
      isModuleLearningEnabled && {
        label: (i18n) => i18n._(t`Learning paths`),
        key: SIDE_TAB_KEYS.learningPaths,
      },
    isCoach &&
      isModuleLearningEnabled && {
        label: (i18n) => i18n._(t`Learning activities`),
        key: SIDE_TAB_KEYS.learningActivities,
      },
    isCoach &&
      isModuleReviewsEnabled && {
        label: (i18n) => i18n._(t`Reviews`),
        key: SIDE_TAB_KEYS.reviews,
      },
    !generalTab &&
      isModuleFeedbackEnabled && {
        label: (i18n) => i18n._(t`Feedback`),
        key: SIDE_TAB_KEYS.rtFeedback,
      },
  ].filter((i) => i);

const AddTalkingPointsModal = ({ onClose, i18n, participants = [], disabledItems, onSubmit }) => {
  const [tabs, setTabs] = useState();
  const [currentTab, setCurrentTab] = useState(initialTabs.GENERAL.key);
  const [currentSideTab, setCurrentSideTab] = useState(sideTabs(true, false)[0].key);
  const [templates, setTemplates] = useState([]);
  const [goals, setGoals] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [paths, setPaths] = useState([]);
  const [activities, setActivities] = useState([]);
  const [customItems, setCustomItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState({});
  const [RTFeedbacks, setRTFeedbacks] = useState([]);
  const $loading = useBoolState(false);
  const users = useSelector(getUsers);
  const currentUser = useSelector(getUser);
  const teams = useSelector(getTeams);
  const $isCoach = useBoolState(false);
  const isModuleFeedbackEnabled = useSelector(checkModuleRTFeedbacks);
  const isModuleGoalsEnabled = useSelector(checkModuleGoals);
  const isModuleLearningEnabled = useSelector(checkModuleLearning);
  const isModuleReviewsEnabled = useSelector(checkModuleReviews);
  const [expandedRTFB, setExpandedRTFB] = useState('');
  const [selectedAutoCompleteFilters, setSelectedAutoCompleteFilters] = useState([]);
  const [itemFilter, setItemFilter] = useState(() => (i) => i);

  const toggleItem = (item = {}) => {
    const newSelectedItems = { ...selectedItems };
    const target = [TALKING_POINT_TYPES.CUSTOM.key, TALKING_POINT_TYPES.TEMPLATES.key].includes(
      item.type,
    )
      ? item.name
      : item.target;
    if (selectedItems[target]) {
      delete newSelectedItems[target];
    } else {
      newSelectedItems[target] = {
        ...item,
        targetUser: [TALKING_POINT_TYPES.CUSTOM.key, TALKING_POINT_TYPES.TEMPLATES.key].includes(
          item.type,
        )
          ? null
          : currentTab,
      };
    }
    setSelectedItems(newSelectedItems);
  };

  const toggleItems = (value, items = []) => {
    const newSelectedItems = { ...selectedItems };
    items.forEach((item) => {
      const target = [TALKING_POINT_TYPES.CUSTOM.key, TALKING_POINT_TYPES.TEMPLATES.key].includes(
        item.type,
      )
        ? item.name
        : item.target;
      if (value) {
        newSelectedItems[target] = {
          ...item,
          targetUser: item.targetUser
            ? item.targetUser
            : [TALKING_POINT_TYPES.CUSTOM.key, TALKING_POINT_TYPES.TEMPLATES.key].includes(
                item.type,
              )
            ? null
            : currentTab,
        };
      } else {
        delete newSelectedItems[target];
      }
    });
    setSelectedItems(newSelectedItems);
  };

  useEffect(() => {
    const fetchData = async () => {
      const fetchedTemplates = await getTemplatesOld();
      setTemplates(Object.values(fetchedTemplates));
    };
    fetchData();
  }, []);

  useEffect(() => {
    let newTabs = { ...initialTabs };
    if (
      isModuleLearningEnabled ||
      isModuleFeedbackEnabled ||
      isModuleGoalsEnabled ||
      isModuleReviewsEnabled
    ) {
      participants
        .filter((i) => i)
        .forEach((participant) => {
          const user = users[participant];
          const isCoach = Object.values(teams).find(
            (t) => t.members.includes(participant) && t.coaches.includes(currentUser.id),
          );
          if (user && (isCoach || participant === currentUser.id || isModuleFeedbackEnabled)) {
            newTabs[user.id] = {
              label: user.firstName || user.email,
              key: user.id,
            };
          }
        });
    }
    setTabs(Object.values(newTabs));
  }, [
    currentUser.id,
    teams,
    isModuleLearningEnabled,
    isModuleFeedbackEnabled,
    isModuleGoalsEnabled,
    isModuleReviewsEnabled,
    participants,
    users,
  ]);

  const fetchTPs = async (userId, fullRights) => {
    $loading.on();
    setGoals([]);
    setPaths([]);
    setActivities([]);
    setRTFeedbacks([]);
    const [
      fetchedGoals,
      fetchedPathsData,
      fetchedActivities,
      fetchedReviews,
      askedRTFB,
      providedRTFB,
    ] = await Promise.all([
      fullRights &&
        getGoals({
          types: [GOAL_TYPES.BUSINESS, GOAL_TYPES.PERSONAL],
          isMyGoalsOnly: true,
          forUser: userId,
          statuses: filter(GOAL_STATUSES, (i) => i !== GOAL_STATUSES.DRAFT), // filter out draft goals
        }),
      fullRights && getUserPaths(userId),
      fullRights && getPersonalUserActivities({ userId }),
      fullRights && getUserReviewsByUser(userId),
      getAskedRTFeedbacks(['goal'], null, null, ['skills']),
      userId !== currentUser.id && getProvidedRTFeedbacks(['goal'], null, null, ['skills']),
    ]);

    const fetchedRTFeedbacks = [...Object.values(askedRTFB), ...Object.values(providedRTFB)]
      .filter((i) => {
        const isCurrentUserTab = currentUser.id === userId;
        const isAsk = i.type === RT_FEEDBACK_TYPES.ASK_FEEDBACK.key;
        const isGive = i.type === RT_FEEDBACK_TYPES.GIVE_FEEDBACK.key;
        let isAsked;
        let isProvidedUnprompt;

        if (isCurrentUserTab) {
          // in own tab user should see his asked feedbacks from all people (not only from 1-1 participants)
          // and feedback given by other people unprompted (without asking)
          isAsked = isAsk && i.createdBy === userId;
          isProvidedUnprompt = isGive && i.createdFor === userId;
        } else {
          // in a foreign tab - only asked RTF between currentUser and tab owner (no other users!!!)
          // and give RTF unprompted (without asking) from currentUser to tab owner (no other unprompted rtf);

          // isAsked = we check here only subRTFeedback (i.originalRTFeedback must have)
          // in subRTFeedback
          // createdBy - user who is asking
          // createdFor - user who need to give answer
          isAsked =
            isAsk &&
            i.originalRTFeedback &&
            i.createdBy === userId &&
            i.createdFor === currentUser.id;
          isProvidedUnprompt = isGive && i.createdFor === userId && i.createdBy === currentUser.id;
        }
        return isAsked || isProvidedUnprompt;
      })
      .reverse();

    const fetchedPaths = !isEmpty(fetchedPathsData)
      ? fetchedPathsData?.data[API_RETURN_FIELDS.USER_PATHS]
      : {};
    setGoals(Object.values(fetchedGoals?.data || {}));
    setPaths(Object.values(fetchedPaths));
    setActivities(Object.values(fetchedActivities));
    setReviews(Object.values(fetchedReviews));
    setRTFeedbacks(fetchedRTFeedbacks);
    setExpandedRTFB(fetchedRTFeedbacks[0]?.id || '');
    $loading.off();
  };

  // we use it when user give feedback in TabRTFeedback
  const onRTFeedbackUpdate = (rtfeedbackId, data) => {
    setRTFeedbacks(RTFeedbacks.map((rtf) => (rtf.id === rtfeedbackId ? { ...rtf, ...data } : rtf)));
  };

  const handleChangeTab = (e) => {
    if (e === currentTab) {
      return;
    }
    const isCoach = Object.values(teams).find(
      (t) => t.members.includes(e) && t.coaches.includes(currentUser.id),
    );
    const isCurrentUser = currentUser.id === e;
    if (e !== initialTabs.GENERAL.key) {
      $isCoach.set(isCoach || isCurrentUser);
      fetchTPs(e, isCoach || isCurrentUser);
    } else {
      $loading.off();
      $isCoach.off();
    }
    setCurrentTab(e);
    setCurrentSideTab(
      isCoach || isCurrentUser
        ? SIDE_TAB_KEYS.goals
        : e === initialTabs.GENERAL.key
        ? SIDE_TAB_KEYS.custom
        : SIDE_TAB_KEYS.rtFeedback,
    );
  };

  const createCustomItem = (name) => {
    const newSelectedItems = { ...selectedItems };
    const item = {
      type: TALKING_POINT_TYPES.CUSTOM.key,
      name,
      target: '',
      targetUser: null,
    };
    newSelectedItems[name] = item;
    setSelectedItems(newSelectedItems);
    setCustomItems([...customItems, item]);
  };

  const createRTFB = async (description) => {
    const data = {
      createdFor: currentTab,
      type: RT_FEEDBACK_TYPES.GIVE_FEEDBACK.key,
      feedback: description,
    };
    const RTFB = await createRTFeedback(data);
    const item = {
      type: TALKING_POINT_TYPES.RTFEEDBACK.key,
      name: RTFB.feedback,
      target: RTFB.id,
      targetUser: RTFB.createdFor,
    };
    toggleItem(item);
    setRTFeedbacks([RTFB, ...RTFeedbacks]);
  };

  const onAddTP = async () => {
    const isNotCustomOrTemplateTP = Object.values(selectedItems).find((i) =>
      notCustomTPTypes.includes(i.type),
    );
    const onSave = () => {
      onSubmit(Object.values(selectedItems).filter((i) => !disabledItems.includes(i.name)));
      onClose();
    };
    if (!isEmpty(isNotCustomOrTemplateTP)) {
      if (
        await confirm(
          i18n,
          i18n._(
            t`All talking points will be visible to all paticipants. Do you want to continue?`,
          ),
        )
      ) {
        onSave();
      }
    } else {
      onSave();
    }
  };

  return (
    <Modal
      title={i18n._(t`Add talking points`)}
      onClose={onClose}
      hideFooter
      contentStyles={{
        padding: 0,
        borderRadius: 0,
      }}
      width={750}
    >
      <ShowSpinnerIfLoading loading={isEmpty(tabs)}>
        <TabsContainer>
          <Tabs items={tabs} isSmall currentItem={currentTab} handleChangeTab={handleChangeTab} />
        </TabsContainer>
        <Wrapper>
          <LeftColumn>
            {sideTabs(
              currentTab === initialTabs.GENERAL.key,
              $isCoach.value,
              isModuleGoalsEnabled,
              isModuleFeedbackEnabled,
              isModuleLearningEnabled,
              isModuleReviewsEnabled,
            ).map((tab) => {
              return (
                <SideTabContainer
                  $isSelected={tab.key === currentSideTab}
                  onClick={() => {
                    setSelectedAutoCompleteFilters([]);
                    setItemFilter(() => (i) => i);
                    setCurrentSideTab(tab.key);
                  }}
                  key={tab.key}
                >
                  {tab.label(i18n)}
                </SideTabContainer>
              );
            })}
          </LeftColumn>
          <RightColumn>
            <RightColumnContent
              currentSideTab={currentSideTab}
              RTFeedbacks={RTFeedbacks}
              disabledItems={disabledItems}
              onRTFeedbackUpdate={onRTFeedbackUpdate}
              selectedItems={selectedItems}
              toggleItem={toggleItem}
              toggleItems={toggleItems}
              user={users[currentTab]}
              createRTFB={createRTFB}
              loading={$loading.value}
              expandedRTFB={expandedRTFB}
              templates={templates}
              activities={activities}
              paths={paths}
              selectedAutoCompleteFilters={selectedAutoCompleteFilters}
              setSelectedAutoCompleteFilters={setSelectedAutoCompleteFilters}
              setItemFilter={setItemFilter}
              goals={goals}
              customItems={customItems}
              reviews={reviews}
              createCustomItem={createCustomItem}
              itemFilter={itemFilter}
            />
            <ButtonContainer>
              <Button
                label={`${i18n._(t`Add`)} ${
                  Object.values(selectedItems).length
                    ? `(${Object.values(selectedItems).length})`
                    : ''
                }`}
                type="primary"
                disabled={!Object.values(selectedItems).length}
                onClick={() => onAddTP()}
              />
            </ButtonContainer>
          </RightColumn>
        </Wrapper>
      </ShowSpinnerIfLoading>
    </Modal>
  );
};

export default withI18n()(AddTalkingPointsModal);
