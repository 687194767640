import React from 'react';

import { REVIEW_PARTICIPATION_COLUMNS, TASK_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { CellViewProgress } from './CellViewProgress';
import { CellViewTaskStatus } from './CellViewTaskStatus';

import type { ICell } from '../types';

interface ICellTeamProps {
  cell: ICell;
}

const CellTeam = ({ cell }: ICellTeamProps) => {
  const { i18n } = useLingui();
  const isCompleted = cell?.value === '100';
  switch (cell?.columnId) {
    case REVIEW_PARTICIPATION_COLUMNS.SELF_REVIEW:
    case REVIEW_PARTICIPATION_COLUMNS.PEER_REVIEW:
    case REVIEW_PARTICIPATION_COLUMNS.COACH_REVIEW:
    case REVIEW_PARTICIPATION_COLUMNS.NOMINATED_PEERS:
    case REVIEW_PARTICIPATION_COLUMNS.PEER_REVIEW_PROVIDED:
    case REVIEW_PARTICIPATION_COLUMNS.COACH_REVIEW_PROVIDED:
    case REVIEW_PARTICIPATION_COLUMNS.COMPLETED_REVIEW:
      return isCompleted ? (
        <CellViewTaskStatus
          columnId={cell.columnId}
          status={TASK_STATUS.COMPLETED}
          withWarning={cell.withWarning}
          isWarningCounter={true}
          warningTooltip={i18n._(t`No guests`)}
        />
      ) : (
        <CellViewProgress
          cell={cell}
          withWarning={cell.withWarning}
          isWarningCounter={true}
          warningTooltip={i18n._(t`No guests`)}
        />
      );
    case REVIEW_PARTICIPATION_COLUMNS.SIGNED: {
      return isCompleted ? (
        <CellViewTaskStatus
          columnId={cell.columnId}
          status={TASK_STATUS.COMPLETED}
          withWarning={cell.withWarning}
          isWarningCounter={true}
          warningTooltip={i18n._(t`Not agreed`)}
        />
      ) : (
        <CellViewProgress
          cell={cell}
          withWarning={cell.withWarning}
          isWarningCounter={true}
          warningTooltip={i18n._(t`Not agreed`)}
        />
      );
    }
    default: {
      return isCompleted ? (
        <CellViewTaskStatus columnId={cell.columnId} status={TASK_STATUS.COMPLETED} />
      ) : (
        <CellViewProgress cell={cell} withWarning={cell.withWarning} isWarningCounter={true} />
      );
    }
  }
};

export { CellTeam };
