import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '~/components/Button';
import ButtonFile from '~/components/ButtonFile';

import LearnedEmailDefaultLogo from '~/assets/learned-logo/learned_logo_new.webp';

import { WrapperPad, CompanyLogoEmail } from '../../design';

interface ILogoForEmailProps {
  value: string | undefined;
  onChange: (file: File | null) => void;
  onReset: () => void;
}

const LogoForEmail = ({ value, onChange, onReset }: ILogoForEmailProps) => {
  const { i18n } = useLingui();

  return (
    <>
      <WrapperPad>
        <CompanyLogoEmail
          style={{
            backgroundImage: value ? `url(${value})` : `url(${LearnedEmailDefaultLogo})`,
          }}
        />
      </WrapperPad>

      <WrapperPad>
        <Button
          label={i18n._(t`Reset`)}
          type="primary-border"
          onClick={onReset}
          disabled={!value}
        />
      </WrapperPad>

      <WrapperPad>
        {/* @ts-ignore */}
        <ButtonFile onChange={onChange} />
      </WrapperPad>
    </>
  );
};

export { LogoForEmail };
