import React from 'react';

import {
  GOAL_CYCLES_VIRTUAL_STATUSES,
  GOAL_TYPES,
  API_RETURN_FIELDS,
  LEARNING_LIBRARY_SHOW_FILTER_OPTIONS,
  SURVEY_THEME_STATUS,
  REVIEW_THEME_STATUS,
  USER_REVIEW_REQUEST_TYPE,
} from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import AutocompleteFilter, { AutocompleteFilterTypes } from '~/components/AutocompleteFilter';
import StringInfinite from '~/components/StringInfinite';

import {
  ACTIVITY_TYPES,
  CONVERSATION_FILTER_STATUSES,
  JOB_PROFILE_STATUSES,
  SORT_BY,
} from '~/constants';
import { LEARNING_LIBRARY_SORT_BY } from '~/constants/learningLibrary';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser } from '~/selectors/baseGetters';
import { getActivityCategories } from '~/services/activityCategories';
import { getConversationsFilter } from '~/services/conversations';
import { getGoalCycles } from '~/services/goalCycles';
import { getGoals } from '~/services/goals';
import { getJobProfiles } from '~/services/jobProfiles';
import { getReviewThemes } from '~/services/reviewThemes';
import { getRoleRelevancies } from '~/services/roleRelevancies';
import { getSkills } from '~/services/skills';
import { getSurveyThemes } from '~/services/surveyThemes';
import { getTeams } from '~/services/teams';
import { getUserReviews } from '~/services/userReviews';
import { getCompanyUsers } from '~/services/users';
import convertToTimeString from '~/utils/convertToTimeString';
import getUserFullName from '~/utils/getUserFullName';

const LIMIT = 20;

export const AutocompleteFilterTeams = (props) => {
  const fetchTeams = (search) => {
    return getTeams({
      limit: LIMIT,
      search,
      ...(props.isPermissionsCheck && { isPermissionsCheck: props.isPermissionsCheck }),
    });
  };

  return (
    <AutocompleteFilter
      placeholder={props.placeholder || <Trans>Teams</Trans>}
      fetch={fetchTeams}
      {...props}
    />
  );
};

AutocompleteFilterTeams.propTypes = {
  ...AutocompleteFilterTypes,
  isPermissionsCheck: PropTypes.bool,
};

export const AutocompleteFilterRoles = (props) => {
  const getMultiLangString = useMultiLangString();

  const fetchJobProfiles = async (search) => {
    const jobProfiles = await getJobProfiles(
      {
        search,
        status: JOB_PROFILE_STATUSES.ACTIVE.key,
        published: true,
      },
      { limit: LIMIT },
    );

    // Override correct multi lang string for name
    Object.values(jobProfiles).forEach((jp) => {
      jp.name = getMultiLangString(jp.name);
    });

    return jobProfiles;
  };

  return (
    <AutocompleteFilter
      placeholder={props.placeholder || <Trans>Roles: All</Trans>}
      fetch={fetchJobProfiles}
      {...props}
    />
  );
};

AutocompleteFilterRoles.propTypes = {
  ...AutocompleteFilterTypes,
};

export const AutocompleteFilterGoals = (props) => {
  const fetchGoals = async () => {
    const fetchedGoals = await getGoals({
      types: [GOAL_TYPES.BUSINESS, GOAL_TYPES.PERSONAL],
      isMyGoalsOnly: true,
    });
    return fetchedGoals?.data || [];
  };

  return (
    <AutocompleteFilter
      placeholder={props.placeholder || <Trans>Goals</Trans>}
      fetch={fetchGoals}
      {...props}
      disableSearch={true}
    />
  );
};

AutocompleteFilterGoals.propTypes = {
  ...AutocompleteFilterTypes,
};

export const AutocompleteFilterActivityTypes = (props) => {
  const { i18n } = useLingui();
  const fetchTypes = (search) => {
    return {
      ...Object.values(ACTIVITY_TYPES)
        .filter((i) => {
          return i.translated(i18n).toLowerCase().includes(search.toLowerCase());
        })
        .map((i) => ({ ...i, id: i.key })),
    };
  };

  return (
    <AutocompleteFilter
      placeholder={props.placeholder || <Trans>Types: All</Trans>}
      fetch={fetchTypes}
      labelProperty={(i) => (i.translated ? i.translated(i18n) : i.name)}
      labelPropertyReserve={'name'}
      {...props}
    />
  );
};

AutocompleteFilterActivityTypes.propTypes = {
  ...AutocompleteFilterTypes,
};

export const AutocompleteFilterActivitySources = (props) => {
  const { i18n } = useLingui();
  const fetchSources = (search) => {
    return [
      {
        id: 'learned',
        key: 'learned',
        name: 'Learned',
        translated: (i18n) => i18n._(t`Learned`),
      },
      {
        id: 'company',
        key: 'company',
        name: 'Company',
        translated: (i18n) => i18n._(t`Company`),
      },
    ].filter((i) => i.translated(i18n).toLowerCase().includes(search.toLowerCase()));
  };

  return (
    <AutocompleteFilter
      placeholder={props.placeholder || <Trans>Library: All</Trans>}
      fetch={fetchSources}
      labelProperty={(i) => (i.translated ? i.translated(i18n) : i.name)}
      labelPropertyReserve={'name'}
      {...props}
    />
  );
};

AutocompleteFilterActivitySources.propTypes = {
  ...AutocompleteFilterTypes,
};

export const AutocompleteFilterActivityShowEditors = (props) => {
  const { i18n } = useLingui();
  const user = useSelector(getUser);
  const fetchEditors = (search) => {
    return [
      {
        id: LEARNING_LIBRARY_SHOW_FILTER_OPTIONS.I_CAN_EDIT,
        key: LEARNING_LIBRARY_SHOW_FILTER_OPTIONS.I_CAN_EDIT,
        name: 'Learnings I can edit',
        translated: (i18n) => i18n._(t`Learnings I can edit`),
      },
      {
        id: LEARNING_LIBRARY_SHOW_FILTER_OPTIONS.NO_EDITORS,
        key: LEARNING_LIBRARY_SHOW_FILTER_OPTIONS.NO_EDITORS,
        name: 'Learnings without editors',
        translated: (i18n) => i18n._(t`Learnings without editors`),
      },
      user.isAdmin && {
        id: LEARNING_LIBRARY_SHOW_FILTER_OPTIONS.ALL_USERS,
        key: LEARNING_LIBRARY_SHOW_FILTER_OPTIONS.ALL_USERS,
        name: 'Learnings visible to all users',
        translated: (i18n) => i18n._(t`Learnings visible to all users`),
      },
    ]
      .filter((i) => i)
      .filter((i) => i.translated(i18n).toLowerCase().includes(search.toLowerCase()));
  };

  return (
    <AutocompleteFilter
      placeholder={props.placeholder || <Trans>Show: All</Trans>}
      fetch={fetchEditors}
      labelProperty={(i) => (i.translated ? i.translated(i18n) : i.name)}
      labelPropertyReserve={'name'}
      showTooltip
      {...props}
    />
  );
};

AutocompleteFilterActivityShowEditors.propTypes = {
  ...AutocompleteFilterTypes,
};

export const AutocompleteFilterCategories = (props) => {
  const fetchCategories = async (search) => {
    const categories = await getActivityCategories({
      search,
    });
    return categories?.data[API_RETURN_FIELDS.ACTIVITY_CATEGORIES];
  };

  return (
    <AutocompleteFilter
      placeholder={props.placeholder || <Trans>Categories</Trans>}
      fetch={fetchCategories}
      labelProperty={'name'}
      labelPropertyReserve={'name'}
      {...props}
    />
  );
};

AutocompleteFilterCategories.propTypes = {
  ...AutocompleteFilterTypes,
};

export const AutocompleteFilterRelevancies = (props) => {
  const fetchRelevancies = async (search) => {
    const result = await getRoleRelevancies(undefined, {
      search,
    });
    return result.data.roleRelevancies;
  };

  return (
    <AutocompleteFilter
      placeholder={props.placeholder || <Trans>Relevancies</Trans>}
      fetch={fetchRelevancies}
      labelProperty={'name'}
      labelPropertyReserve={'name'}
      {...props}
    />
  );
};

AutocompleteFilterRelevancies.propTypes = {
  ...AutocompleteFilterTypes,
};

export const AutocompleteFilterSkills = (props) => {
  const getMultiLangString = useMultiLangString();
  const fetchSkills = (search) => {
    const response = getSkills({
      search,
      limit: LIMIT,
    });
    return response?.data?.skills;
  };

  return (
    <AutocompleteFilter
      placeholder={props.placeholder || <Trans>Skill(s): All</Trans>}
      fetch={fetchSkills}
      labelProperty={(item) => getMultiLangString(item?.name)}
      {...props}
    />
  );
};

AutocompleteFilterSkills.propTypes = {
  ...AutocompleteFilterTypes,
};

export const AutocompleteFilterReviews = (props) => {
  const getMultiLangString = useMultiLangString();
  const fetchReviews = async (search) => {
    const response = await getUserReviews({
      filters: {
        search,
      },
      options: {
        skip: 0,
        limit: LIMIT,
      },
      type: USER_REVIEW_REQUEST_TYPE.ALL,
    });
    return response?.data?.userReviews || [];
  };

  return (
    <AutocompleteFilter
      showTooltip
      placeholder={props.placeholder || <Trans>Reviews</Trans>}
      fetch={fetchReviews}
      labelProperty={(userReview) => getMultiLangString(userReview?.name)}
      {...props}
    />
  );
};

AutocompleteFilterReviews.propTypes = {
  ...AutocompleteFilterTypes,
};

export const AutocompleteThemes = (props) => {
  const getMultiLangString = useMultiLangString();
  const fetchThemes = async (search) => {
    const { data } = await getSurveyThemes({
      limit: LIMIT,
      search,
      ...(props.status && { status: props.status }),
    });
    return data?.surveyThemes;
  };

  return (
    <AutocompleteFilter
      showTooltip
      labelProperty={(theme) => getMultiLangString(theme?.name)}
      placeholder={props.placeholder || <Trans>Themes</Trans>}
      fetch={fetchThemes}
      {...props}
    />
  );
};

AutocompleteThemes.propTypes = {
  ...AutocompleteFilterTypes,
  status: PropTypes.oneOf(Object.values(SURVEY_THEME_STATUS)),
};

export const AutocompleteReviewThemes = (props) => {
  const getMultiLangString = useMultiLangString();
  const fetchThemes = async (search) => {
    const { data } = await getReviewThemes({
      limit: LIMIT,
      ...(search && { search }),
      ...(props.status && { status: [props.status] }),
    });
    return [...(data?.reviewThemes || [])];
  };

  return (
    <AutocompleteFilter
      showTooltip
      labelProperty={(theme) => getMultiLangString(theme?.name)}
      placeholder={props.placeholder || <Trans>Themes</Trans>}
      fetch={fetchThemes}
      {...props}
    />
  );
};

AutocompleteReviewThemes.propTypes = {
  ...AutocompleteFilterTypes,
  status: PropTypes.oneOf(Object.values(REVIEW_THEME_STATUS)),
};

export const AutocompleteFilterConversations = (props) => {
  const fetchConversations = async (search) => {
    const { data } = await getConversationsFilter({
      limit: LIMIT,
      search,
    });
    return data?.conversations;
  };

  return (
    <AutocompleteFilter
      showTooltip
      placeholder={props.placeholder || <Trans>1-1</Trans>}
      fetch={fetchConversations}
      {...props}
    />
  );
};

AutocompleteFilterReviews.propTypes = {
  ...AutocompleteFilterTypes,
};

export const AutocompleteFilterMembers = (props) => {
  const fetchMembers = async (search) => {
    const { data } = await getCompanyUsers({
      limit: LIMIT,
      search,
    });
    const members = data?.users ?? {};

    Object.values(members).forEach((m) => {
      m.name = getUserFullName(m);
    });

    return members;
  };

  return (
    <AutocompleteFilter
      placeholder={props.placeholder || <Trans>Members</Trans>}
      fetch={fetchMembers}
      {...props}
    />
  );
};

AutocompleteFilterMembers.propTypes = {
  ...AutocompleteFilterTypes,
};

function getGoalCyclesLabel(goalCycle) {
  return `${goalCycle.name} ${convertToTimeString(goalCycle.startDate)} | ${convertToTimeString(
    goalCycle.endDate,
  )}`;
}

export const AutocompleteFilterGoalCycles = React.memo((props) => {
  const fetchGoalCycles = async (search) => {
    return await getGoalCycles({
      limit: LIMIT,
      search,
      excludes: props.excludes,
      type: props.type,
    });
  };

  return (
    <AutocompleteFilter
      labelProperty={getGoalCyclesLabel}
      placeholder={props.placeholder || <Trans>Goal cycle</Trans>}
      fetch={fetchGoalCycles}
      {...props}
    />
  );
});

AutocompleteFilterGoalCycles.displayName = 'AutocompleteFilterGoalCycles';
AutocompleteFilterGoalCycles.propTypes = {
  ...AutocompleteFilterTypes,
  type: PropTypes.oneOf(Object.values(GOAL_CYCLES_VIRTUAL_STATUSES)),
};

export const AutocompleteFilterActivityCategories = (props) => {
  const fetchActivityCategories = async (search) => {
    const categories = await getActivityCategories({
      search,
      limit: LIMIT,
    });
    return categories?.data[API_RETURN_FIELDS.ACTIVITY_CATEGORIES];
  };

  return (
    <AutocompleteFilter
      placeholder={props.placeholder || <Trans>Categories: All</Trans>}
      fetch={fetchActivityCategories}
      isClosed={props.isClosed}
      {...props}
    />
  );
};

AutocompleteFilterActivityCategories.propTypes = {
  ...AutocompleteFilterTypes,
};

export const AutocompleteFilterSortBy = (props) => {
  const { activeFilters } = props;
  const { i18n } = useLingui();
  const fetchSortBy = (search) => {
    return {
      ...Object.values(SORT_BY).filter((i) => {
        return (
          i.label(i18n).toLowerCase().includes(search.toLowerCase()) && activeFilters.includes(i.id)
        );
      }),
    };
  };

  return (
    <AutocompleteFilter
      placeholder={props.placeholder || <Trans>Sort By</Trans>}
      fetch={fetchSortBy}
      labelProperty={(i) => i && i.label(i18n)}
      labelPropertyReserve={'Sort'}
      {...props}
    />
  );
};

AutocompleteFilterActivityCategories.propTypes = {
  activeFilters: PropTypes.array,
  ...AutocompleteFilterTypes,
};

export const AutocompleteFilterLearningLibraryCollections = (props) => {
  const { i18n } = useLingui();

  const fetchTypes = (search) => {
    return [
      {
        id: 'paths',
        key: 'paths',
        name: 'Paths',
        translated: (i18n) => i18n._(t`Paths`),
      },
      {
        id: 'activities',
        key: 'activities',
        name: 'Activities',
        translated: (i18n) => i18n._(t`Activities`),
      },
    ].filter((i) => {
      return i.translated(i18n).toLowerCase().includes(search.toLowerCase());
    });
  };

  return (
    <AutocompleteFilter
      placeholder={
        props.placeholder || (
          <StringInfinite maxWidth="125px">
            <Trans>Paths & Activities</Trans>
          </StringInfinite>
        )
      }
      fetch={fetchTypes}
      labelProperty={(i) => (i.translated ? i.translated(i18n) : i.name)}
      labelPropertyReserve={'name'}
      {...props}
    />
  );
};

AutocompleteFilterLearningLibraryCollections.propTypes = {
  ...AutocompleteFilterTypes,
};

export const AutocompleteFilterLearningLibrarySort = (props) => {
  const { i18n } = useLingui();

  const fetchTypes = (search) => {
    return (props.items || Object.values(LEARNING_LIBRARY_SORT_BY)).filter((i) =>
      i.translated(i18n).toLowerCase().includes(search.toLowerCase()),
    );
  };

  return (
    <AutocompleteFilter
      placeholder={props.placeholder || <Trans>Sort by</Trans>}
      fetch={fetchTypes}
      labelProperty={(i) => (i.translated ? i.translated(i18n) : i.name)}
      labelPropertyReserve={'name'}
      {...props}
    />
  );
};

AutocompleteFilterLearningLibrarySort.propTypes = {
  ...AutocompleteFilterTypes,
};

/**
 * @deprecated Should not be used anymore
 */
export const AutocompleteFilterConversationStatus = ({ checkedList, ...props }) => {
  const { i18n } = useLingui();

  const checkedListPopulated = (checkedList || {}).map((checkedItem) =>
    Object.values(CONVERSATION_FILTER_STATUSES).find((i) => i.id === checkedItem.id),
  );

  const fetchTypes = (search) => {
    return Object.values(CONVERSATION_FILTER_STATUSES).filter((i) =>
      i.name(i18n).toLowerCase().includes(search.toLowerCase()),
    );
  };

  return (
    <AutocompleteFilter
      placeholder={props.placeholder || <Trans>Status</Trans>}
      fetch={fetchTypes}
      labelProperty={(i) => i.name(i18n)}
      labelPropertyReserve={'name'}
      checkedList={checkedListPopulated}
      {...props}
    />
  );
};

AutocompleteFilterConversationStatus.propTypes = {
  ...AutocompleteFilterTypes,
};
