import React, { useRef, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import size from 'lodash/size';
import { Controller, useForm } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Editor from '~/components/Editor';
import Modal from '~/components/Modal';
import type { IGeneralForm } from '~/pages/SkillSetsOverview/SkillForm/types';
import type { TTableData } from '~/pages/SkillSetsOverview/types';

import { Header } from './CommonSkillModal.design';
import {
  Content,
  Footer,
  Subtitle,
  Title,
  SecondaryTitle,
  InputContainer,
} from './EditSkillDefinitionModal.design';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { updateSkill } from '~/services/skills';
import { removeEmptyValues } from '~/utils/multiLangString';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

type EditSkillDefinitionModalProps = {
  onClose: () => void;
  onSave: () => Promise<void>;
  skill: TTableData;
  languageState: ILanguageStateReturn;
};

function EditSkillDefinitionModal({
  onClose,
  languageState,
  skill,
  onSave,
}: EditSkillDefinitionModalProps) {
  const { i18n } = useLingui();
  const [isLoading, setIsLoading] = useState(false);
  const getMultiLangString = useMultiLangString();
  const generalFormMethods = useForm<IGeneralForm>({
    mode: 'all',
    defaultValues: {
      description: languageState.companyLanguages.map(({ locale }) => ({
        locale,
        value: skill?.description?.[locale] ?? '',
      })),
    },
  });
  const { control, unregister, register } = generalFormMethods;
  const descriptionFieldArray = useMultiLangFieldArray({
    name: 'description',
    control,
    unregister,
    languageState,
  });

  const handleSave = async () => {
    setIsLoading(true);
    const { description } = generalFormMethods.getValues();
    const filteredDescription = removeEmptyValues(turnArrayIntoMultiLang(description));

    await updateSkill({
      ...skill,
      description: filteredDescription,
    });
    await onSave();
    onClose();
    setIsLoading(false);
  };

  const ref = useRef<HTMLDivElement>(null);

  return (
    <Modal
      onClose={onClose}
      showDivider={false}
      centerModal
      width={750}
      contentStyles={{ padding: '20px 40px 27px', overflow: 'visible' }}
      headerStyles={{
        padding: '20px 40px',
        fontSize: '26px',
        fontWeight: 'normal',
      }}
      borderRadius={6}
      hideFooter
      isHideHeader
    >
      <Header>
        <Title>
          <Trans>Edit skill or KPI description</Trans>
          <Button variant={ButtonVariant.CLOSE} size={ButtonSize.MEDIUM} onClick={onClose} />
        </Title>
        <Subtitle>{skill.name && getMultiLangString(skill.name)}</Subtitle>
      </Header>
      <Content
        ref={ref}
        hasScrollbar={ref.current ? ref.current.scrollHeight > ref.current.clientHeight : false}
      >
        <SecondaryTitle>
          <Trans>Skill/KPI description</Trans>
        </SecondaryTitle>
        <InputContainer>
          {descriptionFieldArray.fields.map((field) => (
            <Controller
              key={field.id}
              {...register(`description.${field.index}.value`)}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Editor
                  minHeight={'231px'}
                  value={value}
                  compact
                  onChange={onChange}
                  big
                  leftIcon={
                    size(languageState.languages) > 1
                      ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                      : undefined
                  }
                />
              )}
            />
          ))}
        </InputContainer>
      </Content>
      <Footer>
        <Button
          label={i18n._(t`Cancel`)}
          variant={ButtonVariant.SECONDARY}
          onClick={onClose}
          size={ButtonSize.MEDIUM}
        />
        <Button
          isLoading={isLoading}
          label={i18n._(t`Save`)}
          variant={ButtonVariant.PRIMARY}
          onClick={handleSave}
          size={ButtonSize.MEDIUM}
        />
      </Footer>
    </Modal>
  );
}

export { EditSkillDefinitionModal };
