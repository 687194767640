import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const AnswerWrapper = styled.div`
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
  padding: 4px 17px 11px;
`;

export const ExpandedWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Arrow = styled.div<{ background: string; marginRight: number }>`
  width: 0;
  height: 0;
  margin-left: auto;
  margin-right: ${({ marginRight }) => marginRight}px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 8px solid ${({ background }) => background};
`;

export const Answer = styled.div<{ background: string }>`
  font-size: 14px;
  background: ${({ background }) => background};
  color: ${COLORS.TEXT_MAIN};
  height: 54px;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  margin-left: 17px;
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.TEXT_MAIN};
`;

export const Comment = styled.div`
  margin: 13px 0 0 16px;
  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};

  p {
    color: ${COLORS.TEXT_MAIN};
  }
`;

export const AnswerPlaceholder = styled.div`
  font-size: 14px;
  color: ${COLORS.PLACEHOLDERS};
`;

export const Row = styled.div``;

export const AnswerContainer = styled.div<{ $answered?: boolean; clickable?: boolean }>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: 48px;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  background-color: ${COLORS.BG_LIST};

  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  ${({ $answered }) =>
    $answered &&
    css`
      &:hover {
        & > div {
          & > div {
            & > div {
              & > div {
                & > span {
                  font-weight: 500;
                  color: ${COLORS.TEXT_HOVER};
                }
              }
            }
          }
        }
      }
    `}
`;
export const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  color: ${COLORS.TEXT_MAIN};
  font-size: 14px;
  > span {
    font-size: 14px;
    color: ${COLORS.SUBTEXT};
    align-self: center;
  }
  & svg {
    margin-right: 21px;
  }
`;

export const UserAvatarContainer = styled.div<{ $answered?: boolean; width?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;

  ${({ width }) =>
    width
      ? css`
          width: ${width}px;
        `
      : ''}

  ${({ $answered }) =>
    !$answered
      ? css`
          & > div {
            & > span {
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.16px;
              color: ${COLORS.INACTIVE};
            }
          }
          & > span {
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.16px;
            color: ${COLORS.INACTIVE};
          }
        `
      : ''}

  & svg {
    margin-right: 21px;
  }
`;

export const UserAvatarName = styled.span`
  margin-left: 3px;
  color: ${COLORS.SUBTEXT};
  align-self: center;
  text-transform: capitalize;
`;

export const RatingWrapper = styled.div<{ $answered: boolean; maxWidth: number }>`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 5px;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth}px;
`;

export const Rating = styled.div<{
  $answered?: boolean;
  selected: boolean;
  dark: string;
  default: string;
}>`
  flex: 1;
  border-radius: 3px;
  background-color: ${(props) => (props.selected ? props.default : COLORS.INACTIVE)};
  height: 20px;
  flex-basis: 20px;
  max-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    background-color: ${({ dark }) => dark};
  }
`;

export const Oval = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 50%;
`;

export const IconWrapper = styled.div<{ $answered: boolean }>`
  display: flex;
  margin-right: 10px;

  ${({ $answered }) =>
    !$answered &&
    css`
      color: ${COLORS.INACTIVE};
    `}
`;
