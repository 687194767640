import React, { useCallback, useEffect, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';
import Modal from '~/components/Modal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import { getColumns } from './columns';
import {
  Footer,
  StyledButton,
  StyledTableList,
  HeaderTitle,
  SubTitle,
  HeaderWrapper,
} from './design';

import { useAsyncFetch } from '~/hooks/useAsyncFetch';
import useBoolState from '~/hooks/useBoolState';
import { usePagination } from '~/hooks/usePagination';
import { getTeams, getUserTeams } from '~/services/teams';
import getUserFullName from '~/utils/getUserFullName';

import type { ITeam, IUser } from '@learned/types';

type Props = {
  employee: IUser;
  onSubmit: (selectedTeams: ITeam[]) => Promise<void>;
  onClose: () => void;
};

const AssignTeamsModal = ({ employee, onClose, onSubmit }: Props) => {
  const $loading = useBoolState();
  const [search, setSearch] = useState<string>('');
  const { pagination, changePagination, resetPagination } = usePagination(5);
  const [userTeams, setUserTeams] = useState<Array<ITeam>>([]);
  const [paginatedTeams, setPaginatedTeams] = useState<Array<ITeam>>([]);
  const [filteredTeams, setFilteredTeams] = useState<Array<ITeam>>([]);
  const [selectedTeams, setSelectedTeams] = useState<Array<ITeam>>([]);

  const { i18n } = useLingui();

  useEffect(() => {
    const fetchTeams = async () => {
      const res = await getUserTeams(employee.id, {
        isMember: true,
        isCoach: true,
        isRequested: false,
      });
      setUserTeams(Object.values(res));
    };

    fetchTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [totalTeams] = useAsyncFetch<ITeam[]>(
    {
      fetch: async () => {
        $loading.on();
        const result = await getTeams();
        $loading.off();
        const teams = (Object.values(result) as ITeam[]) ?? [];
        return teams.filter(({ id }) => !userTeams.find(({ id: userTeamId }) => userTeamId === id));
      },
      initialState: [],
    },
    [userTeams],
  );

  const paginateTeams = useCallback((): void => {
    let sortedTeams = [...totalTeams];

    if (search) {
      sortedTeams = sortedTeams.filter((team) =>
        team?.name?.toLowerCase().includes(search.toLowerCase()),
      );
    }

    setFilteredTeams(sortedTeams);

    const startIndex = (pagination.index - 1) * pagination.limit;
    sortedTeams = slice(sortedTeams, startIndex, startIndex + pagination.limit);

    setPaginatedTeams(sortedTeams);
  }, [pagination, totalTeams, search]);

  useEffect(() => {
    paginateTeams();
  }, [pagination, totalTeams, paginateTeams, search]);

  const handleResetFilter = (): void => {
    setSearch('');
    resetPagination();
  };

  const handleSubmit = async () => {
    $loading.on();
    await onSubmit(selectedTeams);
    $loading.off();
  };

  return (
    <Modal
      onClose={onClose}
      width={500}
      minWidth={'750px'}
      title={<Trans>Teams</Trans>}
      maxHeight={'900px'}
      contentStyles={{ padding: '25px 25px 32px 25px', margin: '0' }}
      isHideHeader
      hideFooter
      showDivider={false}
      overflow
    >
      <HeaderWrapper>
        <div>
          <HeaderTitle>
            <Trans>Assign teams</Trans>
          </HeaderTitle>
          <SubTitle>
            <Trans>To</Trans>: {getUserFullName(employee)}
          </SubTitle>
        </div>
        <Button
          type="button"
          variant={ButtonVariant.ICON}
          size={ButtonSize.BIG}
          icon={ICONS.CLOSE}
          iconSize={ICON_SIZES.LARGE}
          onClick={onClose}
        />
      </HeaderWrapper>
      <ShowSpinnerIfLoading loading={$loading.value || false}>
        <StyledTableList
          columns={getColumns()}
          data={paginatedTeams}
          menuProps={{
            isMenuVisible: false,
          }}
          isLoading={false}
          multiSelectProps={{
            isMultiSelectVisible: true,
            multiSelect: {
              checkedCount: selectedTeams.length,
              isAllChecked: selectedTeams.length === filteredTeams.length,
              onSelectItem: (item) =>
                setSelectedTeams((prevState) => {
                  if (prevState.includes(item)) {
                    return prevState.filter((value) => value !== item);
                  }
                  return [...prevState, item];
                }),
              isItemChecked: (item) => selectedTeams?.find((team) => item === team),
              onCheckAll: () =>
                selectedTeams.length !== filteredTeams.length
                  ? // @ts-ignore
                    setSelectedTeams(filteredTeams)
                  : setSelectedTeams([]),
            },
          }}
          filtersProps={{
            filters: {
              search,
              setSearch,
            },
            resetFilters: handleResetFilter,
            isFiltered: !!search,
          }}
          paginationProps={{
            pagination,
            changePagination,
            totalCount: filteredTeams.length,
          }}
        />
      </ShowSpinnerIfLoading>
      <Footer>
        <StyledButton
          disabled={$loading.value}
          onClick={onClose}
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          label={i18n._(t`Cancel`)}
        />
        <StyledButton
          disabled={$loading.value || !(selectedTeams.length > 0)}
          onClick={handleSubmit}
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          label={i18n._(t`Assign`)}
        />
      </Footer>
    </Modal>
  );
};

export { AssignTeamsModal };
