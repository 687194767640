import React, { useEffect } from 'react';

import { JOB_TEMPLATE_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import nanoid from 'nanoid';
import { useForm } from 'react-hook-form';

import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { SUPER_ADMIN_LANGUAGES } from '~/constants/languages';
import { useFromQuery } from '~/hooks/useFromQuery';
import { useGenericLanguageState } from '~/hooks/useLanguageState';
import { LS_KEYS } from '~/hooks/useLocalStorage';
import { deleteJobTemplateCoverImage, updateJobTemplateSuperAdmin } from '~/services/jobTemplates';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import { SuperAdminJobTemplateForm } from '../SuperAdminJobTemplateForm';
import { useJobTemplate } from '../SuperAdminJobTemplateForm/hooks/useJobTemplate';
import { useSkillCategories } from '../SuperAdminJobTemplateForm/hooks/useSkillCategories';
import { useSkillTemplates } from '../SuperAdminJobTemplateForm/hooks/useSkillTemplates';
import {
  convertSkillsTemplateAPIDataToFormData,
  convertSkillsTemplateFormToAPIData,
  removeEmptyValues,
} from '../SuperAdminJobTemplateForm/utils';
import { resolver } from '../SuperAdminJobTemplateForm/validations';

import type { IGeneralForm } from '../SuperAdminJobTemplateForm/types';

const SuperAdminEditJobTemplate = () => {
  const { i18n } = useLingui();
  const { goBack } = useFromQuery({ includeHash: true });
  const { addToast } = useToasts();

  const languageState = useGenericLanguageState({
    defaultLanguages: SUPER_ADMIN_LANGUAGES,
    primaryLanguage: SUPER_ADMIN_LANGUAGES[0],
    localStorageKey: LS_KEYS.LS_SUPER_ADMIN_LANGUAGES,
  });

  const generalFormMethods = useForm<IGeneralForm>({
    mode: 'all',
    resolver,
    defaultValues: {
      name: languageState.defaultLanguages.map(({ locale }) => ({ locale, value: '' })),
      description: languageState.defaultLanguages.map(({ locale }) => ({ locale, value: '' })),
      aliases: [],
    },
  });
  const { setValue, watch } = generalFormMethods;
  const jobTemplateName =
    watch('name').find(({ locale }) => locale === languageState.primaryLanguage.locale)?.value ||
    watch('name').find(({ value }) => value)?.value;

  const title = i18n._(t`Edit ${jobTemplateName}`);

  const { skillCategories } = useSkillCategories();
  const { jobTemplate } = useJobTemplate();
  const { skillTemplates } = useSkillTemplates({ jobTemplate });

  useEffect(() => {
    if (jobTemplate) {
      setValue('name', turnMultiLangIntoArray(jobTemplate.name, languageState.defaultLanguages));

      if (jobTemplate?.description) {
        setValue(
          'description',
          turnMultiLangIntoArray(jobTemplate.description.default, languageState.defaultLanguages),
        );
      }

      setValue('decisionLevel', jobTemplate.decisionLevel);

      if (jobTemplate?.coverImage) {
        setValue('coverImage', jobTemplate?.coverImage);
      }

      setValue('jobFamily', jobTemplate.jobFamily as string);

      setValue(
        'aliases',
        jobTemplate.aliases.map((alias) => ({ ...alias, id: nanoid() })),
      );

      setValue(
        'skills',
        convertSkillsTemplateAPIDataToFormData(jobTemplate.skills, skillCategories, skillTemplates),
      );
    }
  }, [jobTemplate, languageState.defaultLanguages, setValue, skillCategories, skillTemplates]);

  const handleDeleteCoverImage = async () => {
    if (jobTemplate) {
      await deleteJobTemplateCoverImage(jobTemplate.id);
    }
  };

  const onSubmit = async (data: IGeneralForm & { status: JOB_TEMPLATE_STATUS }) => {
    if (jobTemplate) {
      const predicate = ({ value }: { value: string }) => value !== '';

      const result = await updateJobTemplateSuperAdmin(jobTemplate?.id, {
        name: convertLanguageValue(data.name.filter(predicate)),
        skills: convertSkillsTemplateFormToAPIData(data.skills),
        description: {
          default: convertLanguageValue(data.description.filter(predicate)),
        },
        decisionLevel: data.decisionLevel,
        aliases: data.aliases?.map(({ _id, ...alias }) => removeEmptyValues(alias)),
        status: data.status,
        jobFamily: data.jobFamily,
        coverImage: data.coverImage,
      });

      if (result.code === 200 && result.data.jobTemplate.status === JOB_TEMPLATE_STATUS.PUBLISHED) {
        addToast({
          title: i18n._(t`Success`),
          subtitle: i18n._(t`Job template published`),
          type: TOAST_TYPES.SUCCESS,
        });
        goBack();
      } else if (
        result.code === 200 &&
        result.data.jobTemplate.status === JOB_TEMPLATE_STATUS.DRAFT
      ) {
        addToast({
          title: i18n._(t`Success`),
          subtitle: i18n._(t`Job template saved as draft`),
          type: TOAST_TYPES.SUCCESS,
        });
        goBack();
      }
    }
  };

  return (
    <SuperAdminJobTemplateForm
      title={title}
      onSubmit={onSubmit}
      onCoverImageDelete={handleDeleteCoverImage}
      formMethods={generalFormMethods}
      languageState={languageState}
      currentStatus={jobTemplate?.status}
    />
  );
};

export { SuperAdminEditJobTemplate };
