import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  API_RETURN_FIELDS,
  REVIEW_QUESTION_TYPES,
  REVIEW_QUESTION_TYPES_V1,
  REVIEW_RATING_STATUS,
  TASK_STATUS,
  USER_REVIEW_STATUS,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isArray } from 'lodash';
import some from 'lodash/some';
import { useFieldArray, useForm } from 'react-hook-form';

import { useSectionState } from '~/components/TableOfContents/hooks';
import { SECTION_TYPE } from '~/components/TableOfContents/types';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { QUESTION_TYPES } from '~/constants/questionsTypes';
import { useAutoSave } from '~/hooks/useAutoSave';
import useBoolState from '~/hooks/useBoolState';
import { useFromQuery } from '~/hooks/useFromQuery';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useQueryURL } from '~/hooks/useQueryURL';
import {
  createOrUpdateOutsideReviewRatings,
  createOrUpdateReviewRatings,
} from '~/services/reviewRatings';
import { getUserReview } from '~/services/userReviews';

import {
  createInitialSections,
  findAllRatings,
  findFirstUnansweredQuestion,
  groupQuestionsBasedOnType,
} from '../utils';
import { resolver } from '../validation';

import type {
  AnswerReviewForm,
  IPopulatedReviewTask,
  IPopulatedUserReview,
  IQuestionData,
  IQuestionJobProfileV1Data,
  IUserReviewQuestionSkillCategoryGrouped,
  TransformedQuestion,
} from '../types';
import type { IReviewRating, IUserReview } from '@learned/types';

interface IAnswer {
  questionId: string;
  answer?: string | undefined | null | number; // null could be when NA enabled
  oldAnswer?: string | null | number; // null could be when NA enabled
  comment?: string | undefined;
  isNotApplicable?: boolean | undefined;
}

export function useReviewRatingsState({
  reviewTask,
  userReview,
  languageState,
  useMultiLangString,
  isPreview,
  editedQuestion,
}: {
  reviewTask: IPopulatedReviewTask;
  userReview: IPopulatedUserReview;
  languageState: ILanguageStateReturn;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
  isPreview?: boolean;
  editedQuestion?: string;
}) {
  const getMultiLangString = useMultiLangString();
  const { addToast } = useToasts();
  const { values: queryParams } = useQueryURL({ keys: ['token'] });
  const token = queryParams.token;
  const { goBack } = useFromQuery({ includeHash: true });
  const { i18n } = useLingui();
  const $submitting = useBoolState(false);
  const [goalsPlanned, setGoalsPlanned] = useState<IUserReview['goalsPlanned']>();
  const [highestAnsweredQuestion, setHighestAnsweredQuestion] = useState<number>(-1);
  const $validationModalToggle = useBoolState(false);

  const $autosaveEnabled = useBoolState(
    !isPreview &&
      reviewTask.status !== TASK_STATUS.COMPLETED &&
      reviewTask.status !== TASK_STATUS.EXPIRED &&
      reviewTask.status !== TASK_STATUS.DECLINED,
  );
  const [showOutro, setShowOutro] = useState(reviewTask.status === TASK_STATUS.CALIBRATE);
  const $showExternalConfirmation = useBoolState(false);

  const groupedQuestions = useMemo(() => groupQuestionsBasedOnType(userReview), [userReview]);
  const initialSections = useMemo(
    () => createInitialSections(groupedQuestions, getMultiLangString, reviewTask, userReview, i18n),

    // eslint-disable-next-line
    [getMultiLangString, groupedQuestions, reviewTask, userReview],
  );
  const firstUnansweredQuestion =
    reviewTask.status === TASK_STATUS.CALIBRATE
      ? initialSections.length - 1
      : findFirstUnansweredQuestion(groupedQuestions, reviewTask);
  const sectionState = useSectionState<IQuestionData>(initialSections, {
    currentSection: firstUnansweredQuestion === -1 ? undefined : firstUnansweredQuestion,
  });

  useEffect(() => {
    if (editedQuestion) {
      const sectionIndex = initialSections.findIndex((section) =>
        section.type === SECTION_TYPE.BASIC
          ? // @ts-ignore
            section?.data?.question?.id === editedQuestion
          : section.type === SECTION_TYPE.CHILD
          ? // @ts-ignore
            section?.data?.subQuestions[0]?.question?.id === editedQuestion
          : false,
      );
      if (sectionIndex !== -1) {
        sectionState.setCurrentSection(sectionIndex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedQuestion, initialSections]);

  useEffect(() => {
    setGoalsPlanned(userReview?.goalsPlanned);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(userReview.goalsPlanned)]);

  const questionAndSectionMap = useMemo(
    () =>
      initialSections.reduce((acc: Record<string, number>, section, i) => {
        if (
          section.data?.type === REVIEW_QUESTION_TYPES.TEXT ||
          section.data?.type === REVIEW_QUESTION_TYPES.RATING ||
          section.data?.type === REVIEW_QUESTION_TYPES.GOAL_PLAN
        ) {
          acc[section.data.question.id] = i;
        }
        if (section.data?.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL) {
          section.data.subQuestions.forEach((subQuestion) => {
            acc[subQuestion.question.id] = i;
          });
        }
        if (section.data?.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY) {
          section.data.subQuestions.forEach((subQuestion) => {
            subQuestion.question.settings.duplicateQuestions?.forEach((dupe) => {
              acc[dupe.question.id] = i;
            });
            acc[subQuestion.question.id] = i;
          });
        }
        if (
          section.data?.type === REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1 ||
          section.data?.type === REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1
        ) {
          (section.data as IQuestionJobProfileV1Data).subQuestions.forEach((subQuestion) => {
            acc[subQuestion.question.id] = i;
          });
        }

        return acc;
      }, {}),
    [initialSections],
  );

  // we run autosave on:
  // - onBlurTextArea
  // - onChange answer/comment
  // - onChange section
  const autoSaveState = useAutoSave(async () => {
    const ratings = watch('ratings');

    const transformedData = ratings.map((rating) => ({
      id: rating.ratingId,
      company: reviewTask.company,
      createdBy: reviewTask.userTo,
      createdFor: reviewTask.userFrom as string,
      userReview: userReview.id,
      userReviewQuestion: rating.question,
      answer: rating.isNotApplicable ? null : rating.answer,
      oldAnswer: rating.isNotApplicable ? null : rating.oldAnswer,
      comment: rating.comment,
    }));

    let result: {
      code: number;
      data: {
        reviewRatings: IReviewRating[];
      };
    } | null = null;
    if (reviewTask.userTo?.email) {
      result = await createOrUpdateOutsideReviewRatings(
        {
          taskId: reviewTask.id,
          reviewRatings: transformedData,
          isAutoSave: true,
        },
        token,
      );
    } else {
      result = await createOrUpdateReviewRatings({
        taskId: reviewTask.id,
        reviewRatings: transformedData,
        isAutoSave: true,
      });
    }

    if (result && result.code === 200 && some(ratings, (field) => field.ratingId === undefined)) {
      fields.forEach((field, index) => {
        update(index, {
          ...field,
          ratingId: result?.data.reviewRatings.find(
            (rating) => rating.userReviewQuestion === field.question,
          )?.id,
        });
      });
    }
  });

  const formData = useForm<AnswerReviewForm>({
    mode: 'all',
    resolver,
    context: { reviewTask, userReview },
    defaultValues: {
      ratings: Array.from(findAllRatings(sectionState.sections))
        .filter(Boolean)
        .map((rating) => ({
          ratingId: rating.id,
          question: rating.userReviewQuestion,
          answer: rating.answer,
          oldAnswer: rating.oldAnswer,
          comment: rating.comment,
          isNotApplicable: rating.answer === null,
        })),
      shouldValidate: true,
    },
  });
  const { watch, setValue, trigger, control, handleSubmit, formState } = formData;
  const { fields, update } = useFieldArray({
    name: 'ratings',
    control,
  });

  const getTotalQuestionsCount = (groupedQuestions: TransformedQuestion[]): number =>
    groupedQuestions.reduce((count, question) => {
      if (
        [QUESTION_TYPES.RATING, QUESTION_TYPES.TEXT, QUESTION_TYPES.GOAL_PLAN].includes(
          question.type,
        )
      ) {
        return count + 1;
      }
      const skillCount = (question as IUserReviewQuestionSkillCategoryGrouped)?.skills?.length || 0;
      return count + skillCount;
    }, 0);

  const getAnsweredQuestionsCount = useCallback(() => {
    const ratingErrors = formState.errors?.ratings;
    const sectionsWithErrors: Record<number, boolean> = {};

    const totalQuestions = getTotalQuestionsCount(groupedQuestions);

    fields.forEach((field, index) => {
      const fieldError = ratingErrors?.[index];
      const sectionWithError = questionAndSectionMap[field.question];

      if (fieldError?.comment?.type !== undefined || fieldError?.answer?.type !== undefined) {
        sectionsWithErrors[sectionWithError] = true;
      }
    });

    return totalQuestions - Object.keys(sectionsWithErrors).length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.defaultValues?.ratings]);

  const totalQuestions = getTotalQuestionsCount(groupedQuestions);
  const answeredQuestions = getAnsweredQuestionsCount();

  useEffect(() => {
    if ($autosaveEnabled.value) {
      autoSaveState.executeNow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$autosaveEnabled.value, sectionState.currentSection]);

  useEffect(() => {
    const ratingErrors = formState.errors?.ratings;

    const sectionsWithErrors: Record<number, boolean> = {};
    fields.forEach((field, index) => {
      const fieldError = ratingErrors?.[index];
      // find section that this rating belongs to
      const sectionWithError = questionAndSectionMap[field.question];

      if (fieldError?.comment?.type !== undefined || fieldError?.answer?.type !== undefined) {
        sectionsWithErrors[sectionWithError] = true;
        sectionState.setErrorSection(sectionWithError, true);
      } else if (!sectionsWithErrors[sectionWithError]) {
        // remove error from section - only if all questions in section are valid
        sectionState.setErrorSection(sectionWithError, false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.errors.ratings]);

  useEffect(() => {
    trigger('ratings');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSingleAnswer = (data: IAnswer) => {
    const ratingIndex = fields.findIndex((field) => field.question === data.questionId);
    const updatedData = {
      ...fields[ratingIndex],
      ...(data.answer !== undefined && { answer: data.answer }),
      ...(data.oldAnswer !== undefined && { oldAnswer: data.oldAnswer }),
      ...(data.comment !== undefined && { comment: data.comment }),
      ...(data.isNotApplicable !== undefined && { isNotApplicable: data.isNotApplicable }),
    };

    update(ratingIndex, updatedData);
  };

  const onChange = async (answerData: IAnswer | IAnswer[]) => {
    if (isArray(answerData)) {
      answerData.forEach(handleSingleAnswer);
    } else {
      handleSingleAnswer(answerData);
    }
    const newHighestAnsweredQuestion = Math.max(
      ...sectionState.sections.map((section, index) => {
        if (section.status === 'done') {
          return index;
        }
        return 0;
      }),
    );
    setHighestAnsweredQuestion(newHighestAnsweredQuestion);

    if ($autosaveEnabled.value) {
      autoSaveState.run();
    }

    await trigger('ratings');
  };

  const onBlurTextArea = async () => {
    if ($autosaveEnabled.value) {
      autoSaveState.executeNow();
    }
  };

  const onOutsideReviewSuccess = async (data: AnswerReviewForm, isPublishing = false) => {
    const transformedData = data.ratings.map((rating) => ({
      id: rating.ratingId,
      company: reviewTask.company,
      createdBy: reviewTask.userTo,
      createdFor: reviewTask.userFrom as string,
      userReview: userReview.id,
      userReviewQuestion: rating.question,
      answer: (rating.isNotApplicable ? null : rating.answer) as string | null,
      oldAnswer: (rating.isNotApplicable ? null : rating.oldAnswer) as string | null,
      comment: rating.comment,
    }));

    await createOrUpdateOutsideReviewRatings(
      {
        taskId: reviewTask.id,
        status: isPublishing ? REVIEW_RATING_STATUS.PUBLISHED : REVIEW_RATING_STATUS.TODO,
        reviewRatings: transformedData,
        isAutoSave: false,
      },
      token,
    );

    setValue('shouldValidate', true);
    setShowOutro(false);
    $showExternalConfirmation.on();
  };

  const validateReviewStatus = async () => {
    const {
      data: { [API_RETURN_FIELDS.USER_REVIEW]: UpdatedUserReview },
    } = await getUserReview(userReview.id);
    const { status } = UpdatedUserReview;
    const { isDigitalSign } = UpdatedUserReview.settings;

    if (
      (isDigitalSign &&
        [
          USER_REVIEW_STATUS.SIGNING,
          USER_REVIEW_STATUS.COMPLETED,
          USER_REVIEW_STATUS.ARCHIVED,
        ].includes(status)) ||
      (!isDigitalSign && status === USER_REVIEW_STATUS.ARCHIVED)
    ) {
      $validationModalToggle.on();
    }
  };

  const onSuccess = async (data: AnswerReviewForm, isPublishing = false, isCalibrate = false) => {
    const transformedData = data.ratings.map((rating) => ({
      id: rating.ratingId,
      company: reviewTask.company,
      createdBy: reviewTask.userTo,
      createdFor: reviewTask.userFrom as string,
      userReview: userReview.id,
      userReviewQuestion: rating.question,
      answer: (rating.isNotApplicable ? null : rating.answer) as string | null,
      oldAnswer: (rating.isNotApplicable ? null : rating.oldAnswer) as string | null,
      comment: rating.comment,
    }));
    try {
      const status = isPublishing
        ? REVIEW_RATING_STATUS.PUBLISHED
        : isCalibrate
        ? REVIEW_RATING_STATUS.CALIBRATE
        : REVIEW_RATING_STATUS.TODO;

      const result = await createOrUpdateReviewRatings({
        taskId: reviewTask.id,
        status,
        reviewRatings: transformedData,
        isAutoSave: false,
        goalsPlanned: goalsPlanned?.map((item) => item.id) || [],
      });

      if (
        isPublishing &&
        !isCalibrate &&
        result.code === 200 &&
        (reviewTask.status === TASK_STATUS.TODO ||
          reviewTask.status === TASK_STATUS.ACTIVE ||
          reviewTask.status === TASK_STATUS.CALIBRATE)
      ) {
        addToast({ title: 'Your input has been shared!', type: TOAST_TYPES.SUCCESS });
        goBack();
      }

      if (
        result.code === 200 &&
        reviewTask.status === TASK_STATUS.COMPLETED &&
        isPublishing &&
        !isCalibrate
      ) {
        addToast({ title: 'Your input is saved', type: TOAST_TYPES.SUCCESS });
        goBack();
      }

      if (
        result.code === 200 &&
        reviewTask.status === TASK_STATUS.COMPLETED &&
        !isPublishing &&
        !isCalibrate
      ) {
        addToast({ title: 'Input has been reset to draft', type: TOAST_TYPES.INFO });
        goBack();
      }

      if (
        !isPublishing &&
        !isCalibrate &&
        result.code === 200 &&
        [TASK_STATUS.TODO, TASK_STATUS.ACTIVE].includes(reviewTask.status)
      ) {
        addToast({ title: 'Answers saved as draft', type: TOAST_TYPES.INFO });
        goBack();
      }

      if (
        !isPublishing &&
        !isCalibrate &&
        result.code === 200 &&
        reviewTask.status === TASK_STATUS.CALIBRATE &&
        status === REVIEW_RATING_STATUS.TODO
      ) {
        addToast({ title: i18n._(t`Input has been reset to draft`), type: TOAST_TYPES.INFO });
        goBack();
      }

      if (
        !isPublishing &&
        isCalibrate &&
        result.code === 200 &&
        [TASK_STATUS.TODO, TASK_STATUS.ACTIVE, TASK_STATUS.CALIBRATE].includes(reviewTask.status)
      ) {
        addToast({ title: 'Answers saved as calibrate', type: TOAST_TYPES.INFO });
        goBack();
      }

      if (
        result.code === 200 &&
        reviewTask.status === TASK_STATUS.COMPLETED &&
        !isPublishing &&
        isCalibrate
      ) {
        addToast({ title: 'Input has been reset to calibrate', type: TOAST_TYPES.INFO });
        goBack();
      }

      if (result.code === 403 || result.code === 400) {
        goBack();
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        await validateReviewStatus();
      }
    }
    setValue('shouldValidate', true);
  };

  const onFail = () => {
    sectionState.setTriedToSubmit();
    addToast({
      title: i18n._(t`Warning`),
      subtitle: i18n._(t`Please fill in all obligated fields`),
      type: TOAST_TYPES.INFO,
    });
    setShowOutro(false);
    sectionState.goToFirstErrorSection();
    setValue('shouldValidate', true);
  };

  const onPublish = async (e?: React.BaseSyntheticEvent) => {
    $submitting.on();
    const submit = handleSubmit(
      (data) => {
        return onSuccess(data, true);
      },
      () => onFail(),
    );

    const result = await submit(e);

    $submitting.off();
    return result;
  };

  const onPublishOutside = async (e?: React.BaseSyntheticEvent) => {
    $submitting.on();
    const submit = handleSubmit(
      (data) => {
        return onOutsideReviewSuccess(data, true);
      },
      () => onFail(),
    );

    const result = await submit(e);

    $submitting.off();
    return result;
  };

  const onSaveOutside = async (e?: React.BaseSyntheticEvent) => {
    $submitting.on();
    setValue('shouldValidate', false);
    const submit = handleSubmit(
      (data) => {
        return onOutsideReviewSuccess(data, false);
      },
      () => onFail(),
    );

    const result = await submit(e);

    $submitting.off();

    return result;
  };

  const onSave = async (e?: React.BaseSyntheticEvent) => {
    if (isPreview) {
      return;
    }
    $submitting.on();
    setValue('shouldValidate', false);
    const submit = handleSubmit(
      (data) => {
        return onSuccess(data, false);
      },
      () => onFail(),
    );

    const result = await submit(e);

    $submitting.off();
    return result;
  };

  const onSaveCalibrate = async (e?: React.BaseSyntheticEvent) => {
    $submitting.on();

    const submit = handleSubmit(
      (data) => {
        return onSuccess(data, false, true);
      },
      () => onFail(),
    );

    const result = await submit(e);

    $submitting.off();

    return result;
  };

  const nextSection = () => {
    const hasNextSection = sectionState.goToNextSection();

    if (!hasNextSection) {
      setShowOutro(true);
    }
  };

  const previousSection = () => {
    if (!showOutro) {
      sectionState.goToPreviousSection();
    }
    setShowOutro(false);
  };

  const currentQuestion = useMemo(() => {
    const data = sectionState.sections.at(sectionState.currentSection)!.data!;
    if (
      data.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL ||
      data.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY ||
      data.type === REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1 ||
      data.type === REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1
    ) {
      // @ts-ignore
      return data.subQuestions[0].question;
    } else {
      return data.question;
    }
  }, [sectionState.currentSection, sectionState.sections]);

  const [hasError, hasCommentError] = useMemo(() => {
    let [hasAnswerError, hasCommentError] = [false, false];
    if (!sectionState.triedToSubmit) {
      return [hasAnswerError, hasCommentError];
    }

    const ratings = formState?.errors?.ratings ?? [];
    for (let index = 0; index < (ratings.length ?? 0); index++) {
      const rating = ratings[index];
      const sectionWithError = questionAndSectionMap[fields[index].question];
      if (sectionWithError !== sectionState.currentSection) {
        continue;
      }

      if (rating?.answer?.type !== undefined) {
        hasAnswerError = true;
      }
      if (rating?.comment?.type !== undefined) {
        hasCommentError = true;
      }
    }

    return [hasAnswerError, hasCommentError];
  }, [
    sectionState.triedToSubmit,
    sectionState.currentSection,
    formState?.errors?.ratings,
    questionAndSectionMap,
    fields,
  ]);

  return {
    isSubmitting: $submitting.value,
    currentQuestion,
    showOutro,
    setShowOutro,
    hasError,
    hasCommentError,
    sectionState,
    formData,
    onPublish,
    onPublishOutside,
    onSave,
    onSaveOutside,
    onChange,
    nextSection,
    previousSection,
    autoSaveState,
    languageState,
    autosaveEnabled: $autosaveEnabled,
    highestAnsweredQuestion,
    onSaveCalibrate,
    showExternalConfirmation: $showExternalConfirmation,
    onBlurTextArea,
    setGoalsPlanned,
    goalsPlanned,
    totalQuestions,
    answeredQuestions,
    validationModalToggle: $validationModalToggle,
    lastQuestion: isPreview
      ? sectionState.currentSection === sectionState.sections.length - 1 // So we don't navigate to final screen with preview
      : currentQuestion.id === userReview.questions[userReview.questions.length - 1].id,
  };
}

export type ReturnTypeUseReviewRatingsState = ReturnType<typeof useReviewRatingsState>;
