import styled, { css } from 'styled-components';

import { Button } from '~/components/Buttons';
import { SearchField } from '~/components/Text';

import { COLORS } from '~/styles';

export const StyledSearchFieldWrapper = styled.div`
  position: relative;
`;

export const StyledSearchField = styled(SearchField)`
  height: 32px;
  margin-right: 12px;
`;

export const SkillsSelectorBackground = styled.div`
  background-color: ${COLORS.BG_LIST};
  margin-bottom: 16px;
  padding: 5px;
`;

export const NoRecommendedSkill = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 81px;
  font-size: 14px;
  color: ${COLORS.TEXT_SECONDARY};
`;

export const EmptyPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 351px;
`;

export const Label = styled.label`
  color: ${COLORS.SUBTEXT};
  margin-top: 22px;
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
`;

export const SuggestedSkillsWrapper = styled.div`
  position: absolute;
  top: 32px;
  left: 20px;
  z-index: 1000;
  border-radius: 6px;
  box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
  border: solid 1px ${COLORS.BORDERS};
  background-color: ${COLORS.WHITE};
  max-height: 150px;
  min-width: 226px;
  overflow: auto;

  & .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 16px;
    width: 100%;
    height: 40px;
    color: ${COLORS.TEXT_MAIN};
    background-color: ${COLORS.BG_ELEMENTS};
    box-sizing: border-box;
    font-size: 12px;
    gap: 10px;

    & .icon {
      visibility: hidden;
    }

    &:hover {
      color: ${COLORS.COMPANY};
      cursor: pointer;
      background-color: ${COLORS.BG_LIST};

      & .icon {
        visibility: visible;
      }
    }
  }

  & .notFound {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 16px;
    width: 100%;
    height: 40px;
    color: ${COLORS.TEXT_MAIN};
    background-color: ${COLORS.WHITE};
    box-sizing: border-box;
    font-size: 12px;
    gap: 10px;
    user-select: none;
  }
`;

export const ResultsContainer = styled.div<{ isCustomSkill?: boolean }>`
  min-height: 351px;
  max-height: 351px;
  overflow-y: auto;
  margin-bottom: 28px;

  ${({ isCustomSkill }) =>
    isCustomSkill &&
    css`
      max-height: 480px;
    `}
`;

export const RecommendedSkillsWrapper = styled.div<{
  isRecommendedSection?: boolean;
  isCustomSkill?: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
  justify-content: left;
  align-content: start;
  overflow: auto;

  ${({ isRecommendedSection }) =>
    isRecommendedSection &&
    css`
      max-height: 100px;
    `}

  ${({ isCustomSkill, isRecommendedSection }) =>
    isCustomSkill &&
    !isRecommendedSection &&
    css`
      max-height: 440px;
    `}

  & .item {
    display: flex;
    padding: 8px 17px;
    color: ${COLORS.TEXT_MAIN};
    height: 36px;
    border-radius: 18px;
    font-size: 14px;
    letter-spacing: -0.16px;
    background-color: ${COLORS.BG_ELEMENTS};

    &:hover {
      color: ${COLORS.COMPANY};
      cursor: pointer;
      background-color: ${COLORS.HOVER};
    }
  }
`;

export const RecommendedSkillsActions = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ShowMoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: ${COLORS.COMPANY};
  cursor: pointer;
  height: 36px;
`;

export const Title = styled.span`
  font-size: 26px;
  line-height: 1;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
`;

export const Subtitle = styled.span`
  font-size: 16px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 20px;
  border-bottom: solid 1px ${COLORS.BORDERS};
  justify-content: space-between;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 20px;
  border-top: solid 1px ${COLORS.BORDERS};
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
`;

export const CancelAndStepper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 30%;
  justify-content: space-between;
  align-items: center;
`;

export const StepWrapper = styled.span`
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
`;

export const Step = styled.span`
  font-size: 14px;
  color: ${COLORS.SUBTEXT};
  margin-left: 4px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding-bottom: 8px;
`;

export const HeaderActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const MoveSkillCategoryWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const MoveActionContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;

  & .item {
    padding: 8px 17px;
    color: ${COLORS.TEXT_MAIN};
    height: 36px;
    border-radius: 18px;
    font-size: 14px;
    letter-spacing: -0.16px;
    background-color: ${COLORS.BG_ELEMENTS};
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${({ isSelected }) =>
      isSelected &&
      css`
        background-color: ${COLORS.HOVER};
        color: ${COLORS.COMPANY};
      `}

    &:hover {
      color: ${COLORS.COMPANY};
      cursor: pointer;
      background-color: ${COLORS.HOVER};
    }
  }
`;

export const StyledItemButton = styled('button')<{ isSelected: boolean }>`
  padding: 8px 17px;
  color: ${COLORS.TEXT_MAIN};
  height: 36px;
  border-radius: 18px;
  font-size: 14px;
  letter-spacing: -0.16px;
  background-color: ${COLORS.BG_ELEMENTS};
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${COLORS.HOVER};
      color: ${COLORS.COMPANY};
    `}

  &:hover {
    color: ${COLORS.COMPANY};
    cursor: pointer;
    background-color: ${COLORS.HOVER};
  }
`;

export const StyledButton = styled(Button)`
  color: ${COLORS.TEXT_MAIN};
  background-color: ${COLORS.BG_ELEMENTS};
`;

export const SkillDescWrapper = styled.div<{ isShowMore: boolean }>`
  display: flex
    ${({ isShowMore }) =>
      isShowMore &&
      css`
        flex-direction: column;
      `};
`;

export const SkillDesc = styled.div<{ isShowMore: boolean }>`
  & div,
  & p {
    width: 510px;
    height: 32px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 16px;
    color: ${COLORS.SUBTEXT};
    line-height: 1.86;

    ${({ isShowMore }) =>
      isShowMore &&
      css`
        height: auto;
        white-space: normal;
      `}
  }
`;

export const SeeMoreBtn = styled.button`
  margin-left: 24px;
  color: ${COLORS.COMPANY};
  cursor: pointer;
`;

export const MultiLayerListWrapper = styled.div`
  maxheight: 564px;
  overflow: auto;
`;
