import FileSaver from 'file-saver';
import isEmpty from 'lodash/isEmpty';

import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getUserData() {
  return cloudRequest(serverRoutes.users.getUserData);
}

export function checkEmail(email) {
  return cloudRequest(serverRoutes.users.checkEmail, {}, { email }, { noLogin: true });
}

export function getUsersSuperAdmin(companyId = 'all') {
  return cloudRequest(serverRoutes.users.getUsersSuperAdmin(companyId));
}

export function getSelectedUsersSuperAdmin(companyId = 'all', users) {
  return cloudRequest(serverRoutes.users.getSelectedUsersSuperAdmin(companyId), {}, { users });
}

export function findUserSuperAdmin(email) {
  return cloudRequest(serverRoutes.users.findUserSuperAdmin, { email });
}

export function getUsers(params = {}) {
  return cloudRequest(serverRoutes.users.getUsers, {
    ...params,
    ...(!isEmpty(params.goalCycles) && { goalCycles: JSON.stringify(params.goalCycles) }),
    ...(!isEmpty(params.goalTypes) && { goalTypes: JSON.stringify(params.goalTypes) }),
  });
}

export function getCompanyUsers(filters = {}, join = []) {
  return cloudRequest(serverRoutes.users.getCompanyUsers, { join }, filters);
}

export function updateUserProfile(data) {
  return cloudRequest(serverRoutes.users.updateUserProfile, {}, data);
}

export function updateMembersProfiles(data) {
  return cloudRequest(serverRoutes.users.updateMembersProfiles, {}, data);
}

export function createUsersFromIntegration(usersToCreate = []) {
  return cloudRequest(serverRoutes.users.createUsersFromIntegration, {}, { usersToCreate });
}

export function connectUserToIntegration(integrationId, externalId, userId) {
  return cloudRequest(
    serverRoutes.users.connectUserToIntegration(userId, integrationId),
    {},
    { externalId },
  );
}

export function disconnectUserFromIntegration(userId) {
  return cloudRequest(serverRoutes.users.disconnectUserFromIntegration(userId));
}

export function updateUserAvatar(avatar) {
  return cloudRequest(
    serverRoutes.users.updateUserAvatar,
    {},
    { avatar },
    { sendAsFormData: true },
  );
}

export function deleteUserAvatar(userId) {
  return cloudRequest(serverRoutes.users.deleteUserAvatar(userId), {}, {});
}

// only for outside pages
export function getUserAvatar(userId) {
  return cloudRequest(serverRoutes.users.getUserAvatar, { userId });
}

// only for outside pages
export function getUserLocale(userId) {
  return cloudRequest(serverRoutes.users.getUserLocale, { userId });
}

export function login() {
  return cloudRequest(serverRoutes.users.login);
}

export function logout() {
  return cloudRequest(serverRoutes.users.logout);
}

export function updateAdminStatus(userId, isAdmin, { sendRequestAsAdmin = false } = {}) {
  return cloudRequest(
    serverRoutes.users.updateAdminStatus,
    { id: userId },
    { isAdmin },
    { forceRole: sendRequestAsAdmin ? 'admin' : undefined },
  );
}

export function changeOwnership(userId) {
  return cloudRequest(serverRoutes.users.changeOwnership, { id: userId });
}

export function addSkillsToUser(skillIds) {
  return cloudRequest(serverRoutes.users.addSkillsToUser, {}, { skills: skillIds });
}

export function removeSkillsFromUser(skillIds) {
  return cloudRequest(serverRoutes.users.removeSkillsFromUser, {}, { skills: skillIds });
}

export function removeUserFromCompany(userId) {
  return cloudRequest(serverRoutes.users.removeUserFromCompany, {}, { user: userId });
}

/* usersIds - [id1, id2]*/
export function deleteUsers(usersIds) {
  return cloudRequest(serverRoutes.users.deleteUsers, {}, usersIds);
}

export function removeUserFromCompanySuperAdmin(userId, companyId) {
  return cloudRequest(
    serverRoutes.users.removeUserFromCompanySuperAdmin,
    {},
    { user: userId, companyId },
  );
}

export function getUserConnection(userId) {
  return cloudRequest(serverRoutes.users.getUserConnection, { id: userId });
}

export function getUserSkillsOrder(userId) {
  return cloudRequest(serverRoutes.users.getUserSkillsOrder(userId));
}

export function updateUserSkillsOrder(userId, skillsOrder) {
  return cloudRequest(serverRoutes.users.updateUserSkillsOrder(userId), {}, { skillsOrder });
}

export function switchUserDisabled(userId, disabled) {
  return cloudRequest(serverRoutes.users.switchUserDisabled, {}, { user: userId, disabled });
}

export function switchUserDisabledSuperAdmin(userId, companyId, disabled) {
  return cloudRequest(
    serverRoutes.users.switchUserDisabledSuperAdmin,
    {},
    { user: userId, companyId, disabled },
  );
}

export function updateMFA(use_mfa) {
  return cloudRequest(serverRoutes.users.updateMFA, {}, { use_mfa });
}

export function resetMFA(userId) {
  return cloudRequest(serverRoutes.users.resetMFA(userId));
}

export function updateSSOMulti(users) {
  return cloudRequest(serverRoutes.users.updateSSOMulti, {}, { users });
}

export function resendWelcomeApiEmail(userId) {
  return cloudRequest(serverRoutes.users.resendWelcomeApiEmail(userId));
}

export function resendWelcomeApiEmailSuperAdmin(userId, companyId) {
  return cloudRequest(serverRoutes.users.resendWelcomeApiEmailSuperAdmin(userId, companyId));
}

export function addSuperAdminAsCompanyAdmin(companyId) {
  return cloudRequest(serverRoutes.users.addSuperAdminAsCompanyAdmin(companyId));
}

export function downloadUsersCSVSuperAdmin(companyId) {
  return cloudRequest(serverRoutes.users.downloadUsersCSVSuperAdmin, { companyId }).then(
    (response) => {
      FileSaver.saveAs(response, 'users.csv');
    },
  );
}

export function downloadMembersCSV() {
  return cloudRequest(serverRoutes.users.downloadMembersCSV).then((response) => {
    FileSaver.saveAs(response, 'members.csv');
  });
}

export function getFilteredUsersTotalCount(params) {
  return cloudRequest(serverRoutes.users.getFilteredUsersTotalCount, params);
}

export function downloadUserProfileCvPDF(userId, userName) {
  return cloudRequest(
    serverRoutes.generatePDF.getUserProfileCvPDF(userId),
    {},
    {},
    { arrayBuffer: true },
  ).then((response) => {
    if (response) {
      const blob = new Blob([response], { type: 'application/pdf' });
      FileSaver.saveAs(blob, `${userName}.pdf`);
    }
  });
}

export function getUserById(userId) {
  return cloudRequest(serverRoutes.users.getUserById(userId));
}

export function setPreferredLanguage(language) {
  return cloudRequest(serverRoutes.users.updatePreferredLanguage, {}, { language });
}

export function updateUserNotificationSettings(notificationSettings) {
  return cloudRequest(serverRoutes.users.updateNotificationSettings, {}, { notificationSettings });
}

export function updatePrimaryRole(primaryRole) {
  return cloudRequest(serverRoutes.users.updatePrimaryRole, {}, { primaryRole });
}

export function updateTrialEndedProductsMessages(trialEndedProductsMessages) {
  return cloudRequest(
    serverRoutes.users.updateTrialEndedProductsMessages,
    {},
    { trialEndedProductsMessages },
  );
}

export function getUserPreview({ userId, fields, userReviewId }) {
  return cloudRequest(serverRoutes.users.getUserPreview(userId), { fields, userReviewId });
}
