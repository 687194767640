import type { Dispatch, SetStateAction } from 'react';

import {
  FocusAreaType,
  SKILL_CATEGORIES_ID,
  JOB_PROFILE_DECISION_LEVELS,
  JOB_PROFILE_DECISION_LEVEL_INDEXES,
} from '@learned/constants';
import { isEmpty } from 'lodash';
import groupBy from 'lodash/groupBy';
import isNil from 'lodash/isNil';

import { type ISkillCategoryColumn, type IGeneralForm } from './types';

import type { ISelectedSkillTemplate } from '~/@types/job';

import type {
  IJobTemplate,
  IMultiLangString,
  ISkillCategory,
  ISkillTemplate,
} from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

export const convertSkillsDataToTable = ({
  skillTemplates,
  formMethods,
  skillCategory,
  setSkillTemplate,
  setSkillTemplates,
}: {
  skillTemplates: ISkillTemplate[];
  formMethods: UseFormReturn<IGeneralForm>;
  skillCategory?: ISkillCategory;
  setSkillTemplate?: Dispatch<SetStateAction<ISkillTemplate | undefined>>;
  setSkillTemplates?: Dispatch<SetStateAction<ISkillTemplate[]>>;
}) => {
  const { setValue, watch } = formMethods;
  const result: ISkillCategoryColumn[] = [];

  const { skills } = watch(`skills.${skillCategory?.id}`);

  skills?.forEach((skill) => {
    const skillTemplate = skillTemplates.find(({ id }) => id === skill.skillTemplate);

    if (skillTemplate) {
      const skillRows: { focusArea: IMultiLangString; level: number }[] = [];
      const baseResultItem = {
        id: skillTemplate.id,
        onDelete: () => {
          if (skillCategory?.id) {
            setValue(
              `skills.${skillCategory?.id}.skills`,
              skills.filter(({ skillTemplate: id }) => id !== skillTemplate.id),
            );
            // Removing skill template from templates data
            setSkillTemplates?.((prevState) =>
              prevState.filter((item) => item.id !== skillTemplate.id),
            );
          }
        },
        onEdit: () => {
          if (skillCategory?.id) {
            setSkillTemplate?.(skillTemplate);
          }
        },
      };

      skill.selectedFocusAreas.forEach((s) => {
        const levelFocusAreas = skillTemplate?.focusAreas?.filter(
          ({ level }) => s.level === level,
        )?.[0];

        if (s.type === FocusAreaType.SELECT_ALL) {
          // push all focus area ids
          skillTemplate.focusAreas.forEach((item) => {
            if (isEmpty(item?.values)) {
              skillRows.push({
                focusArea: { en_GB: '' },
                level: item.level,
              });
            } else {
              item?.values.forEach(({ name }) => {
                skillRows.push({
                  focusArea: name,
                  level: item.level,
                });
              });
            }
          });
        } else if (s.type === FocusAreaType.SELECT_LEVEL) {
          if (!isNil(s.level)) {
            if (s.type === FocusAreaType.SELECT_LEVEL && isEmpty(levelFocusAreas?.values)) {
              skillRows.push({
                focusArea: { name: '' },
                level: s.level,
              });
            } else {
              // push all focus area ids for a specific level
              levelFocusAreas?.values.forEach(({ name }) => {
                skillRows.push({
                  focusArea: name,
                  level: s.level!,
                });
              });
            }
          }
        } else if (s.type === FocusAreaType.SELECT_FOCUS_AREA) {
          // push specific focus area ids for a level
          levelFocusAreas?.values
            .filter(({ id }) => s.focusArea?.includes(id))
            .forEach(({ name }) => {
              skillRows.push({
                focusArea: name,
                level: s.level!,
              });
            });
        }
      });

      // push selected rows to the final result
      skillRows.forEach((item, index) => {
        result.push({
          ...baseResultItem,
          // put the name just for first row of skill template
          name: index === 0 ? skillTemplate.name : null,
          focusArea: item.focusArea,
          level: item?.level + 1,
          totalLevels: skillCategory?.skillLevels.length,
        });
      });
    }
  });

  return result;
};

// push each focus area id separately on SELECT_FOCUS_AREA type
export const convertSkillsTemplateFormToAPIData = (skillCategories: IGeneralForm['skills']) => {
  const result: IJobTemplate['skills'] = [];

  Object.values(skillCategories).forEach(({ skills }: ISelectedSkillTemplate) => {
    const skillTemplates: {
      skillTemplate: string;
      selectedFocusAreas: {
        type: FocusAreaType;
        level?: number;
        focusArea?: string;
      }[];
    }[] = [];

    skills.forEach((skill, index) => {
      if (skill.skillTemplate) {
        skillTemplates.push({
          skillTemplate: skill.skillTemplate,
          selectedFocusAreas: [],
        });

        skill.selectedFocusAreas.forEach((s) => {
          if ([FocusAreaType.SELECT_ALL, FocusAreaType.SELECT_LEVEL].includes(s.type)) {
            skillTemplates[index].selectedFocusAreas.push({
              type: s.type,
              level: s.level,
            });
          } else {
            // push each focus area id separately
            s.focusArea?.map((focusAreaId) => {
              skillTemplates[index].selectedFocusAreas.push({
                type: s.type,
                level: s.level,
                focusArea: focusAreaId,
              });
            });
          }
        });
      }
    });

    result.push(...skillTemplates);
  });

  return result;
};

export const convertSkillsTemplateAPIDataToFormData = (
  jobTemplateSkills: IJobTemplate['skills'],
  skillCategories: ISkillCategory[],
  skillTemplates: ISkillTemplate[],
) => {
  let result: IGeneralForm['skills'] = {};

  skillCategories.forEach((category) => {
    const skillLevelsLength = category.skillLevels?.length || 0;

    const defaultFocusAreaLevel = [
      SKILL_CATEGORIES_ID.COMPETENCIES,
      SKILL_CATEGORIES_ID.KPIS,
    ].includes(category.type as SKILL_CATEGORIES_ID)
      ? 1
      : skillLevelsLength >= 3
      ? 3
      : skillLevelsLength;

    result = {
      ...result,
      [category.id]: {
        defaultFocusAreaLevel,
        isDefaultFocusAreaLevelEnabled: true,
        skills: [],
      },
    };
  });

  if (!isEmpty(jobTemplateSkills)) {
    skillCategories.forEach(({ id: skillCategoryId }) => {
      const skills = jobTemplateSkills.filter((jobTemplateSkill) => {
        const skillTemplate = skillTemplates.find((s) => s.id === jobTemplateSkill.skillTemplate);

        return skillTemplate?.skillCategory === skillCategoryId;
      });

      const tempSkillTemplates: {
        skillTemplate: string;
        selectedFocusAreas: {
          type: FocusAreaType;
          level?: number;
          focusArea?: string[];
        }[];
      }[] = [];

      skills.forEach((skill, index) => {
        tempSkillTemplates.push({
          skillTemplate: skill.skillTemplate,
          selectedFocusAreas: [],
        });

        const selectedFocusAreas = groupBy(skill?.selectedFocusAreas, 'level');

        Object.values(selectedFocusAreas).forEach((items, focusAreaIndex) => {
          items.forEach((s) => {
            if (s.type === FocusAreaType.SELECT_ALL) {
              tempSkillTemplates[index].selectedFocusAreas.push({
                type: s.type,
              });
            } else if (s.type === FocusAreaType.SELECT_LEVEL) {
              tempSkillTemplates[index].selectedFocusAreas.push({
                type: s.type,
                level: s?.level,
              });
            } else {
              const focusArea =
                tempSkillTemplates[index].selectedFocusAreas[focusAreaIndex]?.focusArea || [];
              if (s.focusArea) {
                focusArea?.push(s.focusArea);
              }

              tempSkillTemplates[index].selectedFocusAreas[focusAreaIndex] = {
                focusArea,
                level: s.level,
                type: s.type,
              };
            }
          });
        });
      });

      const skillCategory = skillCategories.find((item) => item.id === skillCategoryId);

      if (skillCategory) {
        const skillLevelsLength = skillCategory.skillLevels?.length || 0;

        const defaultFocusAreaLevel = [
          SKILL_CATEGORIES_ID.COMPETENCIES,
          SKILL_CATEGORIES_ID.KPIS,
        ].includes(skillCategory.type as SKILL_CATEGORIES_ID)
          ? 1
          : skillLevelsLength >= 3
          ? 3
          : skillLevelsLength;

        result = {
          ...result,
          [skillCategoryId]: {
            skills: tempSkillTemplates,
            defaultFocusAreaLevel,
            isDefaultFocusAreaLevelEnabled: false,
          },
        };
      }
    });
  }

  return result;
};

export const removeEmptyValues = (value: IMultiLangString) => {
  return Object.entries(value).reduce((acc, [key, value]) => {
    if (value === '') {
      return acc;
    }
    return { ...acc, [key]: value };
  }, {});
};

// define focus areas level in skill
// we use this logic for skill in JobTemplate and in jobProfile
// important the same function (copy) we have on back-end
// to apply same logic on generate full job/jobTemplate with AI
export const defineFocusAreasLevelInJob = (
  skillCategoryObj: ISkillCategory,
  decisionLevel?: JOB_PROFILE_DECISION_LEVELS,
) => {
  const skillLevelsLength = skillCategoryObj.skillLevels.length;
  const isDefaultScale = skillLevelsLength === 4; // default scale has 4 items (names could be changed, so we do not check names)
  const isKPIOrNoLevels =
    [SKILL_CATEGORIES_ID.KPIS].includes(skillCategoryObj.type as SKILL_CATEGORIES_ID) ||
    skillLevelsLength === 0;

  let level = 0; // Default level is 0

  if (!isKPIOrNoLevels) {
    level =
      isDefaultScale && decisionLevel
        ? JOB_PROFILE_DECISION_LEVEL_INDEXES[decisionLevel] // Map decisionLevel to index
        : 0; // For other scenarios, always select level 0
  }
  return level;
};
