import React, { useMemo, useState } from 'react';

import { REVIEW_RATING_STATUS, REVIEW_RATING_TYPE } from '@learned/constants';
import { Trans } from '@lingui/macro';
import sumBy from 'lodash/sumBy';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { FocusArea } from './FocusArea';
import {
  FocusAreaContainer,
  FocusAreaName,
  Score,
  ScoreText,
  UserRatings,
} from './FocusAreaAnswers.design';

import { COLORS } from '~/styles';

import type { IQuestionCustomSkillData, IQuestionSkillCategorySubQuestion } from '../../../types';

function FocusAreaAnswers({
  focusAreaQuestion,
  isFirst,
  useMultiLangString,
}: {
  isFirst?: boolean;
  focusAreaQuestion:
    | Pick<
        IQuestionCustomSkillData['subQuestions'][0],
        'otherCoachRatings' | 'otherSelfRating' | 'otherPeerRatings' | 'yourRating' | 'question'
      >
    | IQuestionSkillCategorySubQuestion;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
}) {
  const [isOpened, setIsOpened] = useState(isFirst);
  const getMultiLangString = useMultiLangString();
  const averageScore = useMemo(() => {
    const ratings = [
      ...(focusAreaQuestion.otherCoachRatings ?? []),
      ...(focusAreaQuestion.otherPeerRatings ?? []),
      focusAreaQuestion.otherSelfRating,
    ].filter((rating) => rating && rating.answer !== null && Number(rating.answer) >= 0);
    const average = sumBy(ratings, 'answer') / ratings.length;
    return isNaN(average) ? '-' : average.toLocaleString(undefined, { maximumFractionDigits: 1 });
  }, [focusAreaQuestion]);

  return (
    <>
      <FocusAreaContainer clickable onClick={() => setIsOpened((prev) => !prev)}>
        <FocusAreaName>
          <Icon
            transform={isOpened ? 'rotate(90)' : 'rotate(0)'}
            size={ICON_SIZES.MEDIUM}
            icon={ICONS.NEXT}
            color={isOpened ? COLORS.COMPANY : COLORS.SUBTEXT}
          />
          {getMultiLangString(focusAreaQuestion.question.settings.focusArea.name)}
        </FocusAreaName>
        <Score>
          <Icon icon={ICONS.QUESTION_STAR} />
          <ScoreText $inactive={!averageScore}>
            <span>{averageScore}</span>/{focusAreaQuestion.question.settings.options.length}
          </ScoreText>
        </Score>
      </FocusAreaContainer>
      {isOpened && (
        <UserRatings>
          {focusAreaQuestion.otherCoachRatings?.map((rating) => (
            <FocusArea
              key={rating.id}
              rating={rating}
              total={focusAreaQuestion.question.settings.options.length}
              showNotSharedLabel={
                focusAreaQuestion.yourRating.type === REVIEW_RATING_TYPE.COACH &&
                rating.status === REVIEW_RATING_STATUS.CALIBRATE
              }
            >
              <Trans>Coach</Trans>
            </FocusArea>
          ))}
          {focusAreaQuestion.otherSelfRating && (
            <FocusArea
              rating={focusAreaQuestion.otherSelfRating}
              total={focusAreaQuestion.question.settings.options.length}
            >
              <Trans>Self</Trans>
            </FocusArea>
          )}
          {focusAreaQuestion.otherPeerRatings?.map((rating) => (
            <FocusArea
              key={rating.id}
              rating={rating}
              total={focusAreaQuestion.question.settings.options.length}
            >
              <Trans>Peer</Trans>
            </FocusArea>
          ))}
        </UserRatings>
      )}
    </>
  );
}

export { FocusAreaAnswers };
