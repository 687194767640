import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { DashboardHeader } from '~/components/DashboardHeader';
import { MultiLangComponent } from '~/components/Dropdown/MultiLangualDropdown';
import { ICONS } from '~/components/Icon';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import { ActionItemBlock, ActionsWrapper, Wrapper } from './design';

import type { HeaderFocusModeProps } from './types';

const HeaderFocusMode = ({
  title,
  goBack,
  languageState,
  actionButtons,
  iconButtons,
  subHeader,
  status,
  isBackIcon,
  isFixed,
}: HeaderFocusModeProps) => {
  return (
    <Wrapper isFixed={isFixed}>
      <DashboardHeader
        title={title}
        titleMaxWidth="400px"
        status={status}
        onBack={goBack}
        actions={
          <ActionsWrapper>
            {languageState && (
              <>
                <MultiLangComponent languageState={languageState} />
              </>
            )}

            {!isEmpty(iconButtons) && (
              <ActionItemBlock>
                {iconButtons?.map((iconButton, index) => {
                  const content = (
                    <span>
                      <Button
                        className={iconButton.icon === ICONS.DELETE_BIN ? 'delete' : ''}
                        icon={iconButton.icon}
                        size={
                          iconButton.icon === ICONS.DELETE_BIN
                            ? ButtonSize.MEDIUM
                            : ButtonSize.MEDIUM
                        }
                        variant={
                          iconButton.icon === ICONS.DELETE_BIN
                            ? ButtonVariant.ICON_DELETE
                            : ButtonVariant.ICON
                        }
                        isLoading={iconButton.isLoading}
                        disabled={iconButton.disabled}
                        onClick={iconButton.onClick}
                      />
                    </span>
                  );

                  return iconButton.tooltip ? (
                    <Tooltip key={index} size={TOOLTIP_SIZES.BIG} tooltip={iconButton.tooltip}>
                      {content}
                    </Tooltip>
                  ) : (
                    content
                  );
                })}
              </ActionItemBlock>
            )}
            {!isEmpty(actionButtons) && (
              <ActionItemBlock>
                {actionButtons?.map((actionButton, index) => (
                  <Button
                    key={`${actionButton.title}-${index}`}
                    label={actionButton.title}
                    icon={actionButton.icon}
                    size={ButtonSize.MEDIUM}
                    variant={actionButton.type}
                    isLoading={actionButton.isLoading}
                    onClick={actionButton.onClick}
                    disabled={actionButton.disabled}
                    tooltip={actionButton.tooltip}
                  />
                ))}
              </ActionItemBlock>
            )}
          </ActionsWrapper>
        }
        subHeader={subHeader}
        isBackIcon={isBackIcon}
      />
    </Wrapper>
  );
};

export { HeaderFocusMode };
