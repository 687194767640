import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import { IReviewInvitationTemplate } from '@learned/types';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { Controller, type UseFormReturn } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Input } from '~/components/Input';
import Modal from '~/components/Modal';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import useBoolState from '~/hooks/useBoolState';
import { createReviewInvitationTemplate } from '~/services/reviewInvitationTemplates';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import { InputContainer, Title } from '../../../design';
import { Footer, Label } from '../design';

import type { IReviewCycleForm } from '../../../types';

interface InvitationTemplateModalProps {
  formMethods: UseFormReturn<IReviewCycleForm>;
  onClose: () => void;
  refreshInvitations: () => void;
  invitationTemplates: Array<IReviewInvitationTemplate>;
}

const InvitationTemplateModal = ({
  onClose,
  formMethods,
  refreshInvitations,
  invitationTemplates,
}: InvitationTemplateModalProps) => {
  const { i18n } = useLingui();
  const {
    control,
    register,
    watch,
    setValue,
    formState: { dirtyFields },
  } = formMethods;
  const $isLoading = useBoolState(false);
  const { addToast } = useToasts();
  const [hasDuplicateNameError, setHasDuplicateNameError] = useState(false);
  const [isEmptyError, setIsEmptyError] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const handleTemplateCreation = async () => {
    const templateName = watch('reviewInvitationTemplateName') || '';

    $isLoading.on();
    const result = await createReviewInvitationTemplate({
      name: templateName,
      description: turnArrayIntoMultiLang(watch('description') || []),
    });

    addToast({ title: i18n._(t`Invitation message created!`), type: TOAST_TYPES.SUCCESS });
    $isLoading.off();
    const template = result.data[API_RETURN_FIELDS.REVIEW_INVITATION_TEMPLATE];
    refreshInvitations();
    setValue('reviewInvitationTemplate', template.id, { shouldDirty: true });
    onClose();
  };

  useEffect(() => {
    const value = watch('reviewInvitationTemplateName');
    const isDuplicateNameError = invitationTemplates.some((template) => {
      return !!value && template.name === value;
    });
    setHasDuplicateNameError(isDuplicateNameError);

    setIsEmptyError(value === '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('reviewInvitationTemplateName'), invitationTemplates]);

  useEffect(() => {
    if (!isEmpty(dirtyFields.reviewInvitationTemplateName)) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dirtyFields.reviewInvitationTemplateName)]);

  return (
    <Modal
      onClose={onClose}
      width={500}
      showDivider={false}
      footerStyles={{ paddingBottom: 25, paddingTop: 0, height: 'unset', minHeight: 'unset' }}
      contentStyles={{ padding: '9px 24px 32px 25.5px', overflowY: 'unset' }}
      headerStyles={{ paddingTop: 23, display: 'flex', alignItems: 'center' }}
      footerRight={
        <Footer>
          <Button
            onClick={onClose}
            label={i18n._(t`Cancel`)}
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
          />

          <Button
            onClick={handleTemplateCreation}
            label={i18n._(t`Save`)}
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            isLoading={$isLoading.value}
            disabled={hasDuplicateNameError || isEmptyError}
          />
        </Footer>
      }
      title={<Title>{i18n._(t`Create invitation message`)}</Title>}
    >
      <InputContainer>
        <Label>
          <Trans>Name</Trans>
        </Label>

        <Controller
          {...register('reviewInvitationTemplateName')}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Input
                value={value}
                onChange={(args) => {
                  onChange(args);
                }}
                width="437px"
                height="38px"
                error={((isDuplicateError, isEmptyError) => {
                  if (!isDirty) {
                    return undefined;
                  }
                  if (isDuplicateError) {
                    return i18n._(t`This name already exists`);
                  }
                  if (isEmptyError) {
                    return i18n._(t`This field is required`);
                  }

                  return undefined;
                })(hasDuplicateNameError, isEmptyError)}
              />
            );
          }}
        />
      </InputContainer>
    </Modal>
  );
};

export { InvitationTemplateModal };
