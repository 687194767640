import React, { useEffect, useState, useMemo } from 'react';

import { CAREER_PLAN_STATUSES, REVIEW_STATUS } from '@learned/constants';
import { uniq, flatten } from 'lodash';
import qs from 'qs';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { useInactiveUsers } from '~/pages/Reviews/hooks/useInactiveUsers';

import { ReviewIndividualForm } from './ReviewIndividualForm';
import { useResolver } from './ReviewIndividualForm/hooks/useResolver';

import { useLanguageState } from '~/hooks/useLanguageState';
import { getUsers, getSettingsRole } from '~/selectors/baseGetters';
import { getCareerPlansAsAdminCoach } from '~/services/careerPlans';
import { getUserTeams } from '~/services/teams';

import type { IReviewIndividualForm, PopulatedCareerPlan } from './ReviewIndividualForm/types';
import type { ITeam, IUser } from '@learned/types';

const ReviewIndividualCreate = () => {
  const languageState = useLanguageState();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const companyUsers: IUser[] = useSelector(getUsers);
  const { removeInactiveUsers } = useInactiveUsers();
  const { resolver } = useResolver();

  const userRole = useSelector(getSettingsRole);

  const [userId, setUserId] = useState<string | undefined>(query.user as unknown as string);

  const user = useMemo(() => {
    return Object.values(companyUsers).find((user) => user.id === userId);
  }, [companyUsers, userId]);

  const [careerPlans, setCareerPlans] = useState<PopulatedCareerPlan[]>([]);
  const [coaches, setCoaches] = useState<string[]>([]);

  useEffect(() => {
    if (user && user?.id) {
      const fetchCareerPlans = async () => {
        const res = await getCareerPlansAsAdminCoach(
          { status: CAREER_PLAN_STATUSES.CURRENT, createdFor: user?.id },
          { populate: ['jobProfile'] },
          userRole,
        );

        setCareerPlans(Object.values(res as Record<string, PopulatedCareerPlan>));
      };

      const fetchTeamsInfo = async () => {
        // @ts-ignore
        const res = await getUserTeams(user.id, { isMember: true });
        setCoaches(
          removeInactiveUsers(
            uniq(flatten(Object.values(res as Record<string, ITeam>).map((item) => item.coaches))),
          ),
        );
      };

      fetchTeamsInfo();
      fetchCareerPlans();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, user?.isCoach, user?.isAdmin]);

  const formMethods = useForm<IReviewIndividualForm>({
    mode: 'all',
    resolver,
    defaultValues: {
      employees: [],
      evaluators: [],
      reviewQuestionTypes: [],
      skills: [],
      status: REVIEW_STATUS.DRAFT,
      settings: {
        isCoachesAskedToScheduleReview: false,
        isCalibrate: false,
        isDigitalSign: false,
        isAutoArchive: true,
        isAutoTimeline: true,
        isShowPreviousScore: true,
      },
      notifications: {
        isOn7DaysBeforeTaskDeadline: true,
        isOnTaskStartDate: true,
        isOnTaskDeadline: true,
      },
      privacy: {
        isAllEmployeeCoachesCanSeeReview: false,
      },
      tasks: {
        reviewPeerNominate: {
          description: languageState.languages.map(({ locale }) => ({ locale, value: '' })),
        },
        isPreviouslyAutoGenerateEnabled: false,
      },
      // @ts-ignore
      description: languageState.languages.map(({ locale }) => ({ locale, value: '' })),
      reviewInvitationTemplateName: '',
      skillCategories: {},
    },
  });

  useEffect(() => {
    if (user) {
      formMethods.setValue('employees', [
        // @ts-ignore
        {
          ...user,
          guests: [],
          coaches: [],
          careerPlans: [],
          availableCoaches: coaches,
          availableCareerPlans: careerPlans,
        },
      ]);
    }
  }, [coaches, formMethods, careerPlans, user]);

  return (
    <ReviewIndividualForm
      formMethods={formMethods}
      languageState={languageState}
      setUserId={setUserId}
    />
  );
};

export { ReviewIndividualCreate };
