import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import some from 'lodash/some';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Button } from '~/components/Buttons/Button';
import { Input } from '~/components/Input';

import {
  BodyArea,
  ButtonCtr,
  Header,
  InputCtr,
  SecondaryTitle,
  Subtitle,
  Title,
  Wrapper,
} from './CommonSkillModal.design';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import { MODEL_TYPE } from '../../constants';

import type { TFlagOptions, TTableData, TInputs, TCategoriesObj } from '../../types';

type TProps = {
  flags: TFlagOptions[];
  title: string;
  subtitle?: string;
  onCancel: () => void;
  inputText: string;
  type: MODEL_TYPE;
  selectedSkill?: TTableData;
  onSave?: (meta: { inputs: TInputs; index?: number }) => void;
  onDelete?: () => void;
  focusAreaIndex?: string | null;
  skillCategoryList?: TCategoriesObj;
  colIndex?: number;
  selectedCategoryId?: string | null;
};
export const CommonSkillModal = ({
  flags,
  title,
  subtitle,
  onCancel,
  inputText,
  type,
  selectedSkill,
  onSave,
  onDelete,
  focusAreaIndex,
  skillCategoryList,
  colIndex,
  selectedCategoryId,
}: TProps) => {
  const { i18n } = useLingui();
  const [inputs, setInputs] = useState<Record<string, string | null>>({});
  const [triedToSave, setTriedToSave] = useState(false);
  const getMultiLangString = useMultiLangString();

  const onInputChange = (locale: string, value: string) => {
    const newLocale = { ...inputs, [locale]: value };
    setInputs(newLocale);
  };

  const getInputValue = (locale: string) => {
    return inputs[locale] || '';
  };

  useEffect(() => {
    if (type === MODEL_TYPE.EDIT_SKILL_NAME) {
      const inputObject = flags.reduce<Record<string, string | null>>((obj, flag) => {
        obj[flag.key] = selectedSkill?.name
          ? getMultiLangString(selectedSkill?.name[flag.key] || '')
          : '';
        return obj;
      }, {});
      setInputs(inputObject);
    } else if (type === MODEL_TYPE.EDIT_FOCUS_AREA) {
      const [focusLevelIndex, focusValueIndex] = (focusAreaIndex || '').split('-').map(Number);
      const filteredSkillLevels = selectedSkill?.levels?.[0];
      const filteredSkillValues =
        filteredSkillLevels?.columns?.[focusLevelIndex]?.values[focusValueIndex];
      if (filteredSkillValues) {
        const inputObject = flags.reduce<Record<string, string | null>>((obj, flag) => {
          obj[flag.key] = filteredSkillValues?.name
            ? getMultiLangString(filteredSkillValues?.name[flag.key] || '')
            : '';
          return obj;
        }, {});
        setInputs(inputObject);
      }
    } else if (type === MODEL_TYPE.EDIT_CATEGORY_LEVEL) {
      const skillCategoryArray = Object.values(skillCategoryList || {}).filter(
        (category) => category.id === selectedCategoryId,
      );

      const skillLevels = skillCategoryArray.length > 0 ? skillCategoryArray[0].skillLevels : [];

      const selectedLevel =
        colIndex !== null && colIndex !== undefined && skillLevels.length > colIndex
          ? skillLevels[colIndex]
          : null;
      const inputObject = flags.reduce<Record<string, string | null>>((obj, flag) => {
        obj[flag.key] = selectedLevel ? getMultiLangString(selectedLevel[flag.key] || '') : '';
        return obj;
      }, {});
      setInputs(inputObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasAtleastOneFilledField = some(
    inputs,
    (input) => input !== '' && input !== null && input !== undefined,
  );

  const isDeletable = !(
    type === MODEL_TYPE.EDIT_FOCUS_AREA ||
    type === MODEL_TYPE.CREATE_FOCUS_AREA ||
    type === MODEL_TYPE.CREATE_CATEGORY_LEVEL
  );

  return (
    <Wrapper minSize={type === MODEL_TYPE.EDIT_FOCUS_AREA ? '600px' : '400px'}>
      <Header>
        <Title>
          {title}
          {[
            MODEL_TYPE.EDIT_FOCUS_AREA,
            MODEL_TYPE.CREATE_CATEGORY_LEVEL,
            MODEL_TYPE.EDIT_CATEGORY_LEVEL,
            MODEL_TYPE.EDIT_SKILL_NAME,
            MODEL_TYPE.CREATE_SKILL,
            MODEL_TYPE.CREATE_FOCUS_AREA,
          ].includes(type) && <Button onClick={onCancel} variant={ButtonVariant.CLOSE} />}
        </Title>
        <Subtitle>{subtitle}</Subtitle>
      </Header>
      <BodyArea>
        <SecondaryTitle>{inputText}</SecondaryTitle>
        <InputCtr>
          {flags.map((flag, i) => (
            <Input
              name="skillName"
              placeholder={inputText}
              value={getInputValue(flag.key)}
              onChange={(e) => onInputChange(flag.key, e.target.value)}
              height="38px"
              leftIcon={flag.title}
              key={`flag-${i + 1}`}
              error={triedToSave && !hasAtleastOneFilledField}
            />
          ))}
        </InputCtr>
        <ButtonCtr isSpaceBetween={type !== MODEL_TYPE.EDIT_FOCUS_AREA}>
          {isDeletable && (
            <Button
              disabled={false}
              label={i18n._(t`Delete`)}
              variant={ButtonVariant.TEXT_DELETE}
              onClick={onDelete}
              size={ButtonSize.MEDIUM}
            />
          )}
          <Button
            disabled={false}
            label={i18n._(t`Cancel`)}
            variant={ButtonVariant.SECONDARY}
            onClick={onCancel}
            size={ButtonSize.MEDIUM}
          />
          <Button
            disabled={false}
            label={i18n._(t`Save`)}
            variant={ButtonVariant.PRIMARY}
            onClick={() => {
              setTriedToSave(true);
              if (!hasAtleastOneFilledField) {
                return;
              }
              onSave && onSave({ inputs });
            }}
            size={ButtonSize.MEDIUM}
          />
        </ButtonCtr>
      </BodyArea>
    </Wrapper>
  );
};

export default CommonSkillModal;
