import React, { useContext, useEffect } from 'react';

import { REVIEW_QUESTION_TYPES } from '@learned/constants';
import {
  IMultiLangString,
  IAllPreviousRatingResponse,
  IUserReviewQuestionRating,
  IUserReviewQuestionText,
} from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  IQuestionCustomSkillData,
  IQuestionSkillCategoryData,
  TPreviousUserReviewWithQuestionAndAnswers,
  TPreviousUserReviewsWithAnswer,
} from 'src/pages/ReviewGiveFeedback/types';

import { Button, ButtonVariant } from '~/components/Buttons';
import { PreviousAnswersContext } from '~/pages/ReviewGiveFeedback/hooks/previousAnswersContext';

import AllRatingAnswers from './AllRatingAnswers';
import AllSkillAnswers from './AllSkillAnswers';
import AllTextAnswers from './AllTextAnswers';
import EmptyStatePlaceholder from './components/EmptyStatePlaceholder';

interface IAllPreviousAnswersProps {
  // make sure to get this useMultiLangString from the props, otherwise it will break the components for external peers
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
  reviewQuestionId: string;
  userReviewQuestions: (
    | IUserReviewQuestionText
    | IUserReviewQuestionRating
    | IQuestionCustomSkillData['subQuestions'][number]['question']
    | IQuestionSkillCategoryData['subQuestions'][number]['question']
  )[];
  allPreviousRatings?: IAllPreviousRatingResponse[];
}

const getMatchingAnswerComponent = (
  questionType: REVIEW_QUESTION_TYPES,
  previousAnswers: TPreviousUserReviewsWithAnswer,
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string,
  ratingOptions?: {
    label: IMultiLangString;
  }[],
) => {
  if (!useMultiLangString) {
    return <></>;
  }

  switch (questionType) {
    case REVIEW_QUESTION_TYPES.TEXT:
      return (
        <AllTextAnswers
          previousUserReviewWithQuestionAndAnswers={
            previousAnswers.userReviews as TPreviousUserReviewWithQuestionAndAnswers<REVIEW_QUESTION_TYPES.TEXT>[]
          }
          useMultiLangString={useMultiLangString}
        />
      );
    case REVIEW_QUESTION_TYPES.RATING:
      return (
        <AllRatingAnswers
          previousUserReviewWithQuestionAndAnswers={
            previousAnswers.userReviews as TPreviousUserReviewWithQuestionAndAnswers<REVIEW_QUESTION_TYPES.RATING>[]
          }
          ratingOptions={ratingOptions || []}
          useMultiLangString={useMultiLangString}
        />
      );
    case REVIEW_QUESTION_TYPES.SKILL_CATEGORY:
      return (
        <AllSkillAnswers
          previousUserReviewWithQuestionAndAnswers={
            previousAnswers.userReviews as TPreviousUserReviewWithQuestionAndAnswers<REVIEW_QUESTION_TYPES.SKILL_CATEGORY>[]
          }
          useMultiLangString={useMultiLangString}
        />
      );
    case REVIEW_QUESTION_TYPES.CUSTOM_SKILL:
      return (
        <AllSkillAnswers
          previousUserReviewWithQuestionAndAnswers={
            previousAnswers.userReviews as TPreviousUserReviewWithQuestionAndAnswers<REVIEW_QUESTION_TYPES.CUSTOM_SKILL>[]
          }
          useMultiLangString={useMultiLangString}
        />
      );
    default:
      return null;
  }
};

const AllPreviousAnswers: React.FC<IAllPreviousAnswersProps> = ({
  useMultiLangString,
  reviewQuestionId,
  userReviewQuestions,
}) => {
  const { i18n } = useLingui();
  const context = useContext(PreviousAnswersContext);
  if (!context) {
    throw new Error('usePreviousAnswers must be used within a PreviousAnswersProvider');
  }

  const { fetchData, getFetchedAnswers, isLoading } = context;

  let skillId: string | undefined;
  let ratingOptions:
    | {
        label: IMultiLangString;
      }[]
    | undefined = [];

  // determine the question type based on the first question.
  const questionType = userReviewQuestions.at(0)?.type as REVIEW_QUESTION_TYPES;

  // only for rating type questions take the options from settings
  if (questionType === REVIEW_QUESTION_TYPES.RATING) {
    ratingOptions = (userReviewQuestions as IUserReviewQuestionRating[]).at(0)?.settings?.options;
  }

  // filter the previous answers based on skill for custom skill related question
  if (
    [REVIEW_QUESTION_TYPES.CUSTOM_SKILL, REVIEW_QUESTION_TYPES.SKILL_CATEGORY].includes(
      questionType,
    )
  ) {
    const skill = (
      userReviewQuestions as IQuestionSkillCategoryData['subQuestions'][number]['question'][]
    ).at(0)?.settings.skill;
    skillId = skill;
  }

  // for skill questions, we should use the skill id with the review question id together
  const previousAnswers = getFetchedAnswers(
    skillId ? `${reviewQuestionId}:${skillId}` : reviewQuestionId,
  );

  // handle initial data fetch
  useEffect(() => {
    if (
      !previousAnswers ||
      (!previousAnswers?.userReviews.length &&
        previousAnswers?.isAllPreviousReviewsFetched === false)
    ) {
      fetchData(reviewQuestionId, userReviewQuestions, undefined, skillId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillId]);

  const shouldShowFetchMoreButton =
    previousAnswers?.isAllPreviousReviewsFetched === false &&
    previousAnswers?.userReviews.length > 0;

  if (!previousAnswers?.userReviews.length) {
    return <EmptyStatePlaceholder isLoading={isLoading} />;
  }

  const oldestReviewQuestionsAnswers =
    previousAnswers.userReviews[previousAnswers.userReviews.length - 1];

  return (
    <div>
      {getMatchingAnswerComponent(questionType, previousAnswers, useMultiLangString, ratingOptions)}
      {shouldShowFetchMoreButton && (
        <Button
          label={i18n._(t`Show more`)}
          variant={ButtonVariant.TEXT_PRIMARY}
          isLoading={isLoading}
          onClick={() => {
            // fetch with the oldest reviewId that we fetched so far,
            fetchData(
              reviewQuestionId,
              userReviewQuestions,
              oldestReviewQuestionsAnswers.reviewStartDate,
              skillId,
            );
          }}
        />
      )}
    </div>
  );
};

export default AllPreviousAnswers;
