import styled from 'styled-components';

import { COLORS } from '~/styles';

export const AllAnswersWrapper = styled.div`
  display: flex;
  width: 750px;
  margin-top: 30px;
  flex-direction: column;
  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
  gap: 11px;
  pointer-events: all;
`;

export const Answers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const TabLabelWrapper = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
    padding-bottom: 2px;
    color: ${(props) => (props.isSelected ? COLORS.COMPANY : 'inherit')};
  }
`;
