import React, { useEffect, useState } from 'react';

import { REVIEW_STATUS, CAREER_PLAN_STATUSES } from '@learned/constants';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { useInactiveUsers } from '~/pages/Reviews/hooks/useInactiveUsers';

import { ReviewSelfForm } from './ReviewSelfForm';
import { useResolver } from './ReviewSelfForm/hooks/useResolver';

import { useLanguageState } from '~/hooks/useLanguageState';
import { getUser } from '~/selectors/baseGetters';
import { getCareerPlans } from '~/services/careerPlans';
import { getUserTeams } from '~/services/teams';

import type { IReviewSelfForm, PopulatedCareerPlan } from './ReviewSelfForm/types';
import type { ITeam } from '@learned/types';

const ReviewSelfCreate = () => {
  const languageState = useLanguageState();
  const [careerPlans, setCareerPlans] = useState<PopulatedCareerPlan[]>([]);
  const [coaches, setCoaches] = useState<string[]>([]);
  const { removeInactiveUsers } = useInactiveUsers();
  const { resolver } = useResolver();

  const user = useSelector(getUser);

  useEffect(() => {
    const fetchCareerPlans = async () => {
      const res = await getCareerPlans(
        { status: CAREER_PLAN_STATUSES.CURRENT, createdFor: user.id },
        { populate: ['jobProfile'] },
      );

      setCareerPlans(Object.values(res as Record<string, PopulatedCareerPlan>));
    };

    const fetchTeamsInfo = async () => {
      // @ts-ignore
      const res = await getUserTeams(user.id, { isMember: true });
      setCoaches(
        removeInactiveUsers(
          uniq(flatten(Object.values(res as Record<string, ITeam>).map((item) => item.coaches))),
        ),
      );
    };

    fetchTeamsInfo();
    fetchCareerPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id, user.isCoach, user.isAdmin]);

  const formMethods = useForm<IReviewSelfForm>({
    mode: 'all',
    resolver,
    defaultValues: {
      employees: [],
      evaluators: [],
      reviewQuestionTypes: [],
      status: REVIEW_STATUS.DRAFT,
      settings: {
        isCoachesAskedToScheduleReview: false,
        isCalibrate: false,
        isDigitalSign: false,
        isAutoArchive: true,
        isAutoTimeline: true,
        isShowPreviousScore: true,
      },
      notifications: {
        isOn7DaysBeforeTaskDeadline: true,
        isOnTaskStartDate: true,
        isOnTaskDeadline: true,
      },
      privacy: {
        isAllEmployeeCoachesCanSeeReview: false,
      },
      tasks: {
        reviewPeerNominate: {
          description: languageState.languages.map(({ locale }) => ({ locale, value: '' })),
        },
        isPreviouslyAutoGenerateEnabled: false,
      },
      // @ts-ignore
      description: languageState.languages.map(({ locale }) => ({ locale, value: '' })),
      userReviews: [],
      skillCategories: {},
    },
  });

  useEffect(() => {
    formMethods.setValue('employees', [
      {
        ...user,
        guests: [],
        coaches: [],
        careerPlans: [],
        availableCoaches: coaches,
        availableCareerPlans: careerPlans,
      },
    ]);
  }, [coaches, formMethods, careerPlans, user]);

  return <ReviewSelfForm formMethods={formMethods} languageState={languageState} />;
};

export { ReviewSelfCreate };
