import React, { ReactNode } from 'react';

import { GOAL_TYPES } from '@learned/constants';
import { Trans } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

import GoalItem from '~/components/GoalsBlock/components/GoalItem';
import { Icon } from '~/components/Icon';
import RickTextView from '~/components/RickTextView';

import { Block, SettingsList, SettingRow, IconCol, TextRow, StyledDivider } from './design';
import { getSettingsItems } from './settingsItems';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import { COLORS } from '~/styles';

import { PlaceholderText, CardTitle, Text } from '../../design';

import type { IGoal } from '@learned/types';

interface ISettingItem {
  name: ReactNode;
  key: string;
  item: ReactNode;
  icon: ReactNode;
}

const SettingsCard = ({ goal }: { goal: IGoal }) => {
  const settingItems = getSettingsItems(goal);
  const children = orderBy(goal.children, [(item) => item?.name?.trim().toLowerCase()], 'asc');

  const goToGoal = (id: string) => {
    // @ts-ignore
    routes.GOAL.go({ role: ROLES.USER }, { goalId: id, isBackPath: true });
  };

  const renderSettingItem = (settingItem: ISettingItem) => (
    <SettingRow key={settingItem.key}>
      {/* @ts-ignore */}
      <IconCol>
        {/* @ts-ignore */}
        <Icon icon={settingItem.icon} color={COLORS.TOOLTIP_BACKGROUND} size={14} />
      </IconCol>
      <TextRow>
        <Text isBold>{settingItem.name}</Text>
      </TextRow>
      <Text>{settingItem.item}</Text>
    </SettingRow>
  );

  const renderGoal = (goal: IGoal) => {
    return (
      <GoalItem
        item={goal}
        isShowCalcProgress
        onGoalClick={goToGoal}
        refreshGoals={() => {}}
        isDashboardItem
      />
    );
  };

  return (
    <>
      {goal.type !== GOAL_TYPES.COMPANY && (
        <Block>
          <CardTitle>
            <Trans>Contributes to</Trans>
          </CardTitle>
          {goal.parent ? (
            renderGoal(goal.parent as IGoal)
          ) : (
            <PlaceholderText>
              <Trans>No goal</Trans>
            </PlaceholderText>
          )}
        </Block>
      )}
      <Block>
        <CardTitle>
          <Trans>Impacted by</Trans>
        </CardTitle>
        {!isEmpty(children) ? (
          children.map(renderGoal)
        ) : (
          <PlaceholderText>
            <Trans>No goal</Trans>
          </PlaceholderText>
        )}
      </Block>
      {!isEmpty(goal.description) && (
        <Block>
          <CardTitle>
            <Trans>Description</Trans>
          </CardTitle>
          <Text>
            {/* @ts-ignore */}
            <RickTextView html={goal.description} fontColor={COLORS.SUBTEXT} />
          </Text>
        </Block>
      )}
      {/* @ts-ignore */}
      <StyledDivider margin={'0 0 24px 0'} />
      {/* @ts-ignore */}
      <SettingsList>{settingItems.map(renderSettingItem)}</SettingsList>
    </>
  );
};

export { SettingsCard };
