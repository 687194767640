import React, { memo } from 'react';

import { USER_REVIEW_STATUS } from '@learned/constants';
import { IUserReview } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';

import useIntegrationSettings from '~/hooks/useIntegrationSettings';

import { Block } from '../components/Block';
import { HRISSendReport } from '../components/HRISSendReport';
import { RowWrapper, Subtitle } from '../design';

type SendReportProps = {
  userReviewId: IUserReview['id'];
  status: USER_REVIEW_STATUS;
};

const SendReport = memo(({ userReviewId, status }: SendReportProps) => {
  const { i18n } = useLingui();

  const { reports, onSendReport } = useIntegrationSettings({
    userReviewId,
  });

  return !isEmpty(reports) ? (
    <Block title={i18n._(t`Send report`)}>
      <RowWrapper $margin={'0 0 24px 0'}>
        <Subtitle>
          <Trans>A PDF of this review report is added to the employee personnel file.</Trans>
        </Subtitle>
      </RowWrapper>

      {reports.map((data, index) => (
        <RowWrapper key={index} $margin={'0 36px 24px 0 '}>
          <HRISSendReport
            {...data}
            isEnabled={[USER_REVIEW_STATUS.COMPLETED, USER_REVIEW_STATUS.ARCHIVED].includes(status)}
            onSendReport={onSendReport}
          />
        </RowWrapper>
      ))}
    </Block>
  ) : null;
});

export { SendReport };
