import styled from 'styled-components';

import { Dropdown } from '~/components/Dropdown';

import { COLORS } from '~/styles';

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 26px;
  font-weight: 400;
  line-height: 40px;
  padding: 0;
  margin: 0;
  color: ${COLORS.TEXT_HOVER};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  padding: 16px 0 24px;
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 436px;
`;

export const Label = styled.p`
  color: ${COLORS.TEXT_HOVER};
  font-size: 12px;
  margin-top: 0;
`;

export const StyledDropdown = styled(Dropdown)`
  width: 100%;
  max-width: 100%;

  & .slider-animation {
    width: 100%;
  }

  div {
    background-color: ${COLORS.WHITE};
    color: ${COLORS.TEXT_MAIN};
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ActionButtonContainer = styled.div`
  padding-top: 24px;
  display: flex;
  gap: 8px;
`;

export const Placeholder = styled.div`
  height: 54px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: ${COLORS.DISABLE_TEXT};
  font-size: 12px;
  font-weight: 400;
  border-radius: 6px;
  background: ${COLORS.BG_SWITCH};
`;
