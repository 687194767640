import React, { ReactNode } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import {
  EmptyButtonWrapper,
  ImageWrapper,
  PlaceholderText,
  Wrapper,
  PlaceholderWrapper,
} from './design';

import CompletedStatePlaceholder from '~/assets/3.0/placeholders/completed-state-round.png';
import allDone from '~/assets/all-done-state.webp';
import emptyState from '~/assets/empty-state.webp';
import noResult from '~/assets/no-result.webp';

import { Button, ButtonSize, ButtonVariant } from '../Buttons';
import { ICONS } from '../Icon';

interface ITablePlaceholderProps {
  isLoading?: boolean;
  isFiltered?: boolean;
  noResultText?: ReactNode;
  emptyStateText?: ReactNode;
  onResetSearch?: () => void;
  isAllDone?: boolean;
  emptyButtonText?: string;
  onClickEmptyButton?: () => void;
  isAllDoneRounded?: boolean;
  padding?: string;
  emptyButtonIcon?: ICONS;
  emptyButtonVariant?: ButtonVariant;
}

const TablePlaceholder = ({
  isLoading,
  isFiltered,
  noResultText,
  emptyStateText,
  onResetSearch,
  isAllDone = false,
  emptyButtonText,
  onClickEmptyButton,
  isAllDoneRounded = false,
  padding,
  emptyButtonIcon,
  emptyButtonVariant,
}: ITablePlaceholderProps) => {
  const { i18n } = useLingui();

  return (
    <Wrapper padding={padding}>
      <ShowSpinnerIfLoading height="150px" loading={!!isLoading}>
        {isFiltered && !isAllDone ? (
          <ImageWrapper>
            <img src={emptyState} alt={i18n._(t`No result`)} width="216px" />
            <PlaceholderText>
              {noResultText || i18n._(t`No results found based on your search…`)}
            </PlaceholderText>
            <Button
              type="button"
              label={i18n._(t`Reset search`)}
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.TEXT_PRIMARY}
              onClick={onResetSearch}
            />
          </ImageWrapper>
        ) : (
          <ImageWrapper imgPadding="0 20px 0 0">
            {isAllDone ? (
              isAllDoneRounded ? (
                <PlaceholderWrapper>
                  <img
                    src={CompletedStatePlaceholder}
                    alt={i18n._(t`No data found`)}
                    width="216px"
                  />
                </PlaceholderWrapper>
              ) : (
                <img src={allDone} alt={i18n._(t`No data found`)} width="216px" />
              )
            ) : (
              <img src={noResult} alt={i18n._(t`No data found`)} width="216px" />
            )}
            {emptyStateText && <PlaceholderText>{emptyStateText}</PlaceholderText>}

            {emptyButtonText && (
              <EmptyButtonWrapper>
                <Button
                  type="button"
                  label={emptyButtonText}
                  size={ButtonSize.MEDIUM}
                  {...(emptyButtonIcon ? { icon: emptyButtonIcon } : {})}
                  variant={emptyButtonVariant ? emptyButtonVariant : ButtonVariant.PRIMARY}
                  onClick={onClickEmptyButton}
                />
              </EmptyButtonWrapper>
            )}
          </ImageWrapper>
        )}
      </ShowSpinnerIfLoading>
    </Wrapper>
  );
};

export { TablePlaceholder };
