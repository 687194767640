import { useCallback, useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  HRIS_TOOLS_NAMES,
  REVIEW_PARTICIPATION_COLUMNS,
  REVIEW_PARTICIPATION_ROW_TYPE,
  REVIEW_PARTICIPATION_SORT_BY,
  REVIEW_STATUS,
} from '@learned/constants';
import { uniq } from 'lodash';

import { createColumns } from '~/pages/Reviews/DashboardCycle/tabs/Participation/PerTeamSection/columns';
import {
  IChildTransformed,
  type IColumn,
  IRowTransformed,
} from '~/pages/Reviews/DashboardCycle/tabs/Participation/PerTeamSection/types';
import { transformRowsToItems } from '~/pages/Reviews/DashboardCycle/tabs/Participation/PerTeamSection/utils';

import useDebounce from '~/hooks/useDebounce';
import useIntegrationSettings from '~/hooks/useIntegrationSettings';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import { fetchReviewParticipationPerTeam } from '~/services/reviews';

import type { IUserReview, IReview } from '@learned/types';

const PAGE_SIZE = 5;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };
const LS_KEY = LS_KEYS.LS_REVIEW_CYCLE_DASHBOARD_PARTICIPATION;

const initialFilters = {
  columns: [
    REVIEW_PARTICIPATION_COLUMNS.SELF_REVIEW,
    REVIEW_PARTICIPATION_COLUMNS.PEER_REVIEW,
    REVIEW_PARTICIPATION_COLUMNS.COACH_REVIEW,
    REVIEW_PARTICIPATION_COLUMNS.SIGNED,
    REVIEW_PARTICIPATION_COLUMNS.COMPLETED_REVIEW,
  ],
  search: '',
  pagination: DEFAULT_PAGINATION,
  sortBy: REVIEW_PARTICIPATION_SORT_BY.NAME_ASC,
};

interface IUseReviewParticipationPerTeamProps {
  reviewId: IReview['id'];
}

export const useReviewParticipationPerTeam = ({
  reviewId,
}: IUseReviewParticipationPerTeamProps) => {
  const { integrationSettings, loading: integrationLoading } = useIntegrationSettings({});
  const [items, setItems] = useState<(IRowTransformed | IChildTransformed)[]>([]);
  const [columns, setColumns] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filters, setFilters] = useLocalStorage(LS_KEY, initialFilters);
  const { ...debCurrentFilters } = useDebounce(filters, 300); // isShowFilters does not affect on reFetch
  const [isLoading, setIsLoading] = useState(true);
  const [selectedEmployee, setSelectedEmployee] = useState<IUserReview['id'] | null>(null);
  const [completedReviewStatus, setCompletedReviewStatus] = useState<REVIEW_STATUS | undefined>();

  useEffect(() => {
    if (!integrationLoading) {
      const values = [...filters.columns, ...integrationSettings.map((value) => value.id)];

      setFilters({
        ...filters,
        columns: uniq(values),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationSettings]);

  const isAllTeamsOpened = items
    .filter((item) => item.type === REVIEW_PARTICIPATION_ROW_TYPE.TEAM)
    .every((item) => !(item as IRowTransformed).isCollapsed);

  // here we display/hide employees in team if team collapsed/expanded
  const itemsFiltered = items.filter(
    (item) =>
      item.type === REVIEW_PARTICIPATION_ROW_TYPE.TEAM || (item as IChildTransformed).isVisible,
  );

  const fetchData = async (signal?: AbortSignal) => {
    const result = await fetchReviewParticipationPerTeam(
      reviewId,
      {
        columns: filters.columns,
        filters: { search: filters.search },
        options: {
          skip: filters.pagination.skip,
          limit: filters.pagination.limit,
          sortBy: filters.sortBy,
        },
      },
      { ...(signal && { signal }) },
    );

    if (result) {
      const columns = result.data.columns;
      const rows = result.data.rows;
      const total = result.data[API_RETURN_FIELDS.TOTAL];
      return { columns, rows, total };
    }

    return result;
  };

  const setValues = async (signal?: AbortSignal) => {
    setIsLoading(true);
    const result = await fetchData(signal);

    if (result) {
      const { columns, rows, total } = result;

      // Check if name is an integration id and replace it
      const columnsRefined = columns.map((column: IColumn) => {
        const integration = integrationSettings.find((item) => item.id === column.name);
        if (integration) {
          return {
            ...column,
            name: HRIS_TOOLS_NAMES[integration.komboData.tool],
          };
        }
        return column;
      });

      // @ts-ignore
      setColumns(createColumns(columnsRefined));
      setItems(transformRowsToItems(rows));
      setTotalCount(total);
      setIsLoading(false);
    }
  };

  // on first render
  // on filters change
  useEffect(() => {
    if (!integrationLoading) {
      const controller = new AbortController();
      const signal = controller.signal;
      setValues(signal);

      return () => {
        controller.abort(); // cancel the request on component unmount
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewId, JSON.stringify(debCurrentFilters), integrationSettings]);

  const onChangePagination = ({ skip, limit, index }: typeof DEFAULT_PAGINATION) =>
    setFilters({
      ...filters,
      pagination: { ...filters.pagination, skip, limit, index },
    });

  const onChangeFilters = useCallback(
    (key: string, value: unknown) => {
      setFilters((prevFilters: typeof initialFilters) => ({
        ...prevFilters,
        [key]: value,
        pagination: DEFAULT_PAGINATION, // reset pagination on filters change
      }));
    },
    [setFilters],
  );

  const onToggleTeam = (clickedItem: IRowTransformed | IChildTransformed) => {
    const rowsUpdated = items.map((item) => {
      const isTeam = item.type === REVIEW_PARTICIPATION_ROW_TYPE.TEAM;
      const isTeamMatch = isTeam && item.temporalUniqueId === clickedItem.temporalUniqueId;
      const isEmployeeFromTeam =
        !isTeam && (item as IChildTransformed).parent === clickedItem.temporalUniqueId;

      if (isTeamMatch) {
        return {
          ...item,
          isCollapsed: !(item as IRowTransformed).isCollapsed,
        };
      }
      if (isEmployeeFromTeam) {
        return {
          ...item,
          isVisible: !(item as IChildTransformed).isVisible,
        };
      }
      return item;
    });

    setItems(rowsUpdated);
  };

  const onToggleAllTeams = (isOpen: boolean) => {
    const rowsUpdated = items.map((item) => {
      const isTeam = item.type === REVIEW_PARTICIPATION_ROW_TYPE.TEAM;

      return isTeam
        ? {
            ...item,
            isCollapsed: !isOpen,
          }
        : {
            ...item,
            isVisible: isOpen,
          };
    });

    setItems(rowsUpdated);
  };

  const onOpenAllTeams = () => onToggleAllTeams(true);
  const onCloseAllTeams = () => onToggleAllTeams(false);

  const onEmployeeClick = (item: IChildTransformed) => {
    setSelectedEmployee(item.userReviewId);
    setCompletedReviewStatus(
      // @ts-ignore
      item?.cells?.find((cell) => cell.columnId === REVIEW_PARTICIPATION_COLUMNS.COMPLETED_REVIEW)
        ?.value || undefined,
    );
  };
  const onCloseEmployeeModal = (isRefreshData = false) => {
    setSelectedEmployee(null);

    if (isRefreshData) {
      setValues();
    }
  };

  // handle click on first column and cell
  const onColClick = (item: IRowTransformed | IChildTransformed) => {
    if (item.type === REVIEW_PARTICIPATION_ROW_TYPE.TEAM) {
      onToggleTeam(item);
    } else {
      onEmployeeClick(item as IChildTransformed);
    }
  };

  return {
    isLoading,
    items: itemsFiltered,
    columns,
    totalCount,
    filters,
    onChangeFilters,
    onChangePagination,
    onColClick,
    onToggleTeam,
    onEmployeeClick,
    isAllTeamsOpened,
    onOpenAllTeams,
    onCloseAllTeams,
    selectedEmployee,
    completedReviewStatus,
    onCloseEmployeeModal,
  };
};
