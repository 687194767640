import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import FocusLayout from '~/layouts/FocusLayout';
import { SkillMatrix, Skills } from '~/pages/SkillSetsOverview';
import { EditSkillMatrix } from '~/pages/SkillSetsOverview/AdminSkillMatrix/EditSkillMatrix';
import { SkillCreate } from '~/pages/SkillSetsOverview/SkillCreate';
import { SkillEdit } from '~/pages/SkillSetsOverview/SkillEdit';
import { SkillView } from '~/pages/SkillSetsOverview/SkillView';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('skills', {
  routesPrivate: {
    user: [],
    coach: [],
    admin: [
      <PrivateRoute
        key={routes.SKILLS.routePath(ROLES.ADMIN)}
        exact
        path={routes.SKILLS.routePath(ROLES.ADMIN)}
        component={Skills}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.SKILL_MATRIX.routePath(ROLES.ADMIN)}
        exact
        path={routes.SKILL_MATRIX.routePath(ROLES.ADMIN)}
        component={SkillMatrix}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.SKILL_MATRIX_EDIT.routePath(ROLES.ADMIN)}
        exact
        path={routes.SKILL_MATRIX_EDIT.routePath(ROLES.ADMIN)}
        component={EditSkillMatrix}
        allowedRoles={[ROLES.ADMIN]}
        layout={FocusLayout}
      />,
      <PrivateRoute
        key={routes.SKILLS_CREATE.routePath(ROLES.ADMIN)}
        path={routes.SKILLS_CREATE.routePath(ROLES.ADMIN)}
        component={SkillCreate}
        allowedRoles={[ROLES.ADMIN]}
        layout={FocusLayout}
        exact
      />,
      <PrivateRoute
        key={routes.SKILL_EDIT.routePath(ROLES.ADMIN)}
        path={routes.SKILL_EDIT.routePath(ROLES.ADMIN)}
        component={SkillEdit}
        allowedRoles={[ROLES.ADMIN]}
        layout={FocusLayout}
        exact
      />,
      <PrivateRoute
        key={routes.SKILL_VIEW.routePath(ROLES.ADMIN)}
        path={routes.SKILL_VIEW.routePath(ROLES.ADMIN)}
        component={SkillView}
        allowedRoles={[ROLES.ADMIN]}
        layout={FocusLayout}
        exact
      />,
    ],
  },
  routesPublic: [],
});
