import React, { useState, useEffect } from 'react';

import { IReviewRating, ITask, IUserReview } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';

import { ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';
import { IQuestionDefaultData } from '~/pages/ReviewGiveFeedback/types';

import {
  Answer,
  AnswerContainer,
  AnswerPlaceholder,
  AnswerWrapper,
  EditButton,
  IconWrapper,
  Row,
  UserContainer,
} from './design';

import { useComponentDimensions } from '~/hooks/useComponentDimensions';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';

import { AnswerType } from '../../../types';
import { mapRatingsList, showCalibratedLabel, showCalibratePlaceholder } from '../../../utils';
import { AvatarWrapper } from '../Avatar';
import { NotSharedLabel } from '../NotSharedLabel';

function TextAnswers({
  userReview,
  isEditable,
  question,
  onEdit,
  isZeroState,
  isPDFView = false,
}: {
  userReview: IUserReview & { tasks?: ITask[] };
  isZeroState: boolean;
  isEditable: (rating: AnswerType) => boolean;
  onEdit: (rating: AnswerType, question: IQuestionDefaultData) => void;
  question: IQuestionDefaultData & {
    reviewRatings?: (IReviewRating & { reviewType: string })[];
  };
  isPDFView?: boolean;
}) {
  const currentUser = useSelector(getUser);
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  const { size, ref } = useComponentDimensions();

  const [answers, setAnswers] = useState<AnswerType[]>([]);

  const onClick = (questionId: number) => {
    setAnswers(
      answers.map((answer, index) => {
        if (questionId === index) {
          return {
            ...answer,
            isOpened: !answer.isOpened,
          };
        }
        return answer;
      }),
    );
  };

  useEffect(() => {
    setAnswers(mapRatingsList(question.reviewRatings || []));
  }, [question.reviewRatings]);

  return (
    <>
      {answers?.map((rating, index) => {
        return (
          <Row key={index}>
            <AnswerContainer clickable onClick={() => onClick(index)} $isZeroState={isZeroState}>
              <UserContainer ref={ref}>
                <div>
                  <Icon
                    transform={rating.isOpened || isPDFView ? 'rotate(90)' : 'rotate(0)'}
                    size={ICON_SIZES.MEDIUM}
                    icon={ICONS.NEXT}
                    color={rating.isOpened || isPDFView ? COLORS.COMPANY : COLORS.INACTIVE}
                  />
                </div>

                <AvatarWrapper
                  key={index}
                  id={rating.createdBy?.id}
                  answer={rating?.answer as string}
                  email={rating.createdBy?.email}
                  reviewType={rating.reviewType}
                  width={size.width && size.width - ICON_SIZES.MEDIUM - ICON_SIZES.LARGE - 51} // 51 is the sum of inner paddings
                />
              </UserContainer>

              <ToolTip
                key={index}
                placement={TOOLTIP_PLACEMENTS.TOP}
                tooltip={i18n._(t`This input is not yet shared with the employee`)}
              >
                <IconWrapper $answered={!!rating?.answer}>
                  {showCalibratedLabel(rating, userReview, currentUser) && <NotSharedLabel />}
                </IconWrapper>
              </ToolTip>
              <Icon icon={ICONS.TEXT_ANSWER} size={ICON_SIZES.LARGE} />
            </AnswerContainer>
            {(rating.isOpened || isPDFView) && (
              <AnswerWrapper>
                {rating?.answer ? (
                  <>
                    {isEditable(rating) && (
                      <EditButton
                        variant={ButtonVariant.SECONDARY}
                        label={i18n._(t`Edit`)}
                        onClick={() => onEdit(rating, question)}
                      />
                    )}
                    <Answer html={getMultiLangString((rating?.answer as string) || '')} />
                  </>
                ) : (
                  <AnswerPlaceholder>
                    {isEditable(rating) && (
                      <EditButton
                        variant={ButtonVariant.SECONDARY}
                        label={i18n._(t`Edit`)}
                        onClick={() => onEdit(rating, question)}
                      />
                    )}
                    {rating?.answer === null ? (
                      <Trans>Answered this question with N/A</Trans>
                    ) : (
                      <Trans>{`${
                        showCalibratePlaceholder(rating.task, userReview, currentUser)
                          ? 'Answer not yet shared'
                          : 'No answer provided yet'
                      }`}</Trans>
                    )}
                  </AnswerPlaceholder>
                )}
              </AnswerWrapper>
            )}
          </Row>
        );
      })}
    </>
  );
}

export { TextAnswers };
