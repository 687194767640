import React, { useEffect } from 'react';

import { isEmpty } from 'lodash';

import type { IReviewThemePreview } from '~/pages/Reviews/components/JobsValidation/types';

import { ThemeSection } from './components/ThemeSection';
import { prepareData } from './utils';

import type { IJobProfile, ISkill, ISkillCategory } from '@learned/types';

interface JobsValidationProps {
  reviewThemes: IReviewThemePreview[];
  jobProfiles: IJobProfile[];
  skills: ISkill[];
  skillCategories: Record<ISkillCategory['id'], ISkillCategory>;
  onValidation: (isValid: boolean) => void;
}

export const JobsValidation = ({
  reviewThemes,
  jobProfiles,
  skills,
  skillCategories,
  onValidation,
}: JobsValidationProps) => {
  // find themes with skill-category questions
  // other themes we do not validate
  // create validation structure
  // if validation passed - items is empty
  const items = prepareData(reviewThemes, jobProfiles, skills, skillCategories);

  useEffect(() => {
    // update status of parent component to display error outside of JobValidation
    onValidation(isEmpty(items));

    // eslint-disable-next-line
  }, [items]);

  return (
    <div>
      {items?.map(({ reviewTheme, items }) => {
        return <ThemeSection key={reviewTheme.id} reviewTheme={reviewTheme} items={items} />;
      })}
    </div>
  );
};
