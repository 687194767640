import styled, { css } from 'styled-components';

import { TableList } from '~/components/TableList';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
`;

export const StyledTableList = styled(TableList)`
  padding: 0;
  margin: 0;

  table tbody tr:last-child {
    .context-menu {
      top: -80px;
    }
  }
`;

export const NameRow = styled.span<{ $isInactive?: boolean }>`
  display: flex;
  box-sizing: border-box;
  max-width: 300px;

  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;

  // hide overflow with ellipsis
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${({ $isInactive }) => {
    return $isInactive
      ? css`
          color: ${COLORS.INACTIVE};
        `
      : css`
          color: ${COLORS.TEXT_MAIN};
        `;
  }}
`;

export const StatusLabel = styled.div<{ borderColor: string; backgroundColor: string }>`
  border-radius: 3px;
  display: flex;
  justify-content: center;
  color: ${({ borderColor }) => borderColor};
  font-size: 12px;
  padding: 0 6px;
  width: min-content;
  border: 1px solid ${({ borderColor }) => borderColor};
  background: ${({ backgroundColor }) => backgroundColor};
`;
