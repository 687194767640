import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { GOAL_SORT_OPTIONS, GOAL_STATUSES_NEW, GOAL_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import styled from 'styled-components';

import { AutocompleteFilterGoalCycles } from '~/components/AutocompleteFilters';
import { Dropdown } from '~/components/Dropdown';
import { SearchSelectModal } from '~/components/SearchSelectModal';

import { createColumns } from './columns';
import { RowItem } from './design';

import useDebounce from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import { getGoals } from '~/services/goals';
import { isNotNil } from '~/utils/typePredicates';

import type { IGoal, IGoalCycle, IUser } from '@learned/types';
import type { I18n } from '@lingui/core';

interface ImportGoalsModalProps {
  onClose: () => void;
  onSave: (goals: IGoal[]) => void;
  subTypes: (GOAL_TYPES.BUSINESS | GOAL_TYPES.PERSONAL)[];
  selectedGoalsByDefault: IGoal['id'][];
  onRowClick: (item: IGoal) => void;
  userFrom?: IUser['id'];
  invokeRefetch: boolean;
}

const Wrapper = styled.div`
  .tableList {
    padding: 10px 0 0 0;
  }
`;

type Item = {
  id: GOAL_STATUSES_NEW;
  label: (i18n: I18n) => string;
};

const STATUS_ITEMS: Array<Item> = [
  { id: GOAL_STATUSES_NEW.TODO, label: (i18n: I18n) => i18n._(t`To do`) },
  { id: GOAL_STATUSES_NEW.IN_PROGRESS, label: (i18n: I18n) => i18n._(t`In progress`) },
  { id: GOAL_STATUSES_NEW.COMPLETED, label: (i18n: I18n) => i18n._(t`Completed`) },
  { id: GOAL_STATUSES_NEW.PROBLEM, label: (i18n: I18n) => i18n._(t`Problem`) },
  { id: GOAL_STATUSES_NEW.DRAFT, label: (i18n: I18n) => i18n._(t`Draft`) },
  { id: GOAL_STATUSES_NEW.ARCHIVED, label: (i18n: I18n) => i18n._(t`Archived`) },
];

const initFilters = {
  status: STATUS_ITEMS.filter(
    (item) => ![GOAL_STATUSES_NEW.COMPLETED, GOAL_STATUSES_NEW.ARCHIVED].includes(item.id),
  ),
};

const ImportGoalsModal = ({
  onClose,
  onSave,
  subTypes,
  onRowClick,
  userFrom,
  invokeRefetch,
  selectedGoalsByDefault,
}: ImportGoalsModalProps) => {
  const { i18n } = useLingui();

  const [total, setTotal] = useState<number | undefined>();
  const [fetchedGoals, setFetchedGoals] = useState<IGoal[]>([]);
  const [goals, setGoals] = useState<IGoal[]>([]);
  const [selectedGoals, setSelectedGoals] = useState<string[]>(selectedGoalsByDefault);
  const [selectedGoalCycles, setSelectedGoalCycles] = useState<IGoalCycle[]>([]);
  const [selectedStatusItems, setSelectedStatusItems] = useState<Item[]>(initFilters.status);

  const [sortBy, setSortBy] = useState(GOAL_SORT_OPTIONS.NAME_A_Z);

  const [isLoading, setIsLoading] = useState(false);
  const { pagination, changePagination } = usePagination(10);
  const [search, setSearch] = useState('');
  const debSearch = useDebounce(search, 300);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setIsLoading(true);
      // @ts-ignore
      const { data, total } = await getGoals({
        search: debSearch,
        limit: pagination.limit,
        skip: pagination.skip,
        statuses: selectedStatusItems.map((item) => item.id),
        goalCycles: selectedGoalCycles.map((item) => item.id),
        owners: [userFrom],
        sort: sortBy,
        isGetProgress: true,
        types: subTypes,
        join: ['children'],
      });

      if (!isMounted) {
        return;
      }

      setFetchedGoals((prevState) => {
        return uniqBy([...prevState, ...data], 'id');
      });

      setGoals(data);
      setTotal(total ?? 0);
      setIsLoading(false);
    };

    fetchData();

    return () => void (isMounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debSearch,
    pagination.limit,
    pagination.skip,
    selectedStatusItems,
    selectedGoalCycles,
    sortBy,
    invokeRefetch,
  ]);

  const filterCount = useMemo(() => {
    let count = 0;

    if (selectedStatusItems.length) {
      count += 1;
    }
    if (selectedGoalCycles.length) {
      count += 1;
    }

    return count;
  }, [selectedStatusItems.length, selectedGoalCycles.length]);

  const disabledStatuses = [
    {
      status: GOAL_STATUSES_NEW.DRAFT,
      tooltip: i18n._(
        t`Goals with status draft need to be published before you can add them to the review.`,
      ),
    },
    {
      status: GOAL_STATUSES_NEW.ARCHIVED,
      tooltip: i18n._(
        t`Goals with status archived cant be added to the review. First unarchive the goal.`,
      ),
    },
  ];

  const filterDisabledGoals = (item: IGoal) =>
    // @ts-ignore
    !disabledStatuses.map(({ status }) => status).includes(item.status);

  const onCheckAll = useCallback(() => {
    return every(goals.filter(filterDisabledGoals).map((goal) => selectedGoals.includes(goal.id)))
      ? setSelectedGoals((prev) =>
          prev.filter((userId) => !goals.map((user) => user.id).includes(userId)),
        )
      : setSelectedGoals((prev) => [
          ...prev,
          ...goals.filter(filterDisabledGoals).map((user) => user.id),
        ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGoals, goals]);

  const isItemChecked = useCallback(
    (item: IUser) => selectedGoals.includes(item.id),
    [selectedGoals],
  );

  const onSelectItem = useCallback(
    (item: IUser) =>
      setSelectedGoals((prevState) => {
        if (prevState.includes(item.id)) {
          return prevState.filter((value) => value !== item.id);
        }
        return [...prevState, item.id];
      }),
    [],
  );

  const resetFilters = () => {
    setSearch('');
    setSelectedStatusItems([]);
    setSelectedGoalCycles([]);
  };

  return (
    <Wrapper>
      <SearchSelectModal
        title={i18n._(t`Import goals`)}
        disablePrimary={isEmpty(selectedGoals)}
        primaryActionLabel={i18n._(t`Import`)}
        onPrimaryAction={() => {
          onSave(
            selectedGoals.map((id) => fetchedGoals.find((item) => item.id === id)).filter(isNotNil),
          );
          onClose();
        }}
        onClose={onClose}
        tableListProps={{
          className: 'tableList',
          disabledStatuses,
          isLoading,
          columns: createColumns(i18n, onRowClick),
          data: goals,
          multiSelectProps: {
            multiSelect: {
              checkedCount: selectedGoals.length,
              isAllChecked:
                goals.filter(filterDisabledGoals).length > 0 &&
                every(
                  goals.filter(filterDisabledGoals).map((user) => selectedGoals.includes(user.id)),
                ),
              onSelectItem,
              isItemChecked,
              onCheckAll,
            },
          },
          sortProps: { sortBy, setSortBy },
          placeholderProps: {
            noResultText: i18n._(t`This search did not produce any results`),
            emptyStateText: i18n._(t`No goals`),
          },
          filtersProps: {
            filters: {
              search,
              setSearch,
              filterCount: filterCount || undefined,
            },
            resetFilters,
            isFiltered: !!filterCount || !!search.length,
            isToggleHideFilterVisible: true,
            filterComponents: (
              <>
                <RowItem>
                  <AutocompleteFilterGoalCycles
                    // @ts-ignore
                    checkedList={selectedGoalCycles}
                    onChange={(value: IGoalCycle[]) => {
                      setSelectedGoalCycles(value);
                    }}
                    placeholder={i18n._(t`Timeframe`)}
                  />
                </RowItem>
                <RowItem>
                  <Dropdown
                    placeholder={i18n._(t`Status`)}
                    items={STATUS_ITEMS}
                    selectedItems={selectedStatusItems}
                    stringifyItem={(item) => item.label(i18n)}
                    onChange={(value) => {
                      setSelectedStatusItems(value);
                    }}
                  />
                </RowItem>
              </>
            ),
          },
          paginationProps: {
            pagination,
            changePagination,
            totalCount: total,
            paginationItemLabel: i18n._(t`goals`),
          },
        }}
      />
    </Wrapper>
  );
};

export { ImportGoalsModal };
