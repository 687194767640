import React from 'react';

import { IMultiLangString } from '@learned/types';
import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';
import { Icon, ICONS, ICON_SIZES } from '~/components/Icon';
import Tooltip from '~/components/Tooltip';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  pointer-events: auto !important;

  div.previous-answer-image {
    height: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-width: 22px;

    div.icon-container {
      background-color: ${COLORS.INACTIVE};
      padding-top: 2px;
      border-radius: 4px 4px 0 0;
      margin: 2px 3px -4px 3px;
    }

    div.mandatory-question {
      margin: 2px 3px 3px 3px;
      padding-bottom: 2px;
      border-radius: 4px;
    }

    div#icon-wrapper {
      display: flex;
      justify-content: center;
      padding-bottom: 1px;

      svg {
        width: 10px;
        height: 10px;
      }
    }
  }
`;

const Label = styled.div`
  letter-spacing: -0.13px;
  color: ${COLORS.ICONS_PRIMARY};
  font-size: 12px;
`;

interface IProps {
  isChecked?: boolean;
  onChange: (value: boolean) => void;
  //  should show the history mark on top of the check box when there is a previousRatingTooltip.
  previousRatingTooltip?: IMultiLangString;
  isAnswerObligated?: boolean;
}

const formatTooltip = (reviewName: string) => {
  return (
    <div>
      <span>Previous answer</span>
      <br />
      <span>{reviewName}</span>
    </div>
  );
};

export const NAOption = ({
  isChecked = false,
  onChange,
  previousRatingTooltip = undefined,
  isAnswerObligated = false,
}: IProps) => {
  const getMultiLangString = useMultiLangString();
  return (
    <Container>
      <div className="previous-answer-image">
        {previousRatingTooltip && (
          <Tooltip tooltip={formatTooltip(getMultiLangString(previousRatingTooltip))}>
            <div className={`icon-container ${isAnswerObligated ? 'mandatory-question' : ''}`}>
              <div id="icon-wrapper">
                <Icon
                  icon={ICONS.HISTORY}
                  size={ICON_SIZES.SMALL}
                  color={COLORS.TIPPY_BACKGROUND}
                />
              </div>
            </div>
          </Tooltip>
        )}
        {/* user should be able to skip the question if the answer is not oblicated. */}
        {!isAnswerObligated && (
          <CheckBox onChange={(_e, value) => onChange(value)} size={'22px'} checked={isChecked} />
        )}
      </div>
      <Label>N/A</Label>
    </Container>
  );
};
