import React, { useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
  REVIEW_THEME_STATUS,
} from '@learned/constants';
import { IReviewTheme } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { size } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { type UseFieldArrayReturn, type UseFormReturn } from 'react-hook-form';
import { QUESTION_TYPES } from 'src/constants/questionsTypes';

import { Banner } from '~/components/Banner';
import { BANNER_TYPES } from '~/components/Banner/types';
import { ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { IconOld } from '~/components/IconOld';
import Dot6Icon from '~/components/Icons/Dot6';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { PreviewQuestionCard } from '~/components/Question';
import { Footer as PreviewQuestionCardFooter } from '~/components/Question/Footer';
import { CheckBox } from '~/components/TableList/components/CheckBox';
import { SelectedHeader } from '~/components/TableList/components/SelectedHeader';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import Divider from '~/components/UI/Divider';
import { QuestionPreviewModal } from '~/pages/ReviewThemeSetup/components/QuestionPreviewModal';
import { RatingScaleError } from '~/pages/ReviewThemeView/design';

import { QuestionModal } from './QuestionModal';
import { SkillModal } from './SkillModal';

import emptyState from '~/assets/no-result.webp';

import useBoolState from '~/hooks/useBoolState';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import {
  copyReviewQuestion,
  createReviewQuestion,
  deleteReviewQuestion,
  deleteReviewQuestions,
  updateReviewQuestion,
} from '~/services/reviewQuestions';
import { getReviewTheme } from '~/services/reviewThemes';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { turnArrayIntoMultiLang, turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import {
  AddQuestion,
  BackButton,
  ButtonContainer,
  CardWrapper,
  CreateNewQuestion,
  CreateNewQuestionWrapper,
  Description,
  HoverIcon,
  ImportQuestionButton,
  List,
  MultiSelectCheckBoxWrapper,
  Placeholder,
  QuestionStepFooter,
  QuestionStepTitle,
  SelectedHeaderContainer,
  ThemePublishButton,
  Wrapper,
} from '../design';
import {
  convertQuestionOptions,
  getEvaluators,
  getLabelsForAvailableLanguages,
  getQuestionTypeObject,
  getRatingLabels,
} from '../utils';

import type {
  IGeneralForm,
  IQuestionForm,
  ISelectedSkill,
  ISkill,
  IThemeQuestionDefaultData,
} from '../types';

interface IThemeProps {
  themeId?: string;
  setCurrentSection: (index: number) => void;
  generalFormMethods: UseFormReturn<IGeneralForm>;
  questionsFormMethods: UseFieldArrayReturn<IGeneralForm, 'questions', 'key'>;
  languageState: ILanguageStateReturn;
  reviewThemeId: string;
  onSubmit: (status?: React.BaseSyntheticEvent) => void;
  onPublish: () => void;
  isLoading?: boolean;
  updateLastSavedTime: () => void;
  handleUpdateTheme: () => void;
  triedToSubmit: boolean;
  onBack: () => void;
  skillCategories: {
    value: string;
    label: Record<string, string>;
    levels: number;
    type: string;
  }[];
  differentRatingScales?: string[];
}

interface ISelectedQuestion {
  questionId: string;
  index: number;
}

const CreateThemeStepTwo = ({
  onPublish,
  onBack,
  questionsFormMethods,
  generalFormMethods,
  languageState,
  reviewThemeId,
  updateLastSavedTime,
  handleUpdateTheme,
  skillCategories,
  differentRatingScales,
  isLoading,
}: IThemeProps) => {
  const { i18n } = useLingui();
  const { addToast } = useToasts();
  const getMultiLangString = useMultiLangString();

  const { fields, move, remove, insert, replace } = questionsFormMethods;

  const $isSkillModalOpen = useBoolState(false);
  const $isLoading = useBoolState(isLoading);
  const $showWarningModal = useBoolState(false);
  const [isCreateQuestionModalOpen, setIsCreateQuestionModalOpen] = useState(false);
  const [hidePreview, setHidePreview] = useState(false);
  const [indexItemToAdd, setIndexItemToAdd] = useState<number>(0);
  const [skills, setSkills] = useState<ISkill[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [previewQuestion, setPreviewQuestion] = useState<IQuestionForm | undefined>(undefined);
  const [selectedSkills, setSelectedSkills] = useState<ISelectedSkill[]>([]);
  const [selectedSkill, setSelectedSkill] = useState<ISkill | null>(null);
  const [selectedQuestionToDelete, setSelectedQuestionToDelete] = useState<{
    questionId: string;
    index: number;
  } | null>(null);
  const [selectedQuestions, setSelectedQuestions] = useState<ISelectedQuestion[]>([]);
  const [selectedQuestionToEdit, setSelectedQuestionToEdit] = useState<
    IQuestionForm | null | undefined
  >(null);
  const [selectedTheme, setSelectedTheme] = useState<string>(reviewThemeId);

  const createQuestion = async (value: IQuestionForm) => {
    if (reviewThemeId) {
      const evaluators = Object.keys(value.settings.evaluators)
        .map((key: string) =>
          value.settings?.evaluators?.[key as keyof typeof value.settings.evaluators] ? key : null,
        )
        .filter(Boolean);
      const {
        data: { reviewQuestion },
      } = await createReviewQuestion({
        position: indexItemToAdd,
        // @ts-ignore
        question: {
          theme: selectedTheme ? selectedTheme : reviewThemeId,
          type: value.type.key as REVIEW_QUESTION_TYPES,
          name: convertLanguageValue(value.name),
          description: convertLanguageValue(value.description),
          settings: {
            options: value?.settings?.isManualScale
              ? getRatingLabels(languageState, value?.options)
              : null,
            evaluators,
            isCommentsAllowed: value.settings.isCommentsAllowed,
            isCommentsObligated: value.settings.isCommentsObligated,
            isAnswerObligated: !value.settings.isAnswerObligated,
            isMeasurementReversed: value.settings.isMeasurementReversed,
            isManualScale: value.settings.isManualScale,
            skillCategory:
              value.type.key === REVIEW_QUESTION_TYPES.SKILL_CATEGORY &&
              value.skillOrKpiCategory?.id,
            skills: value.type.key === REVIEW_QUESTION_TYPES.CUSTOM_SKILL && value.settings.skills,
            subTypes: value.settings.subTypes,
          },
        },
      });
      if (!(await handleThemeChange(reviewThemeId, selectedTheme))) {
        insert(indexItemToAdd, {
          ...value,
          id: reviewQuestion.id,
        });
      }
      resetQuestionForm();
      updateLastSavedTime();
    }
  };

  const editQuestion = async (value: IQuestionForm) => {
    if (selectedQuestionToEdit) {
      const evaluators = Object.keys(value.settings.evaluators)
        .map((key: string) =>
          value.settings?.evaluators?.[key as keyof typeof value.settings.evaluators] ? key : null,
        )
        .filter(Boolean);

      const {
        data: { reviewQuestion },
      } = await updateReviewQuestion(selectedQuestionToEdit.id!, {
        type: value.type.key as REVIEW_QUESTION_TYPES,
        theme: selectedTheme ? selectedTheme : reviewThemeId,
        name: convertLanguageValue(value.name),
        description: convertLanguageValue(value.description),
        settings: {
          options: value?.settings?.isManualScale
            ? getRatingLabels(languageState, value?.options)
            : null,
          evaluators,
          isCommentsAllowed: value.settings.isCommentsAllowed,
          isCommentsObligated: value.settings.isCommentsObligated,
          isAnswerObligated: !value.settings.isAnswerObligated,
          isMeasurementReversed: value.settings.isMeasurementReversed,
          isManualScale: value.settings.isManualScale,
          skillCategory:
            value.type.key === REVIEW_QUESTION_TYPES.SKILL_CATEGORY && value.skillOrKpiCategory?.id,
          skills: value.type.key === REVIEW_QUESTION_TYPES.CUSTOM_SKILL && value.settings.skills,
          subTypes: value.settings.subTypes,
        },
      });
      if (!(await handleThemeChange(reviewThemeId, selectedTheme))) {
        replace(
          fields.map((question) => {
            if (question.id === reviewQuestion.id) {
              return {
                ...value,
                settings: {
                  ...value.settings,
                  skillCategory: value?.skillOrKpiCategory?.id,
                },
                id: reviewQuestion.id,
              };
            }
            return question;
          }),
        );
      } else {
        const indexToRemove = fields.findIndex(
          (question) => question.id === selectedQuestionToEdit.id,
        );
        remove(indexToRemove);
      }
      resetQuestionForm();
      updateLastSavedTime();
    }
  };

  const onSubmit = async (value: IQuestionForm) => {
    if (selectedQuestionToEdit) {
      editQuestion(value);
    } else {
      createQuestion(value);
    }
  };

  const resetQuestionForm = () => {
    setSelectedSkills([]);
    setSelectedSkill(null);
    setSelectedQuestionToEdit(null);
  };

  const createNewQuestion = () => {
    setIsCreateQuestionModalOpen(true);
    setIndexItemToAdd(size(fields));
  };

  const insertNewQuestion = (index: number) => {
    setIsCreateQuestionModalOpen(true);
    setIndexItemToAdd(index + 1);
  };

  const closeQuestionModal = () => {
    setIsCreateQuestionModalOpen(false);
    setHidePreview(false);
    resetQuestionForm();
  };

  const handleThemeChange = async (existingTheme: string, newTheme: string) => {
    if (existingTheme !== newTheme) {
      const response = await getReviewTheme(newTheme);
      const data = response?.data;
      const reviewTheme: IReviewTheme | undefined = data?.[API_RETURN_FIELDS.REVIEW_THEME];

      if (data && reviewTheme) {
        addToast({
          title: i18n._(t`Success`),
          type: TOAST_TYPES.SUCCESS,
          subtitle: i18n._(
            t`Question successfully moved to ${getMultiLangString(reviewTheme.name)}`,
          ),
        });
        return true;
      }
    }
    return false;
  };

  const duplicateQuestion = async (id: IQuestionForm['id'], index?: number) => {
    if (id) {
      const {
        data: { reviewQuestion },
      } = await copyReviewQuestion(id);

      const questionType = getQuestionTypeObject(reviewQuestion.type);

      if (!questionType) {
        return;
      }

      insert(index ?? questionsFormMethods.fields.length, {
        id: reviewQuestion.id,
        name: turnMultiLangIntoArray(reviewQuestion.name, languageState.companyLanguages),
        description: turnMultiLangIntoArray(
          reviewQuestion.description || {},
          languageState.companyLanguages,
        ),
        type: questionType,
        settings: {
          isCommentsAllowed:
            (reviewQuestion.settings as IThemeQuestionDefaultData)?.isCommentsAllowed ?? false,
          isCommentsObligated:
            (reviewQuestion.settings as IThemeQuestionDefaultData)?.isCommentsObligated ?? false,
          isMeasurementReversed:
            (reviewQuestion.settings as IThemeQuestionDefaultData)?.isMeasurementReversed ?? false,
          isManualScale:
            (reviewQuestion.settings as IThemeQuestionDefaultData)?.isManualScale ?? false,
          evaluators: getEvaluators(
            (reviewQuestion.settings as IThemeQuestionDefaultData)
              .evaluators as REVIEW_QUESTION_EVALUATORS[],
          ),
          isAnswerObligated: false,
          skillCategory:
            (reviewQuestion.settings as IThemeQuestionDefaultData).skillCategory || undefined,
          subTypes: reviewQuestion.settings?.subTypes || undefined,
        },
        options: convertQuestionOptions(reviewQuestion, languageState.companyLanguages),
      });

      setIsCreateQuestionModalOpen(false);
      updateLastSavedTime();
    }
  };

  const handleTitleClick = (question: IQuestionForm) => {
    setPreviewQuestion({
      ...question,
      settings: {
        ...question.settings,
        isAnswerObligated: !question.settings.isAnswerObligated, // Whoever implemented this messed up
      },
    });
  };

  const handleEdit = (questionId: string) => {
    setIsCreateQuestionModalOpen(true);
    const question = fields.find(({ id }) => id === questionId);

    if (question?.type?.key === REVIEW_QUESTION_TYPES.CUSTOM_SKILL) {
      setSelectedSkills(question?.settings?.skills || []);
    }

    if (question) {
      setSelectedQuestionToEdit({
        ...question,
        name: getLabelsForAvailableLanguages(languageState, question.name),
        description: getLabelsForAvailableLanguages(languageState, question.description || []),
      });
    }
  };

  const isDraft = generalFormMethods.watch('status') === REVIEW_THEME_STATUS.DRAFT;

  const handleSelectedSkillSubmit = (selectedSkill: ISelectedSkill) => {
    setSelectedSkills([
      ...selectedSkills.filter(({ skill }: ISelectedSkill) => skill !== selectedSkill.skill),
      selectedSkill,
    ]);
    $isSkillModalOpen.off();
  };

  const handleDeleteConfirm = async () => {
    if (selectedQuestionToDelete) {
      await deleteReviewQuestion(selectedQuestionToDelete.questionId);
      remove(selectedQuestionToDelete.index);
      closeQuestionModal();
      updateLastSavedTime();
    }
  };

  const handleMultipleDeleteConfirm = async () => {
    $isLoading.on();
    if (selectedQuestions.length > 0) {
      await deleteReviewQuestions(selectedQuestions.map(({ questionId }) => questionId));
      remove(selectedQuestions.map(({ index }) => index));

      setSelectedQuestions([]);
      closeQuestionModal();
      updateLastSavedTime();
    }
    $isLoading.off();
  };

  const handleDeleteFromQuestionModal = (id: IQuestionForm['id']) => {
    const index = fields.findIndex((question) => question.id === id);

    if (!id || index === -1) {
      return;
    }
    setSelectedQuestionToDelete({
      questionId: id,
      index,
    });
    $showWarningModal.on();
  };

  const getPreviewEvaluators = () => {
    if (!previewQuestion) {
      return [];
    }
    const evaluators: REVIEW_QUESTION_EVALUATORS[] = [];
    if (previewQuestion.settings.evaluators.employee) {
      evaluators.push(REVIEW_QUESTION_EVALUATORS.EMPLOYEE);
    }
    if (previewQuestion.settings.evaluators.peer) {
      evaluators.push(REVIEW_QUESTION_EVALUATORS.PEER);
    }
    if (previewQuestion.settings.evaluators.coach) {
      evaluators.push(REVIEW_QUESTION_EVALUATORS.COACH);
    }
    return evaluators;
  };

  const handleSelectAllQuestions = () => {
    let allQuestions: ISelectedQuestion[] = [];
    if (fields.length !== selectedQuestions.length) {
      allQuestions = fields
        .filter((question) => !!question.id)
        .map((question, index) => {
          return {
            questionId: question.id as string,
            index,
          };
        });
    }

    setSelectedQuestions(allQuestions);
  };

  const handleQuestionSelect = (id: IQuestionForm['id']) => {
    const index = fields.findIndex((question) => question.id === id);

    if (!id || index === -1) {
      return;
    }

    const selectedQuestion = selectedQuestions.find(({ questionId }) => questionId === id);

    if (selectedQuestion) {
      setSelectedQuestions(
        selectedQuestions.filter(({ questionId }) => questionId !== selectedQuestion.questionId),
      );
    } else {
      setSelectedQuestions([
        ...selectedQuestions,
        {
          questionId: id,
          index,
        },
      ]);
    }
  };

  const checkIsItemSelected = (questionId?: string) => {
    return selectedQuestions.findIndex((question) => questionId === question.questionId) >= 0;
  };

  return (
    <Wrapper>
      <QuestionStepTitle>{i18n._(t`Questions`)}</QuestionStepTitle>
      <div>
        {size(fields) ? (
          <div>
            <Description>
              <Trans>
                Add questions to your theme and import these questions into your reviews. You can
                also add, edit or delete questions later. Drag questions in the desired order.
              </Trans>
              {(differentRatingScales ?? []).length > 0 && (
                <RatingScaleError>
                  <Banner
                    type={BANNER_TYPES.WARNING}
                    subTitle={i18n._(
                      t`Please make sure all rated questions in a theme use the same rating scale. Otherwise it
            will not be possible to calculate an average rating of this theme.`,
                    )}
                  />
                </RatingScaleError>
              )}
            </Description>
            <List>
              <SelectedHeaderContainer>
                {selectedQuestions.length > 0 ? (
                  <SelectedHeader
                    isLoading={$isLoading.value}
                    multiSelect={{
                      checkedCount: selectedQuestions.length,
                      isAllChecked: fields.length === selectedQuestions.length,
                      onCheckAll: handleSelectAllQuestions,
                      onSelectItem: handleQuestionSelect,
                      isItemChecked: checkIsItemSelected,
                      onDelete: $showWarningModal.on,
                    }}
                  />
                ) : (
                  <div>
                    <MultiSelectCheckBoxWrapper onClick={handleSelectAllQuestions}>
                      <CheckBox isHovered isSelected={fields.length === selectedQuestions.length} />

                      <span>
                        <Trans>Question</Trans>
                      </span>
                    </MultiSelectCheckBoxWrapper>
                  </div>
                )}
              </SelectedHeaderContainer>
              <DragDropContext
                onDragEnd={(result) => {
                  move(result.source.index, result?.destination?.index || 0);
                  handleUpdateTheme();
                }}
              >
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {fields.map((question, idx) => {
                        const { id, name, type /* ...rest*/ } = question;
                        const questionType = Object.values(REVIEW_QUESTION_TYPES).find(
                          (value) => value === type.key,
                        );
                        if (!id || !questionType) {
                          return null;
                        }
                        const evaluators: REVIEW_QUESTION_EVALUATORS[] = [];
                        if (question.settings.evaluators.coach) {
                          evaluators.push(REVIEW_QUESTION_EVALUATORS.COACH);
                        }
                        if (question.settings.evaluators.peer) {
                          evaluators.push(REVIEW_QUESTION_EVALUATORS.PEER);
                        }
                        if (question.settings.evaluators.employee) {
                          evaluators.push(REVIEW_QUESTION_EVALUATORS.EMPLOYEE);
                        }
                        return (
                          <Draggable key={id} draggableId={id} index={idx}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <CardWrapper>
                                  <div className="question">
                                    <HoverIcon isVisible={snapshot.isDragging}>
                                      <Dot6Icon size={32} />
                                    </HoverIcon>
                                    <PreviewQuestionCard
                                      showCheckbox
                                      onSelect={() => handleQuestionSelect(question.id)}
                                      isSelected={checkIsItemSelected(question.id)}
                                      templates={question.templates}
                                      languageState={languageState}
                                      onClickTitle={() => handleTitleClick(question)}
                                      index={idx}
                                      type={type.key as QUESTION_TYPES}
                                      name={name}
                                      skillCategory={getMultiLangString(
                                        question.skillOrKpiCategory?.value || '',
                                      )}
                                      isActive={snapshot.isDragging}
                                      error={
                                        (differentRatingScales ?? []).includes(id)
                                          ? 'Different rating scale'
                                          : ''
                                      }
                                      evaluators={evaluators}
                                      theme={{
                                        name: turnArrayIntoMultiLang(
                                          generalFormMethods.watch('name'),
                                        ),
                                        icon: generalFormMethods.watch('icon') as ICONS,
                                        iconColor: generalFormMethods.watch('iconColor'),
                                      }}
                                    >
                                      <PreviewQuestionCardFooter
                                        onDelete={() => {
                                          setSelectedQuestionToDelete({
                                            questionId: id,
                                            index: idx,
                                          });
                                          $showWarningModal.on();
                                        }}
                                        onDuplicate={() => duplicateQuestion(id, idx + 1)}
                                        onEdit={() => handleEdit(id)}
                                        isEditButtonVisible
                                      />
                                    </PreviewQuestionCard>
                                  </div>
                                  {size(fields) - 1 > idx && (
                                    <button
                                      className="newQuestion"
                                      onClick={() => insertNewQuestion(idx)}
                                    >
                                      <AddQuestion>
                                        <IconOld name="AddCircle" width={14} height={14} />
                                      </AddQuestion>
                                    </button>
                                  )}
                                </CardWrapper>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <CreateNewQuestionWrapper>
                <CreateNewQuestion onClick={createNewQuestion}>
                  <IconOld name="AddCircle" width={14} height={14} />
                  <span>
                    <Trans>Create new</Trans>
                  </span>
                </CreateNewQuestion>
              </CreateNewQuestionWrapper>
            </List>
          </div>
        ) : (
          <Placeholder>
            <div className="description">
              <span>
                <Trans>No questions yet…</Trans>
              </span>
            </div>
            <img src={emptyState} alt={i18n._(t`No data found`)} width="216px" />
            <ButtonContainer>
              <ImportQuestionButton type="button" onClick={createNewQuestion}>
                <Trans>Create first question</Trans>
              </ImportQuestionButton>
            </ButtonContainer>
          </Placeholder>
        )}
        <Divider />
        <QuestionStepFooter>
          <BackButton type="button" onClick={onBack}>
            <IconOld name="ChevronBack" width={16} height={16} className="icon" />
            <span>{i18n._(t`Back`)}</span>
          </BackButton>
          {!!size(fields) && isDraft && (
            <ThemePublishButton
              onClick={onPublish}
              type="button"
              variant={ButtonVariant.PRIMARY}
              disabled={$isLoading.value}
              isLoading={$isLoading.value}
              label={i18n._(t`Publish`)}
            />
          )}
        </QuestionStepFooter>
      </div>
      {isCreateQuestionModalOpen && (
        <QuestionModal
          selectTheme
          hidePreview={hidePreview}
          languageState={languageState}
          onClose={closeQuestionModal}
          onDelete={handleDeleteFromQuestionModal}
          onSubmit={onSubmit}
          onDuplicate={duplicateQuestion}
          setIsSkillModalOpen={$isSkillModalOpen.set}
          skillCategories={skillCategories}
          selectedSkills={selectedSkills}
          setSelectedSkills={setSelectedSkills}
          skills={skills}
          setSelectedSkill={setSelectedSkill}
          setCurrentStep={setCurrentStep}
          selectedQuestionToEdit={selectedQuestionToEdit}
          reviewThemeId={reviewThemeId}
          selectedTheme={selectedTheme}
          setSelectedTheme={setSelectedTheme}
          setPreviewQuestion={(question: IQuestionForm) => {
            setPreviewQuestion({
              ...question,
              settings: {
                ...question.settings,
                isAnswerObligated: !question.settings.isAnswerObligated, // Whoever implemented this messed up
              },
            });
          }}
        />
      )}
      {$isSkillModalOpen.value && (
        <SkillModal
          onClose={() => $isSkillModalOpen.off()}
          onSubmit={handleSelectedSkillSubmit}
          setSelectedSkill={setSelectedSkill}
          selectedSkill={selectedSkill}
          skills={skills}
          setSkills={setSkills}
          selectedSkills={selectedSkills}
          currentStep={currentStep}
        />
      )}
      {$showWarningModal.value && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.DELETE}
          title={i18n._(t`Delete question?`)}
          description={i18n._(
            t`Are you sure you want to delete this question? The question will also be deleted from the review templates.`,
          )}
          cancelButton={i18n._(t`Cancel`)}
          submitButton={i18n._(t`Delete`)}
          onClose={$showWarningModal.off}
          onSubmit={handleDeleteConfirm}
        />
      )}
      {$showWarningModal.value && selectedQuestions.length > 0 && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.DELETE}
          title={i18n._(t`Delete?`)}
          description={i18n._(
            t`Are you sure you want to delete this question(s)? The question(s) will also be deleted from the review templates.`,
          )}
          cancelButton={i18n._(t`Cancel`)}
          submitButton={i18n._(t`Delete`)}
          onClose={$showWarningModal.off}
          onSubmit={handleMultipleDeleteConfirm}
        />
      )}
      {!!previewQuestion && (
        <QuestionPreviewModal
          question={previewQuestion}
          onEdit={async () => {
            const isConfirmed = await confirm({
              type: CONFIRMATION_MODAL_TYPE.WARNING,
              title: i18n._(t`Be aware!`),
              description: i18n._(
                t`Changing this question will affect all review templates that use this question.`,
              ),
            });
            if (isConfirmed && previewQuestion) {
              setPreviewQuestion(undefined);
              setHidePreview(true);
              setSelectedSkills(previewQuestion?.settings?.skills || []);
              handleEdit(previewQuestion.id as string);
            }
          }}
          evaluators={getPreviewEvaluators()}
          onClose={() => setPreviewQuestion(undefined)}
          themeId={selectedTheme}
          themeName={
            generalFormMethods
              .watch('name')
              ?.find((i) => i.locale === languageState?.companyPrimaryLanguage?.locale)?.value
          }
          themeIcon={generalFormMethods.watch('icon')}
          iconColor={generalFormMethods.watch('iconColor')}
        />
      )}
    </Wrapper>
  );
};

export { CreateThemeStepTwo };
