import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Modal from '~/components/Modal';
import { SearchField } from '~/components/Text';

import CustomUsersTab from './customUsersTab';
import PlaceholderTab from './placeholderTab';
import UsersIntegrationTab from './usersIntegrationTab';

import { getTeams } from '~/services/teams';
import getIntegrationToolName from '~/utils/getIntegrationToolName';

import Tabs from '../Tabs';

const TabsWrapper = styled.div`
  width: 100%;
`;

const SearchFieldWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: 24px;
`;

const UsersWrapper = styled.div`
  padding: 0 25px 0 24px;
`;

const inviteModalHeaderStyles = {
  padding: '0 24px',
};

const inviteModalContentStyles = {
  padding: '0',
};

const FullWidthPlaceholder = styled.div`
  width: 100%;
`;
// TODO: Move to Pages & update to typescript
function InviteUsersModal({ onModalClose, integrationSettings, invites }) {
  const [teams, setTeams] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const { i18n } = useLingui();

  useEffect(() => {
    function retrieveData() {
      getTeams().then((teams = {}) => setTeams(_.sortBy(teams, (team) => team.name.toLowerCase())));
    }
    retrieveData();
  }, []);

  const placeholderTabItem = { key: 'placeholder', label: (i18n) => i18n._(t`Via API`) };

  let tabItems = [
    { key: 'manually', label: (i18n) => i18n._(t`Manually`) },
    ...integrationSettings
      .filter((integration) => integration.integrationModules.importUsers.enabled)
      .map((integration) => ({
        key: integration.id,
        label: () => `${integration.name} - ${getIntegrationToolName(integration)}`,
      })),
  ];

  if (tabItems.length === 1) {
    tabItems.push(placeholderTabItem);
  }

  const [activeTab, setActiveTab] = useState(tabItems[0].key);

  return (
    <Modal
      title={i18n._(t`Invite new member`)}
      onClose={() => onModalClose(false, true)}
      minWidth={1100}
      width={1100}
      hideFooter={true}
      headerStyles={inviteModalHeaderStyles}
      contentStyles={inviteModalContentStyles}
      disableClose={loading}
    >
      <TabsWrapper>
        <Tabs
          currentItem={activeTab}
          items={tabItems}
          handleChangeTab={setActiveTab}
          isSmall
          actions={
            activeTab !== 'manually' ? (
              <SearchFieldWrapper>
                <SearchField
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder={i18n._(t`Search`)}
                  style={{ borderRadius: '6px', maxHeight: '32px', fontSize: '14px' }}
                />
              </SearchFieldWrapper>
            ) : (
              <FullWidthPlaceholder />
            )
          }
          disabled={loading}
        />
      </TabsWrapper>
      <UsersWrapper>
        {activeTab === 'manually' && (
          <CustomUsersTab
            onModalClose={onModalClose}
            invites={invites}
            teams={teams}
            onChangeLoading={setLoading}
          />
        )}
        {activeTab === 'placeholder' && <PlaceholderTab />}
        {activeTab !== 'placeholder' && activeTab !== 'manually' && (
          <UsersIntegrationTab
            onModalClose={onModalClose}
            invites={invites}
            teams={teams}
            search={search}
            integration={integrationSettings.find((integration) => integration.id === activeTab)}
          />
        )}
      </UsersWrapper>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.locale.lang,
    users: state.users.data,
    companies: state.companies.data,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(InviteUsersModal);
