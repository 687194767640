import React, { useEffect } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import { CheckBox } from '~/components/SelectedHeader/components/CheckBox';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import ActivityItem from './ActivityItem';
import GoalItem from './GoalItem';

import useBoolState from '~/hooks/useBoolState';
import { updateUserActivityProgress } from '~/services/userActivities';

import { CheckBoxWrapper, TreeItemActivities, TreeItemRow, TreeItemWrapper } from '../design';

import type { IGoal, IActivity } from '@learned/types';

interface IProps {
  item: IGoal;
  activitiesReadonly: boolean;
  isIndividualTab: boolean;
  refreshGoals: () => void;
  isUpdateActivityProgress: boolean;
  isUpdateGoalProgress: boolean;
  isOpenAll: boolean;
  onGoalClick: (id: string) => void;
  level?: number;
  menuItems?: string[];
  isHideMenuItems?: boolean;
  isPublicPage?: boolean;
  isGoalSelected?: boolean;
  onSelectItem?: (goal: IGoal) => void;
  isUserAllowedToSelectGoal?: ((item: IGoal) => boolean) | undefined;
  showMultiSelect?: boolean;
}

const GoalTreeItem = ({
  item,
  isOpenAll,
  refreshGoals,
  menuItems,
  isUpdateActivityProgress,
  isIndividualTab,
  onGoalClick,
  level,
  isHideMenuItems = false,
  isPublicPage,
  onSelectItem,
  isGoalSelected,
  isUserAllowedToSelectGoal,
  showMultiSelect,
}: IProps) => {
  const { i18n } = useLingui();
  const $open = useBoolState(false);
  const isChildren = !isEmpty(item.children);
  const isActivities = !isEmpty(item.activities);

  useEffect(() => {
    isOpenAll ? $open.on() : $open.off();

    // eslint-disable-next-line
  }, [isOpenAll]);

  const updateActivity = async (id: string, data: any) => {
    await updateUserActivityProgress(id, data);
    await refreshGoals();
  };

  if (level && level > 3) {
    return null;
  }

  return (
    <TreeItemWrapper className="goal-tree-item">
      <TreeItemRow isSelected={!!isGoalSelected}>
        {showMultiSelect && onSelectItem && (
          <CheckBoxWrapper>
            <ToolTip
              tooltip={i18n._(t`You don't have rights to edit this goal.`)}
              placement={TOOLTIP_PLACEMENTS.TOP}
              disabled={isUserAllowedToSelectGoal?.(item)}
            >
              <div className="goal-select">
                <CheckBox
                  isHovered
                  isDisabled={!isUserAllowedToSelectGoal?.(item)}
                  isSelected={isGoalSelected}
                  onChange={(event) => {
                    event.preventDefault();
                    onSelectItem?.(item);
                  }}
                />
              </div>
            </ToolTip>
          </CheckBoxWrapper>
        )}
        <GoalItem
          key={item.id}
          onGoalClick={onGoalClick}
          item={item}
          refreshGoals={refreshGoals}
          isShowCalcProgress
          isIndividualTab={isIndividualTab}
          handleCollapse={$open.toggle}
          isCollapsed={$open.value}
          menuItems={menuItems}
          isHideMenuItems={isHideMenuItems}
          isPublicPage={isPublicPage}
        />
      </TreeItemRow>
      {isActivities && $open.value && (
        <TreeItemActivities>
          {map(item.activities as IActivity[], (activity: IActivity) => (
            <ActivityItem
              key={activity.id}
              activity={activity}
              activitiesReadonly={!isUpdateActivityProgress}
              isGoalTree
              isOnClick={false}
              // @ts-ignore
              onUpdate={(data) => updateActivity(activity.id, data)}
              isPublicPage={isPublicPage}
            />
          ))}
        </TreeItemActivities>
      )}

      {isChildren &&
        $open.value &&
        sortBy(item.children, (child) => child.name.toLowerCase()).map((child) => (
          // @ts-ignore
          <GoalTreeItem
            key={child.id}
            item={child}
            isOpenAll={isOpenAll}
            level={level ? level + 1 : 1}
            isPublicPage={isPublicPage}
          />
        ))}
    </TreeItemWrapper>
  );
};

GoalTreeItem.menuItems = GoalItem.menuItems;

export default GoalTreeItem;
