import React, { useEffect, useMemo, useState } from 'react';

import { COMPANY_SIZE, JOB_TEMPLATE_STATUS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Controller, type UseFormReturn } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Dropdown } from '~/components/Dropdown';
import { ICONS, ICON_SIZES } from '~/components/Icon';
import { Input } from '~/components/Input';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { convertLangToLocale } from './utils';

import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { getIndustries } from '~/services/industries';
import { getJobTemplateNames } from '~/services/jobTemplates';

import { ButtonsWrapper, FormWrapper, Subtitle, Title, SectionWrapper, Label } from '../design';

import type { IRegisterWithCompanyForm } from '../types';
import type { IIndustry } from '@learned/types';
import type { I18n } from '@lingui/core';

interface IAccountSectionProps {
  formMethods: UseFormReturn<IRegisterWithCompanyForm>;
}

interface IJobTemplateName {
  name: string;
  id: string;
}

const COMPANY_SIZE_ITEM = Object.values(COMPANY_SIZE).map((item) => ({
  id: item,
  key: item,
  name: item,
  translated: (i18n: I18n) => i18n._(t`${item} employees`),
}));

const initialFilters = {
  search: '',
  statuses: [JOB_TEMPLATE_STATUS.PUBLISHED],
};

const initialOptions = {
  skip: 0,
  limit: 10,
  projection: { name: 1, id: 1, aliases: 1 },
};

const AccountSection = ({ formMethods }: IAccountSectionProps) => {
  const { i18n } = useLingui();
  const {
    watch,
    setValue,
    register,
    control,
    formState: { errors },
    handleSubmit,
    getValues,
  } = formMethods;
  const [industries, setIndustries] = useState<IIndustry[]>([]);
  const [jobTemplateNames, setJobTemplateNames] = useState<IJobTemplateName[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearch = useDebounce(searchQuery, 500);
  const { addToast } = useToasts();
  const lang = getValues('lang');

  const $isNextButtonTouched = useBoolState(false);

  const goToNextSection = () => {
    setValue('step', 5);
  };

  const handleNextButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    $isNextButtonTouched.set(true);
    if (
      // Ensure the name field is checked here to highlight all empty fields during validation.
      watch('name') !== '' &&
      (validationErrors.companyIndustry || validationErrors.companySize)
    ) {
      e.preventDefault();
    }
    // display a toast if the user has not filled out all obligated fields
    if (errors.name || validationErrors.companyIndustry || validationErrors.companySize) {
      addToast({
        title: i18n._(t`Missing information`),
        subtitle: i18n._(t`please fill out all obligated fields`),
        type: TOAST_TYPES.ERROR,
      });
    }
  };

  const validationErrors = useMemo(() => {
    const companyIndustry = watch('industry');
    const companySize = watch('size');

    return {
      companyIndustry: !companyIndustry ? i18n._(t`Company industry is required`) : undefined,
      companySize: !companySize ? i18n._(t`Company size is required`) : undefined,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify([watch('industry'), watch('size')])]);

  useEffect(() => {
    const fetch = async () => {
      const industries = await getIndustries();
      setIndustries(industries.data.industries);
    };

    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const jobTemplates = await getJobTemplateNames(
        { ...initialFilters, search: debouncedSearch },
        initialOptions,
        // @ts-ignore
        lang,
      );
      setJobTemplateNames(jobTemplates.data.jobTemplates);
    };

    fetch();
    // eslint-disable-next-line
  }, [debouncedSearch]);

  return (
    <SectionWrapper>
      <Title>
        <Trans>Create your account</Trans>
      </Title>
      <Subtitle>
        <Trans>Step 4/5</Trans>
      </Subtitle>
      <form onSubmit={handleSubmit(goToNextSection)}>
        <FormWrapper>
          <Input
            type="text"
            width="285px"
            label={i18n._(t`Company Name`)}
            placeholder={i18n._(t`Company Name`)}
            register={register('name', {
              required: { value: true, message: i18n._(t`Company name is required`) },
              maxLength: {
                value: 50,
                message: i18n._(t`${watch('name')?.length + 1}/50 characters`),
              },
              pattern: {
                value: /^[a-zA-Z0-9\s]*$/,
                message: i18n._(t`Characters are not valid`),
              },
            })}
            error={errors.name?.message}
          />
          <Controller
            name="industry"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <div>
                <Label
                  marginBottom="4px"
                  error={Boolean($isNextButtonTouched.value && validationErrors.companyIndustry)}
                >
                  <Trans>Company industry</Trans>
                </Label>
                <Dropdown
                  className="dropdown"
                  isSingleSelect
                  placeholder={i18n._(t`Select industry`)}
                  items={industries}
                  onChange={(item) => onChange(item?.id)}
                  selectedItem={industries.find((item) => item.id === value)}
                  stringifyItem={(item) => item.name[convertLangToLocale(lang)]}
                  isSearchable
                  error={$isNextButtonTouched.value ? validationErrors.companyIndustry : ''}
                />
              </div>
            )}
          />
          <Controller
            name="size"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <div>
                <Label
                  marginBottom="4px"
                  error={Boolean($isNextButtonTouched.value && validationErrors.companySize)}
                >
                  <Trans>Company size</Trans>
                </Label>
                <Dropdown
                  className="dropdown"
                  isSingleSelect
                  placeholder={i18n._(t`Select company size`)}
                  items={COMPANY_SIZE_ITEM}
                  onChange={(v) => {
                    onChange(v?.key);
                  }}
                  selectedItem={COMPANY_SIZE_ITEM.find((item) => item.key === value)}
                  stringifyItem={(item) => item.translated(i18n)}
                  skipSort
                  error={$isNextButtonTouched.value ? validationErrors.companySize : ''}
                />
              </div>
            )}
          />
          <Controller
            name="job"
            control={control}
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => (
              <div>
                <Label marginBottom="4px">
                  <span>
                    <Trans>Your job</Trans>
                  </span>
                  <span>
                    <Trans>(optional)</Trans>
                  </span>
                </Label>
                <Dropdown
                  isSingleSelect
                  className="dropdown"
                  selectedItem={jobTemplateNames.find((item) => item.id === value)}
                  items={jobTemplateNames}
                  onChange={(item) => {
                    onChange((item as IJobTemplateName)?.id || '');
                  }}
                  placeholder={i18n._(t`Search job`)}
                  stringifyItem={(item) => (item as IJobTemplateName).name}
                  onSearchChange={(query) => setSearchQuery(query)}
                  isSearchable
                />
              </div>
            )}
          />
          <Input
            type="text"
            width="285px"
            label={
              <>
                <Label>
                  <span>
                    <Trans>Phone</Trans>
                  </span>
                  <span>
                    <Trans>(optional)</Trans>
                  </span>
                </Label>
              </>
            }
            placeholder={i18n._(t`+31 6 12 34 56 78`)}
            register={register('phone')}
          />
          <ButtonsWrapper>
            <Button
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.NAVIGATION_PRIMARY}
              label={i18n._(t`Next`)}
              onClick={handleNextButtonClick}
            />
            <Button
              className="back"
              label={i18n._(t`Back`)}
              type="button"
              variant={ButtonVariant.TEXT_PRIMARY}
              icon={ICONS.BACK}
              size={ButtonSize.MEDIUM}
              iconSize={ICON_SIZES.SMALL}
              onClick={() => setValue('step', 3)}
            />
          </ButtonsWrapper>
        </FormWrapper>
      </form>
    </SectionWrapper>
  );
};

export { AccountSection };
