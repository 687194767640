import React, { Component } from 'react';

import get from 'lodash/get';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getCompanies, getCurrentCompanyId } from '~/selectors/baseGetters';
import getUserFullName from '~/utils/getUserFullName';
import goIntercom, { INTERCOM_ID } from '~/vendor/Intercom';

class Intercom extends Component {
  constructor(props) {
    super(props);

    window.intercomSettings = this.createIntercomSettings();

    goIntercom();

    // onRoute change update intercom on each page
    this.props.history.listen((_location, _action) => {
      window.Intercom('update');
    });
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const intercomUserName = get(window.intercomSettings, 'name');
    const intercomUserEmail = get(window.intercomSettings, 'email');

    // login
    if (!intercomUserName && !intercomUserEmail && user) {
      window.intercomSettings = this.createIntercomSettings(user);
      window.Intercom('boot', window.intercomSettings);
    }

    // logout
    if (prevProps.user && !user) {
      window.intercomSettings = this.createIntercomSettings(user);
      window.Intercom('shutdown');
      window.Intercom('boot', window.intercomSettings);
    }
  }

  createIntercomSettings = (user) => {
    const { companyId, companies } = this.props;

    let intercomSettings = {
      app_id: INTERCOM_ID,
    };

    if (user) {
      const connection = user?.connections?.find((c) => c.company === companyId);

      intercomSettings = {
        app_id: INTERCOM_ID,
        name: getUserFullName(user),
        email: user.email,
        isUser: true,
        isCoach: user.isCoach,
        isAdmin: user.isAdmin,
        isOwner: user.isOwner,
        status: connection?.status,
        locale: user.locale,
        // created_at: 1312182000 // Signup date as a Unix timestamp
      };

      // add company data
      if (companyId) {
        const company = Object.values(companies)?.find((company) => company.id === companyId);

        intercomSettings.company = {
          id: companyId,
          name: company?.name,
          ...(company?.meta?.createdDate
            ? { remote_created_at: new Date(company.meta.createdDate).getTime() / 1000 }
            : {}), // time store in epoch seconds
        };
      }
    }

    return intercomSettings;
  };

  render() {
    return <div />;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    companyId: getCurrentCompanyId(state),
    companies: getCompanies(state),
  };
};

export default connect(mapStateToProps)(withRouter(Intercom));
