import React from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import {
  MoveActionContainer,
  MoveSkillCategoryWrapper,
} from '~/components/Modals/AddSkillModal/design';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';

import useBoolState from '~/hooks/useBoolState';
import { updateSkill } from '~/services/skills';

import type { ISkill } from '@learned/types';

type MoveSkillCategoryProps = {
  skill: ISkill;
  skillName: string;
  searchTerm: string;
  currentSkillCategory: string;
  moveToCategory: string;
  moveToCategoryId: string;
  selectedSkill?: ISkill;
  setSelectedSkill: any;
  addSkill: (skill: any) => void;
  refreshCompanySkills?: () => void;
  isSearchLoading: { on: () => void; off: () => void };
  setSource: (skill: ISkill) => void;
};

const MoveSkillCategory = ({
  skill,
  skillName,
  searchTerm,
  currentSkillCategory,
  moveToCategory,
  moveToCategoryId,
  selectedSkill,
  setSelectedSkill,
  addSkill,
  refreshCompanySkills,
  isSearchLoading,
  setSource,
}: MoveSkillCategoryProps) => {
  const { i18n } = useLingui();
  const $isShowWarningModal = useBoolState(false);

  const moveSkillFromCategoryToCategory = async (toCategory: string) => {
    // move skill from category to category
    isSearchLoading.on();
    const updatedSkill = { ...skill, skillCategory: toCategory };
    await updateSkill(updatedSkill);
    refreshCompanySkills?.();
    setSource(updatedSkill);
    isSearchLoading.off();
  };

  return (
    <MoveSkillCategoryWrapper>
      {searchTerm} is found in {currentSkillCategory}{' '}
      <MoveActionContainer isSelected={selectedSkill?.id === skill.id}>
        <button
          key={skill.id}
          className="item"
          onClick={() => {
            setSelectedSkill(skill);
            setSource(skill);
          }}
        >
          {i18n._(t`${skillName}`)}
        </button>

        <Button
          icon={ICONS.FOLDER}
          label={i18n._(t`Move ${skillName} to ${moveToCategory}`)}
          variant={ButtonVariant.SECONDARY}
          onClick={() => {
            $isShowWarningModal.on();
          }}
        />
      </MoveActionContainer>
      {$isShowWarningModal.value && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.WARNING}
          description={i18n._(t`Moving ${skillName} to ${moveToCategory} affects all jobs`)}
          cancelButton={i18n._(t`Cancel`)}
          submitButton={i18n._(t`Continue`)}
          onClose={$isShowWarningModal.off}
          onSubmit={async () => {
            await moveSkillFromCategoryToCategory(moveToCategoryId);
            addSkill(skill);
          }}
        />
      )}
    </MoveSkillCategoryWrapper>
  );
};

export { MoveSkillCategory };
