import { ROLES } from '@learned/constants';

import routes from '~/constants/routes';

// pages to auto collapse menu
export default ({ role, companyId, teamId }) => [
  routes.CONVERSATION.build({ companyId, role: ROLES.USER }),
  routes.CONVERSATION_CREATE.build({ companyId, role: ROLES.USER }),
  routes.CONVERSATION_UPDATE.build({ companyId, role: ROLES.USER }),
  routes.CONVERSATION_SUPERVISION_USER.build({ companyId, teamId, role: ROLES.COACH }),
  routes.REVIEW_GIVE_FEEDBACK_PEER.build({ companyId, role: ROLES.USER }),
  routes.REVIEW_GIVE_FEEDBACK_SELF.build({ companyId, role: ROLES.USER }),
  routes.PATH.build({ companyId, role: ROLES.USER }),
  routes.USER_PATH.build({ companyId, role: ROLES.USER }),
  routes.USER_PATH_SUPERVISION_USER.build({ companyId, teamId, role: ROLES.COACH }),
  routes.USER_PATH_SUPERVISION_USER.build({ companyId, role: ROLES.ADMIN }),
  routes.GOAL.build({ companyId, role: ROLES.USER }),
  routes.GOAL.build({ companyId, teamId, role: ROLES.COACH }),
  routes.GOAL_CREATE.build({ companyId, role: ROLES.USER }),
  routes.GOAL_UPDATE.build({ companyId, role: ROLES.USER }),
  routes.JOB_PROFILE.build({ companyId, role: ROLES.USER }),
  routes.PROFILE.build({ companyId, role: ROLES.USER }),
  routes.PROFILE_SETTINGS.build({ companyId, role: ROLES.USER }),
  routes.USER_PUBLIC_SKILL_PASSPORT.build({ companyId, teamId, role }),
  routes.USER_PUBLIC_PATH.build({ companyId, role: ROLES.COACH, teamId }),
  routes.TEAM_COACH.build({ companyId, role: ROLES.COACH, teamId }),
  routes.REPORTS_COACH_CUSTOM_REPORT.build({ companyId, role: ROLES.USER }),
  routes.REPORTS_USER_CUSTOM_REPORT.build({ companyId, role: ROLES.USER }),
];
