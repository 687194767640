import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { IconWithGradientBlock } from '~/components/IconWithGradientBlock';
import {
  ThemeWrapper,
  ThemeInfo,
  ThemeName,
  NumberOfQuestions,
  ThemeWeight,
} from '~/pages/ReviewTemplateView/components/styling';

import { useMultiLangString } from '~/hooks/useMultiLangString';

const ThemeCard = ({ theme }: { theme: any }) => {
  const getMultiLangString = useMultiLangString();
  const { i18n } = useLingui();
  return (
    <ThemeWrapper>
      <IconWithGradientBlock
        iconColor={theme?.iconColor}
        icon={theme.icon}
        iconSize={26}
        size={46}
      />
      <ThemeInfo>
        <ThemeName>{getMultiLangString(theme.name)}</ThemeName>
        <NumberOfQuestions>
          {theme.numberOfQuestions > 1
            ? i18n._(t`Includes ${theme.numberOfQuestions} questions`)
            : i18n._(t`Includes ${theme.numberOfQuestions} question`)}
        </NumberOfQuestions>
      </ThemeInfo>
      <ThemeWeight>{theme.weight}%</ThemeWeight>
    </ThemeWrapper>
  );
};

export { ThemeCard };
