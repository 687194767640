import React, { useEffect, useState } from 'react';

import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { Container, SearchPeers } from './design';

import { getUsers } from '~/selectors/baseGetters';
import getUserFullName from '~/utils/getUserFullName';

import type { IUser } from '@learned/types';

type InvitePeersProps = {
  selectedIds?: IUser['id'][];
  excludeIds?: IUser['id'][];
  onSelectedPeersIds?(ids: IUser['id'][]): void;
  externalPeers?: string[];
};

const InvitePeers = ({
  selectedIds = [],
  onSelectedPeersIds,
  excludeIds = [],
  externalPeers = [],
}: InvitePeersProps) => {
  const { i18n } = useLingui();

  const [items, setItems] = useState<IUser[]>([]);
  const [selectedItems, setSelectedItems] = useState<IUser[]>([]);
  const [search, setSearch] = useState('');

  const users: IUser[] = Object.values(useSelector(getUsers));

  useEffect(() => {
    const filteredUsers = users.filter(
      (user: IUser) => !externalPeers?.includes(user.email) && !excludeIds?.includes(user.id),
    );
    const selectedUsers = users.filter((user: IUser) => selectedIds?.includes(user.id));
    setItems(filteredUsers);
    setSelectedItems(selectedUsers);
    // eslint-disable-next-line
  }, [excludeIds, selectedIds]);

  const filteredItems = !isEmpty(search)
    ? items.filter(
        // Search word accepting special chars
        (item) => getUserFullName(item).toLowerCase().indexOf(search.trim().toLowerCase()) !== -1,
      )
    : items;

  return items ? (
    <Container>
      <SearchPeers
        selectedItems={selectedItems}
        items={filteredItems}
        onChange={(selectedItems: IUser[]) => {
          setSelectedItems(selectedItems);
          onSelectedPeersIds?.(selectedItems.map((item) => item.id));
        }}
        stringifyItem={(item: IUser) => getUserFullName(item)}
        placeholder={i18n._('Search peers')}
        hashItem={(item: IUser) => item.id}
        isExpandable
        onSearchChange={setSearch}
        showAvatar
        minCharsToShowDropdown={2}
        alreadySelectedError={i18n._('Please enter a user that has not been invited')}
      />
    </Container>
  ) : null;
};
export { InvitePeers };
