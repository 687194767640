import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const RowWrapper = styled.div<{ $hidePadding?: boolean; $margin?: string }>`
  padding: ${(props) => (props.$hidePadding ? '0' : '0 0 0 36px')};

  ${({ $margin }) =>
    $margin &&
    css`
      margin: ${$margin};
    `}
`;

export const Text = styled.div`
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
`;

export const Subtitle = styled.div`
  font-size: 12px;
  color: ${COLORS.SUBTEXT};
`;

export const Separator = styled.div`
  height: 1px;
  border-bottom: 1px solid ${COLORS.BORDERS};
  padding-top: 24px;
  margin: 0 0 0 36px;
`;
