import styled, { css } from 'styled-components';

import { Button } from '~/components/Buttons';
import { TableList } from '~/components/TableList';
import { StyledTop } from '~/components/TableList/design';

import { COLORS } from '~/styles';

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 26px;
  height: 40px;
  line-height: 1;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
  gap: 5px;

  span {
    color: ${COLORS.SUBTEXT};
  }
`;

export const SubTitle = styled.span`
  font-size: 16px;
  line-height: 1.5;
  color: ${COLORS.LABEL_GRAY};
`;

export const Footer = styled.div`
  border-top: 1px solid ${COLORS.BORDERS};
  height: 57px;
  min-height: 57px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 8px;
`;

export const StyledButton = styled(Button)`
  box-shadow: 0 2px 5px 0 ${COLORS.BOX_SHADOW_LIGHT};
`;

export const BackButton = styled(Button)`
  box-shadow: 0 2px 5px 0 ${COLORS.BOX_SHADOW_LIGHT};
  padding: 0 20px;
  min-width: 94px;
  gap: 8px;

  color: ${COLORS.SUBTEXT};

  &:not(:disabled) {
    &:hover {
      & .icon {
        transition: all 0.3s ease;
        transform: translateX(-6px);
      }
    }
  }
`;

export const StyledTableList = styled(TableList)`
  padding: 0;

  ${StyledTop} {
    padding-top: 20px;
    margin-bottom: 14px;
  }

  table thead,
  table tbody tr {
    display: table;
    width: 100%;
    table-layout: auto;
  }

  table tbody td {
    height: 44px;
    padding-left: 24px;
  }

  table tbody {
    display: block;
    max-height: 500px;
    overflow-y: auto;
  }

  table thead tr th,
  table thead tr th div {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  table thead tr th {
    box-sizing: border-box;
    height: 44px;
  }
`;

export const FlexRow = styled.div<{
  $isHidePointer?: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: ${({ $isHidePointer }) => (!$isHidePointer ? 'pointer' : 'default')};
  font-size: 14px;
`;

export const NameRow = styled.div<{ isCentered?: boolean; padding?: string }>`
  background-color: inherit;
  padding: ${({ padding }) => padding || 'unset'};
`;

export const TextRow = styled.div<{ isDraft?: boolean }>`
  color: ${({ isDraft }) => (isDraft ? COLORS.DARK_GRAY : COLORS.BLACK)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 594px;
  max-width: 100%;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
  }
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: -0.18px;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CloseIconButton = styled.div`
  cursor: pointer;
`;

export const ActionContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const StepLabel = styled.div`
  color: ${COLORS.CHARCOAL};
  font-size: 14px;
  align-content: center;

  span {
    color: ${COLORS.SUBTEXT};
  }
`;

export const SettingsContainer = styled.div`
  padding-top: 16px;
  min-height: 659px;
  max-height: 673px;
  overflow-y: auto;
`;

export const ToggleContainer = styled.div`
  display: flex;
  gap: 16px;
  color: ${COLORS.CHARCOAL};
  margin-bottom: 24px;
`;

export const JobSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > div:last-child {
    margin-bottom: 24px;
  }
`;

export const ContentContainer = styled.div<{ isLoading: boolean; selected?: boolean }>`
  overflow: visible;
  min-height: 659px;
  margin-bottom: 24px;

  ${({ isLoading, selected }) =>
    isLoading
      ? css`
          & > div {
            & > :nth-child(2) {
              min-height: ${selected ? '200px' : '350px'};
            }
          }
        `
      : ''}
`;
