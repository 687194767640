import React from 'react';
import type { MouseEventHandler } from 'react';

import styled, { css } from 'styled-components';

import Tooltip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import { MultiSelectCheckBox } from './MultiSelectCheckBox';

import { COLORS } from '~/styles';

export const Wrapper = styled.div<{
  color: string | undefined;
  isVisible?: boolean;
  isDisabled?: boolean;
}>`
  display: ${({ isVisible }) => (isVisible ? 'inline-flex' : 'none')};
  align-items: center;
  cursor: pointer;

  &:hover {
    display: inline-flex;
  }

  svg {
    min-width: 20px;
    min-height: 20px;
    stroke: ${(props) => (props.color ? props.color : COLORS.GRAY_MIDDLE)};
  }

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          pointer-events: none;
        `
      : ''}
`;

interface CheckBoxProps {
  isSelected?: boolean;
  onChange?: MouseEventHandler<HTMLDivElement>;
  color?: string;
  isHovered?: boolean;
  isDisabled?: boolean;
  tooltip?: string;
}

export const CheckBox = ({
  onChange,
  isSelected = false,
  color,
  isHovered = false,
  isDisabled,
  tooltip,
}: CheckBoxProps) => {
  const content = (
    <Wrapper
      isDisabled={isDisabled}
      onClick={!isDisabled ? onChange : undefined}
      color={color}
      isVisible={isSelected || isHovered}
    >
      <MultiSelectCheckBox selected={isSelected} color={color} />
    </Wrapper>
  );

  return tooltip ? (
    <Tooltip tooltip={tooltip} placement={TOOLTIP_PLACEMENTS.BOTTOM}>
      <div>{content}</div>
    </Tooltip>
  ) : (
    content
  );
};
