import React from 'react';

import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Controller, type UseFormReturn } from 'react-hook-form';

import { Dropdown } from '~/components/Dropdown';
import type { IQuestionForm } from '~/pages/ReviewThemeSetup/types';

import { BoldSpan, RequiredError } from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import {
  AnswerTypeContainer,
  Label,
  AnswerTypeWrapper,
  QuestionDescription,
} from '../EditableQuestion/design';

interface ISubSkillCategorySelectorProps {
  skillOrKpiCategory: { key: string; value: string; id?: string }[];
  formMethods: UseFormReturn<IQuestionForm>;
  skillCategoryWatch: {
    key: string;
    value: string;
    id?: string;
    type?: string;
  };
}

const SubSkillCategorySelector = ({
  skillOrKpiCategory,
  formMethods,
  skillCategoryWatch,
}: ISubSkillCategorySelectorProps) => {
  const getMultiLangString = useMultiLangString();
  const {
    control,
    formState: { errors },
  } = formMethods;

  return (
    <>
      <AnswerTypeContainer>
        <div>
          <Label>
            <Trans>Category</Trans>
          </Label>

          <Controller
            name="skillOrKpiCategory"
            rules={{ required: true }}
            control={control}
            render={({ field: { value, onChange } }) => (
              <AnswerTypeWrapper error={!!errors.skillOrKpiCategory}>
                <Dropdown
                  items={skillOrKpiCategory}
                  placeholder={i18n._(t`Select a category`)}
                  isSingleSelect
                  onChange={onChange}
                  stringifyItem={(item) => getMultiLangString(item ? item.value : '')}
                  selectedItem={value}
                />
              </AnswerTypeWrapper>
            )}
          />
        </div>
      </AnswerTypeContainer>
      {errors.skillOrKpiCategory && (
        <RequiredError>
          <Trans>Select a category</Trans>
        </RequiredError>
      )}
      {skillCategoryWatch !== null && (
        <QuestionDescription>
          {skillCategoryWatch?.type === 'kpis' ? (
            <Trans>
              A separate question will be generated for each KPI in the job profile of the member.
            </Trans>
          ) : (
            <Trans>
              A separate question will be generated for each skill or KPI of the selected category{' '}
              <BoldSpan>{skillCategoryWatch?.value}</BoldSpan> in the job profile of the member.
            </Trans>
          )}
        </QuestionDescription>
      )}
    </>
  );
};

export { SubSkillCategorySelector };
