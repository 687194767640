import React from 'react';

import {
  REVIEW_PARTICIPATION_COLUMNS,
  REVIEW_PARTICIPATION_REPORTS_COLUMNS,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { COLORS } from '~/styles';

export const PlaceholderText = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${COLORS.PLACEHOLDERS};
`;

export const StatusIcon = styled(Icon)`
  margin-right: 4px;
`;

const Placeholder = ({ columnId }: { columnId: REVIEW_PARTICIPATION_REPORTS_COLUMNS }) => {
  const { i18n } = useLingui();

  let text = '';
  let isIcon = false;

  switch (columnId) {
    case REVIEW_PARTICIPATION_COLUMNS.PEER_REVIEW: {
      text = i18n._(t`No peers asked`);
      isIcon = true;
      break;
    }

    case REVIEW_PARTICIPATION_COLUMNS.COACH_REVIEW: {
      text = i18n._(t`No coaches selected`);
      isIcon = true;
      break;
    }
    case REVIEW_PARTICIPATION_COLUMNS.PEER_REVIEW_PROVIDED:
    case REVIEW_PARTICIPATION_COLUMNS.COACH_REVIEW_PROVIDED: {
      text = i18n._(t`N/A`);
      isIcon = false;
      break;
    }
    default:
      break;
  }

  return (
    <PlaceholderText>
      {isIcon && (
        <StatusIcon icon={ICONS.WARNING_2} size={ICON_SIZES.SMALL} color={COLORS.ACCENT_WARNING} />
      )}
      {text}
    </PlaceholderText>
  );
};

export { Placeholder };
