import React from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { type UseFormReturn } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Input } from '~/components/Input';

import routes from '~/constants/routes';
import { useAsyncFetch } from '~/hooks/useAsyncFetch';
import useDebounce from '~/hooks/useDebounce';
import { checkEmail } from '~/services/users';
import { COLORS } from '~/styles';
import { EMAIL_PATTERN } from '~/utils/isValidEmail';

import { ButtonsWrapper, FormWrapper, Subtitle, Title, SectionWrapper } from '../design';
import { IRegisterWithCompanyForm } from '../types';

const AgreementWrapper = styled.div`
  margin: 60px 0 140px;
  width: 347px;
  text-align: center;
  & a {
    color: ${COLORS.COMPANY};
    text-decoration: none;
  }
  font-size: 14px;
`;

const AccountExistsMessageWrapper = styled.div`
  text-align: center;
  font-size: 14px;
`;

const LoginWrapper = styled.div`
  width: 226px;
  text-align: center;
  color: ${COLORS.TEXT_MAIN};
  font-size: 14px;
  & a {
    color: ${COLORS.COMPANY};
    text-decoration: none;
  }
`;

interface IMainSectionProps {
  formMethods: UseFormReturn<IRegisterWithCompanyForm>;
}

const MainSection = ({ formMethods }: IMainSectionProps) => {
  const history = useHistory();
  const { i18n } = useLingui();
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = formMethods;
  const { email } = watch();

  const debouncedEmail = useDebounce(email, 300);

  const [emailAlreadyRegistered, isLoading] = useAsyncFetch<boolean>(
    {
      fetch: async () => {
        if (!!errors.email || !watch('email')?.length) {
          return false;
        }
        const result = await checkEmail(debouncedEmail);

        return result?.data?.[API_RETURN_FIELDS.EMAIL_REGISTERED];
      },
      initialState: false,
    },
    [debouncedEmail],
  );

  const isEmailValid = !errors.email && watch('email')?.length;
  const showEmailAlreadyUsedError = isEmailValid && !isLoading && emailAlreadyRegistered;

  return (
    <SectionWrapper>
      <Title>
        <Trans>Welcome at Learned</Trans>
      </Title>
      <Subtitle>
        <Trans>Get started. Try Learned 14 days for free.</Trans>
      </Subtitle>
      <FormWrapper>
        <Input
          type="email"
          width="285px"
          label={i18n._(t`Work email`)}
          placeholder={i18n._(t`Emma@mycompany.com`)}
          register={register('email', {
            required: {
              value: true,
              message: i18n._(t`Email is not valid`),
            },
            pattern: {
              value: EMAIL_PATTERN,
              message: i18n._(t`Email is not valid`),
            },
          })}
          error={
            showEmailAlreadyUsedError ? i18n._(t`It seems like an account already exists`) : ''
          }
        />
        {showEmailAlreadyUsedError ? (
          <AccountExistsMessageWrapper>
            <Trans>
              Click ‘login’ and choose ‘Forgot password?’ in case you’ve forgotten your password.
            </Trans>
          </AccountExistsMessageWrapper>
        ) : (
          <></>
        )}
        <ButtonsWrapper>
          {showEmailAlreadyUsedError ? (
            <Button
              type="button"
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.NAVIGATION_PRIMARY}
              label={i18n._(t`Login`)}
              onClick={() => history.push('/')}
            />
          ) : (
            <Button
              type="button"
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.NAVIGATION_PRIMARY}
              label={i18n._(t`Next`)}
              disabled={!isEmailValid || isLoading || emailAlreadyRegistered}
              onClick={() => setValue('step', 2)}
            />
          )}
        </ButtonsWrapper>
      </FormWrapper>
      <AgreementWrapper>
        <Trans>
          By continuing you accept the{' '}
          <a
            href="https://www.learned.io/wp-content/uploads/2020/12/Learned-Algemene-Voorwaarden.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            T&C
          </a>
          {', '}
          <a href="https://www.learned.io/nl/privacy/" target="_blank" rel="noopener noreferrer">
            Privacy Statement
          </a>{' '}
          and{' '}
          <a
            href="https://www.learned.io/wp-content/uploads/2020/12/Learned-Verwerkersovereenkomst.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            processing agreement
          </a>
          .
        </Trans>
      </AgreementWrapper>
      <LoginWrapper>
        <Trans>
          Already have an account? <Link to={routes.HOME}>Login</Link>
        </Trans>
      </LoginWrapper>
    </SectionWrapper>
  );
};

export { MainSection };
