import React from 'react';

import { InformationBarWrapper } from './design';

interface IReviewInformationBarProps {
  reviewName: string;
  reviewStartDate: string;
}

const ReviewInformationBar: React.FC<IReviewInformationBarProps> = ({
  reviewName,
  reviewStartDate,
}) => {
  return (
    <InformationBarWrapper>
      <span className="review-name">{reviewName}</span>
      <span className="review-start-date">{reviewStartDate}</span>
    </InformationBarWrapper>
  );
};

export { ReviewInformationBar };
