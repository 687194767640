import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const SidebarSection = styled.div`
  font-size: 14px;
  border-bottom: 1px solid ${COLORS.BORDERS};
  padding-bottom: 24px;
`;

export const Separator = styled.div`
  height: 1px;
  border-bottom: 1px solid ${COLORS.BORDERS};
  padding-top: 24px;
`;

export const ListRow = styled.div<{ $isHovering?: boolean }>`
  display: flex;
  min-height: 32px;
  justify-content: space-between;
  margin-top: 5px;
  padding: 0 0 0 36px;
  border-radius: 6px;
  ${(props) =>
    props.$isHovering &&
    css`
      background-color: ${COLORS.WHITE};
    `}
`;

const RIGHT_BLOCK_WIDTH = '135px';
const MARGIN_LEFT = '20px';
export const RowRightBlock = styled.div`
  display: flex;
  align-items: center;
  max-width: calc(100% - ${RIGHT_BLOCK_WIDTH} - ${MARGIN_LEFT});
`;

export const RowLeftBlock = styled.div`
  display: flex;
  align-items: center;
  width: ${RIGHT_BLOCK_WIDTH};
  margin-left: ${MARGIN_LEFT};
`;

export const EvaluatorLabel = styled.div`
  margin-left: 5px;
  font-size: 12px;
  color: ${COLORS.PLACEHOLDERS};
`;
