import { JOB_STATUS } from '@learned/constants';

import { ICareerPlanPopulated } from './types';

export function getJobStatus(careerPlan: ICareerPlanPopulated) {
  const today = new Date().getTime();
  const startTime = new Date(careerPlan.startDate || careerPlan.meta.createdDate).getTime();
  const endTime = careerPlan.endDate ? new Date(careerPlan.endDate).getTime() : today;

  if (endTime && endTime < today) {
    return JOB_STATUS.PREVIOUS;
  } else if (startTime && startTime < today) {
    return JOB_STATUS.ACTIVE;
  } else if (startTime && startTime > today) {
    return JOB_STATUS.FUTURE;
  }

  return null;
}
