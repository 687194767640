import React, { memo } from 'react';

import { ISkillCategory } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Dropdown } from '~/components/Dropdown';
import { Icon, ICONS } from '~/components/Icon';
import Divider from '~/components/UI/Divider';

import {
  ActionContainer,
  Header,
  SubTitle,
  Title,
  TitleContainer,
  Footer,
  Content,
  Description,
  SectionTitle,
  CategoriesSection,
} from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

type CreateFromLucaProps = {
  title: string;
  subtitle?: string;
  description: string;
  loadingMessage: string;
  onClickManually?(): void;
  onClickUsingAI?(): void;
  onClose?(): void;
  loading?: boolean;
  categories?: ISkillCategory[];
  selectedCategory?: ISkillCategory;
  onSelectCategory?(item: ISkillCategory): void;
};

const CreateFromLuca = memo(
  ({
    title,
    subtitle,
    description,
    onClickManually,
    onClickUsingAI,
    onClose,
    loading,
    categories,
    onSelectCategory,
    loadingMessage,
    selectedCategory,
  }: CreateFromLucaProps) => {
    const { i18n } = useLingui();

    const getMultiLangString = useMultiLangString();

    return (
      <>
        <Header>
          <TitleContainer>
            <Title>{title}</Title>
            {subtitle && <SubTitle>{subtitle}</SubTitle>}
          </TitleContainer>
          <ActionContainer>
            <Button
              size={ButtonSize.MEDIUM}
              onClick={onClose}
              variant={ButtonVariant.CLOSE}
              disabled={loading}
            />
          </ActionContainer>
        </Header>

        <Divider $color={COLORS.BORDERS} />

        <Content>
          <Icon icon={ICONS.AI_COLORED} size={113} />

          {loading ? (
            <Description>{loadingMessage}</Description>
          ) : (
            <Description>{description}</Description>
          )}

          {categories && !loading && (
            <CategoriesSection>
              <SectionTitle>{i18n._(t`Add to category`)}</SectionTitle>
              <Dropdown<ISkillCategory>
                className="categories-dropdown"
                isSingleSelect
                selectedItem={selectedCategory}
                onChange={(item) => {
                  item && onSelectCategory?.(item);
                }}
                items={categories}
                stringifyItem={(item) => getMultiLangString(item.name)}
              />
            </CategoriesSection>
          )}
        </Content>

        <Footer>
          {onClickManually && (
            <Button
              label={i18n._(t`Do it manually`)}
              type="button"
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              onClick={() => onClickManually?.()} // Prevents sent click event data, only action
              disabled={loading}
            />
          )}
          {onClickUsingAI && (
            <Button
              label={i18n._(t`Use Luca AI`)}
              onClick={() => !loading && onClickUsingAI?.()}
              type="button"
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              isLoading={loading}
            />
          )}
        </Footer>
      </>
    );
  },
);

export { CreateFromLuca };
