import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div<{ isPDFView?: boolean }>`
  ${(props) => !props.isPDFView && 'width: 750px;'}
  background-color: ${COLORS.WHITE};
  padding: 24px 32px 26px;
  border-radius: 6px;
  margin-bottom: 46px;
  box-sizing: border-box;

  @media print {
    page-break-inside: avoid;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  @media print {
    page-break-after: avoid;
    page-break-inside: avoid;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${COLORS.TEXT_HOVER};
  font-weight: 500;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.SKY_BLUE};
  color: ${COLORS.WHITE};
  width: 24px;
  height: 24px;
  border-radius: 100px;
  margin-right: 9px;
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.08);

  @media print {
    /* having a box shadow makes a background square on some PDF readers, specially the reader in mac named "preview" */
    box-shadow: none;
  }
`;

export const NextStepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const NextStepCard = styled.div`
  display: flex;
  align-items: start;
  gap: 16px;
  background-color: ${COLORS.BG_LIST};
  border-radius: 8px;
  padding: 12px;
  box-sizing: border-box;
  margin-bottom: 8px;

  .main {
    width: 100%;
  }

  @media print {
    page-break-before: auto;
  }
`;

export const Content = styled.div<{
  isCompleted: boolean;
  color?: string;
}>`
  font-size: 14px;
  color: ${({ color }) => color || COLORS.TEXT_HOVER};
  text-decoration: ${({ isCompleted }) => (isCompleted ? 'line-through' : 'unset')};
  margin-bottom: 14px;
  word-break: break-all;
`;

export const Placeholder = styled.div<{ color?: string }>`
  color: ${({ color }) => color || 'unset'};
  position: relative;
  margin-top: 7px;
  background-color: ${COLORS.BG_LIST};
  border-radius: 6px;
  padding: 10px 14px;
  box-sizing: border-box;
  font-size: 14px;
  min-height: 48px;
  display: flex;
  align-items: center;

  & .arrow {
    position: absolute;
    top: -5px;
    left: 32px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid ${COLORS.BG_LIST};
  }
`;
