import styled from 'styled-components';

export const PreviousAnswerIndicatorWrapper = styled.div`
  display: flex;
  height: 18px;
  align-items: center;
  font-size: 12px;

  span.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    height: 18px;
    margin-right: 5px;
  }
`;
