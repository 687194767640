import React from 'react';

import { USER_REVIEW_HRIS_REPORT_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { format } from 'date-fns';

import Label from '~/components/Label';
import Tooltip from '~/components/Tooltip';
import {
  getLabelProps,
  SEND_REPORT_STATUS,
} from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/sidebarLeft/sections/Details/components/HRISSendReport';

import { CellViewWrapper } from '../../designs';

type CellViewReportStatusProps = {
  status?: USER_REVIEW_HRIS_REPORT_STATUS;
  message?: string;
  executionDate?: string;
};

const CellViewReportStatus = ({
  status = USER_REVIEW_HRIS_REPORT_STATUS.NOT_SENT,
  executionDate,
  message = '',
}: CellViewReportStatusProps) => {
  const { i18n } = useLingui();

  const statusTooltip =
    status === USER_REVIEW_HRIS_REPORT_STATUS.SENT && executionDate
      ? i18n._(t`Sent on: ${format(new Date(executionDate), 'dd-MM-yyyy')}`)
      : status === USER_REVIEW_HRIS_REPORT_STATUS.FAILED && message;

  return (
    <CellViewWrapper>
      <Tooltip tooltip={statusTooltip}>
        <div>
          <Label {...getLabelProps(status, true)} noBorder={false}>
            {SEND_REPORT_STATUS[status].children}
          </Label>
        </div>
      </Tooltip>
    </CellViewWrapper>
  );
};
export { CellViewReportStatus };
