import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  padding: 10px 0 3px;
`;

export const CheckBoxContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  min-width: 20px;
  padding: 0 25px 0;
  height: 100%;
`;

export const SelectedContainer = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${COLORS.COMPANY};
  border-radius: 6px;
`;

export const SelectText = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-transform: uppercase;
  color: ${COLORS.WHITE};
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const SelectActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  padding-right: 6px;
`;
