import React from 'react';

import { REVIEW_QUESTION_TYPES } from '@learned/constants';

import convertToTimeString from '~/utils/convertToTimeString';

import { Answers } from '../../../design';
import { FocusAreaAnswers } from '../../FocusAreaAnswers';
import { FocusAreaComments } from '../../FocusAreaComments';
import { QuestionSeparator } from '../components/design';
import { ReviewInformationBar } from '../components/ReviewInformationBar';
import { IAllPreviousAnswersProps } from '../types';

const AllSkillAnswers: React.FC<
  IAllPreviousAnswersProps<
    REVIEW_QUESTION_TYPES.SKILL_CATEGORY | REVIEW_QUESTION_TYPES.CUSTOM_SKILL
  >
> = ({ previousUserReviewWithQuestionAndAnswers, useMultiLangString }) => {
  const getMultiLangString = useMultiLangString();
  return (
    <>
      {previousUserReviewWithQuestionAndAnswers.map((userReview) => {
        const { questionsWithAnswers, reviewName, reviewStartDate } = userReview;
        return (
          <>
            <ReviewInformationBar
              reviewName={getMultiLangString(reviewName)}
              reviewStartDate={convertToTimeString(reviewStartDate)}
            />
            <Answers>
              {(questionsWithAnswers || []).map((faQuestion, index) => {
                return (
                  <FocusAreaAnswers
                    useMultiLangString={useMultiLangString}
                    key={index}
                    focusAreaQuestion={faQuestion}
                  />
                );
              })}
            </Answers>
            {questionsWithAnswers?.length && (
              <FocusAreaComments
                useMultiLangString={useMultiLangString}
                focusAreaQuestion={questionsWithAnswers.at(0)}
              />
            )}
            <QuestionSeparator />
          </>
        );
      })}
    </>
  );
};

export default AllSkillAnswers;
