import { ROLES } from '~/constants';
import routes from '~/constants/routes';

/* OLD routes from emails */
const ROUTE_TYPE = {
  PRIVATE: 'private',
  PUBLIC: 'public',
};

export default {
  USER_PROFILE: {
    urlOld: '/settings',
    url: () => routes.PROFILE_SETTINGS.build({ role: ROLES.USER }),
    type: ROUTE_TYPE.PRIVATE,
  },
  FEEDBACK_OVERVIEW_REVIEW: (reviewId = ':reviewId') => ({
    urlOld: `/plan/feedback/feedback-rounds/my-feedback-rounds/${reviewId}/feedback`,
    url: ({ reviewId } = {}) =>
      routes.USER_REVIEW_DASHBOARD.build({ role: ROLES.USER }, { userReviewId: reviewId }),
    type: ROUTE_TYPE.PRIVATE,
  }),
  FEEDBACK_OVERVIEW_RTFEEDBACK: (RTFeedbackId = ':RTFeedbackId') => ({
    urlOld: `/plan/real-time-feedback/${RTFeedbackId}`,
    url: () => routes.RTFEEDBACKS.build({ role: ROLES.USER }),
    type: ROUTE_TYPE.PRIVATE,
  }),
  FEEDBACK_GIVE: {
    urlOld: '/plan/feedback/incoming-requests',
    url: () => routes.TASKS.build({ role: ROLES.USER }),
    type: ROUTE_TYPE.PRIVATE,
  },
  TASKS: {
    urlOld: routes.TASKS.routePath(ROLES.USER),
    url: () => routes.DASHBOARD.build({ role: ROLES.USER }),
    type: ROUTE_TYPE.PRIVATE,
  },
  USER_RT_FEEDBACK_OUTSIDE: (RTFeedbackId = ':RTFeedbackId') => ({
    urlOld: `/real-time-feedback-outside/${RTFeedbackId}`,
    url: ({ rtfeedbackId } = {}) =>
      routes.RTFEEDBACKS_GIVE_FEEDBACK_OUTSIDE.build({}, { rtfeedbackId }),
    type: ROUTE_TYPE.PUBLIC,
  }),
  USER_REVIEW_OUTSIDE: () => ({
    urlOld: '/rate-outside',
    url: () => routes.REVIEW_GIVE_FEEDBACK_OUTSIDE.build(),
    type: ROUTE_TYPE.PUBLIC,
  }),
  REQUEST_RT_FEEDBACK: (requestId = ':requestId', RTFeedbackId = ':RTFeedbackId') => ({
    urlOld: `/plan/feedback/feedback-requests/${requestId}/real-time-feedback/${RTFeedbackId}`,
    url: ({ requestId, rtfeedbackId } = {}) =>
      routes.RTFEEDBACKS_GIVE_FEEDBACK.build(
        { role: ROLES.USER },
        {
          requestId,
          rtfeedbackId,
        },
      ),
    type: ROUTE_TYPE.PRIVATE,
  }),
  CONVERSATION: (conversationId = ':conversationId') => ({
    urlOld: `/conversation/${conversationId}`,
    url: ({ conversationId } = {}) =>
      routes.CONVERSATION.build({ role: ROLES.USER }, { conversationId }),
    type: ROUTE_TYPE.PRIVATE,
  }),
};
