import { joiResolver } from '@hookform/resolvers/joi';
import { REVIEW_TEMPLATE_STATUSES } from '@learned/constants';
import Joi from 'joi';

export const Errors = {
  notAllowed: 'notAllowed',
  missingPrimaryLang: 'missingPrimaryLang',
  themeWeightsTotalIncorrect: 'themeWeightsTotalIncorrect',
};

const schema = Joi.object({
  name: Joi.array()
    .items(
      Joi.object({
        locale: Joi.string(),
        value: Joi.string().allow(''),
      }),
    )
    .custom((items: { locale: string; value: string }[], helpers) => {
      const companyPrimaryLanguage: string | undefined =
        helpers?.prefs?.context?.companyPrimaryLanguage;
      if (
        companyPrimaryLanguage &&
        !items
          .filter((item) => !!item.value)
          .map((item) => item.locale)
          .includes(companyPrimaryLanguage)
      ) {
        return helpers.message({ custom: Errors.missingPrimaryLang });
      }
      return items;
    }),
  description: Joi.array().items(
    Joi.object({
      locale: Joi.string(),
      value: Joi.string().allow(''),
    }),
  ),
  status: Joi.string().valid(...Object.values(REVIEW_TEMPLATE_STATUSES)),
  questions: Joi.when(Joi.ref('status'), {
    is: REVIEW_TEMPLATE_STATUSES.PUBLISHED,
    then: Joi.array()
      .items(Joi.object({ questionId: Joi.string().required(), theme: Joi.string() }))
      .min(1)
      .required(),
    otherwise: Joi.array()
      .items(Joi.object({ questionId: Joi.string().required(), theme: Joi.string() }))
      .required(),
  }),
  themeWeights: Joi.array()
    .items(
      Joi.object({
        themeId: Joi.string(),
        enabled: Joi.boolean(),
        weight: Joi.number().min(0).max(100.001),
      }),
    )
    .custom((values: { themeId: string; weight: number; enabled: boolean }[], helpers) => {
      if (!Array.isArray(values) || values.filter(({ weight }) => weight > 0).length === 0) {
        return values;
      }

      const sum = values.reduce((acc, curr) => acc + curr.weight, 0);
      if (values.length > 0 && (sum < 99.999 || sum > 100.001)) {
        return helpers.message({ custom: Errors.themeWeightsTotalIncorrect });
      }

      return values;
    })
    .required(),
  isCustomWeight: Joi.boolean().optional(),
});

export const resolver = joiResolver(schema);
