import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function testIntegrationSettings(body) {
  return cloudRequest(serverRoutes.integrationSettings.testIntegrationSettings, {}, body);
}

export function createIntegrationSettings(body) {
  return cloudRequest(serverRoutes.integrationSettings.createIntegrationSettings, {}, body);
}

export function getCompanyIntegrationSettings(shouldReturnKomboInt, { forceRole } = {}) {
  return cloudRequest(
    serverRoutes.integrationSettings.getCompanyIntegrationSettings,
    {
      shouldReturnKomboInt,
    },
    null,
    {
      ...(forceRole && { forceRole }),
    },
  );
}

export function getIntegrationSettings(integrationId) {
  return cloudRequest(serverRoutes.integrationSettings.getIntegrationSettings(integrationId));
}

export function updateIntegrationSettings(integrationId, body) {
  return cloudRequest(
    serverRoutes.integrationSettings.updateIntegrationSettings(integrationId),
    {},
    body,
  );
}

export function deleteIntegrationSettings(integrationId) {
  return cloudRequest(serverRoutes.integrationSettings.deleteIntegrationSettings(integrationId));
}
